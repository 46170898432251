import React, {useEffect} from 'react';
import { View, StyleSheet, Text, Linking, TouchableOpacity } from 'react-native';
import { Card } from "react-native-elements";
import axios from "axios";
import {
    SERVER_API_URL,
	API_URL_CUSTOMER_ADDRESS
} from "../../config/config";

export const CustomerDetails = ({route}) => {
    const item = route.params.item;
    const [addressList, setAddressList] = React.useState([]);

	useEffect(() => {
		getCustomerAddress();
	}, [route]);

    const getCustomerAddress = async () => {
		await axios
			.get(
				`${SERVER_API_URL}${API_URL_CUSTOMER_ADDRESS}`,{
                headers: {
                    client : 'delishtic',
                    customerId : item.id
                }
            })
			.then((res) => {
                console.log('response', res.data);
                setAddressList(res.data);
            })
			.catch((err) => console.log(err));
	};
    return (
        <View>
            <Card style={{ flex: 1 }}>
                <Card.Title>{item.name}</Card.Title>
                <Card.Divider></Card.Divider>
                <Card.Title>Phone: {item.phoneNumber}</Card.Title>
                <Card.Title>Email: {item.email}</Card.Title>
            </Card>
            {addressList.map((address,index) => (
            <Card key={index}>
                <Card.Title>Address : {address.category.toUpperCase()}</Card.Title>
                <Card.Divider></Card.Divider>
                <Text style={styles.text}>Flat Number: {address.flatNumber}</Text>
                <Text style={styles.text}>Address: {address.address}</Text>
                <Text style={styles.text}>Landmark: {address.landmark}</Text>
                <Text style={styles.text}>Latitude: {address.latitude}</Text>
                <Text style={styles.text}>Longitude: {address.longitude}</Text>
                <TouchableOpacity style={styles.button} onPress={() => Linking.openURL(`https://www.google.com/maps/dir/?api=1&destination=${address.latitude}%2C${address.longitude}`)}>
                    <Card.Title>
                        Map
                    </Card.Title>
                </TouchableOpacity>
            </Card>
            ))}                
        </View>
    )
}

const styles = StyleSheet.create({
	text: {
		fontSize: 12,
	},
    button: {
        margin: 20
    }
});