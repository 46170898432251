import React, { useContext, useState } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	StyleSheet,
	FlatList,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { Card, Header } from "react-native-elements";
import Entypo from "react-native-vector-icons/Entypo";
import Feather from "react-native-vector-icons/Feather";
import { Ionicons, AntDesign } from "@expo/vector-icons";

import { Dimensions } from "react-native";
import { NavigationContainer, useFocusEffect } from "@react-navigation/native";
import axios from "axios";
import {
	SERVER_API_URL,
	API_URL_MENU_GROUPS,
	API_URL_LOCATIONS,
} from "../../config/config";
import { UserContext } from "../../src/context/userContext";

export const restaurantMenuGroup = ({ route, navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	const windowWidth = Dimensions.get("window").width;
	const [group, updateGroup] = useState([]);
	const [refresh, updateResfresh] = useState(false);
	// console.log(route);
	const [loading, setLoading] = useState(false);
	const restaurantId = route.params.id;
	const clientId = route.params.clientId;
	const businessType = route.params.businessType;

	const sortMenuGroup = (data) => {
		let sortedGroup = data.sort((a, b) => a.priority - b.priority);
		updateGroup(sortedGroup);
		setLoading(false);
	};

	const updatePriorityValue = async (currentGroup, swappedGroup) => {
		console.log("Current group ==", group[currentGroup]);
		console.log("Previous group ==", group[swappedGroup]);
		await axios
			.patch(
				`${SERVER_API_URL}${API_URL_MENU_GROUPS}/${group[currentGroup].id}`,
				{
					priority: parseInt(group[swappedGroup].priority, 10),
				},
				{
					headers: {
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) =>
				console.log("succesfully swapped current group priority", res.data)
			)
			.catch((err) => console.log(err));

		await axios
			.patch(
				`${SERVER_API_URL}${API_URL_MENU_GROUPS}/${group[swappedGroup].id}`,
				{
					priority: parseInt(group[currentGroup].priority, 10),
				},
				{
					headers: {
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => getMenuGroup())
			.catch((err) => console.log(err));

		console.log("called");
	};

	const getMenuGroup = async () => {
		setLoading(true);
		await axios
			.get(`${SERVER_API_URL}${API_URL_MENU_GROUPS}`, {
				headers: {
					client: clientId,
				},
			})
			.then((res) => {
				// updateGroup(res.data);
				sortMenuGroup(res.data);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};
	const deleteGroup = async (groupId) => {
		await axios
			.delete(`${SERVER_API_URL}${API_URL_LOCATIONS}/${groupId}`, {
				headers: {
					Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				},
			})
			.then(alert("Group Deleted"))
			.catch((err) => console.log(err));

		return updateResfresh(!refresh);
	};

	useFocusEffect(
		React.useCallback(() => {
			getMenuGroup();
			return () => {
				// Do something when the screen is unfocused
				// Useful for cleanup functions
			};
		}, [refresh])
	);
	const image = (item) => {
		if (item.image == undefined) {
			return;
		} else {
			return (
				<Card.Image
					source={{ uri: item.image }}
					resizeMode='cover'></Card.Image>
			);
		}
	};

	const renderItem = ({ item, index }) => {
		console.log("ITEM==", index);
		return (
			<TouchableOpacity
				key={item.id}
				onPress={() =>
					navigation.navigate("RestaurantMenuItem", {
						params: {
							id: route.params.id,
							item: item,
							menuGroupId: item.id,
							restaurantId: restaurantId,
							client: clientId,
							businessType: businessType,
						},
					})
				}>
				<View style={styles.mainView}>
					{image(item)}
					<View style={styles.itemView}>
						<Text style={styles.label}>Name:</Text>
						<Text style={styles.subTitle}>{item.name}</Text>
					</View>
					<View style={styles.itemView}>
						<Text style={styles.label}>Description:</Text>
						<Text style={styles.subTitle}> {item.description}</Text>
					</View>
					<View style={styles.itemView}>
						<Text style={styles.label}>Priority:</Text>
						<Text style={styles.subTitle}> {item.priority}</Text>
					</View>
					<View style={styles.buttons}>
						<View style={{ flexDirection: "row" }}>
							<TouchableOpacity onPress={() => deleteGroup(item.id)}>
								<Text>Delete</Text>
								<Ionicons name='trash-outline' size={30} color='red' />
							</TouchableOpacity>
							<TouchableOpacity
								style={{ marginLeft: 20 }}
								onPress={() =>
									navigation.navigate("AddMenuGroup", {
										item: item,
										type: "edit",
									})
								}>
								<Text>Edit</Text>
								<Entypo name='edit' size={30} color='green'></Entypo>
							</TouchableOpacity>
						</View>
						<View style={{ flexDirection: "row" }}>
							{index !== 0 && (
								<TouchableOpacity
									onPress={() => updatePriorityValue(index, index - 1)}
									style={{ marginRight: 5 }}>
									<AntDesign name='arrowup' size={30} color='green' />
								</TouchableOpacity>
							)}
							{index !== group.length - 1 && (
								<TouchableOpacity
									onPress={() => updatePriorityValue(index, index + 1)}>
									<AntDesign name='arrowdown' size={30} color='red' />
								</TouchableOpacity>
							)}
						</View>
					</View>
				</View>
			</TouchableOpacity>
		);
	};
	return (
		<>
			<Header
				centerComponent={{
					text: "Menu Group",
					style: { color: "#fff", fontSize: 20 },
				}}
				rightComponent={{
					text: "Back",
					style: { color: "#fff", fontSize: 20 },
					onPress: () => navigation.navigate("Restaurants/Shops"),
				}}></Header>
			<Card>
				<Text style={styles.text}>
					Restaurant Menu Group
					<TouchableOpacity
						style={styles.addButton}
						onPress={() =>
							navigation.navigate("AddMenuGroup", {
								id: restaurantId,
								clientId: clientId,
								type: "add",
							})
						}>
						<Entypo name='plus' size={30} color='green' />
						<Text>Add</Text>
					</TouchableOpacity>
				</Text>
			</Card>

			{loading == true ? (
				<ActivityIndicator size='large' color='#00ff00' />
			) : (
				<FlatList data={group} renderItem={renderItem}></FlatList>
			)}
		</>
	);
};
const styles = StyleSheet.create({
	text: {
		fontSize: 26,
		color: "green",
	},
	label: {
		fontSize: 20,
		fontWeight: "bold",
		marginRight: 4,
	},
	addButton: {
		paddingLeft: 140,
		paddingTop: 30,
	},
	CardText: {
		fontWeight: "bold",
		color: "red",
		fontSize: 15,
	},
	buttons: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	itemView: {
		flexDirection: "row",
		marginBottom: 10,
		alignItems: "center",
	},
	mainView: {
		margin: 10,
		marginHorizontal: 15,
		padding: 10,
		backgroundColor: "white",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.36,
		shadowRadius: 6.68,

		elevation: 11,
	},
	subTitle: {
		fontSize: 16,
		fontWeight: "400",
	},
});
