import React, { useEffect, useState, useContext } from "react";
import {
	Text,
	View,
	SafeAreaView,
	FlatList,
	ScrollView,
	StyleSheet,
	TouchableOpacity,
} from "react-native";
// import { getPromotionsList } from '../functions/Promotions'
import { getPromotionsList } from "../functions/Promotions";
import SinglePromotion from "../components/promotions/SinglePromotion";
import { Header } from "react-native-elements";
import axios from "axios";
//import SinglePromotion from '../components/SinglePromotion'
import RNPickerSelect from "react-native-picker-select";
import { AntDesign } from "@expo/vector-icons";
import { getRestaurantsList } from "../functions/Promotions";
import { getLocationsList } from "../functions/Promotions";
import { UserContext } from "../src/context/userContext";
import {
	SERVER_API_URL,
	API_URL_CLIENT,
	API_URL_PROMOTION,
} from "../config/config";
import { useFocusEffect } from "@react-navigation/native";

export default function PromotionsList({ navigation, route }) {
	const { state } = useContext(UserContext);
	const userType = state.userType;
	const assignedClient = state.user.attributes["custom:client"];
	const [list, setList] = useState([]);
	const [restaurantGroup, setRestaurantGroup] = useState("All");
	const [locationId, setLocationId] = useState("All");
	const [restaurants, setRestaurants] = useState([]);
	const [locations, setLocations] = useState([{ label: "All", value: "All" }]);
	const [client, updateClient] = React.useState([]);
	const [restructuredClient, updateRestructureClient] = React.useState([]);
	const [selectClient, updateClientSelect] = React.useState(
		assignedClient ? assignedClient : ""
	);

	useEffect(() => {
		let isSubscribed = true;
		getClient();
		loadRestaurants(selectClient);
		// getPromotions();

		return () => (isSubscribed = false);
	}, []);

	useFocusEffect(
		React.useCallback(() => {
			getPromotions();
		}, [navigation])
	);

	const getPromotions = async () => {
		console.log("runs");
		axios
			.get(`${SERVER_API_URL}${API_URL_PROMOTION}`, {
				headers: {
					client: selectClient,
					restaurantGroup: restaurantGroup,
					locationId: locationId,
				},
			})
			.then((res) => {
				console.log("list===", res.data);
				setList(res.data);
			})
			.catch((err) => {
				console.log("err is===", err);
				setList([]);
			});
	};

	const restructureClient = (item) => {
		let labels = [];
		for (var i = 0; i < item.length; i++) {
			labels[i] = { label: item[i].clientName, value: item[i].slug };
		}
		updateRestructureClient(labels);
	};
	const getClient = async () => {
		await axios
			.get(SERVER_API_URL + API_URL_CLIENT, {
				headers: {
					Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				},
			})
			.then((res) => {
				// console.log("client data", res.data);
				updateClient(res.data), restructureClient(res.data);

				loadRestaurants();
			})
			.catch((err) => console.log(err));
	};
	const loadRestaurants = (value) => {
		if (value) {
			getRestaurantsList(value)
				.then((res) => {
					// console.log("restaurant data===", res.data);
					restructureRestaurant(res.data);
				})
				.catch((err) => {
					console.log("err", err);
				});
		}
	};
	const restructureRestaurant = (item) => {
		let labels = [{ label: "All", value: "All" }];
		for (let i = 0; i < item.length; i++) {
			labels[i + 1] = { label: item[i].name, value: item[i].id };
		}

		setRestaurants(labels);
		setRestaurantGroup(labels[1].value);
	};
	const loadLocations = (value) => {
		getLocationsList(value)
			.then((res) => {
				// console.log("location list=", res.data)
				restructureLocation(res.data);
			})
			.catch((err) => {
				console.log("err", err);
			});
	};
	const restructureLocation = (item) => {
		let labels = [{ label: "All", value: "All" }];
		for (let i = 0; i < item.length; i++) {
			labels[i + 1] = { label: item[i].name, value: item[i].id };
		}
		console.log("labels=", labels);

		setLocations(labels);
		setLocationId(labels[1].value);
	};
	return (
		<SafeAreaView style={styles.areaview}>
			{/* <Header
				leftComponent={{
					icon: "menu",
					color: "#fff",
					onPress: () => navigation.openDrawer(),
				}}
				centerComponent={{ text: "PROMOTIONS", style: { color: "#fff" } }}
				rightComponent={{
					icon: "home",
					color: "#fff",
					onPress: () => navigation.navigate("Dashboard"),
				}}></Header> */}
			<View style={styles.listbox}>
				<View style={styles.box}>
					<Text style={styles.heading}>PROMOTIONS LIST</Text>
					{userType === "Admin" && (
						<View style={styles.inputContainer}>
							<Text style={styles.text}>Client Name</Text>

							<RNPickerSelect
								fixAndroidTouchableBug={true}
								style={pickerSelectStyles}
								useNativeAndroidPickerStyle={false}
								placeholder={{
									label: "Select client",
									color: "black",
								}}
								items={restructuredClient}
								onValueChange={(value) => {
									console.log("status value is", value);
									updateClientSelect(value);
									loadRestaurants(value);
								}}
							/>
							<Text></Text>
						</View>
					)}

					<View style={styles.inputContainer}>
						<Text style={styles.text}>Restaurant / Shop Name</Text>
						<RNPickerSelect
							onValueChange={(value) => {
								setRestaurantGroup(value);
								console.log("id=", value);
								loadLocations(value);
							}}
							value={restaurantGroup}
							items={restaurants}
							placeholder={{}}
							useNativeAndroidPickerStyle={false}
							fixAndroidTouchableBug={true}
							style={pickerSelectStyles}
						/>
						<Text></Text>
					</View>
					<View style={styles.inputContainer}>
						<Text style={styles.text}>Location</Text>
						<RNPickerSelect
							onValueChange={(value) => setLocationId(value)}
							value={locationId}
							items={locations}
							placeholder={{}}
							useNativeAndroidPickerStyle={false}
							fixAndroidTouchableBug={true}
							style={pickerSelectStyles}
						/>
						<Text style={styles.errorText}></Text>
					</View>
				</View>
				<TouchableOpacity
					onPress={() => navigation.navigate("Promotion", { id: "" })}
					style={styles.button}>
					<Text style={styles.buttontext}>
						<AntDesign name='plus' size={18} color='white' />
						Add Promotion
					</Text>
				</TouchableOpacity>
				<View style={{ alignItems: "center" }}>
					<TouchableOpacity onPress={getPromotions} style={styles.button}>
						<Text style={styles.buttonText}>Show Promotions</Text>
					</TouchableOpacity>
				</View>
			</View>

			<FlatList
				data={list}
				renderItem={({ item }) => (
					<SinglePromotion sp={item} navigation={navigation} />
				)}
				keyExtractor={(item) => item.id}
			/>
		</SafeAreaView>
	);
}
const styles = StyleSheet.create({
	areaview: {
		flex: 1,
		//  alignItems: "center"
		//  paddingTop: StatusBar.currentHeight,
	},
	listbox: {
		//  backgroundColor: "grey",
		//flex: 1,
		alignItems: "center",
	},
	heading: {
		textAlign: "center",
		fontSize: 22,
		fontWeight: "bold",
		marginTop: 20,
		marginBottom: 25,
	},
	inputContainer: {
		// backgroundColor: "green",
		marginBottom: 2,
		maxWidth: 650,
	},
	text: {
		fontSize: 20,
	},
	errorText: {
		color: "red",
	},
	box: {
		//  backgroundColor: "pink",
		width: 300,
		marginBottom: 10,
		borderRadius: 10,
		paddingHorizontal: 5,
		paddingVertical: 5,
		fontSize: 18,
	},
	button: {
		//flex: 1,
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 35,
		backgroundColor: "green",
		padding: 6,
		fontWeight: "600",
		textAlign: "center",
		//backgroundColor: "yellow"
		marginBottom: 20,
		// paddingHorizontal: 20,
		width: 300,
		//  marginLeft: 10,
	},
	buttontext: {
		fontSize: 18,
		// fontWeight: "700",
		color: "white",
		marginLeft: 10,
	},
});
const pickerSelectStyles = StyleSheet.create({
	inputIOS: {
		borderColor: "grey",
		borderWidth: 1,
		paddingVertical: 1.5,
		paddingHorizontal: 4,
		paddingRight: 30,
		color: "black",
	},
	inputAndroid: {
		borderColor: "grey",
		color: "black",
		borderWidth: 1,
		paddingVertical: 2,
		paddingHorizontal: 5,
		paddingRight: 30,
	},
});
