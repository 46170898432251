const BannerDataEditable = [
	{
		id: 1,
		url: require("../assets/solid1.jpg"),
		imgPath: "../assets/solid1.jpg",
	},
	{
		id: 2,
		url: require("../assets/solid2.jpg"),
		imgPath: "../assets/solid2.jpg",
	},
	{
		id: 3,
		url: require("../assets/solid3.jpg"),
		imgPath: "../assets/solid3.jpg",
	},
	{
		id: 4,
		url: require("../assets/solid4.jpg"),
		imgPath: "../assets/solid4.jpg",
	},
	{
		id: 5,
		url: require("../assets/solid5.jpg"),
		imgPath: "../assets/solid5.jpg",
	},
	{
		id: 6,
		url: require("../assets/solid6.jpg"),
		imgPath: "../assets/solid6.jpg",
	},
	{
		id: 7,
		url: require("../assets/solid7.jpg"),
		imgPath: "../assets/solid7.jpg",
	},
	{
		id: 8,
		url: require("../assets/solid8.jpg"),
		imgPath: "../assets/solid8.jpg",
	},
	{
		id: 9,
		url: require("../assets/solid9.jpg"),
		imgPath: "../assets/solid9.jpg",
	},
];
export default BannerDataEditable;
