import {
  Dimensions,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Image,
  ScrollView,
  Platform,
} from "react-native";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BottomSheet } from "react-native-btr";
import { Feather } from "@expo/vector-icons";
import { Entypo } from "@expo/vector-icons";
import ProductUnitData from "../ProductUnitData";
import { Modal, Portal, Provider, TextInput } from "react-native-paper";
import * as ImagePicker from "expo-image-picker";
import { Camera } from "expo-camera";
import { Ionicons } from "@expo/vector-icons";
import { UserContext } from "../../src/context/userContext";
import {
  API_URL_CLIENT,
  API_URL_MENU_GROUPS,
  API_URL_MENU_ITEMS,
  API_URL_RESTAURANT,
  SERVER_API_URL,
} from "../../config/config";
import resizeImage from "../../utils/resizeImage";
import { uploadImage } from "../../utils/utils";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const AddEditProducts = ({ navigation, route }) => {
  const { state, dispatch } = useContext(UserContext);
  const assignedClient = state.user.attributes["custom:client"];
  const [client, setClient] = useState(assignedClient ? assignedClient : "");
  const selectedItem = route.params?.item;
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);
  const [visible, setVisible] = useState(false);
  const [productCategoryModal, setProductCategoryModal] = useState(false);
  const [productDetailsBottomSheet, setProductDetailsBottomSheet] =
    useState(false);
  const [productCategoryBottomSheet, setProductCategoryBottomSheet] =
    useState(false);
  const [mrp, setMrp] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const toggleBottomNavigationView = () => {
    setVisible(!visible);
  };
  const toggleProductDetailsBottomSheet = () => {
    setProductDetailsBottomSheet(!productDetailsBottomSheet);
  };

  const toggleProductCategoryDetailsBottomSheet = () => {
    setProductCategoryBottomSheet(!productCategoryBottomSheet);
  };
  const [productUnitName, setProductUnitName] = useState("");
  const [productUnitData, setProductUnitData] = useState([]);
  const [productUnit, setProductUnit] = useState("");
  const [productDetails, setProductDetails] = useState("");
  const [hasPermission, setHasPermission] = useState(null);
  const [restaurants, setRestaurants] = useState([]);
  // const [priority, setPriority] = useState("");
  // const [calories, setCalories] = useState("");
  const [restaurantId, setRestaurantId] = useState("");
  const [menuGroupList, setMenuGroupList] = useState([]);
  const [productCategory, setProductCategory] = useState("");
  const [productCategoryName, setProductCategoryName] = useState("");
  const [productNameError, setProductNameError] = useState(false);
  const [productCategoryError, setProductCategoryError] = useState(false);
  // const [productUnitError, setProductUnitError] = useState(false);
  // const [productDetailsError, setProductDetailsError] = useState(false);
  const [priceError, setPriceError] = useState(false);

  const [discountedPriceError, setDiscountedPriceError] = useState(false);
  const cam = useRef();
  const [image, setImage] = useState([]);

  useEffect(() => {
    setProductUnitData(ProductUnitData);
    setProductUnitName(ProductUnitData[0].name);
  }, []);

  useEffect(() => {
    (async () => {
      if (Platform.OS !== "web") {
        const { status } = await Camera.requestCameraPermissionsAsync();
        setHasPermission(status === "granted");
      }
    })();
  }, []);

  useEffect(() => {
    console.log(selectedItem);
    if (selectedItem) {
      setName(selectedItem.name);
      setRestaurantId(selectedItem.restaurantGroup);
      setProductCategory(selectedItem.menuGroupId);
      const originalPrice =
        selectedItem && selectedItem.originalPrice
          ? selectedItem.originalPrice
          : selectedItem.price;
      setMrp(String(originalPrice));
      if (selectedItem && selectedItem.originalPrice) {
        setDiscountedPrice(String(selectedItem.price));
      }
      setProductDetails(selectedItem.description);
      setImage(selectedItem.images);
    }
  }, [route.params]);

  const removeImage = (element) => {
    let filteredImage = image.filter((elem) => elem !== element);
    setImage(filteredImage);
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      //allowsMultipleSelection: true,
    });
    // console.log("file size is ", filesize);
    if (!result.cancelled) {
      console.log("result", result);
      let img = await resizeImage(result.uri, 75, { height: 400, width: 400 });
      console.log("img is ", img);
      const remoteImagePath = await uploadImage(uuidv4(), img);
      if (remoteImagePath) {
        setImage([...image, remoteImagePath]);
        hideModal();
      }
    }
  };

  const restructureRestaurant = (item) => {
    let labels = [];
    if (item.length) {
      for (let i = 0; i < item.length; i++) {
        labels[i] = { label: item[i].name, value: item[i].id };
      }
    }
    // setRestaurants(labels);
    console.log("business Details", item[0]);
    setRestaurantId(labels[0].value);
    setCategory(item[0].businessType === "shop" ? "product" : "food");
    loadMenuGroups(labels[0].value);
    console.log("labels=", labels);
  };
  const loadRestaurants = async (value) => {
    await axios
      .get(`${SERVER_API_URL}${API_URL_RESTAURANT}${API_URL_CLIENT}`, {
        headers: {
          client: value,
        },
      })
      .then((res) => {
        //console.log("clients=", res.data);
        restructureRestaurant(res.data);
      })
      .catch((err) => {
        console.log("err2=", err);
        setRestaurants([]);
      });
  };
  const restructureMenuGroups = (items) => {
    let labels = [];
    items.sort((a, b) => a.priority - b.priority);
    if (items.length) {
      for (let i = 0; i < items.length; i++) {
        labels[i] = { label: items[i].name, value: items[i].id };
      }
    }
    //console.log("labels", labels);
    setMenuGroupList(labels);
    // setMenuGroup(labels[0].value);
    // setMenuGroupName(labels[0].label);
    // setMenuGroupId(labels[0].value);
  };
  const loadMenuGroups = async (value) => {
    await axios
      .get(`${SERVER_API_URL}${API_URL_MENU_GROUPS}`, {
        headers: {
          restaurantGroup: value,
        },
      })
      .then((res) => {
        //console.log("menuGroups=", res.data);
        restructureMenuGroups(res.data);
      })
      .catch((err) => {
        console.log("err4=", err);
        setMenuGroupList([]);
      });
  };
  const containerStyle = {
    backgroundColor: "white",
    padding: 10,
    marginHorizontal: 10,
    paddingHorizontal: 20,
  };

  useEffect(() => {
    if (assignedClient) {
      loadRestaurants(assignedClient);
    }
  }, []);
  const addItem = async () => {
    let payload = {
      name: name,
      itemType: category,
      images: image,
      price: discountedPrice ? parseInt(discountedPrice) : parseInt(mrp),
      description: productDetails,
    };

    const headers = {
      restaurantgroup: restaurantId,
      menugroupid: productCategory,
      client,
      Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
    };

    if (discountedPrice) {
      payload.originalPrice = parseInt(mrp);
    }

    console.log("Payload is", payload);
    console.log("Headers is ", headers);

    if (selectedItem) {
      const endPoint = `${SERVER_API_URL}${API_URL_MENU_ITEMS}/${selectedItem.id}`;
      console.log("endPoint is", endPoint);

      await axios
        .patch(endPoint, payload, {
          headers,
        })
        .then((res) => {
          // alert("Menu item created Check respective Restaurant");

          console.log(res.data);
          navigation.navigate("Add/Edit Items", {
            menuGroupId: selectedItem.menuGroupId,
            restaurantGroup: selectedItem.restaurantGroup,
          });
        })
        .catch((err) => {
          console.log(err);
          alert("Error in saving the product. Please try again!");
        });
    } else {
      const endPoint = `${SERVER_API_URL}${API_URL_MENU_ITEMS}`;
      console.log("endPoint is", endPoint);

      await axios
        .post(endPoint, payload, {
          headers,
        })
        .then((res) => {
          // alert("Menu item created Check respective Restaurant");

          console.log(res.data);
          navigation.navigate("Add/Edit Items");
        })
        .catch((err) => {
          console.log(err);
          alert("Error in saving the product. Please try again!");
        });
    }
  };

  const updateData = async (id) => {
    await axios
      .patch(
        `${SERVER_API_URL}${API_URL_MENU_ITEMS}/${id}`,
        {
          name: name,
          itemType: category,
          images: image,
          price: parseInt(mrp),
          description: productDetails,
          // options: [],
          // additionalDescriptions: additional,
          // calories: calories,
          // dietaryRestrictions: collectionsArr,
          // priority: priority,
          // options: [],
        },
        {
          headers: {
            Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
          },
        }
      )
      .then((res) => {
        alert("Info changed");

        console.log(res.data);
      })
      .catch((err) => console.log(err));

    return navigation.navigate("menuRestaurantGroup");
  };
  const createMenuGroup = async () => {
    await axios
      .post(
        `${SERVER_API_URL}${API_URL_MENU_GROUPS}`,
        {
          name: productCategory,
          // priority: menuGroupPriority,
        },
        {
          headers: {
            restaurantGroup: restaurantId,
            client: assignedClient,
            Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
          },
        }
      )
      .then((res) => {
        alert("Menu Group created");
        setProductCategoryModal(false);
        console.log(res.data);
      })
      .catch((err) => {
        setProductCategoryModal(false);
        console.log(err);
      });
  };
  return (
    <>
      <Provider>
        {/* <BottomSheet
          visible={visible}
          onBackButtonPress={toggleBottomNavigationView}
          onBackdropPress={toggleBottomNavigationView}
        >
          <View style={{ backgroundColor: "#fff", width: "100%", height: 400 }}>
            <View>
              <View
                style={{
                  paddingVertical: 8,
                  margin: 10,
                  marginHorizontal: 5,
                  borderBottomWidth: 0.2,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  borderColor: "lightgrey",
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Choose Product Unit{" "}
                </Text>

                <Entypo
                  onPress={() => {
                    // setCategory(elem.name);
                    toggleBottomNavigationView();
                  }}
                  style={{ position: "absolute", right: 10 }}
                  name="cross"
                  size={24}
                  color="black"
                />
              </View>
            </View>
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              {productUnitData.map((elem, index) => {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      setProductUnitName(elem.name);
                      toggleBottomNavigationView();
                    }}
                    key={index}
                    style={{
                      margin: 10,
                      marginHorizontal: 5,
                      borderWidth: 0.3,
                      padding: 10,
                      borderRadius: 8,
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      borderColor: "lightgrey",
                      marginHorizontal: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {elem.name}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </View>
          </View>
        </BottomSheet> */}

        <BottomSheet
          visible={productDetailsBottomSheet}
          onBackButtonPress={toggleProductDetailsBottomSheet}
          onBackdropPress={toggleProductDetailsBottomSheet}
        >
          <View style={{ backgroundColor: "#fff", width: "100%", height: 400 }}>
            <View>
              <View
                style={{
                  paddingVertical: 8,
                  margin: 10,
                  marginHorizontal: 5,
                  borderBottomWidth: 0.2,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  borderColor: "lightgrey",
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Product Details{" "}
                </Text>

                <Entypo
                  onPress={() => {
                    // setCategory(elem.name);
                    toggleProductDetailsBottomSheet();
                  }}
                  style={{ position: "absolute", right: 10 }}
                  name="cross"
                  size={24}
                  color="black"
                />
              </View>
            </View>
            <TextInput
              style={{
                ...styles.textInput,
                marginHorizontal: 10,
                borderWidth: 1,
                borderColor: "grey",
                paddingVertical: 15,
              }}
              underlineColorAndroid="transparent"
              label="Product Details "
              autoCapitalize="none"
              onChangeText={(value) => {
                setProductDetails(value);
              }}
              theme={{ colors: { primary: "#136eb4" } }}
              value={productDetails}
            />

            <TouchableOpacity
              onPress={() => {
                setProductDetailsBottomSheet(false);
              }}
              style={{
                borderWidth: 2,
                borderColor: "#e18846",
                padding: 10,
                paddingHorizontal: 20,
                margin: 20,
                marginHorizontal: 20,
                borderRadius: 8,
                position: "absolute",
                bottom: 0,
                left: "50%",
                transform: [{ translateX: -50 }],
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  color: "#e18846",
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                Done{" "}
              </Text>
            </TouchableOpacity>
          </View>
        </BottomSheet>

        <BottomSheet
          visible={productCategoryBottomSheet}
          onBackButtonPress={toggleProductCategoryDetailsBottomSheet}
          onBackdropPress={toggleProductCategoryDetailsBottomSheet}
        >
          <View style={{ backgroundColor: "#fff", width: "100%", height: 400 }}>
            <View>
              <View
                style={{
                  paddingVertical: 8,
                  margin: 10,
                  marginHorizontal: 5,
                  borderBottomWidth: 0.2,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  borderColor: "lightgrey",
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Product Category{" "}
                </Text>

                <Entypo
                  onPress={() => {
                    // setCategory(elem.name);
                    toggleProductCategoryDetailsBottomSheet();
                  }}
                  style={{ position: "absolute", right: 10 }}
                  name="cross"
                  size={24}
                  color="black"
                />
              </View>
            </View>
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              {menuGroupList.map((elem, index) => {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      setProductCategory(elem.value);
                      setProductCategoryName(elem.label);
                      toggleProductCategoryDetailsBottomSheet();
                    }}
                    key={index}
                    style={{
                      margin: 10,
                      marginHorizontal: 5,
                      borderWidth: 0.3,
                      padding: 10,
                      borderRadius: 8,
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      borderColor: "lightgrey",
                      marginHorizontal: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {elem.label}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </View>

            <TouchableOpacity
              onPress={() => {
                toggleProductCategoryDetailsBottomSheet(false);

                setProductCategoryModal(true);
              }}
              style={{
                borderWidth: 2,
                borderColor: "#e18846",
                padding: 10,
                paddingHorizontal: 20,
                // margin: 20,
                marginHorizontal: 20,
                borderRadius: 8,
                // position: "absolute",
                bottom: 50,
                width: "70%",
                marginLeft: 60,
                marginTop: 130,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  color: "#e18846",
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                Add New Category{" "}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                toggleProductCategoryDetailsBottomSheet(false);
              }}
              style={{
                borderWidth: 2,
                borderColor: "#e18846",
                padding: 10,
                paddingHorizontal: 20,
                margin: 20,
                marginHorizontal: 20,
                borderRadius: 8,
                position: "absolute",
                bottom: 0,
                left: "45%",
                transform: [{ translateX: -45 }],
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  color: "#e18846",
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                Done{" "}
              </Text>
            </TouchableOpacity>
          </View>
        </BottomSheet>

        <Portal>
          <Modal
            visible={modalVisible}
            onDismiss={hideModal}
            contentContainerStyle={containerStyle}
          >
            <View>
              <View
                style={{
                  paddingVertical: 8,
                  margin: 10,
                  marginHorizontal: 5,
                  borderBottomWidth: 0.2,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  borderColor: "lightgrey",
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Upload product image
                </Text>

                <Entypo
                  onPress={hideModal}
                  style={{ position: "absolute", right: 10 }}
                  name="cross"
                  size={24}
                  color="black"
                />
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {Platform.OS !== "web" && (
                <TouchableOpacity
                  onPress={() => {
                    // setDone(false);
                    // setSelfieModal(true);
                    ImagePicker.launchCameraAsync({
                      allowsEditing: true,
                      aspect: [4, 4],
                    })
                      .then(async (res) => {
                        if (res.cancelled == true) {
                          return;
                        }
                        console.log("Res is ", res);
                        let img = await resizeImage(res.uri, 20, {
                          height: 400,
                          width: 400,
                        });
                        console.log("img is ", img);
                        const remoteImagePath = await uploadImage(
                          uuidv4(),
                          img
                        );
                        if (remoteImagePath) {
                          setImage([...image, remoteImagePath]);
                          hideModal();
                        }
                      })
                      .catch((err) => {
                        console.log("first err is ", err);
                      });
                  }}
                  style={{
                    padding: 15,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Feather name="camera" size={30} color="black" />
                  <Text>Camera</Text>
                </TouchableOpacity>
              )}
              <View
                style={{
                  width: 1.6,
                  backgroundColor: "lightgrey",
                  height: 100,
                }}
              ></View>
              <TouchableOpacity
                onPress={() => pickImage()}
                style={{
                  padding: 15,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Feather name="image" size={30} color="black" />
                <Text>Gallery</Text>
              </TouchableOpacity>
            </View>
          </Modal>
        </Portal>

        <Portal>
          <Modal
            visible={productCategoryModal}
            onDismiss={() => setProductCategoryModal(false)}
            contentContainerStyle={containerStyle}
          >
            <View>
              <TextInput
                style={styles.textInput}
                underlineColorAndroid="transparent"
                label="Product Category"
                autoCapitalize="none"
                onChangeText={(value) => {
                  setProductCategoryName(value);
                }}
                theme={{ colors: { primary: "#136eb4" } }}
                value={productCategoryName}
              />
              <TouchableOpacity
                onPress={() => setProductCategoryModal(false)}
                style={{
                  backgroundColor: "#136eb4",
                  padding: 10,
                  borderRadius: 8,
                }}
              >
                <Text
                  onPress={() => createMenuGroup()}
                  style={{ color: "white", textAlign: "center" }}
                >
                  Done
                </Text>
              </TouchableOpacity>
            </View>
          </Modal>
        </Portal>
        <ScrollView>
          <View
            style={{
              padding: 10,
              flex: 1,
              backgroundColor: "white",
              minHeight: Dimensions.get("screen").height,
              paddingTop: 20,
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <TouchableOpacity
                onPress={showModal}
                style={{
                  padding: 10,
                  borderWidth: 0.8,
                  width: 100,
                  height: 90,
                  borderRadius: 8,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Feather name="camera" size={30} color="black" />
              </TouchableOpacity>
              <ScrollView horizontal={true}>
                {image.length > 0 &&
                  image.map((elem) => (
                    <View
                      key={elem}
                      style={{
                        marginHorizontal: 5,
                        borderColor: "black",
                        borderWidth: 1,
                        padding: 0,
                        borderStyle: "dashed",
                        borderRadius: 10,
                        position: "relative",
                        marginBottom: 10,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => removeImage(elem)}
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          zIndex: 10,
                        }}
                      >
                        <Entypo name="cross" size={24} color="black" />
                      </TouchableOpacity>

                      <Image
                        source={{ uri: elem }}
                        style={{
                          width: 90,
                          height: 90,
                          margin: 10,
                          borderRadius: 8,
                        }}
                      />
                    </View>
                  ))}
              </ScrollView>
            </View>
            {productNameError && (
              <Text style={{ color: "red", fontSize: 12, marginTop: 5 }}>
                Product name is required
              </Text>
            )}
            <TextInput
              style={styles.textInput}
              underlineColorAndroid="transparent"
              label="Product Name"
              autoCapitalize="none"
              onChangeText={(value) => {
                setName(value);
                if (value.length > 0) {
                  setProductNameError(false);
                } else {
                  setProductNameError(true);
                }
              }}
              theme={{ colors: { primary: "#136eb4" } }}
              value={name}
            />
            {/* <TextInput
					onFocus={() => toggleProductCategoryDetailsBottomSheet()}
					style={styles.textInput}
					underlineColorAndroid='transparent'
					label='Product Category '
					autoCapitalize='none'
					onChangeText={(value) => setCategory(value)}
					theme={{ colors: { primary: "#136eb4" } }}
					value={category}
				/> */}
            {productCategoryError && (
              <Text style={{ color: "red", fontSize: 12, marginTop: 5 }}>
                Please select product category
              </Text>
            )}
            {!selectedItem && (
              <TouchableOpacity
                onPress={() => setProductCategoryBottomSheet(true)}
                style={{
                  borderBottomWidth: 0.6,
                  borderColor: "lightgrey",
                  marginTop: 20,
                  paddingBottom: 10,
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    fontSize: 13,
                    marginVertical: 10,
                    color: "grey",
                    marginBottom: 6,
                  }}
                >
                  Product Category{" "}
                </Text>
                <Text
                  style={{
                    fontSize: 18,
                    marginRight: 15,
                    color: `${productDetails.length > 0 ? "black" : "grey"}`,
                  }}
                >
                  {productCategoryName.length > 0
                    ? productCategoryName
                    : "Enter product Category"}
                </Text>
              </TouchableOpacity>
            )}
            {discountedPriceError && (
              <Text style={{ color: "red", fontSize: 12, marginTop: 5 }}>
                Discounted price cannot be greater than MRP
              </Text>
            )}
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <View>
                {priceError && (
                  <Text style={{ color: "red", fontSize: 12, marginTop: 5 }}>
                    Price is required
                  </Text>
                )}
                <TextInput
                  onChangeText={(value) => {
                    if (value.length > 0) {
                      setPriceError(false);
                    } else {
                      setPriceError(true);
                    }
                    setMrp(value);
                  }}
                  style={{
                    ...styles.textInput,
                    width: 150,
                    // marginRight: 10,
                    // flexGrow: 1,
                  }}
                  underlineColorAndroid="transparent"
                  label="MRP"
                  autoCapitalize="none"
                  theme={{ colors: { primary: "#136eb4" } }}
                  value={mrp}
                  keyboardType="numeric"
                />
              </View>

              <TextInput
                onChangeText={(value) => {
                  console.log("value ", value);
                  // discount price cannot be greater than MRP
                  if (value.length > 0) {
                    if (Number(value) <= Number(mrp)) {
                      setDiscountedPriceError(false);
                      setDiscountedPrice(value);
                    } else {
                      setDiscountedPriceError(true);
                      setDiscountedPrice("");
                    }
                  } else {
                    setDiscountedPriceError(false);
                    setDiscountedPrice(value);
                  }
                }}
                style={{ ...styles.textInput, width: "50%" }}
                underlineColorAndroid="transparent"
                label="Discounted Price"
                autoCapitalize="none"
                theme={{ colors: { primary: "#136eb4" } }}
                value={discountedPrice}
                keyboardType="numeric"
              />
            </View>
            {discountedPrice.length > 0 ? (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Text
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    Price :₹{discountedPrice}
                  </Text>
                  <Text
                    style={{
                      textDecorationLine: "line-through",
                      marginLeft: 10,
                      fontSize: 18,
                    }}
                  >
                    ₹{mrp}
                  </Text>
                </View>
                {/* display discount percentage */}
                <Text
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 18,
                    backgroundColor: "#dd7c38",
                    padding: 7,
                    paddingHorizontal: 10,
                    borderRadius: 8,
                  }}
                >
                  {((mrp - discountedPrice) / mrp).toFixed(2) * 100}% off
                </Text>
              </View>
            ) : (
              <></>
            )}

            {/* <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <View>
                <TextInput
                  onChangeText={(value) => {
                    setProductUnit(value);
                  }}
                  style={{ ...styles.textInput, width: 180, marginRight: 10 }}
                  underlineColorAndroid="transparent"
                  label="Product Unit"
                  autoCapitalize="none"
                  theme={{ colors: { primary: "#136eb4" } }}
                  value={productUnit}
                  keyboardType="numeric"
                />
                {productUnit !== "" ? (
                  <Text>Unit: Per {productUnit} piece</Text>
                ) : (
                  <></>
                )}
              </View>

              <TouchableOpacity
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  marginTop: 20,
                }}
                onPress={() => toggleBottomNavigationView()}
              >
                <Text
                  style={{
                    fontSize: 18,
                    marginRight: 15,
                    textTransform: "capitalize",
                  }}
                >
                  {productUnitName == "" ? "Select Unit" : productUnitName}
                </Text>
                <Entypo name="chevron-down" size={24} color="black" />
              </TouchableOpacity>
            </View> */}

            {/* {caloriesError && (
						<Text style={{ color: "red", fontSize: 12, marginTop: 5 }}>
							Calories is required
						</Text>
					)}
					<TextInput
						style={styles.textInput}
						underlineColorAndroid='transparent'
						label='Calories '
						autoCapitalize='none'
						onChangeText={(value) => {
							setCalories(value);
							if (value.length > 0) {
								setCaloriesError(false);
							} else {
								setCaloriesError(true);
							}
						}}
						theme={{ colors: { primary: "#136eb4" } }}
						value={calories}
						keyboardType='numeric'
					/> */}

            {/* {priorityError && (
						<Text style={{ color: "red", fontSize: 12, marginTop: 5 }}>
							Priority is required
						</Text>
					)}
					<TextInput
						style={styles.textInput}
						underlineColorAndroid='transparent'
						label='Priority '
						autoCapitalize='none'
						onChangeText={(value) => {
							setPriority(value);
							if (value.length > 0) {
								setPriorityError(false);
							} else {
								setPriorityError(true);
							}
						}}
						theme={{ colors: { primary: "#136eb4" } }}
						value={priority}
						keyboardType='numeric'
					/> */}

            <TouchableOpacity
              onPress={() => setProductDetailsBottomSheet(true)}
              style={{
                borderBottomWidth: 0.6,
                borderColor: "lightgrey",
                marginTop: 20,
                paddingBottom: 10,
              }}
            >
              <Text
                style={{
                  fontSize: 13,
                  marginVertical: 10,
                  color: "grey",
                  marginBottom: 6,
                }}
              >
                Product Details
              </Text>
              <Text
                style={{
                  fontSize: 18,
                  marginRight: 15,
                  color: `${productDetails.length > 0 ? "black" : "grey"}`,
                }}
              >
                {productDetails.length > 0
                  ? productDetails
                  : "Enter product details"}
              </Text>
            </TouchableOpacity>

            {/* <TouchableOpacity
              onPress={() => navigation.navigate("Add Variants")}
              style={{ marginVertical: 10, marginTop: 20 }}
            >
              <Text
                style={{
                  color: "#136eb4",
                  fontWeight: "bold",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <Ionicons
                  name="md-add"
                  size={24}
                  style={{ fontWeight: "bold" }}
                />
                <Text
                  style={{
                    fontSize: 18,
                    textTransform: "uppercase",
                    marginLeft: 10,
                  }}
                >
                  Add Variants
                </Text>
              </Text>
            </TouchableOpacity> */}

            <TouchableOpacity
              onPress={() => addItem()}
              disabled={
                !(
                  name.length > 0 &&
                  productCategory.length > 0 &&
                  mrp.length > 0
                )
              }
              style={{
                backgroundColor:
                  name.length > 0 &&
                  productCategory.length > 0 &&
                  mrp.length > 0
                    ? "#136eb4"
                    : "#ccc",

                padding: 10,
                borderRadius: 8,
                justifyContent: "center",
                alignItems: "center",
                marginVertical: 10,
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                Save
              </Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </Provider>
    </>
  );
};

export default AddEditProducts;

const styles = StyleSheet.create({
  textInput: {
    backgroundColor: "white",

    marginBottom: 10,
  },
});
