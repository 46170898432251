import * as React from "react";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import AntDesign from "react-native-vector-icons/AntDesign";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { input } from "../screens/LocationDetails/input";
// import { day1 } from "./helper/day1form";
import { dayForm } from "../screens/LocationDetails/dayForm";
import { createStackNavigator } from "@react-navigation/stack";

export const LocationForm = ({ route, navigation }) => {
	const Tab = createBottomTabNavigator();
	const Stack = createStackNavigator();
	console.log("loationForm====", route);
	const id = route.params.params.id;
	const clientId = route.params.params.clientId;

	return (
		<Stack.Navigator>
			<Stack.Screen
				name='input'
				component={input}
				options={{
					headerShown: false,
				}}
				initialParams={{ id: id, clientId: clientId }}
			/>
			<Stack.Screen
				name='day1'
				component={dayForm}
				options={{
					headerShown: false,
				}}
				initialParams={{ id: id, clientId: clientId }}
			/>
		</Stack.Navigator>
	);
};
