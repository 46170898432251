import { StyleSheet, Text, View } from "react-native";
import React from "react";
import WebView from "react-native-webview";

const Browser = ({ navigation, route }) => {
	const { url } = route.params;
	console.log(url);
	return (
		<View style={{ flex: 1 }}>
			<WebView source={{ uri: url }} />
		</View>
	);
};

export default Browser;

const styles = StyleSheet.create({});
