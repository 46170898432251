import React, { useEffect, useContext, useState } from "react";
import {
	Button,
	StyleSheet,
	Text,
	View,
	TouchableOpacity,
	Animated,
	Linking,
	ActivityIndicator,
	Platform,
} from "react-native";
import RNPickerSelect from "react-native-picker-select";
import { useFocusEffect } from "@react-navigation/native";
import { v4 as uuidv4 } from "uuid";
import { useToast } from "react-native-toast-notifications";
import axios from "axios";
import { FlatList, ScrollView } from "react-native-gesture-handler";
import { format } from "date-fns";
import Modal from "react-native-modal";
import { Icon } from "react-native-elements";
import openMap from "react-native-open-maps";
import { BarIndicator } from "react-native-indicators";
import { UserContext } from "../src/context/userContext";
import { capitalize, GetDescSortOrder } from "../utils/utils";
import {
	SERVER_API_URL,
	API_URL_CLIENT,
	API_URL_LOCATIONS,
	API_URL_RESTAURANT,
	API_URL_ORDER_DETAIL,
	API_URL_ORDER,
	API_URL_CUSTOMER,
	BORZO_API_URL,
	DUNZO_API_URL,
} from "../config/config";
import { Feather } from "@expo/vector-icons";

// Functional component
const OrderManagement = () => {
	const { state } = useContext(UserContext);
	const toast = useToast();
	const userType = state.userType;
	const assignedClient = state.user.attributes["custom:client"];
	const [orderData, updateOrderData] = React.useState([]);
	const [isModalVisible, setModalVisible] = React.useState(false);
	const [isTrackingModalVisible, setTrackingModalVisible] =
		React.useState(false);
	const [client, updateClient] = React.useState([]);
	const [restructuredClient, updateRestructureClient] = React.useState([]);
	const [selectedRestaurant, setSelectedRestaurant] = React.useState("");
	const [feeLoading, setFeeLoading] = useState(false);

	const [defaultLocation, setDefaultLocation] = React.useState("");
	const [locations, setLocations] = React.useState([]);
	const [selectedLocation, setSelectedLocation] = React.useState("");

	const [selectClient, updateClientSelect] = React.useState(
		assignedClient ? assignedClient : ""
	);
	const [restaurants, setRestaurants] = React.useState([]);
	const [visible, setVisible] = React.useState(false);
	const [refresh, updateRefresh] = React.useState(false);
	const [index, setIndex] = React.useState(0);
	const [accepted, setAccepted] = React.useState([]);
	const [pending, setPending] = React.useState([]);
	const [processing, setProcessing] = React.useState([]);
	const [ready, setReady] = React.useState([]);
	const [delivered, setDelivered] = React.useState([]);
	const [cancelled, setCancelled] = React.useState([]);
	const [outForDelivery, setOutForDelivery] = React.useState([]);
	const [modalItem, setModalItem] = React.useState({});
	const [routes, setRoutes] = React.useState([]);
	const [order, setOrder] = React.useState([]);
	const [selectedTab, setSelectedTab] = useState("Active");
	const [deliveryTrackingDetails, setDeliveryTrackingDetails] = useState({});
	const [loading, setLoading] = useState(false);
	const [customerDetails, setCustomerDetails] = useState([]);
	const [deliveryAgentModal, setDeliveryAgentModal] = useState(false);
	const [fromLocation, setFromLocation] = useState("");
	const [fromLatitude, setFromLatitude] = useState("");
	const [fromLongitude, setFromLongitude] = useState("");
	const [fromName, setFromName] = useState("");
	const [fromPhone, setFromPhone] = useState("");
	const [fromCity, setFromCity] = useState("");
	const [fromState, setFromState] = useState("");
	const [fromPinCode, setFromPinCode] = useState("");
	const [deliveryFee, setDeliveryFee] = useState("");
	const [dunzoDeliveryFee, setDunzoDeliveryFee] = useState("");
	const [clientName, setClientName] = useState("");
	const renderTab = () => {
		switch (selectedTab) {
			case "pending":
				return (
					<>
						<TabHeader />
						<PendingRoute />
					</>
				);
			case "accepted":
				return (
					<>
						<TabHeader />
						<AcceptedRoute />
					</>
				);
			case "processing":
				return (
					<>
						<TabHeader />
						<ProcessingRoute />
					</>
				);
			case "ready":
				return (
					<>
						<TabHeader />
						<ReadyRoute />
					</>
				);
			case "outForDelivery":
				return (
					<>
						<TabHeader />
						<OurForDeliveryRoute />
					</>
				);
			case "delivered":
				return (
					<>
						<TabHeader />
						<DeliveryRoute />
					</>
				);
			case "cancelled":
				return (
					<>
						<TabHeader />
						<CancelledRoute />
					</>
				);
		}
	};

	// Secondary Tab routes
	const PendingRoute = () => (
		<>
			{pending && pending.length > 0 ? (
				<FlatList data={pending} renderItem={myFunc} />
			) : (
				<View style={styles.mainView}>
					<Text>No Orders!</Text>
				</View>
			)}
		</>
	);
	const AcceptedRoute = () => (
		<>
			{accepted && accepted.length > 0 ? (
				<FlatList data={accepted} renderItem={myFunc} />
			) : (
				<View style={styles.mainView}>
					<Text>No Orders!</Text>
				</View>
			)}
		</>
	);
	const ProcessingRoute = () => (
		<>
			{processing && processing.length > 0 ? (
				<FlatList data={processing} renderItem={myFunc} />
			) : (
				<View style={styles.mainView}>
					<Text>No Orders!</Text>
				</View>
			)}
		</>
	);

	const ReadyRoute = () => (
		<>
			{ready && ready.length > 0 ? (
				<FlatList data={ready} renderItem={myFunc} />
			) : (
				<View style={styles.mainView}>
					<Text>No Orders!</Text>
				</View>
			)}
		</>
	);
	const OurForDeliveryRoute = () => (
		<>
			{outForDelivery && outForDelivery.length > 0 ? (
				<FlatList data={outForDelivery} renderItem={myFunc} />
			) : (
				<View style={styles.mainView}>
					<Text>No Orders!</Text>
				</View>
			)}
		</>
	);
	const DeliveryRoute = () => (
		<>
			{delivered && delivered.length > 0 ? (
				<FlatList data={delivered} renderItem={myFunc} />
			) : (
				<View style={styles.mainView}>
					<Text>No Orders!</Text>
				</View>
			)}
		</>
	);
	const CancelledRoute = () => (
		<>
			{cancelled && cancelled.length > 0 ? (
				<FlatList data={cancelled} renderItem={myFunc} />
			) : (
				<View style={styles.mainView}>
					<Text>No Orders!</Text>
				</View>
			)}
		</>
	);

	const toggleTrackingModal = () => {
		setTimeout(() => {
			toggleModal();
			setTrackingModalVisible(!isTrackingModalVisible);
		}, 1000);
	};

	// Function to render secondary tab content
	const myFunc = ({ item }) => {
		let items = item.data.items;
		//console.log('items', items);
		var count = {};
		let val = [];
		items &&
			Array.isArray(items) &&
			items.forEach(function (i) {
				count[i] = (count[i] || 0) + 1;
			});
		// console.log("count is", count);
		for (const key in count) {
			val.push(count[key]);
		}
		// console.log("item===", item);
		const uniqueValues = [...new Set(items.map((v) => v.itemName))];
		// console.log("unique is ", uniqueValues);

		return (
			<TouchableOpacity
				key={item.id}
				onPress={() => {
					toggleModal();
					getCustomerDetails(item.customerId);
					getItemLocation(item.locationId);
					setModalItem(item);
				}}
				style={styles.mainView}>
				<View style={{ marginVertical: 5 }}>
					<Text style={styles.itemLabel}>Item Name</Text>

					{uniqueValues.map((elem, index) => {
						return (
							<View key={index}>
								<Text>{elem}</Text>
							</View>
						);
					})}
				</View>

				<View style={{ marginVertical: 5 }}>
					<Text style={styles.itemLabel}>Order #</Text>
					<Text>{item.orderNo}</Text>
				</View>

				<View style={{ marginVertical: 5 }}>
					<Text style={styles.itemLabel}>Order Date</Text>

					<Text>
						{format(new Date(item.data.orderDate), "dd/MM/yyyy hh:mm aa")}
					</Text>
				</View>

				<View style={{ marginVertical: 5 }}>
					<Text style={styles.itemLabel}>Order Type</Text>

					<Text>{item.data.orderType.toUpperCase()} </Text>
				</View>
				<View style={{ marginVertical: 5 }}>
					<Text style={styles.itemLabel}>Number of Items</Text>

					<Text>{items.length} </Text>
				</View>
			</TouchableOpacity>
		);
	};

	const getClientName = (value) => {
		console.log("value is ", value);
		const data = client.filter((elem) => {
			return elem.slug === value;
		});
		console.log("data is ", data[0]?.clientName);
		setClientName(data[0]?.clientName);
	};
	const toggleModal = () => {
		setModalVisible(!isModalVisible);
	};

	const restructure = (item) => {
		let labels = [];
		for (var i = 0; i < item.length; i++) {
			labels[i] = { label: item[i].clientName, value: item[i].slug };
		}
		updateRestructureClient(labels);
	};
	const restaurantRestructure = (data) => {
		let array = [];
		for (var i = 0; i < data.length; i++) {
			array[i] = { label: data[i].name, value: data[i].id };
		}
		setRestaurants(array);
		setSelectedRestaurant(array[0].value);
	};
	const getClient = async () => {
		// console.log("getClient");
		await axios
			.get(SERVER_API_URL + API_URL_CLIENT, {
				headers: {
					Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				},
			})
			.then((res) => {
				console.log("client data is ", res.data[0].clientName);
				updateClient(res.data), restructure(res.data);
			})
			.catch((err) => console.log("getClient Error", err));
	};

	const getRestaurants = async () => {
		// console.log("getRestaurants");
		if (!selectClient || selectClient == null) return;
		await axios
			.get(`${SERVER_API_URL}${API_URL_RESTAURANT}${API_URL_CLIENT}`, {
				headers: {
					client: selectClient,
				},
			})
			.then((res) => {
				restaurantRestructure(res.data);
				// console.log("Restaurants are", res.data);
			})
			.catch((err) => console.log("getRestaurants Error", err));
	};
	const getLocations = async () => {
		// console.log("getLocations");
		if (!selectedRestaurant || selectedRestaurant == null) return;
		await axios
			.get(`${SERVER_API_URL}${API_URL_LOCATIONS}`, {
				headers: {
					restaurantgroup: selectedRestaurant,
				},
			})
			.then((res) => {
				// console.log("getLocations Response", res.data[0].name);
				setLocations(res.data);
				setSelectedLocation(res.data[0]?.id);
			})
			.catch((err) => {
				// console.log("getLocations Error", err);
				setLocations([]);
			});
	};

	const getOrders = async (locationId) => {
		updateOrderData([]);
		// console.log("selected client is", selectClient);
		// console.log("selected res is", selectedRestaurant);
		if (
			!selectClient ||
			!selectedRestaurant ||
			selectClient == null ||
			selectedRestaurant == null
		) {
			console.log("returning");
			return;
		}
		const locationForOrder = locationId ? locationId : selectedLocation;
		// console.log("selected location is", locationForOrder);
		let endPoint = `${SERVER_API_URL}${API_URL_ORDER_DETAIL}`;
		await axios
			.post(
				endPoint,
				{
					resGrpId: selectedRestaurant ? selectedRestaurant : null,
					locationId: locationForOrder ? locationForOrder : null,
				},
				{
					headers: {
						client: selectClient,
					},
				}
			)
			.then((res) => {
				//console.log("Updating Order Data");
				// console.log("ORDERS ARE ", res.data);
				let orderArray = res.data.sort(GetDescSortOrder("orderNo"));

				updateOrderData(orderArray);

				setOrder(res.data);

				//setting pending array
				var pendingArr = orderArray.filter(function (el) {
					return (
						el?.data?.orderStatus == "pending" ||
						el?.data?.orderStatus == "Pending"
					);
				});
				setPending(pendingArr);

				//setting accepted array
				var acceptedArr = orderArray.filter(function (el) {
					return (
						el?.data?.orderStatus == "accepted" ||
						el?.data?.orderStatus == "Accepted"
					);
				});
				setAccepted(acceptedArr);

				//setting processing array
				var processingArr = orderArray.filter(function (el) {
					return (
						el?.data?.orderStatus == "processing" ||
						el?.data?.orderStatus == "Processing"
					);
				});
				setProcessing(processingArr);

				//setting ready array

				var readyArr = orderArray.filter(function (el) {
					return (
						el?.data?.orderStatus == "ready" || el?.data?.orderStatus == "Ready"
					);
				});
				setReady(readyArr);

				//setting delivered array

				var deliveryArr = orderArray.filter(function (el) {
					return (
						el?.data?.orderStatus == "delivered" ||
						el?.data?.orderStatus == "Delivered"
					);
				});

				setDelivered(deliveryArr);

				//setting cancelled array
				var cancelledArr = orderArray.filter(function (el) {
					return (
						el?.data?.orderStatus == "cancelled" ||
						el?.data?.orderStatus == "Cancelled"
					);
				});
				setCancelled(cancelledArr);

				var outForDeliveryArr = orderArray.filter(function (el) {
					return (
						el?.data?.orderStatus == "outfordelivery" ||
						el?.data?.orderStatus == "Outfordelivery"
					);
				});
				setOutForDelivery(outForDeliveryArr);

				//console.log('selectedTab', selectedTab);
				updateRoutes(selectedTab ? selectedTab : "Active");
			})
			.catch((err) => {
				console.log("Error getting orders" + err);
				setAccepted([]);
				setProcessing([]);
				setCancelled([]);
				setOutForDelivery([]);
				setPending([]);
				setReady([]);
				setDelivered([]);
			});
	};

	const updateRoutes = (tab) => {
		//console.log('updateRoutes', tab);
		setVisible(false);
		setSelectedTab(tab);
		if (tab === "pending") {
			setRoutes([{ key: "pending", title: "Pending Orders" }]);
		} else if (tab === "accepted") {
			setRoutes([{ key: "accepted", title: "Accepted Orders" }]);
		} else if (tab === "processing") {
			setRoutes([{ key: "processing", title: "Processing Orders" }]);
		} else if (tab === "ready") {
			setRoutes([{ key: "ready", title: "Ready Orders" }]);
		}
		if (tab === "outForDelivery") {
			setRoutes([{ key: "outForDelivery", title: "Out For Delivery Orders" }]);
		}
		if (tab === "delivered") {
			setRoutes([{ key: "delivered", title: "Delivered Orders" }]);
		}
		if (tab === "cancelled") {
			setRoutes([{ key: "cancelled", title: "Cancelled Orders" }]);
		}
		setVisible(true);
	};
	async function updateOrderApi(orderStatusVal, orderId) {
		setVisible(false);
		setModalVisible(false);

		//console.log("orderStatusValue", orderStatusVal);
		//console.log("order id is", orderId);
		await axios
			.patch(
				`${SERVER_API_URL}${API_URL_ORDER}/${orderId}`,
				{
					orderStatus: orderStatusVal,
				},
				{
					headers: {
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => {
				// console.log("After patch is", res.data);
				alert("Successfully updated");
				updateRefresh(!refresh);
			})
			.catch((err) => {
				console.log(err);
				updateRefresh(!refresh);
			});
	}
	async function updateDeliveryProvider(
		orderId,
		deliveryProvider,
		deliveryTracking
	) {
		console.log(orderId, deliveryProvider, deliveryTracking);
		await axios
			.patch(
				`${SERVER_API_URL}${API_URL_ORDER}/${orderId}`,
				{
					deliveryProvider,
					deliveryTracking,
				},
				{
					headers: {
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => {
				// console.log("success data == \n", res.data);
				toast.show("Successfully updated", {
					type: "success",
					placement: "bottom",
					duration: 2000,
					offset: 30,
					animationType: "slide-in",
				});
				updateRefresh(!refresh);
			})
			.catch((err) => {
				console.log(err);
				toast.show("Something went wrong", {
					type: "danger",
					placement: "bottom",
					duration: 2000,
					offset: 30,
					animationType: "slide-in",
				});
				updateRefresh(!refresh);
			});
	}
	const getCustomerDetails = async (customerId) => {
		await axios
			.get(`${SERVER_API_URL}${API_URL_CUSTOMER}/${customerId}`)
			.then((res) => {
				// console.log("customer response", res.data);
				setCustomerDetails(res.data);
			})
			.catch((err) => console.log("customer err===", err));
	};

	useFocusEffect(
		React.useCallback(() => {
			getClient();
			getRestaurants();

			return () => {};
		}, [selectClient])
	);

	useEffect(() => {
		// console.log("useEffect getOrders");
		getOrders();
	}, [refresh]);

	useEffect(() => {
		getLocations();
	}, []);

	const renderTabBar = (props) => {
		return (
			<View style={styles.tabBar}>
				{props.navigationState.routes.map((route, i) => {
					return (
						<TouchableOpacity
							style={index === i ? styles.tabSelectedItem : styles.tabItem}
							onPress={() => setIndex(i)}
							key={i}>
							<Animated.Text style={styles.tabText}>
								{route.title}
							</Animated.Text>
						</TouchableOpacity>
					);
				})}
			</View>
		);
	};

	const TabHeader = () => {
		let title = "";
		if (selectedTab === "pending") {
			title = "Pending Orders";
		} else if (selectedTab === "accepted") {
			title = "Accepted Orders";
		} else if (selectedTab === "processing") {
			title = "Processing Orders";
		} else if (selectedTab === "ready") {
			title = "Ready Orders";
		} else if (selectedTab === "outForDelivery") {
			title = "Out For Delivery Orders";
		} else if (selectedTab === "delivered") {
			title = "Delivered Orders";
		} else if (selectedTab === "cancelled") {
			title = "Cancelled Orders";
		}
		return (
			<View style={styles.tabBar}>
				<TouchableOpacity style={styles.tabSelectedItem}>
					<Animated.Text style={styles.tabText}>{title}</Animated.Text>
				</TouchableOpacity>
			</View>
		);
	};

	const getDunzoDeliveryFee = async (
		orderApt,
		orderDeliveryAddress,
		orderStreet2,
		ordercity,
		orderstate,
		orderzip,
		orderlat,
		orderlong,
		customerId,
		orderId,
		orderStatus,
		orderNo,
		location
	) => {
		setFeeLoading(true);
		setLoading(true);

		const headers = {
			Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
			client: selectClient,
			"Content-Type": " application/json",
		};
		const params = JSON.stringify({
			pickup_details: [
				{
					reference_id: "pickup",
					lat: fromLatitude,
					lng: fromLongitude,
					address: {
						apartment_address: "",
						street_address_1: fromLocation,
						street_address_2: "",
						landmark: "",
						city: fromCity,
						state: fromState,
						pincode: fromPinCode,
						country: "India",
						contact_details: {
							name: fromName,
							phone_number: fromPhone,
						},
					},
				},
			],
			optimised_route: true,
			drop_details: [
				{
					reference_id: "drop",

					lat: modalItem.data?.deliveryPoint?.lat,
					lng: modalItem.data?.deliveryPoint?.long,
					address: {
						apartment_address: "",
						street_address_1: orderDeliveryAddress,
						street_address_2: "",
						landmark: "",
						city: ordercity,
						state: orderstate,
						pincode: orderzip,
						country: "India",
						contact_details: {
							name: customerDetails?.name ? customerDetails?.name : "none",
							phone_number: customerDetails?.phoneNumber
								? customerDetails?.phoneNumber
								: "none",
						},
					},
					special_instructions: "",
				},
			],
			payment_method: "DUNZO_CREDIT",
		});
		// console.log("Dunzo params ", params);
		await axios
			.post(`${DUNZO_API_URL}/quote`, params, {
				headers: headers,
			})
			.then((res) => {
				setDunzoDeliveryFee(res.data?.estimated_price);
				setFeeLoading(false);
			})
			.catch((err) => {
				setFeeLoading(false);
			});
	};
	const bookDunzo = async (
		orderApt,
		orderDeliveryAddress,
		orderStreet2,
		ordercity,
		orderstate,
		orderzip,
		orderlat,
		orderlong,
		customerId,
		orderId,
		orderStatus,
		orderNo,
		location
	) => {
		// setLoading(true);
		// console.log("details are ", customerDetails);

		const headers = {
			Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
			client: selectClient,
			"Content-Type": " application/json",
		};
		const params = JSON.stringify({
			request_id: uuidv4(),
			reference_id: orderNo,
			pickup_details: [
				{
					reference_id: "pickup",
					address: {
						apartment_address: "",
						street_address_1: fromLocation,
						street_address_2: "",
						landmark: "",
						city: fromCity,
						state: fromState,
						pincode: fromPinCode,
						country: "India",
						lat: fromLatitude,
						lng: fromLongitude,
						contact_details: {
							name: fromName,
							phone_number: fromPhone,
						},
					},
				},
			],
			optimised_route: true,
			drop_details: [
				{
					reference_id: "drop",

					address: {
						apartment_address: "",
						street_address_1: orderDeliveryAddress,
						street_address_2: "",
						landmark: "",
						city: ordercity,
						state: orderstate,
						pincode: orderzip,
						country: "India",
						lat: orderlat,
						lng: orderlong,
						contact_details: {
							name: customerDetails?.name ? customerDetails?.name : "none",
							phone_number: customerDetails?.phoneNumber
								? customerDetails?.phoneNumber
								: "none",
						},
					},
					special_instructions: "",
				},
			],
			payment_method: "DUNZO_CREDIT",
		});

		// console.log("params is ", params);
		await axios
			.post(`${DUNZO_API_URL}/task`, params, {
				headers: headers,
			})
			.then((res) => {
				// console.log("Dunzo response is ", res.data);

				setDeliveryAgentModal(false);

				toast.show("Dunzo's Delivery Agent booked succesfully", {
					type: "normal",
					placement: "top",
					duration: 2000,
					offset: 30,
					animationType: "slide-in",
				});

				updateDeliveryProvider(orderId, "Dunzo", res?.data?.task_id);
			})
			.catch((err) => {
				setDeliveryAgentModal(false);
				toast.show("Delivery Agent booking failed. Try again!!", {
					type: "danger",
					placement: "top",
					duration: 2000,
					offset: 30,
					animationType: "slide-in",
				});
				console.log("Err", err);
			});
	};

	const refreshTrackingDetails = async (deliveryProvider, taskId) => {
		setDeliveryTrackingDetails({});
		let trackingResponseData = await getDeliveryTrackingDetails(
			deliveryProvider,
			taskId
		);
		// console.log("after getting trackingResponseData", trackingResponseData);
		setDeliveryTrackingDetails(trackingResponseData);
	};

	const getDeliveryTrackingDetails = async (deliveryProvider, taskId) => {
		// console.log("taskId=" + taskId);
		try {
			const headers = {
				Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				client: selectClient,
				"Content-Type": " application/json",
			};
			let url = "";
			if (deliveryProvider === "Dunzo") {
				url = `${DUNZO_API_URL}/track/${taskId}`;
			} else if (deliveryProvider === "Borzo") {
				url = `${BORZO_API_URL}/track/${taskId}`;
			}
			let response = await axios.get(url, {
				headers,
			});
			// console.log(
			// 	"Task Tracking response.data" + JSON.stringify(response.data)
			// );
			let deliveryResponse = response.data;
			if (deliveryProvider === "Dunzo") {
				let trackingURLs = [];
				if (response.data?.tracking_url) {
					trackingURLs.push(response.data?.tracking_url);
				}
				deliveryResponse.trackingURLs = trackingURLs;
			}
			if (deliveryProvider === "Borzo") {
				deliveryResponse = {
					state: response.data?.status,
					event_timestamp: response.data?.created_datetime,
				};
				if (response.data?.points && response.data?.points?.length > 1) {
					let currentTime = new Date();
					let pickupTime = new Date(
						response.data?.points[0].required_finish_datetime
					);
					let dropoffTime = new Date(
						response.data?.points[1].required_finish_datetime
					);
					const pickupETA = Math.ceil(
						Math.abs(pickupTime - currentTime) / (1000 * 60)
					);
					const dropoffETA = Math.ceil(
						Math.abs(dropoffTime - currentTime) / (1000 * 60)
					);
					deliveryResponse.eta = {
						pickup: pickupETA,
						dropoff: dropoffETA,
					};
					let trackingURLs = [];
					if (response.data?.points[0].tracking_url) {
						trackingURLs.push(response.data?.points[0].tracking_url);
					}
					if (response.data?.points[1].tracking_url) {
						trackingURLs.push(response.data?.points[1].tracking_url);
					}
					deliveryResponse.trackingURLs = trackingURLs;
				}
				if (response.data?.courier) {
					deliveryResponse.runner = {
						name: response.data?.courier?.name,
						phone_number: response.data?.courier?.phone,
					};
					if (
						response.data?.courier?.latitude &&
						response.data?.courier?.longitude
					) {
						deliveryResponse.runner.location = {
							lat: response.data?.courier?.latitude,
							lng: response.data?.courier?.longitude,
						};
					}
				}
			}
			return deliveryResponse;
		} catch (err) {
			console.log("Task Tracking API err", err);
		}
	};

	const renderContent = () => {
		// console.log("deliveryTrackingDetails", deliveryTrackingDetails);
		if (deliveryTrackingDetails) {
			return (
				<View
					style={{
						backgroundColor: "white",
						paddingTop: 0,
					}}>
					<Text style={styles.contentTitle}>Delivery Details</Text>
					{deliveryTrackingDetails.state && (
						<Text style={styles.contentHeading}>
							Delivery State: {capitalize(deliveryTrackingDetails?.state)}
						</Text>
					)}
					{deliveryTrackingDetails.event_timestamp && (
						<Text style={styles.contentHeading}>
							Delivery Requested On :{" "}
							{format(
								new Date(deliveryTrackingDetails?.event_timestamp),
								"dd/MM/yyyy hh:mm aa"
							)}
						</Text>
					)}
					{deliveryTrackingDetails.eta && (
						<>
							<Text style={styles.contentHeading}>
								Pickup ETA: {Math.round(deliveryTrackingDetails?.eta.pickup)}{" "}
								mins
							</Text>
							<Text style={styles.contentHeading}>
								Dropoff ETA: {Math.round(deliveryTrackingDetails?.eta.dropoff)}{" "}
								mins
							</Text>
						</>
					)}
					{deliveryTrackingDetails.trackingURLs &&
						deliveryTrackingDetails.trackingURLs.map((trackingURL) => (
							<TouchableOpacity
								onPress={() => {
									Linking.openURL(`${trackingURL}`);
								}}>
								<Text style={styles.contentHeading}>
									Tracking Link: {trackingURL}
								</Text>
							</TouchableOpacity>
						))}
					{deliveryTrackingDetails.runner && (
						<>
							<Text style={styles.contentHeading}>
								Delivery Agent Name: {deliveryTrackingDetails?.runner.name}
							</Text>
							<Text style={styles.contentHeading}>
								Delivery Agent Contact Number:{" "}
								{deliveryTrackingDetails?.runner?.phone_number}
							</Text>
							<View style={styles.buttonRow}>
								<TouchableOpacity
									onPress={() => {
										Linking.openURL(
											`tel:${deliveryTrackingDetails?.runner?.phone_number}`
										);
									}}>
									<Icon
										name='mobile-alt'
										type='font-awesome-5'
										size={26}
										color={"green"}
										style={{ alignContent: "flex-end", paddingLeft: 40 }}
									/>
								</TouchableOpacity>
								{deliveryTrackingDetails.runner.location && (
									<TouchableOpacity
										onPress={() => {
											if (Platform.OS === "web") {
												Linking.openURL(
													`https://maps.google.com/?q=${deliveryTrackingDetails.runner.location.lat},${deliveryTrackingDetails.runner.location.lng}`
												);
											} else {
												openMap({
													end: ` ${deliveryTrackingDetails.runner.location.lat}, ${deliveryTrackingDetails.runner.location.lng}`,
													travelType: "drive",
													navigate: true,
												});
											}
										}}>
										<Icon
											name='map-marker-alt'
											type='font-awesome-5'
											size={30}
											color='green'
										/>
									</TouchableOpacity>
								)}
							</View>
						</>
					)}
				</View>
			);
		} else {
			return <Text>Delivery Tracking is currently not available!</Text>;
		}
	};

	const getItemLocation = async (location) => {
		// console.log("Location is ", location);
		try {
			const res = await axios.get(
				`${SERVER_API_URL}${API_URL_LOCATIONS}/${location}`
			);
			// console.log("data is ", res.data?.phone.value);
			setFromLocation(res.data?.address?.street1);
			setFromLatitude(res.data?.address?.geo?.lat);
			setFromLongitude(res.data?.address?.geo?.long);
			setFromName(res.data?.restaurantGroups[0]?.name);
			setFromPhone(res.data?.phone?.value);
			setFromCity(res.data?.address?.city);
			setFromState(res.data?.address?.state);
			setFromPinCode(res.data?.address?.zip);
		} catch (error) {
			setFromLocation("");
		}
	};

	//function to get delivery fee for borzo
	const getBorzoDeliveryFee = async () => {
		// console.log("Runs");
		setFeeLoading(true);
		// console.log(
		// 	fromLocation,
		// 	fromLatitude,
		// 	fromLongitude,
		// 	modalItem.data?.deliveryPoint.long,
		// 	modalItem.data?.deliveryPoint.lat
		// );
		await axios({
			method: "post",
			url: `${BORZO_API_URL}/quote`,
			headers: {
				Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				client: selectClient,
				"Content-Type": " application/json",
			},
			data: {
				matter: "Food",
				points: [
					{
						address: fromLocation,
						latitude: Number(fromLatitude),
						longitude: Number(fromLongitude),
						contact_person: {
							name: fromName,
							phone: fromPhone,
						},
					},
					{
						address: modalItem?.data?.deliveryAddress,
						latitude: modalItem.data?.deliveryPoint.lat,
						longitude: modalItem.data?.deliveryPoint.long,
						contact_person: {
							name: customerDetails?.name ? customerDetails?.name : "none",
							phone: customerDetails?.phoneNumber
								? customerDetails?.phoneNumber
								: "none",
						},
					},
				],
			},
		})
			.then((res) => {
				setDeliveryFee(res.data.order["delivery_fee_amount"]);

				setFeeLoading(false);
			})
			.catch((err) => {
				setFeeLoading(false);
			});
	};

	// function to book borzo delivery provider
	const bookBorzo = async () => {
		// console.log("phone is ", customerDetails?.phoneNumber);
		await axios({
			url: `${BORZO_API_URL}/task`,
			method: "post",
			data: {
				matter: "Food",
				points: [
					{
						address: fromLocation,
						latitude: fromLatitude,
						longitude: fromLongitude,
						contact_person: {
							name: fromName,
							phone: fromPhone,
						},
					},
					{
						address: modalItem?.data?.deliveryAddress,
						latitude: modalItem.data?.deliveryPoint?.lat,
						longitude: modalItem.data?.deliveryPoint?.long,
						contact_person: {
							name: customerDetails?.name ? customerDetails?.name : "",
							phone: customerDetails?.phoneNumber
								? customerDetails?.phoneNumber
								: "none",
						},
					},
				],
			},
			headers: {
				Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				client: selectClient,
				"Content-Type": " application/json",
			},
		})
			.then((res) => {
				updateDeliveryProvider(
					modalItem?.id,
					"Borzo",
					res?.data?.order?.order_id
				);

				toast.show("Borzo's Delivery Agent booked succesfully", {
					type: "normal",
					placement: "top",
					duration: 2000,
					offset: 30,
					animationType: "slide-in",
				});
				setDeliveryAgentModal(false);
			})
			.catch((err) => {
				setDeliveryAgentModal(false);
				toast.show("Delivery Agent booking failed. Try again!!", {
					type: "danger",
					placement: "top",
					duration: 2000,
					offset: 30,
					animationType: "slide-in",
				});
				console.log("Err", err);
			});
	};

	const cancelBorzo = async (order_id) => {
		// console.log(order_id);
		await axios({
			method: "post",
			url: `${BORZO_API_URL}/cancel`,
			data: { order_id: `${order_id}` },
			headers: {
				Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				client: selectClient,
				"Content-Type": " application/json",
			},
		})
			.then((res) => {
				updateDeliveryProvider(modalItem?.id, "", "");
				setModalVisible(false);
				// console.log(res.data);
				toast.show("Borzo's Delivery cancelled succesfully", {
					type: "normal",
					placement: "top",
					duration: 2000,
					offset: 30,
					animationType: "slide-in",
				});
			})
			.catch((err) => {
				setModalVisible(false);
				console.log("err  ", err);

				toast.show("oops!!! something went wrong. Try again!!", {
					type: "danger",
					placement: "top",
					duration: 2000,
					offset: 30,
					animationType: "slide-in",
				});
			});
	};
	const cancelDunzo = async (task_id) => {
		const params = JSON.stringify({
			cancellation_reason: "Order cancelled",
			state: "cancelled",
			reference_id: modalItem?.orderNo,
		});

		// console.log("Params is ", params);
		await axios({
			method: "post",
			url: `${DUNZO_API_URL}/cancel/${task_id}`,
			data: params,
			headers: {
				Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				client: selectClient,
				"Content-Type": " application/json",
			},
		})
			.then((res) => {
				updateDeliveryProvider(modalItem?.id, "", "");
				setModalVisible(false);
				// console.log("data is", res.data);

				toast.show("Dunzo's Delivery cancelled succesfully", {
					type: "normal",
					placement: "top",
					duration: 2000,
					offset: 30,
					animationType: "slide-in",
				});
			})
			.catch((err) => {
				setModalVisible(false);
				console.log("err  ", err);

				toast.show("oops!!! something went wrong. Try again!!", {
					type: "danger",
					placement: "top",
					duration: 2000,
					offset: 30,
					animationType: "slide-in",
				});
			});
	};

	const cancelSelfDelivery = async () => {
		updateDeliveryProvider(modalItem?.id, "", "");
		setModalVisible(false);
		toast.show("Self Delivery cancelled succesfully", {
			type: "normal",
			placement: "top",
			duration: 2000,
			offset: 30,
			animationType: "slide-in",
		});
	};
	const requestReview = () => {
		const whatsappMessage = `
		Dear ${customerDetails?.name},

Thank you for shopping with ${selectClient} recently. We hope you were happy with your order no. ${modalItem?.orderNo}.

We would love to know how you found the experience of ordering with us so would like to invite you to rate , review us on https://reviews.delishtic.com/ . It will only take few clicks and will be invaluable to us!

Thank you so much,
${clientName} Team`;

		const whatsappMessageWeb = `Dear ${customerDetails?.name},%0a %0a

Thank you for shopping with ${selectClient} recently. We hope you were happy with your order no. ${modalItem?.orderNo}. %0a %0a

We would love to know how you found the experience of ordering with us so would like to invite you to rate and review us on https://reviews.delishtic.com/ . It will only take few clicks and will be invaluable to us! %0a %0a

Thank you so much, %0a
${clientName} Team`;
		if (Platform.OS == "web") {
			Linking.openURL(
				`whatsapp://send?text=${whatsappMessageWeb}&phone=${customerDetails?.phoneNumber}`
			);
		} else {
			Linking.openURL(
				`whatsapp://send?text=${whatsappMessage}&phone=${customerDetails?.phoneNumber}`
			);
		}
	};

	const ModalFunc = (item) => {
		// console.log("ITEM IS  \n", modalItem?.data?.deliveryProvider?.length);

		return (
			<>
				<Modal
					backdropOpacity={0.95}
					backdropColor='white'
					isVisible={isModalVisible}>
					<ScrollView
						style={{
							...styles.mainView,
							marginHorizontal: 0,
							marginVertical: 5,
						}}>
						<TouchableOpacity
							style={{
								backgroundColor: "orange",
								padding: 5,
								borderRadius: 5,
								marginTop: 5,
							}}
							onPress={toggleModal}>
							<Text
								style={{ color: "white", textAlign: "center", fontSize: 14 }}>
								Close
							</Text>
						</TouchableOpacity>
						<View style={{ marginVertical: 20 }}>
							<View style={{ flexDirection: "row", alignItems: "center" }}>
								<Text style={{ ...styles.itemLabel, fontSize: 18 }}>
									Order No :{" "}
								</Text>
								<Text style={{ ...styles.itemLabel, fontSize: 18 }}>
									{modalItem?.orderNo}
								</Text>
							</View>
							<View style={{ flexDirection: "row" }}>
								<Text style={styles.itemLabel}>Order Type : </Text>
								<Text style={{ fontSize: 16, fontWeight: "500" }}>
									{capitalize(modalItem?.data?.orderType)}
								</Text>
							</View>
							<View style={{ flexDirection: "row" }}>
								<Text style={styles.itemLabel}>Order Status : </Text>
								<Text style={{ fontSize: 16, fontWeight: "500" }}>
									{capitalize(modalItem?.data?.orderStatus)}
								</Text>
							</View>
							<View style={{ flexDirection: "row" }}>
								<Text style={styles.itemLabel}>Received : </Text>
								<Text style={{ fontSize: 16, fontWeight: "500" }}>
									{modalItem?.data?.orderDate &&
										format(
											new Date(modalItem?.data?.orderDate),
											"dd/MM/yyyy hh:mm aa"
										)}
								</Text>
							</View>
						</View>

						<View
							style={{
								...styles.mainView,
								marginHorizontal: 0,
								marginVertical: 5,
							}}>
							{modalItem?.data?.items?.map((elem, index) => (
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "space-between",
									}}
									key={index}>
									<View
										key={elem.id}
										style={{
											flexDirection: "row",
											alignItems: "center",
										}}>
										<Text
											style={{
												...styles.itemLabel,
												fontSize: 18,
												fontWeight: "400",
											}}>
											{elem?.itemQty} ✕{" "}
										</Text>
										<Text
											style={{
												...styles.itemLabel,
												fontSize: 18,
												marginRight: 5,
												maxWidth: 200,
											}}>
											{elem?.itemName}
										</Text>
									</View>
									<Text
										style={{
											...styles.itemLabel,
											fontSize: 18,
											marginRight: 5,
										}}>
										₹ {elem?.itemTotal?.toFixed(2)}
									</Text>
								</View>
							))}
						</View>
						<View
							style={{
								...styles.mainView,
								marginHorizontal: 0,
								marginVertical: 5,
							}}>
							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}>
								<Text
									style={{ ...styles.itemLabel, fontSize: 18, marginRight: 5 }}>
									Sub Total
								</Text>
								<Text
									style={{ ...styles.itemLabel, fontSize: 18, marginRight: 5 }}>
									₹ {modalItem?.data?.subTotal.toFixed(2)}
								</Text>
							</View>
							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}>
								<Text
									style={{ ...styles.itemLabel, fontSize: 18, marginRight: 5 }}>
									Tax
								</Text>
								<Text
									style={{ ...styles.itemLabel, fontSize: 18, marginRight: 5 }}>
									₹ {modalItem?.data?.tax.toFixed(2)}
								</Text>
							</View>
							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}>
								<Text
									style={{
										...styles.itemLabel,
										fontSize: 18,
										marginRight: 5,
									}}>
									Packing Charges{" "}
								</Text>
								<Text
									style={{
										...styles.itemLabel,
										fontSize: 18,
										marginRight: 5,
									}}>
									₹ {modalItem?.data?.packingCharges?.toFixed(2)}
								</Text>
							</View>
							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}>
								<Text
									style={{ ...styles.itemLabel, fontSize: 18, marginRight: 5 }}>
									Delivery Fee
								</Text>
								<Text
									style={{ ...styles.itemLabel, fontSize: 18, marginRight: 5 }}>
									₹ {modalItem?.data?.deliveryFee.toFixed(2)}
								</Text>
							</View>
							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}>
								<Text
									style={{ ...styles.itemLabel, fontSize: 18, marginRight: 5 }}>
									Discount{" "}
								</Text>
								<Text
									style={{ ...styles.itemLabel, fontSize: 18, marginRight: 5 }}>
									₹ {modalItem?.data?.discount.toFixed(2)}
								</Text>
							</View>

							<Text> </Text>
							<View
								style={{
									borderBottomColor: "black",
									borderBottomWidth: 1,
								}}
							/>
							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}>
								<Text
									style={{ ...styles.itemLabel, fontSize: 18, marginRight: 5 }}>
									Total
								</Text>
								<Text
									style={{ ...styles.itemLabel, fontSize: 18, marginRight: 5 }}>
									₹ {modalItem?.data?.total?.toFixed(2)}
								</Text>
							</View>
						</View>
						<View
							style={{
								...styles.mainView,
								marginHorizontal: 0,
								marginVertical: 5,
							}}>
							<Text
								style={{
									...styles.itemLabel,
									fontSize: 18,
									marginRight: 5,
									marginBottom: 10,
									textAlign: "center",
									borderBottomColor: "black",
									borderBottomWidth: 1,
								}}>
								Customer details
							</Text>

							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
									marginBottom: 10,
								}}>
								<Text
									style={{ ...styles.itemLabel, fontSize: 18, marginRight: 5 }}>
									Name{" "}
								</Text>
								<Text style={{ fontSize: 18, marginRight: 5 }}>
									{customerDetails?.name}
								</Text>
							</View>

							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
									marginVertical: 10,
								}}>
								<Text
									style={{ ...styles.itemLabel, fontSize: 18, marginRight: 5 }}>
									Phone Number{" "}
								</Text>
								<Text style={{ fontSize: 18, marginRight: 5 }}>
									{customerDetails?.phoneNumber}
								</Text>
							</View>
							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
									marginVertical: 10,
									flexWrap: "wrap",
								}}>
								<Text
									style={{ ...styles.itemLabel, fontSize: 18, marginRight: 5 }}>
									Address{" "}
								</Text>
								<Text style={{ fontSize: 14, marginRight: 5 }}>
									{modalItem?.data?.deliveryAddress}
								</Text>
								<TouchableOpacity
									onPress={() =>
										Linking.openURL(
											`https://www.google.com/maps/dir/?api=1&destination=${modalItem?.data?.deliveryPoint?.lat}%2C${modalItem?.data?.deliveryPoint?.long}`
										)
									}>
									<Feather name='map-pin' size={24} color='black' />
								</TouchableOpacity>
							</View>
						</View>
						<View>
							{modalItem?.data?.orderStatus?.toLowerCase() == "pending" ? (
								<View
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
										padding: 20,
									}}>
									<TouchableOpacity
										onPress={() => updateOrderApi("cancelled", modalItem.id)}
										style={{
											backgroundColor: "red",
											padding: 10,
											borderRadius: 5,
										}}>
										<Text style={{ color: "white" }}>Reject</Text>
									</TouchableOpacity>
									<TouchableOpacity
										onPress={() => updateOrderApi("accepted", modalItem.id)}
										style={{
											backgroundColor: "green",
											padding: 10,
											borderRadius: 5,
										}}>
										<Text style={{ color: "white" }}>Accept Order</Text>
									</TouchableOpacity>
								</View>
							) : (
								<></>
							)}

							{modalItem?.data?.orderStatus?.toLowerCase() == "accepted" ? (
								<View
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
										padding: 20,
									}}>
									<TouchableOpacity
										onPress={() => updateOrderApi("cancelled", modalItem.id)}
										style={{
											backgroundColor: "red",
											padding: 10,
											borderRadius: 5,
										}}>
										<Text style={{ color: "white" }}>Reject</Text>
									</TouchableOpacity>
									<TouchableOpacity
										onPress={() => updateOrderApi("processing", modalItem.id)}
										style={{
											backgroundColor: "green",
											padding: 10,
											borderRadius: 5,
										}}>
										<Text style={{ color: "white" }}>Mark Processing</Text>
									</TouchableOpacity>
								</View>
							) : (
								<></>
							)}

							{modalItem?.data?.orderStatus?.toLowerCase() == "processing" ? (
								<>
									<View
										style={{
											flexDirection: "row",
											justifyContent: "space-between",
											padding: 20,
										}}>
										<TouchableOpacity
											onPress={() => updateOrderApi("cancelled", modalItem.id)}
											style={{
												backgroundColor: "red",
												padding: 10,
												borderRadius: 5,
											}}>
											<Text style={{ color: "white" }}>Reject</Text>
										</TouchableOpacity>
										<TouchableOpacity
											onPress={() => updateOrderApi("ready", modalItem.id)}
											style={{
												backgroundColor: "green",
												padding: 10,
												borderRadius: 5,
											}}>
											<Text style={{ color: "white" }}>Mark Ready</Text>
										</TouchableOpacity>
									</View>
									<TouchableOpacity
										disabled={
											!modalItem?.data?.deliveryProvider ||
											modalItem?.data?.deliveryProvider?.length === 0
												? false
												: true
										}
										onPress={() => {
											getBorzoDeliveryFee();
											setModalVisible(false);
											setDeliveryAgentModal(true);
											getDunzoDeliveryFee(
												modalItem.apt,
												modalItem.data.deliveryAddress,
												modalItem.data.deliveryDetailedAddress?.street
													?.long_name,
												modalItem.data.deliveryDetailedAddress?.city?.long_name,
												modalItem.data.deliveryDetailedAddress?.state
													?.long_name,
												modalItem.zip,
												modalItem.data.deliveryPoint?.lat,
												modalItem.data.deliveryPoint?.long,
												modalItem.customerId,
												modalItem.id,
												modalItem?.data?.orderStatus,
												modalItem.orderNo,
												modalItem.locationId
											);
										}}
										style={{
											backgroundColor: `${
												!modalItem?.data?.deliveryProvider ||
												modalItem?.data?.deliveryProvider?.length == 0
													? "lightgreen"
													: "grey"
											}`,
											padding: 10,
											borderRadius: 5,
											marginVertical: 5,
										}}>
										<Text
											style={{
												color: `${
													!modalItem?.data?.deliveryProvider ||
													modalItem?.data?.deliveryProvider?.length == 0
														? "black"
														: "white"
												}`,
												fontWeight: "bold",
												textAlign: "center",
											}}>
											Book Delivery Agent
										</Text>
									</TouchableOpacity>
									<TouchableOpacity
										disabled={
											!modalItem?.data?.deliveryProvider ||
											modalItem?.data?.deliveryProvider?.length === 0
												? false
												: true
										}
										onPress={() => {
											updateDeliveryProvider(modalItem.id, "Self Delivery", "");
											setModalVisible(false);
											toast.show("Self Delivery updated succesfully", {
												type: "normal",
												placement: "top",
												duration: 2000,
												offset: 30,
												animationType: "slide-in",
											});
										}}
										style={{
											backgroundColor: `${
												!modalItem?.data?.deliveryProvider ||
												modalItem?.data?.deliveryProvider?.length === 0
													? "lightgreen"
													: "grey"
											}`,
											padding: 10,
											borderRadius: 5,
											marginVertical: 5,
										}}>
										<Text
											style={{
												color: `${
													!modalItem?.data?.deliveryProvider ||
													modalItem?.data?.deliveryProvider?.length === 0
														? "black"
														: "white"
												}`,
												fontWeight: "bold",
												textAlign: "center",
											}}>
											Self Delivery{" "}
										</Text>
									</TouchableOpacity>

									{modalItem?.data?.deliveryProvider === "Borzo" ? (
										<TouchableOpacity
											onPress={() => {
												// console.log("Pressed");
												cancelBorzo(modalItem?.data?.deliveryTracking);
											}}
											style={{
												backgroundColor: "red",
												padding: 10,
												borderRadius: 5,
												marginVertical: 5,
											}}>
											<Text
												style={{
													color: "white",
													fontWeight: "bold",
													textAlign: "center",
												}}>
												Cancel Borzo{" "}
											</Text>
										</TouchableOpacity>
									) : modalItem?.data?.deliveryProvider === "Dunzo" ? (
										<TouchableOpacity
											onPress={() => {
												// console.log("Pressed");
												cancelDunzo(modalItem?.data?.deliveryTracking);
											}}
											style={{
												backgroundColor: "red",
												padding: 10,
												borderRadius: 5,
												marginVertical: 5,
											}}>
											<Text
												style={{
													color: "white",
													fontWeight: "bold",
													textAlign: "center",
												}}>
												Cancel Dunzo{" "}
											</Text>
										</TouchableOpacity>
									) : modalItem?.data?.deliveryProvider === "Self Delivery" ? (
										<TouchableOpacity
											onPress={() => {
												// console.log("Pressed");
												cancelSelfDelivery();
											}}
											style={{
												backgroundColor: "red",
												padding: 10,
												borderRadius: 5,
												marginVertical: 5,
											}}>
											<Text
												style={{
													color: "white",
													fontWeight: "bold",
													textAlign: "center",
												}}>
												Cancel Self Delivery{" "}
											</Text>
										</TouchableOpacity>
									) : (
										<></>
									)}
								</>
							) : (
								<></>
							)}
							{modalItem?.data?.orderStatus?.toLowerCase() == "ready" ? (
								<>
									<View
										style={{
											flexDirection: "row",
											justifyContent: "space-between",
											paddingVertical: 10,
										}}>
										<TouchableOpacity
											onPress={() =>
												updateOrderApi("outfordelivery", modalItem.id)
											}
											style={{
												backgroundColor: "green",
												padding: 10,
												borderRadius: 5,
											}}>
											<Text style={{ color: "white" }}>Out for delivery</Text>
										</TouchableOpacity>
									</View>
									<TouchableOpacity
										disabled={
											!modalItem?.data?.deliveryProvider ||
											modalItem?.data?.deliveryProvider?.length === 0
												? false
												: true
										}
										onPress={() => {
											getBorzoDeliveryFee();
											getDunzoDeliveryFee(
												modalItem.apt,
												modalItem.data.deliveryAddress,
												modalItem.data.deliveryDetailedAddress?.street
													?.long_name,
												modalItem.data.deliveryDetailedAddress?.city?.long_name,
												modalItem.data.deliveryDetailedAddress?.state
													?.long_name,
												modalItem.zip,
												modalItem.data.deliveryPoint?.lat,
												modalItem.data.deliveryPoint?.long,
												modalItem.customerId,
												modalItem.id,
												modalItem?.data?.orderStatus,
												modalItem?.orderNo,
												modalItem?.locationId
											);
											setModalVisible(false);
											setDeliveryAgentModal(true);
										}}
										style={{
											backgroundColor: `${
												!modalItem?.data?.deliveryProvider ||
												modalItem?.data?.deliveryProvider?.length == 0
													? "lightgreen"
													: "grey"
											}`,

											padding: 10,
											borderRadius: 5,
											marginVertical: 5,
										}}>
										<Text
											style={{
												color: `${
													!modalItem?.data?.deliveryProvider ||
													modalItem?.data?.deliveryProvider?.length == 0
														? "black"
														: "white"
												}`,
												fontWeight: "bold",
												textAlign: "center",
											}}>
											Book Delivery Agent
										</Text>
									</TouchableOpacity>
									<TouchableOpacity
										disabled={
											!modalItem?.data?.deliveryProvider ||
											modalItem?.data?.deliveryProvider?.length === 0
												? false
												: true
										}
										onPress={() => {
											updateDeliveryProvider(modalItem.id, "Self Delivery", "");
											setModalVisible(false);

											toast.show("Self Delivery updated succesfully", {
												type: "normal",
												placement: "top",
												duration: 2000,
												offset: 30,
												animationType: "slide-in",
											});
										}}
										style={{
											backgroundColor: `${
												!modalItem?.data?.deliveryProvider ||
												modalItem?.data?.deliveryProvider?.length === 0
													? "lightgreen"
													: "grey"
											}`,
											padding: 10,
											borderRadius: 5,
											marginVertical: 5,
										}}>
										<Text
											style={{
												color: `${
													!modalItem?.data?.deliveryProvider ||
													modalItem?.data?.deliveryProvider?.length === 0
														? "black"
														: "white"
												}`,
												fontWeight: "bold",
												textAlign: "center",
											}}>
											Self Delivery{" "}
										</Text>
									</TouchableOpacity>
									{modalItem?.data?.deliveryProvider === "Borzo" ? (
										<TouchableOpacity
											onPress={() => {
												cancelBorzo(modalItem?.data?.deliveryTracking);
												// console.log("Pressed");
											}}
											style={{
												backgroundColor: "red",
												padding: 10,
												borderRadius: 5,
												marginVertical: 5,
											}}>
											<Text
												style={{
													color: "white",
													fontWeight: "bold",
													textAlign: "center",
												}}>
												Cancel Borzo{" "}
											</Text>
										</TouchableOpacity>
									) : modalItem?.data?.deliveryProvider === "Dunzo" ? (
										<TouchableOpacity
											onPress={() => {
												// console.log("Pressed");
												cancelDunzo(modalItem?.data?.deliveryTracking);
											}}
											style={{
												backgroundColor: "red",
												padding: 10,
												borderRadius: 5,
												marginVertical: 5,
											}}>
											<Text
												style={{
													color: "white",
													fontWeight: "bold",
													textAlign: "center",
												}}>
												Cancel Dunzo{" "}
											</Text>
										</TouchableOpacity>
									) : modalItem?.data?.deliveryProvider === "Self Delivery" ? (
										<TouchableOpacity
											onPress={() => {
												// console.log("Pressed");
												cancelSelfDelivery();
											}}
											style={{
												backgroundColor: "red",
												padding: 10,
												borderRadius: 5,
												marginVertical: 5,
											}}>
											<Text
												style={{
													color: "white",
													fontWeight: "bold",
													textAlign: "center",
												}}>
												Cancel Self Delivery{" "}
											</Text>
										</TouchableOpacity>
									) : (
										<></>
									)}
								</>
							) : (
								<></>
							)}
						</View>
						{modalItem?.data?.orderStatus?.toLowerCase() == "outfordelivery" ? (
							<>
								<View
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
										paddingVertical: 10,
									}}>
									<TouchableOpacity
										onPress={() => updateOrderApi("delivered", modalItem.id)}
										style={{
											backgroundColor: "green",
											padding: 10,
											borderRadius: 5,
										}}>
										<Text style={{ color: "white" }}>Delivered</Text>
									</TouchableOpacity>
								</View>
							</>
						) : (
							<></>
						)}
						{modalItem?.data?.orderStatus?.toLowerCase() == "delivered" ? (
							<></>
						) : (
							<></>
						)}
						{(modalItem?.data?.orderStatus == "processing" ||
							modalItem?.data?.orderStatus == "ready" ||
							modalItem?.data?.orderStatus == "outfordelivery" ||
							modalItem?.data?.orderStatus == "delivered") && (
							<View
								style={{
									...styles.mainView,
									marginHorizontal: 0,
									marginVertical: 5,
									marginBottom: 20,
								}}>
								<View
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
									}}>
									<Text
										style={{
											...styles.itemLabel,
											fontSize: 18,
											marginRight: 5,
										}}>
										Delivery Provider :
									</Text>
									<Text
										style={{
											...styles.itemLabel,
											fontSize: 18,
											marginRight: 5,
										}}>
										{modalItem?.data?.deliveryProvider}
									</Text>
								</View>
								<View
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
									}}>
									<Text
										style={{
											...styles.itemLabel,
											fontSize: 18,
											marginRight: 5,
										}}>
										Delivery Tracking :
									</Text>
								</View>
								<View
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
									}}>
									<Text
										style={{
											...styles.itemLabel,
											fontSize: 14,
											marginRight: 5,
										}}
										selectable={true}>
										{modalItem?.data?.deliveryTracking}
									</Text>
								</View>
								{(modalItem?.data?.deliveryProvider === "Dunzo" ||
									modalItem?.data?.deliveryProvider === "Borzo") && (
									<View
										style={{
											flexDirection: "row",
											justifyContent: "center",
											alignItems: "center",
											backgroundColor: "lightblue",
											marginVertical: 5,
										}}>
										<TouchableOpacity
											onPress={() => {
												toggleTrackingModal();
												refreshTrackingDetails(
													modalItem?.data?.deliveryProvider,
													modalItem?.data?.deliveryTracking
												);
											}}>
											<Text
												style={{
													...styles.itemLabel,
													fontSize: 15,
													marginRight: 5,
												}}>
												Track Delivery Details
											</Text>
										</TouchableOpacity>
									</View>
								)}
							</View>
						)}
						<TouchableOpacity
							onPress={() => requestReview()}
							style={{
								borderRadius: 5,
								padding: 10,
								marginTop: 10,
								paddingVertical: 15,
								backgroundColor: "#136eb4",
							}}>
							<Text
								style={{
									color: "white",
									textAlign: "center",
									textTransform: "uppercase",
								}}>
								Request Review
							</Text>
						</TouchableOpacity>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
								marginBottom: 40,
							}}>
							<Text style={{ fontSize: 8 }}>Order Id : </Text>
							<Text style={{ fontSize: 8 }} selectable={true}>
								{modalItem?.id}
							</Text>
						</View>
					</ScrollView>
				</Modal>
				<Modal
					isVisible={isTrackingModalVisible}
					onBackdropPress={() => setTrackingModalVisible(false)}>
					<View style={{ backgroundColor: "white" }}>
						{renderContent()}
						<Button title='Close' onPress={toggleTrackingModal} />
					</View>
				</Modal>
				<Modal
					style={{ backgroundColor: Platform.OS === "web" && "white" }}
					backdropOpacity={1}
					backdropColor='white'
					isVisible={deliveryAgentModal}>
					<ScrollView
						style={{
							...styles.mainView,
							marginHorizontal: 0,
							marginVertical: 5,
							padding: 20,
						}}>
						<View>
							<Text style={{ ...styles.label, textAlign: "center" }}>
								BOOK Dunzo
							</Text>
							<View style={{ marginBottom: 10 }}>
								<Text style={{ fontWeight: "bold", marginBottom: 5 }}>
									From :
								</Text>
								<Text>{fromLocation}</Text>
							</View>

							<View style={{ marginBottom: 10 }}>
								<Text style={{ fontWeight: "bold", marginBottom: 5 }}>
									To :
								</Text>
								<Text>{modalItem?.data?.deliveryAddress}</Text>
							</View>

							<View style={{ marginBottom: 10 }}>
								<Text style={{ fontWeight: "bold", marginBottom: 5 }}>
									Delivery Fee :
								</Text>

								{feeLoading == true ? (
									<ActivityIndicator size='small' color='#0000ff' />
								) : (
									<Text>Rs. {dunzoDeliveryFee} </Text>
								)}
							</View>
							<View style={styles.buttonRow}>
								<TouchableOpacity
									onPress={() =>
										bookDunzo(
											modalItem?.apt,
											modalItem?.data.deliveryAddress,
											modalItem?.data.deliveryDetailedAddress?.street
												?.long_name,
											modalItem?.data.deliveryDetailedAddress?.city?.long_name,
											modalItem?.data.deliveryDetailedAddress?.state?.long_name,
											modalItem?.zip,
											modalItem?.data.deliveryPoint?.lat,
											modalItem?.data.deliveryPoint?.long,
											modalItem?.customerId,
											modalItem?.id,
											modalItem?.data?.orderStatus,
											modalItem?.orderNo,
											modalItem?.locationId
										)
									}
									style={{
										backgroundColor: "green",
										padding: 10,
										borderRadius: 5,
									}}>
									<Text style={{ color: "white" }}>Book Dunzo</Text>
								</TouchableOpacity>
							</View>
						</View>
						<View style={{ marginTop: 20 }}>
							<Text style={{ ...styles.label, textAlign: "center" }}>
								BOOK BORZO
							</Text>
							<View style={{ marginBottom: 10 }}>
								<Text style={{ fontWeight: "bold", marginBottom: 5 }}>
									From :
								</Text>
								<Text>{fromLocation}</Text>
							</View>

							<View style={{ marginBottom: 10 }}>
								<Text style={{ fontWeight: "bold", marginBottom: 5 }}>
									To :
								</Text>
								<Text>{modalItem?.data?.deliveryAddress}</Text>
							</View>

							<View style={{ marginBottom: 10 }}>
								<Text style={{ fontWeight: "bold", marginBottom: 5 }}>
									Delivery Fee :
								</Text>

								{feeLoading == true ? (
									<ActivityIndicator size='small' color='#0000ff' />
								) : (
									<Text>Rs. {deliveryFee} </Text>
								)}
							</View>
							<View style={styles.buttonRow}>
								<TouchableOpacity
									onPress={() => bookBorzo()}
									style={{
										backgroundColor: "green",
										padding: 10,
										borderRadius: 5,
									}}>
									<Text style={{ color: "white" }}>Book Borzo</Text>
								</TouchableOpacity>
							</View>
						</View>
					</ScrollView>
					<TouchableOpacity
						onPress={() => {
							setModalVisible(true);
							setDeliveryAgentModal(false);
						}}
						style={{
							backgroundColor: "red",
							padding: 10,
							borderRadius: 5,
						}}>
						<Text style={{ color: "white", textAlign: "center" }}>Close</Text>
					</TouchableOpacity>
				</Modal>
			</>
		);
	};
	return (
		<>
			<TouchableOpacity
				onPress={() => {
					getOrders();
				}}
				style={{ alignItems: "flex-end", marginRight: 10 }}>
				<Icon name='sync-alt' type='font-awesome-5' size={20} color={"green"} />
			</TouchableOpacity>
			{userType === "Admin" && (
				<View style={styles.itemView}>
					<Text style={styles.label}>Select Client :</Text>
					<RNPickerSelect
						fixAndroidTouchableBug={true}
						style={pickerSelectStyles}
						useNativeAndroidPickerStyle={false}
						items={restructuredClient}
						onValueChange={(value, index) => {
							setVisible(false);
							updateClientSelect(value);
							getClientName(value);
						}}
						value={selectClient}
					/>
				</View>
			)}

			<View style={styles.itemView}>
				<Text style={styles.label}>Select Restaurant / Shop Group :</Text>

				<RNPickerSelect
					style={pickerSelectStyles}
					fixAndroidTouchableBug={true}
					useNativeAndroidPickerStyle={false}
					onValueChange={(itemValue, itemIndex) => {
						//console.log("values is ", itemValue);
						setVisible(false);

						setSelectedRestaurant(itemValue);
						getLocations(itemValue);
						setDefaultLocation("");
					}}
					items={restaurants.map((res) => ({
						label: res.label,
						value: res.value,
					}))}
					value={selectedRestaurant}
				/>
			</View>
			<View style={styles.itemView}>
				<Text style={styles.label}>Select Location:</Text>

				<RNPickerSelect
					fixAndroidTouchableBug={true}
					style={pickerSelectStyles}
					useNativeAndroidPickerStyle={false}
					onValueChange={(itemValue, itemIndex) => {
						// console.log("Location Value Change", itemValue);
						if (itemValue) {
							setSelectedLocation(itemValue);
							setVisible(false);
							getOrders(itemValue);
							setVisible(true);
						}
					}}
					value={selectedLocation}
					items={locations.map((res) => ({
						label: res.name,
						value: res.id,
					}))}
				/>
			</View>
			<ModalFunc />

			<View style={styles.tabView}>
				<TouchableOpacity
					onPress={() => {
						updateRoutes("pending");
					}}
					style={selectedTab === "pending" ? styles.selectedTab : styles.tabs}>
					<Text style={{ textAlign: "center", color: "white" }}>Pending</Text>
				</TouchableOpacity>

				<TouchableOpacity
					onPress={() => {
						updateRoutes("accepted");
					}}
					style={selectedTab === "accepted" ? styles.selectedTab : styles.tabs}>
					<Text style={{ textAlign: "center", color: "white" }}>Accepted</Text>
				</TouchableOpacity>

				<TouchableOpacity
					onPress={() => {
						updateRoutes("processing");
					}}
					style={
						selectedTab === "processing" ? styles.selectedTab : styles.tabs
					}>
					<Text style={{ textAlign: "center", color: "white" }}>
						Processing
					</Text>
				</TouchableOpacity>

				<TouchableOpacity
					onPress={() => {
						updateRoutes("ready");
					}}
					style={selectedTab === "ready" ? styles.selectedTab : styles.tabs}>
					<Text style={{ textAlign: "center", color: "white" }}>Ready</Text>
				</TouchableOpacity>
			</View>

			<View style={styles.tabView}>
				<TouchableOpacity
					onPress={() => {
						updateRoutes("outForDelivery");
					}}
					style={
						selectedTab === "outForDelivery" ? styles.selectedTab : styles.tabs
					}>
					<Text style={{ textAlign: "center", color: "white" }}>
						Out For Delivery
					</Text>
				</TouchableOpacity>

				<TouchableOpacity
					onPress={() => {
						updateRoutes("delivered");
					}}
					style={
						selectedTab === "delivered" ? styles.selectedTab : styles.tabs
					}>
					<Text style={{ textAlign: "center", color: "white" }}>Delivered</Text>
				</TouchableOpacity>

				<TouchableOpacity
					onPress={() => {
						updateRoutes("cancelled");
					}}
					style={
						selectedTab === "cancelled" ? styles.selectedTab : styles.tabs
					}>
					<Text style={{ textAlign: "center", color: "white" }}>Cancelled</Text>
				</TouchableOpacity>
			</View>

			{visible ? renderTab() : <></>}
		</>
	);
};

export default OrderManagement;

const styles = StyleSheet.create({
	itemView: {
		paddingHorizontal: 20,
		marginTop: 5,
	},
	label: {
		marginTop: 10,
		marginBottom: 6,
		fontSize: 16,
		fontWeight: "bold",
	},
	itemLabel: {
		fontSize: 16,
		fontWeight: "bold",
		textAlign: "left",
	},
	detailsView: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginVertical: 6,
	},
	settingView: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingHorizontal: 10,
		marginBottom: 15,
	},
	container: {
		marginTop: 5,
		fontSize: 12,
	},
	scene: {
		flex: 1,
	},
	tabView: {
		flexDirection: "row",
		justifyContent: "space-between",
		paddingHorizontal: 20,
		marginTop: 5,
	},
	tabs: {
		backgroundColor: "rgb(56,81,126)",
		padding: 10,
		borderRadius: 10,
		marginTop: 5,
	},
	selectedTab: {
		backgroundColor: "orange",
		padding: 10,
		borderRadius: 10,
		marginTop: 5,
	},
	mainView: {
		margin: 10,
		marginHorizontal: 15,
		padding: 10,
		backgroundColor: "white",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.36,
		shadowRadius: 6.68,

		elevation: 11,
	},
	tabBar: {
		flexDirection: "row",
		paddingTop: 20,
		marginHorizontal: 10,
	},
	tabItem: {
		flex: 1,
		alignItems: "center",
		padding: 5,
		backgroundColor: "green",
		borderWidth: 0.5,
	},
	tabSelectedItem: {
		flex: 1,
		alignItems: "center",
		padding: 5,
		backgroundColor: "lightblue",
	},
	tabText: {
		fontWeight: "bold",
		fontSize: 14,
	},
	contentHeading: {
		fontSize: 16,
		fontWeight: "500",
		color: "black",
		marginTop: 10,
		marginLeft: 20,
	},
	contentTitle: {
		fontSize: 20,
		fontWeight: "bold",
		alignSelf: "center",
		marginVertical: 10,
	},
	buttonRow: {
		flexDirection: "row",
		justifyContent: "space-around",
		marginVertical: 10,
	},
});
const pickerSelectStyles = StyleSheet.create({
	inputIOS: {
		fontSize: 12,
		marginHorizontal: 5,
		borderWidth: 1,
		borderColor: "gray",
		borderRadius: 4,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon
		padding: 5,
	},
	inputAndroid: {
		fontSize: 12,
		marginHorizontal: 5,

		borderWidth: 0.5,
		borderColor: "black",
		borderRadius: 8,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon

		padding: 5, // to ensure the text is never behind the icon
	},
});
