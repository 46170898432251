import {
  ScrollView,
  StyleSheet,
  Text,
  View,
  Modal,
  TextInput,
  Platform,
} from "react-native";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { UserContext } from "../src/context/userContext";
import RNPickerSelect from "react-native-picker-select";
import { useFocusEffect } from "@react-navigation/native";
import axios from "axios";
import {
  API_URL_CLIENT,
  API_URL_FULL_MENUS,
  API_URL_RESTAURANT,
  API_URL_STOCK,
  SERVER_API_URL,
} from "../config/config";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Feather } from "@expo/vector-icons";
import { Button, Overlay } from "react-native-elements";
const AddEditItems = ({ navigation, route }) => {
  const { state, dispatch } = useContext(UserContext);

  const assignedClient = state.user.attributes["custom:client"];
  const [client, setClient] = useState(assignedClient ? assignedClient : "");
  const [restaurant, setRestaurant] = useState("");
  const [restaurants, setRestaurants] = useState([]);
  const [menuGroups, setMenuGroups] = useState([]);
  const [menuGroup, setMenuGroup] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  const [showMenuItems, setShowMenuItems] = useState([]);
  const [visible, setVisible] = useState(false);
  const [overlayItem, setOverlayItem] = useState({});
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [overlayMode, setOverlayMode] = useState("");

  const toggleOverlay = () => {
    setVisible(!visible);
  };

  const restructureRestaurant = (item) => {
    // console.log("item is ", item);
    let labels = [];
    if (item.length) {
      for (let i = 0; i < item.length; i++) {
        labels[i] = { label: item[i].name, value: item[i].id };
      }
    }
    setRestaurants(labels);
    console.log({ params: route.params });
    const restaurantGroupParam = route.params?.restaurantGroup;
    console.log({ restaurantGroupParam });
    const selectedRestaurant = restaurantGroupParam
      ? restaurantGroupParam
      : labels[0].value;
    setRestaurant(selectedRestaurant);
    showMenuGroups(selectedRestaurant);
  };

  const restructureMenuGroups = (groups) => {
    let labels = [];

    if (groups.length) {
      for (let i = 0; i < groups.length; i++) {
        labels[i] = { label: groups[i].name, value: groups[i].id };
      }
    }
    setMenuGroups(labels);
    const menuGroupIdParam = route.params?.menuGroupId;
    console.log({ menuGroupIdParam });
    const selectedMenuGroup = menuGroupIdParam
      ? menuGroupIdParam
      : labels[0].value;
    console.log(selectedMenuGroup);
    setMenuGroup(selectedMenuGroup);
  };

  const loadRestaurants = async (value) => {
    await axios
      .get(`${SERVER_API_URL}${API_URL_RESTAURANT}${API_URL_CLIENT}`, {
        headers: {
          client: value,
        },
      })
      .then((res) => {
        restructureRestaurant(res.data);
      })
      .catch((err) => {
        console.log("err2=", err);
        setRestaurants([]);
      });
  };

  const showMenuGroups = async (res) => {
    console.log("show runs", restaurant);
    await axios
      .get(`${SERVER_API_URL}${API_URL_FULL_MENUS}`, {
        headers: {
          restaurantGroup: restaurant ? restaurant : res,
        },
      })

      .then((res) => {
        restructureMenuGroups(res.data.groups);
        console.log("set menu items", res.data.items);
        setMenuItems(res.data.items);
      })
      .catch((err) => {
        console.log("menu err=", err);
        setMenuGroups([]);
      });
  };

  const filterMenuItems = () => {
    console.log("runs");
    let data = menuItems.filter((elem) => elem?.menuGroupId == menuGroup);
    setShowMenuItems(data);
  };

  const updateItem = () => {
    console.log({
      name: name,
      price: price,
      status: "submitted",
    });
    toggleOverlay();
  };

  const addItem = () => {
    console.log({
      name: name,
      price: price,
      status: "submitted",
    });
    toggleOverlay();
  };

  useEffect(() => {
    filterMenuItems();
  }, [menuGroup]);

  useEffect(() => {
    if (assignedClient) {
      loadRestaurants(assignedClient);
    }
  }, []);

  useFocusEffect(
    useCallback(() => {
      console.log("useFocusEffect AddEditItems");
      if (assignedClient) {
        loadRestaurants(assignedClient);
      }
    }, [navigation, route])
  );

  return (
    <>
      {/* <Overlay
        isVisible={visible}
        onBackdropPress={toggleOverlay}
        ModalComponent={Modal}
      >
        <View style={{ padding: 10, width: 300 }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginVertical: 10,
            }}
          >
            <Text style={{ fontSize: 18, fontWeight: "bold" }}>Name </Text>
            <TextInput
              placeholder="Name"
              defaultValue={name}
              style={{
                borderBottomWidth: 1,
                borderColor: "black",
                fontSize: 13,
                textAlign: "center",
                maxWidth: 200,
              }}
              onChangeText={(value) => {
                setName(value);
              }}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginVertical: 10,
            }}
          >
            <Text style={{ fontSize: 18, fontWeight: "bold" }}>Price</Text>
            <TextInput
              placeholder="Price"
              keyboardType="number-pad"
              defaultValue={price}
              style={{
                borderBottomWidth: 1,
                borderColor: "black",
                fontSize: 16,
                textAlign: "center",
              }}
              onChangeText={(value) => {
                setPrice(value);
              }}
            />
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginVertical: 10,
            }}
          >
            <Button title="Cancel" onPress={toggleOverlay} />

            {overlayMode == "edit" ? (
              <Button
                title="Submit for Approval"
                onPress={() => updateItem()}
              />
            ) : (
              <Button title="Submit for Approval" onPress={() => addItem()} />
            )}
          </View>
        </View>
      </Overlay> */}
      <View style={styles.container}>
        {client ? (
          <View style={styles.dropdown}>
            <Text style={styles.text}>Restaurant / Shop</Text>
            <View style={styles.menu}>
              <RNPickerSelect
                onValueChange={(value) => {
                  setRestaurant(value);
                  console.log("changed value2=", value);
                  // loadlocations(value);
                }}
                items={restaurants}
                value={restaurant}
                placeholder="Select a restaurant or shop"
                useNativeAndroidPickerStyle={false}
                fixAndroidTouchableBug={true}
                style={pickerSelectStyles}
              />
            </View>
          </View>
        ) : (
          <Text></Text>
        )}

        <View style={styles.dropdown}>
          <Text style={styles.text}>Menu Group</Text>
          <View style={styles.menu}>
            <RNPickerSelect
              onValueChange={(value) => {
                setMenuGroup(value);
                console.log("changed value2=", value);
              }}
              items={menuGroups}
              value={menuGroup}
              // placeholder='Select a restaurant or shop'
              useNativeAndroidPickerStyle={false}
              fixAndroidTouchableBug={true}
              style={pickerSelectStyles}
            />
          </View>
        </View>
        <View style={{ justifyContent: "center", alignItems: "center" }}>
          <TouchableOpacity
            style={{
              backgroundColor: "lightgreen",
              padding: 10,
              paddingHorizontal: 15,
              borderRadius: 10,
            }}
            onPress={() => filterMenuItems()}
          >
            <Text style={{ fontWeight: "bold", fontSize: 16 }}>Show Items</Text>
          </TouchableOpacity>
        </View>
      </View>

      <ScrollView style={{ padding: 20 }}>
        {showMenuItems?.map((elem, index) => (
          <View style={styles.itemView} key={index}>
            <View>
              <Text style={{ fontSize: 16, fontWeight: "700", width: 250 }}>
                {elem.name}
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <>
                  <Text
                    style={{
                      color: "grey",
                      fontSize: 18,
                    }}
                  >
                    Price :₹{elem.price}
                  </Text>
                  {elem.originalPrice && (
                    <>
                      <Text
                        style={{
                          textDecorationLine: "line-through",
                          marginLeft: 10,
                          fontSize: 18,
                        }}
                      >
                        ₹{elem.originalPrice}
                      </Text>
                      <Text
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: 18,
                          backgroundColor: "#dd7c38",
                          padding: 7,
                          paddingHorizontal: 10,
                          borderRadius: 8,
                        }}
                      >
                        {(
                          (elem.originalPrice - elem.price) /
                          elem.price
                        ).toFixed(2) * 100}
                        % off
                      </Text>
                    </>
                  )}
                  {/* <Text style={{ color: "grey" }}>₹ {elem.price}</Text> */}
                </>
              </View>
            </View>
            {/* <Feather
              onPress={() => {
                console.log("item price", elem.price);
                setPrice(elem.price.toString());
                setName(elem.name);
                setOverlayItem(elem);
                setOverlayMode("edit");
                toggleOverlay();
              }}
              name="edit-3"
              size={24}
              color="green"
            /> */}
            <Feather
              onPress={() => {
                navigation.navigate("Add Product", {
                  item: elem,
                  title: "Edit Product",
                });
              }}
              name="settings"
              size={24}
              color="green"
            />
          </View>
        ))}
      </ScrollView>
      <View style={{ justifyContent: "center", alignItems: "center" }}>
        <TouchableOpacity
          style={{
            backgroundColor: "lightgreen",
            padding: 10,
            paddingHorizontal: 15,
            borderRadius: 10,
            marginBottom: 20,
          }}
          onPress={() => {
            // setOverlayMode("add");
            // setName("");
            // setPrice(null);
            // toggleOverlay();
            navigation.navigate("Add Product", { title: "Add Product" });
          }}
        >
          <Text style={{ fontWeight: "bold", fontSize: 16 }}>Add Item</Text>
        </TouchableOpacity>
      </View>
    </>
  );
};

export default AddEditItems;

const styles = StyleSheet.create({
  areaview: {
    margin: 10,
    paddingVertical: Platform.OS !== "web" ? "5%" : 20,
    paddingHorizontal: Platform.OS !== "web" ? "5%" : 20,
  },
  container: {
    borderWidth: 1,
    borderColor: "grey",
    borderRadius: 15,
    flexDirection: "column",
    paddingVertical: Platform.OS !== "web" ? "4%" : 20,
    paddingHorizontal: Platform.OS !== "web" ? "4%" : 20,
    margin: 10,
    marginBottom: 8,
  },
  dropdown: {
    marginBottom: Platform.OS !== "web" ? 25 : 15,
  },

  heading: {
    //  backgroundColor: "red",
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 5,
  },
  itemView: {
    margin: 10,
    borderLeftColor: "lightgreen",
    borderLeftWidth: 10,

    padding: 15,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.36,
    shadowRadius: 6.68,

    elevation: 11,
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    borderColor: "grey",
    // borderWidth: 1,
    borderBottomWidth: 1,
    paddingVertical: 1.5,
    paddingHorizontal: 4,
    paddingRight: 30,
    color: "black",
    fontSize: 17,
  },
  inputAndroid: {
    borderColor: "grey",
    color: "black",
    borderBottomWidth: 1,

    // borderWidth: 1,
    paddingVertical: 2,
    paddingHorizontal: 5,
    paddingRight: 30,
    fontSize: 17,
  },
});
