import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
//import EditPromotion from "../screens/EditPromotion";
import moment from "moment"
export default function SinglePromotion({ sp, navigation }) {
    const [from, setFrom] = useState("")
    const [to, setTo] = useState("")
    useEffect(() => {
        let date1 = new Date(sp.validFrom * 1000)
        setFrom(`${date1.getDate()}/${date1.getMonth() + 1}/${date1.getFullYear()} ${moment(date1).format('hh:mm A')}`)

        date1 = new Date(sp.validTo * 1000)
        setTo(`${date1.getDate()}/${date1.getMonth() + 1}/${date1.getFullYear()} ${moment(date1).format('hh:mm A')}`)

    }, [])


    return (
        <View style={styles.listbox}>
            <View style={styles.box}>
                <TouchableOpacity onPress={() => navigation.navigate("Promotion", { ...sp, from, to })}>
                    <Text style={{ fontSize: 18 }}>Promotion Name : {sp.promotionName} </Text>
                    <Text style={{ fontSize: 18 }}>Promotion Code : {sp.code}</Text>
                    <Text style={{ fontSize: 18 }}>Discount Amount (Rs): {sp.discountAmount}</Text>
                    <Text style={{ fontSize: 18 }}>Discount Percentage (%): {sp.discountPercent}</Text>
                    <Text style={{ fontSize: 18 }}>Maximum Discount (Rs): {sp.maxDiscount}</Text>
                    <Text style={{ fontSize: 18 }}>Valid From Date : {from} </Text>
                    <Text style={{ fontSize: 18 }}>Valid To Date : {to}</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}
const styles = StyleSheet.create({
    listbox: {
        //  backgroundColor: "grey",
        flex: 1,
        alignItems: "center",

    },
    box: {
        backgroundColor: "#E8E8E8",
        width: 300,
        marginBottom: 10,
        borderRadius: 10,
        paddingHorizontal: 5,
        paddingVertical: 5,
        fontSize: 18,
        shadowColor: "black",
        elevation: 5,
        marginTop: 10,


    }

})