import React, { useContext } from "react";
import { Card, Input, Icon } from "react-native-elements";
import {
	View,
	SafeAreaView,
	Text,
	StyleSheet,
	ScrollView,
	TextInput,
} from "react-native";
import axios from "axios";
import Entypo from "react-native-vector-icons/Entypo";
import { TouchableOpacity } from "react-native-gesture-handler";
import { SERVER_API_URL, API_URL_CLIENT } from "../../config/config";
import { UserContext } from "../../src/context/userContext";

export const CreateClient = ({ route, navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	React.useEffect(() => {
		if (type == "create") {
			navigation.setOptions({
				title: "Create client",
			});
		} else {
			navigation.setOptions({
				title: "Edit client",
			});
		}
	}, [navigation, type]);
	const type = route.params.type;
	console.log("type is ", type);
	const [name, updateName] = React.useState(
		type == "create" ? "" : route.params.item.clientName
	);
	const [slug, updateSlug] = React.useState(
		type == "create" ? "" : route.params.item.slug
	);
	const [email, updateEmail] = React.useState(
		type == "create" ? "" : route.params.item.email
	);
	const [phone, updatePhone] = React.useState(
		type == "create" ? "" : route.params.item.phoneNumber
	);
	const [deliveryDistanceCap, setDeliveryDistanceCap] = React.useState(
		type == "create" ? "5" : route.params.item.deliveryDistanceCap.toString()
	);
	const [warning, setWarning] = React.useState(false);
	const [isValid, setIsValid] = React.useState(null);
	// let isValid;
	let deliveryVal = deliveryDistanceCap;
	const testNumber = (value) => {
		let pattern = /^[0-9]+$/;
		console.log("Value is ", value);
		setDeliveryDistanceCap(value);
		deliveryVal = value;
		console.log("ans is ", pattern.test(value));
		if (pattern.test(value) == true) {
			setIsValid(true);
		} else {
			setIsValid(false);
		}
		console.log("is valid is ", isValid);
		console.log("tracking cap is", deliveryVal);
	};
	const sendData = async () => {
		if ((name && slug && email && phone) == "") {
			alert("Enter All Fields");

			return;
		} else if (isValid == false) {
			alert("Delivery cap invalid");
			return;
		}
		await axios
			.post(
				SERVER_API_URL + API_URL_CLIENT,
				{
					slug: slug,
					email: email,
					phoneNumber: phone,
					clientName: name,
					deliveryDistanceCap: deliveryVal,
				},
				{
					headers: {
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => {
				alert("Client is Created!");

				navigation.navigate("Client");
				console.log(res);
			})
			.catch((err) => console.log(err));
	};
	const editData = async () => {
		if ((name && slug && email && phone) == "") {
			alert("Enter All Fields");

			return;
		} else if (isValid == false) {
			alert("Delivery cap invalid");
			return;
		}

		await axios
			.patch(
				`${SERVER_API_URL}${API_URL_CLIENT}/${route.params.item.slug}`,
				{
					clientName: name,
					slug: slug,
					phoneNumber: phone,
					email: email,
					deliveryDistanceCap: deliveryDistanceCap,
				},
				{
					headers: {
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => {
				console.log(res.data);
				alert("Client updated");
			})
			.catch((err) => console.log(err));
	};
	return (
		<ScrollView style={{ paddingTop: 20 }}>
			<Card containerStyle={{ borderRadius: 20, elevation: 5, paddingTop: 20 }}>
				<Card.Title>Client Data</Card.Title>

				<Input
					label='Name'
					placeholder='Name'
					defaultValue={name}
					onChangeText={(value) => updateName(value)}></Input>

				<Input
					label='Slug'
					placeholder='Slug'
					defaultValue={slug}
					onChangeText={(value) => updateSlug(value)}></Input>

				<Input
					label='Email'
					placeholder='Email'
					defaultValue={email}
					onChangeText={(value) => updateEmail(value)}></Input>

				<Input
					keyboardType='numeric'
					label=' Phone Number'
					placeholder='Phone'
					defaultValue={phone}
					onChangeText={(value) => updatePhone(value)}></Input>

				<Input
					label='Delivery cap'
					pattern={["0 - 9"]}
					keyboardType='number-pad'
					defaultValue={deliveryDistanceCap}
					onChangeText={(value) => {
						console.log(value);
						testNumber(value);
					}}></Input>

				{isValid == false ? (
					<Text style={styles.errortext}>Distance should only be number </Text>
				) : (
					<></>
				)}
			</Card>

			{type == "create" ? (
				<View
					style={{ flexDirection: "row", alignSelf: "center", paddingTop: 20 }}>
					<TouchableOpacity onPress={() => sendData()}>
						<Icon
							type='material-community'
							name='content-save'
							size={50}
							color='green'
						/>
						<Text style={{ fontWeight: "bold", textAlign: "center" }}>
							Save
						</Text>
					</TouchableOpacity>
				</View>
			) : (
				<View
					style={{ flexDirection: "row", alignSelf: "center", paddingTop: 20 }}>
					<TouchableOpacity onPress={() => editData()}>
						<Icon
							type='material-community'
							name='content-save'
							size={50}
							color='green'
						/>
						<Text style={{ fontWeight: "bold", textAlign: "center" }}>
							Save
						</Text>
					</TouchableOpacity>
				</View>
			)}
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	errortext: {
		color: "red",
		fontSize: 12,
	},
	label: {
		marginTop: 10,
		marginBottom: 10,
		fontSize: 16,
		fontWeight: "bold",
	},
});
