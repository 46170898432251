// create a json for product unit data

const ProductUnitData = [
	{
		id: "1",
		name: "Kg",
	},
	{
		id: "2",
		name: "gm",
	},
	{
		id: "3",
		name: "ml",
	},
	{
		id: "4",
		name: "liter",
	},
	{
		id: "5",
		name: "mm",
	},
	{
		id: "6",
		name: "ft",
	},
	{
		id: "7",
		name: "meter",
	},
	{
		id: "8",
		name: "sq. ft.",
	},
	{
		id: "9",
		name: "sq. meter",
	},
	{
		id: "10",
		name: "km",
	},
	{
		id: "11",
		name: "pair",
	},
	{
		id: "12",
		name: "ton",
	},
	{
		id: "13",
		name: "dozen",
	},
];

export default ProductUnitData;
