import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
	ScrollView,
	StyleSheet,
	Text,
	View,
	TouchableOpacity,
} from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { Input } from "react-native-elements";
import RNPickerSelect from "react-native-picker-select";
import SelectBox from "react-native-multi-selectbox";
import { xorBy } from "lodash";
import axios from "axios";
import {
	SERVER_API_URL,
	API_URL_MENU_GROUPS,
	API_URL_MENUS,
} from "../../config/config";
import { UserContext } from "../../src/context/userContext";

const CreateEditMenu = ({ route, navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	useEffect(() => {
		if (type == "create") {
			navigation.setOptions({
				title: "Add menu",
			});
		} else {
			navigation.setOptions({
				title: "Edit menu ",
			});
		}
	}, [navigation, type]);
	const [refresh, setRefresh] = useState(false);
	const type = route.params.type;
	const item = route.params.item;
	// console.log("Type is ", type);
	console.log("Item is", route.params.businessType);
	const id = route.params.id;
	const clientname = route.params.clientname;
	// console.log("id is ", id);
	const [K_OPTIONS, setK_Options] = useState([]);
	const [name, setName] = useState(type == "create" ? "" : item?.name);
	const [menuType, setMenuType] = useState(
		type == "create" ? null : item?.menuType
	);
	const [priority, setPriority] = useState(
		type == "create" ? "" : item?.priority
	);
	// const [groups, setGroups] = useState(type == "create" ? [] : item.groups);
	const [groups, setGroups] = useState(
		type == "edit"
			? []
			: K_OPTIONS.filter((elem) => item?.groups.includes(elem.id.toLowerCase()))
	);

	const [groupData, setGroupData] = useState(null);
	function onMultiChange() {
		return (item) => {
			setGroups(xorBy(groups, [item], "id"));
			// setCollections(collections.push(item));
		};
	}

	const getMenuGroupsByResturantGroup = async () => {
		try {
			const response = await axios.get(
				`${SERVER_API_URL}${API_URL_MENU_GROUPS}`,
				{
					headers: {
						restaurantgroup: id,
					},
				}
			);
			console.log("response is", response.data);

			let data = response.data;
			let myArr = [];
			data.map((elem) => {
				myArr.push({
					item: elem.name,
					id: elem.id,
				});
			});
			// setGroupArr(groupArr);
			setK_Options(myArr);
			if (type == "edit") {
				setGroups(
					K_OPTIONS.filter((elem) =>
						item?.groups.includes(elem.id.toLowerCase())
					)
				);
				setRefresh("hello");
			}
			console.log("group arr is ", groups);
		} catch (error) {
			console.error(error);
		}
	};

	useLayoutEffect(() => {
		getMenuGroupsByResturantGroup();
	}, [refresh]);

	const sendData = async () => {
		let groupsValues = [];
		console.log(
			"Values are",

			id
		);
		Object.keys(groups).forEach(function (key) {
			console.log("Collections are", groups[key].id);
			groupsValues.push(groups[key].id);
			groupsValues = [...new Set(groupsValues)];
		});
		console.log("values is", groupsValues);
		await axios
			.post(
				`${SERVER_API_URL}${API_URL_MENUS}`,
				{
					name: name,
					menuType: menuType,
					priority: parseInt(priority, 10),
					groups: groupsValues,
				},
				{
					headers: {
						restaurantgroup: id,
						client: clientname,
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => {
				alert("Menu item created Check respective Restaurant");

				console.log("data is ", res.data);
			})
			.catch((err) => console.log(err));

		// return navigation.navigate("menuRestaurantGroup");
	};
	const updateData = async () => {
		let groupsValues = [];
		console.log(
			"Values are",

			id
		);
		Object.keys(groups).forEach(function (key) {
			console.log("Collections are", groups[key].id);
			groupsValues.push(groups[key].id);
			groupsValues = [...new Set(groupsValues)];
		});
		console.log("values is", groupsValues);
		await axios
			.patch(
				`${SERVER_API_URL}${API_URL_MENUS}/${item?.id}`,
				{
					name: name,
					menuType: menuType,
					priority: parseInt(priority, 10),
					groups: groupsValues,
				},
				{
					headers: {
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => {
				alert("Menu item update Check respective Restaurant");

				console.log("data is ", res.data);
			})
			.catch((err) => console.log(err));

		// return navigation.navigate("menuRestaurantGroup");
	};
	return (
		<View>
			<ScrollView style={{ padding: 20 }}>
				<View
					style={{
						padding: 10,
						borderRadius: 10,
						backgroundColor: "white",
					}}>
					<View style={styles.input}>
						<Input
							defaultValue={name}
							label='Name'
							labelStyle={{ color: "black" }}
							placeholder='name'
							onChangeText={(value) => setName(value)}></Input>
					</View>
					<View style={styles.input}>
						<Text style={styles.label}>Menu Type</Text>
						<RNPickerSelect
							fixAndroidTouchableBug={true}
							style={pickerSelectStyles}
							useNativeAndroidPickerStyle={false}
							placeholder={{
								label: "Type",
								value: null,
							}}
							value={menuType}
							items={[
								{ label: "All", value: null },

								{ label: "Delivery", value: "delivery" },
								{ label: "Pickup", value: "pickup" },
							]}
							onValueChange={(value) => {
								console.log("status value is", value);
								setMenuType(value);
							}}
						/>
					</View>
					<View style={styles.input}>
						<Input
							value={priority}
							label='Priority'
							keyboardType='number-pad'
							labelStyle={{ color: "black" }}
							placeholder='Priority'
							onChangeText={(value) => setPriority(value)}></Input>
					</View>
					{console.log("inside function", K_OPTIONS)}
					<View style={styles.input}>
						<SelectBox
							listOptionProps={{ nestedScrollEnabled: true }}
							label='Groups'
							labelStyle={styles.label}
							options={K_OPTIONS}
							selectedValues={groups}
							onMultiSelect={onMultiChange()}
							onTapClose={onMultiChange()}
							isMulti
						/>
					</View>
					{type == "create" ? (
						<TouchableOpacity
							style={{
								backgroundColor: "rgb(56,81,126)",
								padding: 10,
								borderRadius: 10,
								marginTop: 10,
								marginBottom: 30,
							}}
							onPress={() => sendData()}>
							<Text
								style={{
									color: "white",
									textAlign: "center",
									textTransform: "capitalize",
									fontSize: 15,
								}}>
								Submit
							</Text>
						</TouchableOpacity>
					) : (
						<TouchableOpacity
							style={{
								backgroundColor: "rgb(56,81,126)",
								padding: 10,
								borderRadius: 10,
								marginTop: 10,
								marginBottom: 30,
							}}
							onPress={() => updateData()}>
							<Text
								style={{
									color: "white",
									textAlign: "center",
									textTransform: "capitalize",
									fontSize: 15,
								}}>
								Update
							</Text>
						</TouchableOpacity>
					)}
				</View>
			</ScrollView>
		</View>
	);
};

export default CreateEditMenu;

const styles = StyleSheet.create({
	label: {
		color: "black",
		marginBottom: 6,
		marginLeft: 10,
		fontSize: 16,
		fontWeight: "bold",
	},
	input: {
		marginVertical: 10,
	},
});
const pickerSelectStyles = StyleSheet.create({
	inputIOS: {
		fontSize: 12,
		marginHorizontal: 5,
		borderWidth: 1,
		borderColor: "gray",
		borderRadius: 4,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon
		padding: 5,
	},
	inputAndroid: {
		fontSize: 12,
		marginHorizontal: 5,

		borderWidth: 0.5,
		borderColor: "black",
		borderRadius: 8,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon

		padding: 5, // to ensure the text is never behind the icon
	},
});
