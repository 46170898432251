import React from "react";
import { View, Text, Button } from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { restaurantMenuGroup } from "../screens/Menu/restaurantMenuGroup";
import { editRestaurant } from "../screens/Restaurants/EditRestaurant";
import { restaurantInfo } from "../screens/Restaurants/restaurantInfo";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import AntDesign from "react-native-vector-icons/AntDesign";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import Menus from "../screens/Menu/Menus";

export const TabNavigator = ({ route, navigation }) => {
	const Tab = createBottomTabNavigator();
	console.log("params is", route.params);
	const id = route.params.params.id;
	const name = route.params.params.name;
	const clientId = route.params.params.clientId;
	const clientname = route.params.params.client;
	const businessType = route.params.params.businessType;

	console.log("client is ", clientname);
	console.log("ClientID==", clientId);
	return (
		<Tab.Navigator>
			<Tab.Screen
				name='restaurantInfo'
				component={restaurantInfo}
				options={{
					title: "Restaurant Details",
					headerShown: false,
					tabBarLabel: "Details",
					tabBarIcon: ({ color, size }) => (
						<MaterialCommunityIcons
							name='information-variant'
							color={color}
							size={30}
						/>
					),
				}}
				initialParams={{ id: id, name: name, clientId: clientId }}
			/>
			<Tab.Screen
				name='editResaurant'
				component={editRestaurant}
				options={{
					title: "Location",

					tabBarLabel: "Location",
					tabBarIcon: ({ color, size }) => (
						<AntDesign name='edit' color={color} size={30} />
					),
				}}
				initialParams={{ id: id, name: name, clientId: clientId }}
			/>

			<Tab.Screen
				name='menu'
				component={Menus}
				options={{
					title: "Menu",

					tabBarLabel: "Menu",
					tabBarIcon: ({ color, size }) => (
						<MaterialIcons name='menu-book' color={color} size={30} />
					),
				}}
				initialParams={{
					id: id,
					clientname: clientname,
					businessType: businessType,
				}}
			/>
			<Tab.Screen
				name='menuRestaurantGroup'
				component={restaurantMenuGroup}
				options={{
					title: "Menu Group",
					headerShown: false,

					tabBarLabel: "Menu Group",
					tabBarIcon: ({ color, size }) => (
						<MaterialIcons name='speaker-group' color={color} size={30} />
					),
				}}
				initialParams={{
					id: id,
					name: name,
					clientId: clientId,
					businessType: businessType,
				}}
			/>
		</Tab.Navigator>
	);
};
