import {
	StyleSheet,
	Text,
	View,
	Image,
	Dimensions,
	TouchableOpacity,
	Linking,
	Platform,
	ScrollView,
	Share,
} from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { Entypo, Feather, FontAwesome } from "@expo/vector-icons";
import { Modal, Portal, Provider, TextInput } from "react-native-paper";
import * as Print from "expo-print";
import { shareAsync } from "expo-sharing";
import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";
import { BottomSheet } from "react-native-btr";
import { UserContext } from "../src/context/userContext";
import axios from "axios";
import {
	API_URL_CLIENT,
	API_URL_MENU_GROUPS,
	API_URL_MENU_ITEMS,
	API_URL_RESTAURANT,
	SERVER_API_URL,
} from "../config/config";
const MenuItems = () => {
	const { state, dispatch } = useContext(UserContext);

	const assignedClient = state.user.attributes["custom:client"];
	let ownerName = state?.user?.attributes["custom:owner_name"];

	const [modalVisible, setModalVisible] = useState(false);
	const showModal = () => setModalVisible(true);
	const hideModal = () => setModalVisible(false);
	const [productCategoryBottomSheet, setProductCategoryBottomSheet] =
		useState(false);
	const toggleProductCategoryDetailsBottomSheet = () => {
		setProductCategoryBottomSheet(!productCategoryBottomSheet);
	};
	const [productCategory, setProductCategory] = useState("");

	const [menuGroupList, setMenuGroupList] = useState([]);
	const [productDetails, setProductDetails] = useState("");
	const [productCategoryName, setProductCategoryName] = useState("");
	const [restaurantId, setRestaurantId] = useState("");
	const [menuItems, setMenuItems] = useState([]);
	const [selectedImage, setSelectedImage] = useState(null);
	const [htmlContent, setHtmlContent] = useState(`
	<!DOCTYPE html>
	<html lang="en">
		<head>
			<meta charset="UTF-8" />
			<meta http-equiv="X-UA-Compatible" content="IE=edge" />
			<meta name="viewport" content="width=device-width, initial-scale=1.0" />
			<title>Document</title>
			<style>
				* {
					margin: 0;
					padding: 0;
				}
				header {
					background-color: #136eb4;
					padding: 40px;
					color: white;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			</style>
		</head>
		<body>
			<header>
				<div>
					<h1>Test shop</h1>
					<p>https://link.com</p>
				</div>
				<div class="logo">
					<h1>Delishtic</h1>
				</div>
			</header>
			<section style="padding: 40px">
				<h1>Berry cake</h1>
				<img
					style="height: 200px; width: 200px; margin: 10px 0"
					src="https://images.unsplash.com/photo-1494253109108-2e30c049369b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8cmFuZG9tfGVufDB8fDB8fA%3D%3D&w=1000&q=80"
					alt=""
				/>
				<p style="color: grey; font-size: 20px; font-weight: bold">
					Here is the description
				</p>
				<p>224</p>
				<button
					style="
						background-color: #136eb4;
						outline: none;
						border: none;
						padding: 10px 20px;
						color: white;
						border-radius: 8px;
						cursor: pointer;
						margin: 10px 0;
					"
				>
					Buy Now
				</button>
			</section>
		</body>
	</html>
	
`);

	const containerStyle = {
		backgroundColor: "white",
		padding: 10,
		marginHorizontal: 10,
		paddingHorizontal: 20,
	};

	const restructureRestaurant = (item) => {
		let labels = [];
		if (item.length) {
			for (let i = 0; i < item.length; i++) {
				labels[i] = { label: item[i].name, value: item[i].id };
			}
		}
		// setRestaurants(labels);
		setRestaurantId(labels[0].value);
		loadMenuGroups(labels[0].value);
		console.log("labels=", labels);
	};
	const loadRestaurants = async (value) => {
		await axios
			.get(`${SERVER_API_URL}${API_URL_RESTAURANT}${API_URL_CLIENT}`, {
				headers: {
					client: value,
				},
			})
			.then((res) => {
				console.log("clients=", res.data);
				restructureRestaurant(res.data);
			})
			.catch((err) => {
				console.log("err2=", err);
				// setRestaurants([]);
			});
	};
	const loadMenuGroups = async (value) => {
		await axios
			.get(`${SERVER_API_URL}${API_URL_MENU_GROUPS}`, {
				headers: {
					restaurantGroup: value,
				},
			})
			.then((res) => {
				console.log("menuGroups=", res.data);
				restructureMenuGroups(res.data);
			})
			.catch((err) => {
				console.log("err4=", err);
				setMenuGroupList([]);
			});
	};
	const restructureMenuGroups = (items) => {
		let labels = [];
		items.sort((a, b) => a.priority - b.priority);
		if (items.length) {
			for (let i = 0; i < items.length; i++) {
				labels[i] = { label: items[i].name, value: items[i].id };
			}
		}
		console.log("labels", labels);
		setMenuGroupList(labels);
		setProductCategory(labels[0].value);
		setProductCategoryName(labels[0].label);
		// setMenuGroup(labels[0].value);
		// setMenuGroupName(labels[0].label);
		// setMenuGroupId(labels[0].value);
	};
	const printToFile = async (content) => {
		// On iOS/android prints the given html. On web prints the HTML from the current page.
		try {
			const { uri } = await Print.printToFileAsync({
				html: content == undefined ? htmlContent : content,
			});

			console.log("uri=", uri);
			await shareAsync(uri, {
				UTI: ".pdf",
				mimeType: "application/pdf",
				dialogTitle: "Share Product",
			})
				.then((res) => {
					console.log("res=", res);
				})
				.catch((err) => {
					console.log(err);
				});
		} catch (error) {
			console.log("error", error);
		}
	};

	const getItems = async () => {
		// setLoading(true);
		if (productCategory == "") {
			return;
		}
		return await axios
			.get(`${SERVER_API_URL}${API_URL_MENU_GROUPS}${API_URL_MENU_ITEMS}`, {
				headers: {
					menugroupid: productCategory,
				},
			})
			.then((res) => {
				// console.log("data is ", res.data);
				// updateItems(res.data.items);
				// sortMenuItems(res.data.items);
				console.log("res is ", res.data.items);
				setMenuItems(res.data.items);
			})
			.catch((err) => {
				console.log("err is", err);
				// setLoading(false);
			});
	};

	useEffect(() => {
		getItems();
	}, [productCategory]);
	useEffect(() => {
		if (assignedClient) {
			loadRestaurants(assignedClient);
		}
	}, []);

	let openShareDialogAsync = async (uri) => {
		// console.log(selectedImage);
		if (Platform.OS === "web") {
			alert(`Uh oh, sharing isn't available on your platform`);
			return;
		}

		await Sharing.shareAsync(uri);
	};
	return (
		<Provider>
			<BottomSheet
				visible={productCategoryBottomSheet}
				onBackButtonPress={toggleProductCategoryDetailsBottomSheet}
				onBackdropPress={toggleProductCategoryDetailsBottomSheet}>
				<View style={{ backgroundColor: "#fff", width: "100%", height: 400 }}>
					<View>
						<View
							style={{
								paddingVertical: 8,
								margin: 10,
								marginHorizontal: 5,
								borderBottomWidth: 0.2,
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								position: "relative",
								borderColor: "lightgrey",
							}}>
							<Text
								style={{
									fontSize: 20,
									textAlign: "center",
									fontWeight: "bold",
								}}>
								Product Category{" "}
							</Text>

							<Entypo
								onPress={() => {
									// setCategory(elem.name);
									toggleProductCategoryDetailsBottomSheet();
								}}
								style={{ position: "absolute", right: 10 }}
								name='cross'
								size={24}
								color='black'
							/>
						</View>
					</View>
					<View style={{ flexDirection: "row", flexWrap: "wrap" }}>
						{menuGroupList.map((elem, index) => {
							return (
								<TouchableOpacity
									onPress={() => {
										setProductCategory(elem.value);
										setProductCategoryName(elem.label);
										toggleProductCategoryDetailsBottomSheet();
									}}
									key={index}
									style={{
										margin: 10,
										marginHorizontal: 5,
										borderWidth: 0.3,
										padding: 10,
										borderRadius: 8,
										flexDirection: "row",
										justifyContent: "center",
										alignItems: "center",
										position: "relative",
										borderColor: "lightgrey",
										marginHorizontal: 10,
									}}>
									<Text
										style={{
											fontSize: 16,
											textAlign: "center",
											fontWeight: "bold",
										}}>
										{elem.label}
									</Text>
								</TouchableOpacity>
							);
						})}
					</View>
					<TouchableOpacity
						onPress={() => {
							setProductCategoryBottomSheet(false);
						}}
						style={{
							borderWidth: 2,
							borderColor: "#e18846",
							padding: 10,
							paddingHorizontal: 20,
							margin: 20,
							marginHorizontal: 20,
							borderRadius: 8,
							position: "absolute",
							bottom: 0,
							left: "50%",
							transform: [{ translateX: -50 }],
						}}>
						<Text
							style={{
								textAlign: "center",
								color: "#e18846",
								fontWeight: "bold",
								fontSize: 16,
							}}>
							Done{" "}
						</Text>
					</TouchableOpacity>
				</View>
			</BottomSheet>

			<Portal>
				<Modal
					visible={modalVisible}
					onDismiss={hideModal}
					contentContainerStyle={containerStyle}>
					<View>
						<View
							style={{
								paddingVertical: 8,
								margin: 10,
								marginHorizontal: 5,
								borderBottomWidth: 0.2,
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								position: "relative",
								borderColor: "lightgrey",
							}}>
							<Text
								style={{
									fontSize: 20,
									textAlign: "center",
									fontWeight: "bold",
								}}>
								Share menu items
							</Text>

							<Entypo
								onPress={hideModal}
								style={{ position: "absolute", right: 10 }}
								name='cross'
								size={24}
								color='black'
							/>
						</View>
					</View>

					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
						}}>
						<TouchableOpacity
							onPress={() => {
								printToFile();
							}}
							style={{
								padding: 15,
								justifyContent: "center",
								alignItems: "center",
							}}>
							<FontAwesome name='file-pdf-o' size={24} color='black' />
							<Text>PDF</Text>
						</TouchableOpacity>
						<View
							style={{
								width: 1.6,
								backgroundColor: "lightgrey",
								height: 100,
							}}></View>
						<TouchableOpacity
							onPress={() => {
								//whatsapp link to share image
								// Linking.openURL("whatsapp://send?text=https://www.google.com/");
								// Share.sharedAction(selectedImage);
								// Share.open(shareImage).catch((err) => console.log(err));
								FileSystem.downloadAsync(
									selectedImage,
									FileSystem.documentDirectory + "image.png"
								)
									.then(({ uri }) => {
										console.log("Finished downloading to ", uri);

										openShareDialogAsync(uri);
									})
									.catch((error) => {
										console.error(error);
									});
								// openShareDialogAsync();
							}}
							style={{
								padding: 15,
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Feather name='image' size={30} color='black' />
							<Text>Text + Image</Text>
						</TouchableOpacity>
					</View>
				</Modal>
			</Portal>
			<ScrollView>
				<View
					style={{
						minHeight: Dimensions.get("screen").height,
						backgroundColor: "white",
						padding: 20,
						paddingHorizontal: 10,
					}}>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "flex-end",
						}}>
						<TouchableOpacity
							onPress={() => setProductCategoryBottomSheet(true)}
							style={{
								borderBottomWidth: 0.6,
								borderColor: "lightgrey",
								marginTop: 20,
								paddingBottom: 10,
								marginBottom: 10,
								flexGrow: 1,
							}}>
							<Text
								style={{
									fontSize: 13,
									marginVertical: 10,
									color: "grey",
									marginBottom: 6,
								}}>
								Product Category{" "}
							</Text>
							<Text
								style={{
									fontSize: 18,
									marginRight: 15,
									color: `${productDetails.length > 0 ? "black" : "grey"}`,
								}}>
								{productCategoryName.length > 0
									? productCategoryName
									: "Enter product Category"}
							</Text>
						</TouchableOpacity>

						<TouchableOpacity
							onPress={() => {
								printToFile(`<!DOCTYPE html>
								<html lang="en">
									<head>
										<meta charset="UTF-8" />
										<meta http-equiv="X-UA-Compatible" content="IE=edge" />
										<meta name="viewport" content="width=device-width, initial-scale=1.0" />
										<title>Document</title>
										<style>
											* {
												margin: 0;
												padding: 0;
											}
											header {
												background-color: #136eb4;
												padding: 40px;
												color: white;
												display: flex;
												justify-content: space-between;
												align-items: center;
											}
										</style>
									</head>
									<body>
									<header>
									<div>
										<h1  style="text-transform: capitalize;">${ownerName}</h1>
										<a href=" https://${assignedClient}.delishtic.com"> Visit</a>
									</div>
									<div class="logo">
										<h1>Delishtic</h1>
									</div>
								</header>
										<section style="padding: 40px">
										
	
											${menuItems.map(
												(elem) => `
												<h1>${elem.name}</h1>
												<img style="height: 200px; width: 200px; margin: 10px 0" src="${elem.images[0]}" alt="" />
											
											
												${elem.description} </p>
												<p>₹ ${elem.price}</p>
												<button style="
													background-color: #136eb4;
													outline: none;
													border: none;
													padding: 10px 20px;
													color: white;
													border-radius: 8px;
													cursor: pointer;
													margin: 10px 0;
												">
													Buy Now
												</button>`
											)}
										</section>
									</body>
								</html>`);
							}}
							style={{
								backgroundColor: "#166bae",
								padding: 10,
								borderRadius: 9,
								marginBottom: 10,
								marginLeft: 10,
							}}>
							<Text style={{ color: "white", fontSize: 15 }}>
								Share Catalogue
							</Text>
						</TouchableOpacity>
					</View>
					{menuItems.map((elem) => (
						<View style={{ marginTop: 10, marginBottom: 20 }}>
							<View
								style={{
									display: "flex",
									flexDirection: "row",
									padding: 10,
									alignItems: "center",
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 2,
									},
									shadowOpacity: 0.25,
									shadowRadius: 3.84,
									backgroundColor: "white",
									elevation: 5,
									borderRadius: 8,
									borderBottomLeftRadius: 0,
									borderBottomRightRadius: 0,
								}}>
								<Image
									style={styles.img}
									source={{
										uri: elem.images[0],
									}}
								/>
								<View style={{ marginLeft: 15 }}>
									<Text
										style={{
											fontSize: 16,
											textTransform: "capitalize",
											marginBottom: 2,
											width: 200,

											fontWeight: "bold",
										}}>
										{elem.name}{" "}
									</Text>
									<Text style={{ marginBottom: 5, width: 150, fontSize: 13 }}>
										{elem.description}
									</Text>
									<Text style={{ fontWeight: "bold", fontSize: 15 }}>
										₹{elem.price}
									</Text>
									{/* <Text style={{ color: "green" }}>instock</Text> */}
								</View>
							</View>
							<TouchableOpacity
								onPress={() => {
									showModal();
									setSelectedImage(elem.images[0]);
									setHtmlContent(`
									<!DOCTYPE html>
									<html lang="en">
										<head>
											<meta charset="UTF-8" />
											<meta http-equiv="X-UA-Compatible" content="IE=edge" />
											<meta name="viewport" content="width=device-width, initial-scale=1.0" />
											<title>Document</title>
											<style>
												* {
													margin: 0;
													padding: 0;
												}
												header {
													background-color: #136eb4;
													padding: 40px;
													color: white;
													display: flex;
													justify-content: space-between;
													align-items: center;
												}
											</style>
										</head>
										<body>
											<header>
												<div>
													<h1  style="text-transform: capitalize;">${ownerName}</h1>
													<a href=" https://${assignedClient}.delishtic.com"> Visit</a>
												</div>
												<div class="logo">
													<h1>Delishtic</h1>
												</div>
											</header>
											<section style="padding: 40px">
												<h1>${elem.name}</h1>
												<img
													style="height: 200px; width: 200px; margin: 10px 0"
													src="${elem.images[0]}"
													alt=""
												/>
												<p style="color: grey; font-size: 20px; font-weight: bold">


${elem.description}												</p>
												<p>₹ ${elem.price}</p>
												<button
													style="
														background-color: #136eb4;
														outline: none;
														border: none;
														padding: 10px 20px;
														color: white;
														border-radius: 8px;
														cursor: pointer;
														margin: 10px 0;
													"
												>
													Buy Now
												</button>
											</section>
										</body>
									</html>
									
								`);
								}}
								style={{
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
									padding: 20,
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 2,
									},
									shadowOpacity: 0.25,
									shadowRadius: 3.84,
									backgroundColor: "white",
									elevation: 5,
									borderTopWidth: 1,
									borderColor: "lightgrey",
									borderRadius: 8,
									borderTopRightRadius: 0,
									borderTopLeftRadius: 0,
								}}>
								<Entypo
									style={{ marginRight: 10 }}
									name='share'
									size={24}
									color='black'
								/>
								<Text>Share product</Text>
							</TouchableOpacity>
						</View>
					))}
				</View>
			</ScrollView>
		</Provider>
	);
};

export default MenuItems;

const styles = StyleSheet.create({
	container: {
		paddingTop: 50,
	},
	tinyLogo: {
		width: 50,
		height: 50,
	},
	img: {
		width: 140,
		height: 140,
	},
});
