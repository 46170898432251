import {
	Dimensions,
	StyleSheet,
	Text,
	View,
	ScrollView,
	TouchableOpacity,
	Button,
	Image,
	Platform,
	Modal,
	Pressable,
} from "react-native";
import React, { useRef, useState } from "react";
import { BottomSheet } from "react-native-btr";
import categories from "./categories";
import {
	SERVER_API_URL,
	API_URL_CLIENT,
	API_URL_RESTAURANT,
} from "../../config/config";
import { UserContext } from "../../src/context/userContext";
import { TextInput } from "react-native-paper";
import * as ImagePicker from "expo-image-picker";
import { businessTypeFood } from "./businessSubType";
import { businessSubTypeShop } from "./businessSubType";
const OnBoarding = ({ navigation }) => {
	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [businessSubType, setBusinessSubType] = useState("");
	const [visible, setVisible] = useState(false);
	const [businessTypeBottomSheet, setBusinessTypeBottomSheet] = useState(false);
	const [businessType, setBusinessType] = useState("");
	const [showBusinessType, setShowBusinessType] = useState("");
	const [logoImage, setLogoImage] = useState(null);
	const [logoUri, setLogoUri] = useState("");
	const [bannerImage, setBannerImage] = useState("");
	const [bannerUri, setBannerUri] = useState("");
	const [nameError, setNameError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [businessTypeError, setBusinessTypeError] = useState(false);
	const [businessSubTypeError, setBusinessSubTypeError] = useState(false);
	const [logoImageError, setLogoImageError] = useState(false);
	const [bannerImageError, setBannerImageError] = useState(false);
	const [businessTypeModal, setBusinessTypeModal] = useState(false);
	const [businessSubTypeModal, setBusinessSubTypeModal] = useState(false);
	const toggleBottomNavigationView = () => {
		setVisible(!visible);
	};

	const toggleSheet = () => {
		setBusinessTypeBottomSheet(!businessTypeBottomSheet);
	};
	const pickImage = async () => {
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.All,
			allowsEditing: true,
			aspect: [4, 3],
			quality: 1,
		});

		console.log("result==", result);

		if (!result.cancelled) {
			setLogoImage(result.uri);
			setLogoUri(result.uri);
		}
	};

	const pickBannerImage = async () => {
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.All,
			allowsEditing: true,
			aspect: [4, 3],
			quality: 1,
		});
		// console.log(result);

		if (!result.cancelled) {
			// const awsResult = await Storage.put(result.uri, "Banner Image");

			setBannerImage(result.uri);
			setBannerUri(result.uri);
		}
	};
	const submit = () => {
		if (name === "") {
			setNameError(true);
		}
		if (email === "") {
			setEmailError(true);
		}
		if (businessType === "") {
			setBusinessTypeError(true);
		}
		if (businessSubType === "") {
			setBusinessSubTypeError(true);
		}
		if (logoImage === null) {
			setLogoImageError(true);
		}
		if (bannerImage === null) {
			setBannerImageError(true);
		}
		if (
			name !== "" &&
			email !== "" &&
			businessType !== "" &&
			businessSubType !== "" &&
			logoImage !== null &&
			bannerImage !== null
		) {
			setNameError(false);
			setEmailError(false);
			setBusinessTypeError(false);
			setBusinessSubTypeError(false);
			setLogoImageError(false);
			setBannerImageError(false);
			setVisible(false);
			setBusinessTypeBottomSheet(false);
			setBusinessType("");
			setBusinessSubType("");
			setLogoImage(null);
			setBannerImage(null);
			setLogoUri("");
			setBannerUri("");
			navigation.navigate("Login");
		}

		//clear error after 3 second
		setTimeout(() => {
			setNameError(false);
			setEmailError(false);
			setBusinessTypeError(false);
			setBusinessSubTypeError(false);
			setLogoImageError(false);
			setBannerImageError(false);
		}, 3000);
	};
	const containerStyle = {
		backgroundColor: "white",
		padding: 10,
		marginHorizontal: 10,
		paddingHorizontal: 20,
	};
	return (
		<>
			<View style={styles.centeredView}>
				<Modal
					animationType='slide'
					transparent={true}
					visible={businessTypeModal}
					onRequestClose={() => {
						setBusinessTypeModal(!businessTypeModal);
					}}>
					<View style={styles.centeredView}>
						<View style={styles.modalView}>
							<Text style={styles.modalText}>Enter Business Type</Text>
							<TextInput
								// style={styles.textInput}
								underlineColorAndroid='transparent'
								label='Business Type'
								autoCapitalize='none'
								onChangeText={(value) => {
									setShowBusinessType(value);
								}}
								theme={{ colors: { primary: "#136eb4" } }}
								value={showBusinessType}
							/>

							<Pressable
								style={[styles.button, styles.buttonClose]}
								onPress={() => setBusinessTypeModal(!businessTypeModal)}>
								<Text style={styles.textStyle}>Done</Text>
							</Pressable>
						</View>
					</View>
				</Modal>

				<Modal
					animationType='slide'
					transparent={true}
					visible={businessSubTypeModal}
					onRequestClose={() => {
						setBusinessSubTypeModal(!businessSubTypeModal);
					}}>
					<View style={styles.centeredView}>
						<View style={styles.modalView}>
							<Text style={styles.modalText}>Enter Business Subtype</Text>
							<TextInput
								// style={styles.textInput}
								underlineColorAndroid='transparent'
								label='Business Subtype'
								autoCapitalize='none'
								onChangeText={(value) => {
									setBusinessSubType(value);
								}}
								theme={{ colors: { primary: "#136eb4" } }}
								value={businessSubType}
							/>
							<Pressable
								style={[styles.button, styles.buttonClose]}
								onPress={() => setBusinessSubTypeModal(!businessSubTypeModal)}>
								<Text style={styles.textStyle}>Done</Text>
							</Pressable>
						</View>
					</View>
				</Modal>
			</View>
			<View style={styles.mainView}>
				<BottomSheet
					visible={visible}
					onBackButtonPress={toggleBottomNavigationView}
					onBackdropPress={toggleBottomNavigationView}>
					<View style={{ backgroundColor: "#fff", width: "100%", height: 400 }}>
						<ScrollView>
							{businessType == "food"
								? businessTypeFood.map((elem) => (
										<TouchableOpacity
											onPress={() => {
												setBusinessSubType(elem);
												toggleBottomNavigationView();
											}}
											style={{
												paddingVertical: 8,
												margin: 10,
												marginHorizontal: 5,
												borderBottomWidth: 0.2,
												borderColor: "lightgrey",
											}}
											key={elem.id}>
											<Text style={{ fontSize: 16 }}>{elem}</Text>
										</TouchableOpacity>
								  ))
								: businessSubTypeShop.map((elem) => (
										<TouchableOpacity
											onPress={() => {
												setBusinessSubType(elem);
												toggleBottomNavigationView();
											}}
											style={{
												paddingVertical: 8,
												margin: 10,
												marginHorizontal: 5,
												borderBottomWidth: 0.2,
												borderColor: "lightgrey",
											}}
											key={elem.id}>
											<Text style={{ fontSize: 16 }}>{elem}</Text>
										</TouchableOpacity>
								  ))}
						</ScrollView>
						<TouchableOpacity
							onPress={() => {
								setVisible(false);
								setBusinessSubTypeModal(true);
							}}
							style={{
								borderWidth: 2,
								borderColor: "#e18846",
								padding: 20,
								margin: 20,
								marginHorizontal: 20,
								borderRadius: 8,
							}}>
							<Text
								style={{
									textAlign: "center",
									color: "#e18846",
									fontWeight: "bold",
									fontSize: 16,
								}}>
								My business Subtype is not listed
							</Text>
						</TouchableOpacity>
					</View>
				</BottomSheet>

				<BottomSheet
					visible={businessTypeBottomSheet}
					onBackButtonPress={toggleSheet}
					onBackdropPress={toggleSheet}>
					<View style={{ backgroundColor: "#fff", width: "100%", height: 300 }}>
						<ScrollView>
							<TouchableOpacity
								onPress={() => {
									setBusinessType("food");
									setShowBusinessType("Restaurant");
									setBusinessSubType("");
									setBusinessTypeBottomSheet(false);
								}}
								style={{
									paddingVertical: 8,
									margin: 10,
									marginHorizontal: 5,
									borderBottomWidth: 0.2,
									borderColor: "lightgrey",
								}}>
								<Text style={{ fontSize: 16 }}>Restaurant</Text>
							</TouchableOpacity>
							<TouchableOpacity
								onPress={() => {
									setShowBusinessType("Store");

									setBusinessType("shop");
									setBusinessSubType("");
									setBusinessTypeBottomSheet(false);
								}}
								style={{
									paddingVertical: 8,
									margin: 10,
									marginHorizontal: 5,
									borderBottomWidth: 0.2,
									borderColor: "lightgrey",
								}}>
								<Text style={{ fontSize: 16 }}>Store</Text>
							</TouchableOpacity>
						</ScrollView>
						<TouchableOpacity
							onPress={() => {
								setBusinessTypeModal(true);
								setBusinessTypeBottomSheet(false);
							}}
							style={{
								borderWidth: 2,
								borderColor: "#e18846",
								padding: 20,
								margin: 20,
								marginHorizontal: 20,
								borderRadius: 8,
							}}>
							<Text
								style={{
									textAlign: "center",
									color: "#e18846",
									fontWeight: "bold",
									fontSize: 16,
								}}>
								My business Type is not listed
							</Text>
						</TouchableOpacity>
					</View>
				</BottomSheet>
				<Text style={styles.title}>Enter Business details</Text>
				<ScrollView style={{ marginBottom: 160 }}>
					<View style={styles.inputContainer}>
						{nameError ? (
							<Text style={styles.error}>{"Business Name is required"}</Text>
						) : null}
						<TextInput
							style={styles.textInput}
							underlineColorAndroid='transparent'
							label='Business Name'
							autoCapitalize='none'
							onChangeText={(value) => {
								setName(value);
								if (value.length > 0) {
									setNameError(false);
								}
							}}
							theme={{ colors: { primary: "#136eb4" } }}
							value={name}
						/>
					</View>
					<View style={styles.inputContainer}>
						{emailError ? (
							<Text style={styles.error}>{"Email is required"}</Text>
						) : null}
						<TextInput
							style={styles.textInput}
							underlineColorAndroid='transparent'
							label='Email'
							autoCapitalize='none'
							onChangeText={(value) => {
								setEmail(value);
								if (value.length > 0) {
									setEmailError(false);
								}
							}}
							theme={{ colors: { primary: "#136eb4" } }}
							value={email}
						/>
					</View>
					<View style={styles.inputContainer}>
						{businessTypeError ? (
							<Text style={styles.error}>{"Business Type is required"}</Text>
						) : null}
						<TouchableOpacity onPress={() => toggleSheet()}>
							{/* <TextInput
								style={styles.textInput}
								underlineColorAndroid='transparent'
								label='Business Type'
								autoCapitalize='none'
								onChangeText={(value) => {
									setBusinessType(value);
									if (value.length > 0) {
										setBusinessTypeError(false);
									}
								}}
								theme={{ colors: { primary: "#136eb4" } }}
								value={showBusinessType}
								onFocus={() => {
									if (Platform.OS !== "web") {
										setBusinessTypeBottomSheet(true);
									}
								}}
							/> */}
							<Text
								style={{
									fontSize: 14,
									color: "#136eb4",
									marginLeft: 10,
									marginTop: 5,
								}}>
								Business category
							</Text>
							<Text
								style={{
									fontSize: 16,
									color: "grey",
									marginLeft: 10,
									marginTop: 8,
									textTransform: "capitalize",
									borderBottomColor: "lightgrey",
									borderBottomWidth: 0.8,
								}}>
								{showBusinessType}
							</Text>
						</TouchableOpacity>
					</View>
					<View style={styles.inputContainer}>
						{businessSubTypeError ? (
							<Text style={styles.error}>{"Business subtype is required"}</Text>
						) : null}
						<TouchableOpacity onPress={() => toggleBottomNavigationView()}>
							{/* <TextInput
								style={styles.textInput}
								underlineColorAndroid='transparent'
								label='Business  Subtype'
								autoCapitalize='none'
								theme={{ colors: { primary: "#136eb4" } }}
								value={businessSubType}
								onFocus={() => {
									if (Platform.OS !== "web") {
										setVisible(true);
									}
								}}
							/> */}

							<Text
								style={{
									fontSize: 14,
									color: "#136eb4",
									marginLeft: 10,
									marginTop: 10,
								}}>
								Business Subtype
							</Text>
							<Text
								style={{
									fontSize: 16,
									color: "grey",
									marginLeft: 10,
									marginTop: 8,
									textTransform: "capitalize",
									borderBottomColor: "lightgrey",
									borderBottomWidth: 0.8,
								}}>
								{businessSubType}
							</Text>
						</TouchableOpacity>
					</View>
					<View>
						{logoImageError ? (
							<Text style={styles.error}>{"Logo image is required"}</Text>
						) : null}
						<Text style={styles.label}>Logo Image</Text>
						<Button title='Choose image from Gallery' onPress={pickImage} />

						{logoImage !== null ? (
							<Image
								source={{ uri: logoImage }}
								style={{ width: 200, height: 200, marginVertical: 20 }}
							/>
						) : (
							<></>
						)}
					</View>

					<View>
						{bannerImageError ? (
							<Text style={styles.error}>{"Banner Image is required"}</Text>
						) : null}
						<Text style={styles.label}>Banner Image</Text>
						<Button
							title='Choose image from Gallery'
							onPress={pickBannerImage}
						/>

						{bannerImage !== "" ? (
							<>
								<Image
									source={{ uri: bannerImage }}
									style={{ width: 200, height: 200, marginVertical: 20 }}
								/>
							</>
						) : (
							<></>
						)}
					</View>

					<TouchableOpacity
						disabled={
							!(
								name.length > 0 &&
								email.length > 0 &&
								businessType.length > 0 &&
								businessSubType.length > 0 &&
								logoImage !== null &&
								bannerImage !== null > 0
							)
						}
						onPress={() => submit()}
						style={{
							// backgroundColor: { disabled: "#136eb4" },
							//change background color when disabled
							backgroundColor:
								name.length > 0 &&
								email.length > 0 &&
								businessType.length > 0 &&
								businessSubType.length > 0 &&
								logoImage !== null &&
								bannerImage !== null > 0
									? "#136eb4"
									: "#ccc",

							borderRadius: 10,
							padding: 17,
							borderRadius: 8,
							marginTop: 20,
						}}>
						<Text style={{ color: "white", textAlign: "center", fontSize: 18 }}>
							Finish
						</Text>
					</TouchableOpacity>
				</ScrollView>
			</View>
		</>
	);
};

export default OnBoarding;

const styles = StyleSheet.create({
	label: {
		marginTop: 10,
		marginBottom: 10,
		fontSize: 16,
		fontWeight: "bold",
	},
	mainView: {
		backgroundColor: "white",
		minHeight: Dimensions.get("screen").height,
		paddingHorizontal: 15,
		position: "relative",
	},

	title: {
		fontSize: 20,
		fontWeight: "bold",
		marginVertical: 10,
	},
	textInput: {
		backgroundColor: "white",
		marginBottom: 10,
	},
	error: {
		color: "red",
		fontSize: 12,
		marginBottom: 10,
	},
	centeredView: {
		flex: 1,
		justifyContent: "center",

		// alignItems: "center",
		// width: Dimensions.get("screen").width,
		// top: Dimensions.get("screen").height,
	},
	modalView: {
		margin: 20,
		backgroundColor: "white",
		borderRadius: 20,
		padding: 35,
		paddingHorizontal: 10,
		// alignItems: "center",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
	},
	modalText: {
		marginBottom: 10,
	},
	textStyle: {
		color: "white",
		fontWeight: "bold",
		textAlign: "center",
	},
	buttonClose: {
		marginVertical: 10,
		borderRadius: 8,
		backgroundColor: "#2196F3",
		padding: 10,
	},
	inputContainer: {
		marginVertical: 5,
	},
});
