import React, { useContext, useState } from "react";
import { StyleSheet, Text, TextInput, View, Button } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { UserContext } from "../src/context/userContext";
import { Auth } from "aws-amplify";

//Main functional component
const AccountCreate = ({ route, navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	const [password, setPassword] = useState("");

	// Getting data from navigation params
	const {
		clientName,
		restaurantName,
		mobileNumber,
		managerName,
		notificationEmail,
		onBoardingDate,
		id,
		ownerName,
		ownerEmailId,
	} = route.params;
	console.log(ownerName);
	//Sign up function to store some informtion about merchant to AWS
	async function signUp() {
		console.log("before");
		try {
			const { user } = await Auth.signUp({
				username: notificationEmail,
				password: password,
				attributes: {
					email: notificationEmail,
					"custom:owner_name": ownerName,
					"custom:owner_email_id": ownerEmailId,
					"custom:role": "merchant",
					"custom:client": clientName,
				},
			});

			console.log(user);
			navigation.navigate("View / Approve Merchant Accounts");
		} catch (error) {
			console.log("error signing up:", error);
			alert(error.message);
		}
	}

	// This function run when user submit the form (it call signUp then dispatches the action which change the approved status from pending to approved and then navigate it to new merchant page)
	const submitData = () => {
		if (password == "") {
			alert("Password empty");
		} else {
			signUp();
			dispatch({ type: "submitMerchant", payload: id });
		}
	};
	return (
		<SafeAreaView>
			<Text
				style={{
					fontSize: 30,
					textAlign: "center",
					marginTop: 30,
					fontWeight: "bold",
					color: "black",
				}}>
				Create Account
			</Text>

			<View style={styles.main_container}>
				<View style={styles.item_view}>
					<Text style={styles.title}>Client Name</Text>
					<Text> {clientName} </Text>
				</View>
				<View style={styles.item_view}>
					<Text style={styles.title}>Restaurant Name</Text>
					<Text>{restaurantName}</Text>
				</View>
				<View style={styles.item_view}>
					<Text style={styles.title}>Manager Name is</Text>
					<Text> {managerName}</Text>
				</View>
				<View style={styles.item_view}>
					<Text style={styles.title}>Manager Mobile is</Text>
					<Text>{mobileNumber}</Text>
				</View>
				<View style={styles.item_view}>
					<Text style={styles.title}>Manager Email</Text>

					<Text>{notificationEmail}</Text>
				</View>
				<View style={styles.item_view}>
					<Text style={styles.title}>Onboarding Date</Text>
					<Text>{onBoardingDate}</Text>
				</View>
				<TextInput
					style={{
						marginVertical: 10,
						padding: 8,
						borderRadius: 8,
						borderColor: "black",
						borderWidth: 2,
					}}
					placeholder='Password'
					value={password}
					onChangeText={(value) => setPassword(value)}
				/>
				<View style={{ marginVertical: 10 }}>
					<Button onPress={() => submitData()} title='Submit' />
				</View>
			</View>
		</SafeAreaView>
	);
};

export default AccountCreate;

const styles = StyleSheet.create({
	title: {
		fontWeight: "bold",
		fontSize: 18,
	},
	item_view: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginVertical: 5,
	},
	main_container: {
		marginHorizontal: 5,
		padding: 20,
		marginVertical: 30,
		backgroundColor: "#fff",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.36,
		shadowRadius: 6.68,

		elevation: 11,
	},
});
