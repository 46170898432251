const imageData = [
	{
		category: "Fresh Vegetables",
		name: "AMLA (GOOSEBERRY)",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/amla.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "AVARAI",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/avarai.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "BABY CORN",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/babycorn.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "BABY POTATO        ",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/babypotato.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "BANANA FLOWER",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/bananaflower.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "BANANA LEAF ",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/bananaleaf.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "BANANA RAW",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/bananaraw.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "BANANA STEAM ",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/bananastem.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "BEANS",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/beans.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "BEETROOT",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/beetroot.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "BITTER GUARD BIG        ",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/bitterguard.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "BITTER GUARD SMALL",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/bitterguard.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "BOTTLE GUARD ",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/bottleguard.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "BRINJAL UJALA",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/brinjalujala.jpg",
	},

	{
		category: "Fresh Vegetables",
		name: "BRINJAL VARI",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/brinjalvari.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "BROCCOLI",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/broccoli.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "CABBAGE",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/cabbage.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "CAPSICUM COLOUR        ",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/capsicumcolor.jpg",
	},

	{
		category: "Fresh Vegetables",
		name: "CAPSICUM GREEN        ",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/capsicumgreen.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "CARROT",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/carrot.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "CAULIFLOWER",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/cauliflower.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "CHILLI GREEN",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/chilligreen.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "CHOW CHOW",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/chowchow.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "COCCINIA (KOVAKKAI)",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/kovakkai.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "COCONUT",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/coconut.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "COLACASIA (SENAI)",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/senai.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "CORIANDER LEAVES",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/corianderleaves.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "CUCUMBER",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/cucumber.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "CURRY LEAVES",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/curryleaves.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "DRUM STICK",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/drumstick.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "GARLIC ORDINARY",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/garlic.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "GARLIC HILL",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/garlichill.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "GINGER",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/ginger.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "MANGO RAW",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/mangoraw.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "MUSHROOM",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/mushroom.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "POTATO",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/potato.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "RADISH WHITE",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/radish.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "SUNDAKAI",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/sundakkai.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "SWEET CORN",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/sweetcorn.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "SWEET LIME",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/sweetlime.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "TAPIACO",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/tapiaco.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "TOMATO NAADU",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/tomatodesi.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "TOMATO NAVEEN",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/tomato.jpg",
	},
	{
		category: "Fresh Vegetables",
		name: "YAM BIG",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshvegetables/yam.jpg",
	},
	{
		category: "Fresh Fruits",
		name: "APPLE ROYALGALA",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshfruits/appleroyalgala.jpg",
	},
	{
		category: "Fresh Fruits",
		name: "APPLE SIMLA",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshfruits/applesimla.jpg",
	},
	{
		category: "Fresh Fruits",
		name: "APPLE WASHINGTON ",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshfruits/applewashington.jpg",
	},
	{
		category: "Fresh Fruits",
		name: "BANANA ELAKKI",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshfruits/bananaelakki.jpg",
	},
	{
		category: "Fresh Fruits",
		name: "BANANA HILL",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshfruits/bananahill.jpg",
	},
	{
		category: "Fresh Fruits",
		name: "BANANA KARPOORAVALLI",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshfruits/bananakarpooravalli.jpeg",
	},
	{
		category: "Fresh Fruits",
		name: "BANANA MORIS",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshfruits/bananamoris.jpg",
	},
	{
		category: "Fresh Fruits",
		name: "BANANA NENDRAN",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshfruits/banananendran.jpg",
	},
	{
		category: "Fresh Fruits",
		name: "BANANA POOVAN",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshfruits/bananapoovan.jpg",
	},
	{
		category: "Fresh Fruits",
		name: "BANANA RED",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshfruits/bananared.jpg",
	},
	{
		category: "Fresh Fruits",
		name: "FIG",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshfruits/fig.jpg",
	},
	{
		category: "Fresh Fruits",
		name: "GUAVA",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshfruits/guava.jpg",
	},
	{
		category: "Fresh Fruits",
		name: "MANGO BANGANAPALLI",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshfruits/mangobanganapalli.jpg",
	},
	{
		category: "Fresh Fruits",
		name: "MUSK MELON",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshfruits/muskmelon.jpg",
	},
	{
		category: "Fresh Fruits",
		name: "PAPAYA",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshfruits/papaya.jpg",
	},
	{
		category: "Fresh Fruits",
		name: "PINEAPPLE",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshfruits/pineapple.jpg",
	},
	{
		category: "Fresh Fruits",
		name: "POMEGRANATE",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshfruits/pomegranate.jpg",
	},
	{
		category: "Fresh Fruits",
		name: "WATER MELON KIRAN",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshfruits/watermelonkiran.jpg",
	},
	{
		category: "Fresh Fruits",
		name: "WATER MELON STRIPED",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/freshfruits/watermelonstriped.jpg",
	},
	{
		category: "Dairy",
		name: "AASHIRVAD SVASTI PURE COW GHEE 500ML",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/dairy/aashirvadcowghee.jpg",
	},
	{
		category: "Dairy",
		name: "AAVIN BLUE MAGIC MILK 500ML",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/dairy/aavinbluemagicmilk.jpg",
	},
	{
		category: "Dairy",
		name: "AAVIN GREEN MAGIC MILK 500ML",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/dairy/aavingreenmagicmilk.jpg",
	},
	{
		category: "Dairy",
		name: "AMUL BUTTER PASTEURISED 500 GMS",
		image:
			"https://s3.ap-south-1.amazonaws.com/images.delishtic.com/products/dairy/amulbutterpasteurised.jpg",
	},
	{
		category: "Dairy",
		name: "",
		image: "",
	},
	{
		category: "Dairy",
		name: "",
		image: "",
	},
	{
		category: "Dairy",
		name: "",
		image: "",
	},
	{
		category: "Dairy",
		name: "",
		image: "",
	},
	{
		category: "Dairy",
		name: "",
		image: "",
	},
	{
		category: "Dairy",
		name: "",
		image: "",
	},
];

export default imageData;
