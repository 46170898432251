import React, { useContext } from "react";
import { View, Button } from "react-native";
import { Input, Card } from "react-native-elements";
import axios from "axios";
import { SERVER_API_URL, API_URL_ORDER } from "../../config/config";
import { UserContext } from "../../src/context/userContext";

export const EditOrder = ({ route, navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	console.log(route);
	let item = route.params.params.item;
	const [orderStatus, updateStatus] = React.useState(item.data.orderStatus);
	const [discount, updateDiscount] = React.useState(item.data.discount);
	const [deliveryAddress, updateAddress] = React.useState(
		item.data.deliveryAddress
	);

	const sendData = async () => {
		await axios
			.patch(
				`${SERVER_API_URL}${API_URL_ORDER}/${item.id}`,
				{
					orderStatus: orderStatus,
					discount: discount,
					deliveryAddress: deliveryAddress,
				},
				{
					headers: {
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => alert("Data Updated For Order"))
			.catch((err) => console.log(err));
		return navigation.navigate("orders");
	};
	// const sendData = async () => {
	// 		await axios
	// 			.patch(`https://dev-api.delishtic.com/orders/${item.id}`, {
	// 				orderStatus: orderStatus,
	// 				discount: discount,
	// 				deliveryAddress: deliveryAddress,
	// 			})
	// 			.then((res) => alert("Data Updated For Order"))
	// 			.catch((err) => console.log(err));
	// 		return navigation.navigate("Orders");
	// };

	return (
		<View>
			<Card>
				<Input
					label='Order status'
					defaultValue={orderStatus}
					onChangeText={(value) => updateStatus(value)}
				/>
				<Input
					label='Discount'
					defaultValue={discount}
					onChangeText={(value) => updateDiscount(value)}
				/>
				<Input
					label='Address'
					defaultValue={deliveryAddress}
					onChangeText={(value) => updateAddress(value)}
				/>
				<Button title='Submit' onPress={() => sendData()} />
			</Card>
		</View>
	);
};
