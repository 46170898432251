import {
	Dimensions,
	StyleSheet,
	Text,
	TouchableOpacity,
	Button,
	View,
	ScrollView,
} from "react-native";
import React, { useState } from "react";
import Collapsible from "react-native-collapsible";
import { Entypo } from "@expo/vector-icons";
import { TextInput } from "react-native-paper";
import { TriangleColorPicker, fromHsv, toHsv } from "react-native-color-picker";
import Modal from "react-native-modal";

const AddVariant = ({ navigation }) => {
	const [numberOfSize, setNumberOfSize] = useState([0]);
	const [numberOfColor, setNumberOfColor] = useState([0]);
	const [isCollapsed, setIsCollapsed] = useState(true);
	const [isColorCollapsed, setIsColorCollapsed] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [colors, setColors] = useState([]);
	const toggleModal = () => {
		setIsModalVisible(!isModalVisible);
	};
	let selectedColor = "";
	return (
		<ScrollView
			style={{
				backgroundColor: "white",
				position: "relative",
			}}>
			<Modal
				isVisible={isModalVisible}
				onBackdropPress={() => setIsModalVisible(false)}>
				<TriangleColorPicker
					// sliderComponent={Slider}

					onColorChange={(color) => {
						console.log("color is ", fromHsv(color));
						selectedColor = fromHsv(color);
					}}
					style={{ flex: 1 }}
				/>
				<TouchableOpacity
					style={{
						backgroundColor: "white",
						padding: 10,
						justifyContent: "center",
						alignItems: "center",
						marginVertical: 10,
						borderRadius: 8,
					}}
					onPress={() => {
						setColors([...colors, fromHsv(selectedColor)]);
						setIsModalVisible(false);
					}}>
					<Text style={{ fontWeight: "bold", fontSize: 16 }}>Select</Text>
				</TouchableOpacity>
			</Modal>

			<View
				style={{
					padding: 20,
				}}>
				<TouchableOpacity
					onPress={() => setIsCollapsed(!isCollapsed)}
					style={{
						flexDirection: "row",
						marginTop: 20,
						justifyContent: "space-between",
						// padding: 20,
					}}>
					<Text>Size</Text>
					{isCollapsed ? (
						<Entypo name='chevron-down' size={24} color='black' />
					) : (
						<Entypo name='chevron-up' size={24} color='black' />
					)}
				</TouchableOpacity>

				<Collapsible collapsed={isCollapsed}>
					{numberOfSize.map((_, idx) => (
						<View
							style={{
								marginTop: 10,
							}}>
							<TextInput
								// onChangeText={(value) => setProductUnit(value)}
								style={styles.textInput}
								underlineColorAndroid='transparent'
								label={`Size ${idx + 1}`}
								autoCapitalize='none'
								theme={{ colors: { primary: "#136eb4" } }}
								// value={productUnit}
							/>
							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
								}}>
								<TextInput
									// onChangeText={(value) => setProductUnit(value)}
									style={{ ...styles.textInput, width: "47%" }}
									underlineColorAndroid='transparent'
									label={"Price"}
									autoCapitalize='none'
									theme={{ colors: { primary: "#136eb4" } }}
									// value={productUnit}
								/>
								<TextInput
									// onChangeText={(value) => setProductUnit(value)}
									style={{ ...styles.textInput, width: "50%" }}
									underlineColorAndroid='transparent'
									label={"Discounted Price"}
									autoCapitalize='none'
									theme={{ colors: { primary: "#136eb4" } }}
									// value={productUnit}
								/>
							</View>
							<TouchableOpacity
								onPress={() => {
									setNumberOfSize(numberOfSize.filter((_, i) => i !== idx));
								}}>
								<Text
									style={{
										color: "red",
										fontWeight: "700",
										textAlign: "right",
									}}>
									DELETE
								</Text>
							</TouchableOpacity>
						</View>
					))}
					<TouchableOpacity
						onPress={() => {
							setNumberOfSize([...numberOfSize, numberOfSize.length]);
						}}
						style={{
							borderWidth: 0.7,
							borderColor: "lightgrey",
							padding: 10,
							width: 200,
							justifyContent: "center",
							alignSelf: "center",
							alignItems: "center",
							borderRadius: 8,
						}}>
						<Text
							style={{
								color: "grey",
								textTransform: "capitalize",
								fontSize: 14,
								fontWeight: "bold",
							}}>
							Add another size
						</Text>
					</TouchableOpacity>
				</Collapsible>
				<TouchableOpacity
					onPress={() => setIsColorCollapsed(!isColorCollapsed)}
					style={{
						flexDirection: "row",
						marginTop: 20,
						justifyContent: "space-between",
						// padding: 20,
					}}>
					<Text>Colour</Text>
					{isColorCollapsed ? (
						<Entypo name='chevron-down' size={24} color='black' />
					) : (
						<Entypo name='chevron-up' size={24} color='black' />
					)}
				</TouchableOpacity>
				<Collapsible collapsed={isColorCollapsed}>
					{colors.map((elem, idx) => (
						<View
							style={{
								marginTop: 10,
							}}>
							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
								}}>
								<View
									style={{
										height: 50,
										width: 50,
										borderRadius: 8,
										backgroundColor: elem,
										marginHorizontal: 10,
									}}></View>
								<TouchableOpacity
									onPress={() => {
										// setNumberOfColor(numberOfColor.filter((_, i) => i !== idx));
										setColors(colors.filter((_, i) => i !== idx));
									}}>
									<Text
										style={{
											color: "red",
											fontWeight: "700",
											textAlign: "right",
										}}>
										DELETE
									</Text>
								</TouchableOpacity>
							</View>
						</View>
					))}
					<TouchableOpacity
						onPress={toggleModal}
						style={{
							borderWidth: 0.7,
							borderColor: "lightgrey",
							padding: 10,
							width: 200,
							justifyContent: "center",
							alignSelf: "center",
							alignItems: "center",
							borderRadius: 8,
						}}>
						<Text
							style={{
								color: "grey",
								textTransform: "capitalize",
								fontSize: 14,
								fontWeight: "bold",
							}}>
							{colors.length > 0 ? "Add another colour" : "Add colour"}
						</Text>
					</TouchableOpacity>
				</Collapsible>
			</View>
			<TouchableOpacity
				onPress={() => navigation.goBack()}
				style={{
					backgroundColor: "#136eb4",
					padding: 10,
					marginHorizontal: 30,
					borderRadius: 8,
					justifyContent: "center",
					alignItems: "center",
				}}>
				<Text style={{ color: "white" }}>Save and continue</Text>
			</TouchableOpacity>
		</ScrollView>
	);
};

export default AddVariant;

const styles = StyleSheet.create({
	textInput: {
		backgroundColor: "white",

		marginBottom: 10,
	},
});
