import React, { useCallback, useContext, useState, useEffect } from "react";
import {
	Card,
	ListItem,
	ThemeProvider,
	Header,
	Input,
	Button,
	Icon,
} from "react-native-elements";

import {
	TouchableOpacity,
	View,
	StyleSheet,
	Text,
	Image,
	Platform,
	Switch,
} from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { xorBy } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { TriangleColorPicker, fromHsv, toHsv } from "react-native-color-picker";
// import Slider from "@react-native-community/slider";
import Modal from "react-native-modal";
import axios from "axios";
import { NavigationContainer, useFocusEffect } from "@react-navigation/native";
import * as ImagePicker from "expo-image-picker";
import RNPickerSelect from "react-native-picker-select";
import { ScrollView } from "react-native-gesture-handler";
import SelectBox from "react-native-multi-selectbox";
import {
	SERVER_API_URL,
	API_URL_CLIENT,
	API_URL_RESTAURANT,
} from "../../config/config";
import { UserContext } from "../../src/context/userContext";

const shopValues = [
	{
		item: "Grocery",
		id: "grocery",
	},
	{
		item: "Fruits veg",
		id: "fruitsveg",
	},
	{
		item: "Chocolate",
		id: "chocolate",
	},
	{
		item: "Sweetsnack",
		id: "sweetsnack",
	},
	{
		item: "Meat",
		id: "meat",
	},
	{
		item: "Medicines",
		id: "medicines",
	},
	{
		item: "Personalcare",
		id: "personalcare",
	},
	{
		item: "Gifts",
		id: "gifts",
	},
];
const foodValues = [
	{
		item: "Biryani",
		id: "biryani",
	},
	{
		item: "Pizza",
		id: "pizza",
	},
	{
		item: "Chicken",
		id: "chicken",
	},
	{
		item: "Mutton",
		id: "mutton",
	},
	{
		item: "Burger",
		id: "burger",
	},
	{
		item: "Fish",
		id: "fish",
	},
	{
		item: "Parotta",
		id: "parotta",
	},
	{
		item: "Meals",
		id: "meals",
	},
	{
		item: "Rolls",
		id: "rolls",
	},
	{
		item: "Icecream",
		id: "icecream",
	},
];
const popularRestaurantSubType = [
	{
		id: 1,
		label: "Cafes",
		value: "Cafe",
	},

	{
		id: 2,
		label: "Bakeries",
		value: "bakery",
	},
	{
		id: 3,
		label: "Casual Dining ",
		value: "casual dining",
	},
	{
		id: 4,
		label: "Beverages",
		value: "beverages",
	},
	{
		id: 5,
		label: "Bhojanalya",
		value: "Bhojanalya",
	},
	{
		id: 6,
		label: "Dessert Parlors",
		value: "Dessert Parlors",
	},
	{
		id: 7,
		label: "meat",
		value: "meat",
	},
	{
		id: 8,
		label: "sweets",
		value: "sweets",
	},
	{
		id: 9,
		label: "Dhabas",
		value: "Dhabas",
	},
	{
		id: 10,
		label: "Fine Dining",
		value: "Fine Dining",
	},
	{
		id: 11,
		label: "Food Courts",
		value: "Food Courts",
	},
	{
		id: 12,
		label: "Food Trucks",
		value: "Food Trucks",
	},
	{
		id: 13,
		label: "Irani Cafes",
		value: "Irani Cafes",
	},
	{
		id: 14,
		label: "Kiosks",
		value: "Kiosks",
	},
	{
		id: 15,
		label: "Lounges",
		value: "Lounges",
	},
	{
		id: 16,
		label: "Meat Shops ",
		value: "Meat Shops ",
	},
	{
		id: 17,
		label: "Paan Shop",
		value: "Paan Shop",
	},
	{
		id: 18,
		label: "Quick Bites",
		value: "Quick Bites",
	},
	{
		id: 19,
		label: "Shacks ",
		value: "Shacks ",
	},
	{
		id: 20,
		label: "Sweet Shops ",
		value: "Sweet Shops ",
	},
];
const popularShopsSubType = [
	{
		id: 1,
		label: "Sports",
		value: "Sports",
	},
	{
		id: 2,
		label: "Gifts",
		value: "Gifts",
	},
	{
		id: 3,
		label: "Grocery",
		value: "Grocery",
	},
	{
		id: 4,
		label: "General Stores",
		value: "General Stores",
	},
	{
		id: 5,
		label: "Beauty",
		value: "Beauty",
	},
	{
		id: 6,
		label: "Fitness",
		value: "Fitness",
	},
	{
		id: 7,
		label: "Electronics",
		value: "Electronics",
	},
	{
		id: 8,
		label: "Pharmacy",
		value: "Pharmacy",
	},
	{
		id: 9,
		label: "Fashion",
		value: "Fashion",
	},
];

export const AddRestaurantGroup = ({ route, navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	const id = route.params.id;
	const showCollection = route.params.collections;
	// console.log("route is ", route);
	const type = route.params.type;
	// if (type !== "add") {
	// console.log("Logo is ", route.params.minDeliveryCharges);
	// }

	const [name, updateName] = useState(type == "add" ? "" : route.params.name);
	const [description, updateDescription] = useState(
		type == "add" ? "" : route.params.description
	);
	const [isModalVisible, setModalVisible] = useState(false);
	const [isModalVisible2, setModalVisible2] = useState(false);

	const [client, updateClient] = useState([]);
	const [deliveryCharges, setDeliveryCharges] = useState(
		type == "add" ? "30" : route.params.minDeliveryCharges
	);
	const [deliveryPerKm, setDeliveryPerKm] = useState(
		type == "add" ? "30" : route.params.deliveryRatePerKM
	);
	const [backColor, setBackColor] = useState("rbg(0,0,0)");
	const [masterSwitchOff, setMasterSwitchOff] = useState(
		type == "add" ? "" : route.params.masterSwitchOff
	);
	const [selectClient, updateClientSelect] = useState("");
	const [restructuredClient, updateRestructureClient] = useState([]);
	const [primaryColor, setPrimaryColor] = useState(
		type == "add" ? "" : route.params.primaryColorCode
	);
	const [secondaryColor, setSecondaryColor] = useState(
		type == "add" ? "" : route.params.secondaryColorCode
	);
	const [logoImage, setLogoImage] = useState(
		type == "add" ? null : route.params.restaurantLogo
	);
	const [bannerImage, setBannerImage] = useState(
		type == "add" ? null : route.params.restaurantBanner
	);
	const [logoUri, setLogoUri] = useState();
	const [bannerUri, setBannerUri] = useState();
	const [businessType, updateBusinessType] = useState(
		type == "add" ? "food" : route.params.businessType
	);
	const [businessSubType, updateBusinessSubType] = useState(
		type == "add" ? "" : route.params?.businessSubType
	);
	// if (type !== "add") {
	// 	var myCollections = route.params.collections;

	// 	if (route.params.businessType == "food") {
	// 		foodValues.filter((elem) =>
	// 			myCollections.includes(elem.item.toLowerCase())
	// 		);
	// 	} else {
	// 		shopValues.filter((elem) =>
	// 			myCollections.includes(elem.item.toLowerCase())
	// 		);
	// 	}
	// }

	let showFoodValues = [];
	let showShopValues = [];

	const [collections, setCollections] = useState(
		type == "add"
			? []
			: businessType == "food"
			? foodValues.filter((elem) =>
					showCollection.includes(elem.item.toLowerCase())
			  )
			: shopValues.filter((elem) =>
					showCollection.includes(elem.item.toLowerCase())
			  )
	);

	const [clientValue, setClientValue] = useState([]);

	const [selectedItems, setSelectedItems] = useState([]);
	const [modalType, setModalType] = useState("");
	const toggleModal = (type) => {
		setModalType(type);
		setModalVisible(!isModalVisible);

		// console.log("Primary color ", primaryColor);
		// console.log("secondary color ", secondaryColor);
	};
	const toggleModal2 = () => {
		setModalVisible2(!isModalVisible2);
	};
	useEffect(() => {
		(async () => {
			if (Platform.OS !== "web") {
				const { status } =
					await ImagePicker.requestMediaLibraryPermissionsAsync();
				if (status !== "granted") {
					alert("Sorry, we need camera roll permissions to make this work!");
				}
			}
		})();
	}, []);
	useEffect(() => {
		// console.log("businessType is", businessType);

		type == "add"
			? setCollections([])
			: businessType == "food"
			? setCollections(
					foodValues.filter((elem) =>
						showCollection.includes(elem.item.toLowerCase())
					)
			  )
			: setCollections(
					shopValues.filter((elem) =>
						showCollection.includes(elem.item.toLowerCase())
					)
			  );
	}, [businessType]);
	const pickImage = async () => {
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.All,
			allowsEditing: true,
			aspect: [4, 3],
			quality: 1,
		});

		// console.log(result);

		if (!result.cancelled) {
			setLogoImage(result.uri);
			setLogoUri(result.uri);
		}
	};

	const pickBannerImage = async () => {
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.All,
			allowsEditing: true,
			aspect: [4, 3],
			quality: 1,
		});
		// console.log(result);

		if (!result.cancelled) {
			// const awsResult = await Storage.put(result.uri, "Banner Image");

			setBannerImage(result.uri);
			setBannerUri(result.uri);

			try {
				let imgdata = await fetch(result.uri);
				const blob = await imgdata.blob();
				// console.log("blob is", blob);
				// console.log("data is", imgdata);
				const awsResult = await Storage.put("Banner image", blob);

				// console.log("Aws result is ", awsResult);
			} catch (error) {
				console.log("err is ", error);
			}
		}
	};

	function onMultiChange() {
		// console.log("Before change ", collections);
		return (item) => {
			setCollections(xorBy(collections, [item], "id"));
		};
	}
	// console.log("after change ", collections);
	let collectionsArr = [];
	if (type == "add") {
		collectionsArr = [];
	} else {
		collectionsArr = showCollection;
	}
	Object.keys(collections).forEach(function (key) {
		console.log(collections[key].id);
		collectionsArr.push(collections[key].id);
		collectionsArr = [...new Set(collectionsArr)];
	});
	// console.log("selectedItems are, ", collectionsArr);
	const restructure = (item) => {
		let labels = [];
		for (var i = 0; i < item.length; i++) {
			labels[i] = {
				label: item[i].clientName,
				value: item[i].slug,
			};
		}
		updateRestructureClient(labels);
	};
	// console.log("Client=====", selectClient);

	const createRestaurantGroup = async () => {
		if (name == "" || selectClient == "") {
			alert("Enter Valid Name");
			return;
		}
		await axios
			.post(
				`${SERVER_API_URL}${API_URL_RESTAURANT}`,
				{
					client: selectClient,
					name: name,
					description: description,
					generalConfig: {
						minDeliveryCharges: deliveryCharges,
						masterSwitchOff: masterSwitchOff,
						deliveryRatePerKM: deliveryPerKm,
					},
					UIConfig: {
						primaryColorCode: primaryColor,
						secondaryColorCode: secondaryColor,
					},
					media: {
						restaurantUnselectedIcon: logoUri,
						restaurantLogo: logoUri,
						restaurantSelectedIcon: logoUri,
						restaurantBanner: bannerUri,
						restaurantIcon: logoUri,
						restaurantThumbnail: logoUri,
					},
					businessType: businessType,
					collections: collectionsArr,
					businessSubType: businessSubType,
				},
				{
					headers: {
						client: selectClient,
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => {
				navigation.navigate("Restaurants/Shops");
				// console.log(res.data);
			})
			.catch((err) => console.log(err));

		alert("Group Created");
	};

	const editDetails = async () => {
		// console.log("Collection is ", [...new Set(collectionsArr)]);
		if (name == "") {
			alert("Please Enter A Valid Name ");
			return;
		}
		await axios
			.patch(
				`${SERVER_API_URL}${API_URL_RESTAURANT}/${id}`,
				{
					name: name,
					description: description,
					generalConfig: {
						minDeliveryCharges: deliveryCharges,
						masterSwitchOff: masterSwitchOff,
						deliveryRatePerKM: deliveryPerKm,
					},
					UIConfig: {
						primaryColorCode: primaryColor,
						secondaryColorCode: secondaryColor,
					},
					media: {
						restaurantUnselectedIcon: logoUri
							? logoUri
							: route.params.logoImage,
						restaurantLogo: logoUri ? logoUri : route.params.logoImage,
						restaurantSelectedIcon: logoUri ? logoUri : route.params.logoImage,
						restaurantBanner: bannerUri ? bannerUri : route.params.bannerImage,
						restaurantIcon: logoUri ? logoUri : route.params.logoImage,
						restaurantThumbnail: logoUri ? logoUri : route.params.logoImage,
					},
					businessType: businessType,
					collections: [...new Set(collectionsArr)],
					businessSubType: businessSubType,
				},
				{
					headers: {
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) =>
				// navigation.navigate("TabNavigator", { screen: "restaurantInfo" },{id:res.data.id,name:res.data.name,clientId:res.data.clientName})
				console.log("data is ", res.data)
			)
			.catch((err) => console.log(err));
		alert(`${name} Saved SuccessFully `);
	};

	const getClient = async () => {
		await axios
			.get(SERVER_API_URL + API_URL_CLIENT, {
				headers: {
					Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				},
			})
			.then((res) => {
				updateClient(res.data), restructure(res.data);
				console.log(res.data);
			})
			.catch((err) => console.log(err));

		// console.log("client is ,", client);
	};
	useFocusEffect(
		useCallback(() => {
			getClient();
			return () => {
				// Do something when the screen is unfocused
				// Useful for cleanup functions
			};
		}, [])
	);

	return (
		<>
			<Header
				centerComponent={{
					text: `${
						type == "add" ? "Add Restaurant / Shop" : "Edit Restaurant / Shop"
					}`,
					style: { color: "#fff", fontSize: 20 },
				}}
				rightComponent={{
					text: "Back",
					style: { color: "#fff", fontSize: 20 },
					onPress: () => navigation.navigate("Restaurants/Shops"),
				}}></Header>

			<ScrollView
				style={{
					flex: 1,
					paddingBottom: type == "add" ? 150 : 200,
					paddingTop: 50,
					paddingHorizontal: 30,
					minHeight: 180,
				}}>
				<View>
					{type == "add" && (
						<RNPickerSelect
							fixAndroidTouchableBug={true}
							style={pickerSelectStyles}
							useNativeAndroidPickerStyle={false}
							placeholder={{
								label: "Select client",
								color: "black",
							}}
							items={client.map((res) => ({
								label: res.clientName,
								value: res.slug,
							}))}
							// items={restructuredClient}
							onValueChange={(value) => {
								// console.log("status value is", value);
								updateClientSelect(value);
								// console.log(client.filter);
								var newArray = client.filter(function (el) {
									return el.clientName.toLowerCase() == value?.toLowerCase();
								});
								// console.log("newArray is ", newArray);
								setClientValue(newArray);
							}}
						/>
					)}
				</View>
				<View>
					<View>
						<Text style={styles.label}>Enter New Restaurant / Shop</Text>
						<Input
							defaultValue={name}
							placeholder='Enter New Restaurant or Shop'
							containerStyle={{
								// width: "75%",
								height: 50,
								alignSelf: "center",
							}}
							inputStyle={{ paddingLeft: 15 }}
							onChangeText={(value) => updateName(value)}
							leftIcon={
								<Icon
									type='material-community'
									name='silverware-fork-knife'
									size={24}
									color='black'
								/>
							}></Input>
					</View>
					<View>
						<Text style={styles.label}>Enter Description</Text>
						<Input
							placeholder='Enter Description'
							containerStyle={{
								// width: "75%",
								height: 50,
								alignSelf: "center",
							}}
							defaultValue={description}
							inputStyle={{ paddingLeft: 15 }}
							onChangeText={(value) => updateDescription(value)}
							leftIcon={
								<MaterialIcons name='description' size={30} color='black' />
							}></Input>
					</View>
					<View>
						<Text style={styles.label}>Business Type</Text>
						<RNPickerSelect
							fixAndroidTouchableBug={true}
							style={pickerSelectStyles}
							useNativeAndroidPickerStyle={false}
							items={[
								{ label: "Food", value: "food" },
								{ label: "Shop", value: "shop" },
							]}
							value={businessType}
							onValueChange={(value) => {
								// console.log(" value is", value);
								updateBusinessType(value);

								setCollections([]);
							}}
						/>
					</View>

					<View>
						<Text style={styles.label}>Business Sub Type</Text>
						{businessType == "food" ? (
							<RNPickerSelect
								fixAndroidTouchableBug={true}
								style={pickerSelectStyles}
								useNativeAndroidPickerStyle={false}
								items={popularRestaurantSubType}
								value={businessSubType}
								onValueChange={(value) => {
									// console.log(" value is", value);
									updateBusinessSubType(value);
								}}
							/>
						) : (
							<RNPickerSelect
								fixAndroidTouchableBug={true}
								style={pickerSelectStyles}
								useNativeAndroidPickerStyle={false}
								items={popularShopsSubType}
								value={businessSubType}
								onValueChange={(value) => {
									// console.log(" value is", value);
									updateBusinessSubType(value);
								}}
							/>
						)}
					</View>
					<View>
						<View>
							<Text style={styles.label}>Logo Image</Text>
							<Button title='Choose image from Gallery' onPress={pickImage} />

							{logoImage !== "" ? (
								<Image
									source={{ uri: logoImage }}
									style={{ width: 200, height: 200, marginVertical: 20 }}
								/>
							) : (
								<></>
							)}
						</View>
					</View>

					<View>
						<View>
							<Text style={styles.label}>Banner Image</Text>
							<Button
								title='Choose image from Gallery'
								onPress={pickBannerImage}
							/>

							{bannerImage !== "" ? (
								<>
									<Image
										source={{ uri: bannerImage }}
										style={{ width: 200, height: 200, marginVertical: 20 }}
									/>
								</>
							) : (
								<></>
							)}
						</View>
					</View>

					<View style={styles.sectionView}>
						<Text style={styles.label}>General Configuration</Text>

						<Input
							label='Minimum delivery charges'
							labelStyle={{ color: "black" }}
							defaultValue={deliveryCharges}
							placeholder='20'
							containerStyle={{
								// width: "75%",
								marginVertical: 10,

								height: 50,
								alignSelf: "center",
							}}
							value={deliveryCharges}
							onChangeText={(value) => setDeliveryCharges(value)}></Input>

						<Input
							label='Delivery Rate Per Km'
							labelStyle={{ color: "black" }}
							defaultValue={deliveryPerKm}
							placeholder='20'
							containerStyle={{
								// width: "75%",
								marginVertical: 10,

								height: 50,
								alignSelf: "center",
							}}
							value={deliveryPerKm}
							onChangeText={(value) => setDeliveryPerKm(value)}></Input>

						<View style={styles.settingView}>
							<Text style={{ ...styles.label }}>Master Switch off</Text>
							<Switch
								trackColor={{ false: "#767577", true: "#81b0ff" }}
								thumbColor={masterSwitchOff ? "#f5dd4b" : "#f4f3f4"}
								ios_backgroundColor='#3e3e3e'
								onValueChange={() => setMasterSwitchOff(!masterSwitchOff)}
								value={masterSwitchOff}
							/>
						</View>
					</View>

					<View style={styles.sectionView}>
						<Text style={styles.label}>UI Configuration</Text>

						<Modal
							isVisible={isModalVisible}
							onBackdropPress={() => setModalVisible(false)}>
							{Platform.OS !== "web" ? (
								<TriangleColorPicker
									// sliderComponent={Slider}
									defaultColor={
										modalType == "primary" ? primaryColor : secondaryColor
									}
									onColorChange={(color) =>
										// console.log("color is ", fromHsv(color))

										{
											if (modalType == "primary") {
												setPrimaryColor(fromHsv(color));
											} else {
												setSecondaryColor(fromHsv(color));
											}
										}
									}
									style={{ flex: 1 }}
								/>
							) : (
								<input
									placeholder='Select colors'
									style={{
										borderRadius: 10,
										borderColor: "#ccc",
										borderWidth: 1,
										padding: 10,
										height: 30,
										marginBottom: 10,
									}}
									type='color'
									onChange={(e) => {
										if (modalType == "primary") {
											setPrimaryColor(e.target.value);
										} else {
											setSecondaryColor(e.target.value);
										}
									}}
								/>
							)}
							<Button title='Close' onPress={toggleModal} />
						</Modal>
						<Text>{primaryColor}</Text>
						<Button
							onPress={() => toggleModal("primary")}
							title='Select Primary color'
						/>
						<View style={{ marginVertical: 20 }}></View>
						<Text>{secondaryColor}</Text>

						<Button
							onPress={() => toggleModal("secondary")}
							title='Select Secondary color'
						/>
					</View>
					<SelectBox
						listOptionProps={{ nestedScrollEnabled: true }}
						label='Collections'
						labelStyle={styles.label}
						options={businessType == "food" ? foodValues : shopValues}
						selectedValues={collections}
						onMultiSelect={onMultiChange()}
						onTapClose={onMultiChange()}
						isMulti
					/>
					{type == "add" ? (
						<TouchableOpacity style={{ marginVertical: 70 }}>
							<Button
								title='Create'
								onPress={createRestaurantGroup}
								icon={{ name: "add", size: 20, color: "white" }}
							/>
						</TouchableOpacity>
					) : (
						<View
							style={{
								flexDirection: "row",
								marginBottom: 50,
								paddingVertical: 30,
							}}>
							<Button
								title='Done'
								onPress={() => editDetails()}
								icon={
									<Icon
										type='material-community'
										name='check-outline'
										size={30}
										color='white'
									/>
								}
								titleStyle={{ marginLeft: 10 }}
								buttonStyle={{
									backgroundColor: "#3562ab",
									width: 100,
									borderRadius: 90,
									width: 120,
									padding: 5,
								}}></Button>
							<Button
								title='Cancel'
								onPress={() => navigation.navigate("restaurantInfo")}
								icon={
									<Icon
										type='material-community'
										name='close-outline'
										size={30}
										color='white'
									/>
								}
								titleStyle={{ marginLeft: 10 }}
								buttonStyle={{
									backgroundColor: "#bd0b29",
									width: 100,
									borderRadius: 90,
									width: 120,
									padding: 5,
									marginLeft: 50,
								}}></Button>
						</View>
					)}
				</View>
			</ScrollView>
		</>
	);
};

const pickerSelectStyles = StyleSheet.create({
	inputIOS: {
		fontSize: 12,
		marginHorizontal: 5,
		borderWidth: 1,
		borderColor: "gray",
		borderRadius: 4,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon
		padding: 5,
	},
	inputAndroid: {
		fontSize: 12,
		marginHorizontal: 5,

		borderWidth: 0.5,
		borderColor: "black",
		borderRadius: 8,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon

		padding: 5, // to ensure the text is never behind the icon
	},
});

const styles = StyleSheet.create({
	label: {
		marginTop: 10,
		marginBottom: 10,
		fontSize: 16,
		fontWeight: "bold",
	},
	settingView: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingHorizontal: 10,
		marginBottom: 15,
	},
	sectionView: {
		marginVertical: 10,
		padding: 10,
		backgroundColor: "white",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.36,
		shadowRadius: 6.68,

		elevation: 11,
	},
});
