import { manipulateAsync, FlipType, SaveFormat } from "expo-image-manipulator";

const resizeImage = async (source, compressedAmount = 0, resize) => {
  console.log("compressed==", compressedAmount);
  let compressedUpto;

  if (compressedAmount >= 200 || compressedAmount <= 300) {
    compressedUpto = 0.1;
  } else if (compressedAmount >= 100 || compressedAmount < 200) {
    compressedUpto = 0.2;
  } else if (compressedAmount >= 90 || compressedAmount < 100) {
    compressedUpto = 0.3;
  } else if (compressedAmount >= 80 || compressedAmount < 90) {
    compressedUpto = 0.4;
  } else if (compressedAmount >= 70 || compressedAmount < 80) {
    compressedUpto = 0.5;
  } else if (compressedAmount >= 60 || compressedAmount < 70) {
    compressedUpto = 0.6;
  } else if (compressedAmount >= 50 || compressedAmount < 60) {
    compressedUpto = 0.7;
  } else if (compressedAmount >= 40 || compressedAmount < 50) {
    compressedUpto = 0.8;
  } else if (compressedAmount >= 30 || compressedAmount < 40) {
    compressedUpto = 0.9;
  } else if (compressedAmount >= 10 || compressedAmount < 30) {
    compressedUpto = 1;
  } else if (compressedAmount > 300) {
    compressedUpto = 0;
  } else {
    compressedUpto = 1;
  }

  const manipResult = await manipulateAsync(
    source,
    [
      {
        resize: resize,
      },
    ],
    // { compress: compressedUpto, format: SaveFormat.JPEG }
    { format: SaveFormat.JPEG }
  );

  console.log("result is ", manipResult);
  return manipResult.uri;
};
export default resizeImage;
