import {
	ActivityIndicator,
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
} from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { TextInput } from "react-native-paper";
import axios from "axios";
import {
	API_URL_CLIENT,
	API_URL_RESTAURANT,
	SERVER_API_URL,
} from "../config/config";
import { UserContext } from "../src/context/userContext";

const AboutUs = () => {
	const [loading, setLoading] = useState(false);
	const [aboutUs, setAboutUs] = useState("");
	const [restaurantId, setRestaurantId] = useState([]);
	const { state, dispatch } = useContext(UserContext);
	const assignedClient = state.user.attributes["custom:client"];

	const getRestaurants = async () => {
		setLoading(true);
		await axios
			.get(`${SERVER_API_URL}${API_URL_RESTAURANT}${API_URL_CLIENT}`, {
				headers: {
					client: assignedClient,
				},
			})
			.then((res) => {
				console.log("data is ", res.data[0].id);
				setRestaurantId(res.data[0].id);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};

	const submitData = async () => {
		// console.log(preDesc);
		// console.log(id);
		console.log("hello");
		await axios
			.patch(
				`${SERVER_API_URL}${API_URL_RESTAURANT}/${restaurantId}`,
				{
					aboutUs: aboutUs,
				},
				{
					headers: {
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => console.log(res.data))
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		getRestaurants();
	}, []);
	return (
		<View style={{ padding: 10 }}>
			{loading ? (
				<ActivityIndicator size='large' color='#00ff00' />
			) : (
				<>
					<TextInput
						onChangeText={(value) => {
							setAboutUs(value);
						}}
						multiline={true}
						maxLength={200}
						style={{ ...styles.textInput }}
						underlineColorAndroid='transparent'
						label='About us'
						autoCapitalize='none'
						theme={{ colors: { primary: "#136eb4" } }}
						value={aboutUs}
					/>
					<TouchableOpacity
						onPress={() => {
							submitData();
						}}
						disabled={aboutUs.length < 1}
						style={{
							//change background color when diabled
							backgroundColor: aboutUs.length < 1 ? "#d3d3d3" : "#136eb4",
							// backgroundColor: "#136eb4",
							padding: 15,
							borderRadius: 8,
							paddingHorizontal: 30,
							alignItems: "center",
							justifyContent: "center",
							flexDirection: "row",
							marginTop: 20,
						}}>
						<Text
							style={{
								color: "white",
								fontSize: 15,
								fontWeight: "bold",
								marginLeft: 10,
							}}>
							Submit
						</Text>
					</TouchableOpacity>
				</>
			)}
		</View>
	);
};

export default AboutUs;

const styles = StyleSheet.create({
	textInput: {
		backgroundColor: "white",
		padding: 20,
		paddingHorizontal: 0,
		fontSize: 15,
		marginBottom: 10,
	},
});
