import {
	Dimensions,
	Platform,
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
} from "react-native";
import React from "react";
import {
	AntDesign,
	MaterialCommunityIcons,
	FontAwesome5,
	Entypo,
	FontAwesome,
} from "@expo/vector-icons";

const Settings = ({ navigation }) => {
	return (
		<View
			style={{
				flexDirection: "row",
				flexWrap: "wrap",
				alignItems: "center",
				// justifyContent: "center",
				minHeight: Platform.OS !== "web" ? Dimensions.get("screen").height : "",
				marginTop: Platform.OS == "web" ? "20px" : 0,
			}}>
			<TouchableOpacity
				onPress={() => navigation.navigate("GetQr")}
				style={styles.card}>
				<AntDesign style={styles.icon} name='qrcode' size={30} color='blue' />
				<Text style={styles.heading}>Get a QR Code</Text>
			</TouchableOpacity>
			<TouchableOpacity
				onPress={() => navigation.navigate("Delivery Time")}
				style={styles.card}>
				<AntDesign name='clockcircleo' size={30} color='orange' />
				<Text style={styles.heading}>Delivery Time</Text>
			</TouchableOpacity>
			<TouchableOpacity
				onPress={() => navigation.navigate("Social Profiles")}
				style={styles.card}>
				<MaterialCommunityIcons name='web' size={30} color='green' />
				<Text style={styles.heading}>Social Profile</Text>
			</TouchableOpacity>

			<TouchableOpacity
				onPress={() => navigation.navigate("About Us")}
				style={styles.card}>
				<FontAwesome5 name='users' size={30} color='black' />
				<Text style={styles.heading}>About Us</Text>
			</TouchableOpacity>

			<TouchableOpacity
				onPress={() => navigation.navigate("Zone Based Delivery")}
				style={styles.card}>
				<Entypo name='location' size={30} color='red' />
				<Text style={styles.heading}>Zone Based Delivery</Text>
			</TouchableOpacity>

			<TouchableOpacity
				onPress={() => navigation.navigate("Promotional Banner")}
				style={styles.card}>
				<FontAwesome name='paint-brush' size={30} color='pink' />
				<Text
					style={{
						...styles.heading,
						fontSize: Platform.OS == "web" ? 16 : 15,
						fontWeight: Platform.OS == "web" ? "bold" : "700",
						marginTop: Platform.OS == "web" ? "10px" : 0,
					}}>
					Create Promotional Banner
				</Text>
			</TouchableOpacity>
		</View>
	);
};

export default Settings;

const styles = StyleSheet.create({
	card: {
		height: 150,
		backgroundColor: "#fff",
		borderRadius: 5,
		padding: 22,
		margin: 10,
		marginRight: 4,
		paddingLeft: 10,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.8,
		shadowRadius: 2,
		elevation: 1,
		width: Platform.OS !== "web" ? "44%" : "200px",
		justifyContent: "center",
		// alignItems: "center",
	},
	heading: {
		fontSize: 16,
		fontWeight: "700",
		color: "#000",
		marginTop: 10,
	},
});
