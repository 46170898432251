import React, { useContext } from "react";
import { View, Text, TouchableOpacity, StyleSheet, Switch } from "react-native";
import { Card, Button, CheckBox, Input } from "react-native-elements";
import { ScrollView } from "react-native-gesture-handler";
import Entypo from "react-native-vector-icons/Entypo";
import DropDownPicker from "react-native-dropdown-picker";
import { NavigationContainer, useFocusEffect } from "@react-navigation/native";
import axios from "axios";
import RNPickerSelect from "react-native-picker-select";
import { SERVER_API_URL, API_URL_LOCATIONS } from "../../config/config";
import { UserContext } from "../../src/context/userContext";

export const InputEdit = ({ route, navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	const { item, id } = route.params;
	console.log("route is ", route);
	// let item = route.params.item;
	// const id = route.params.id;
	console.log("item is ", item);
	console.log("Input edit page");
	const [street1, updateStreet1] = React.useState(item.address.street1);
	const [city, updateCity] = React.useState(item.address.city);
	const [stateVal, updateStateVal] = React.useState(item.address.state);
	const [zip, updateZip] = React.useState(item.address.zip);
	const [phone, updatePhone] = React.useState(item.phone.value);
	const [verified, updateVerified] = React.useState(item.phone.isVerified);
	const [preorder, updatePreorder] = React.useState(item.isPreorderRequired);
	const [miles, updateMiles] = React.useState(item.distanceInMiles);
	const [street2, updateStreet2] = React.useState(item.address.street2);
	const [email, setEmail] = React.useState(item.email);
	const [fssai, setFssai] = React.useState(item.fssaiLicense);
	const [packing, setPacking] = React.useState(
		item?.serviceEstimates?.packingCharges
	);
	const [lat, setLat] = React.useState(item.address.geo.lat);
	const [long, setLong] = React.useState(item.address.geo.long);
	// const [checkApple, updateCheckApple] = React.useState(
	// 	item.isApplePayAccepted
	// );
	// const [checkCash, updateCheckCash] = React.useState(item.isCashAccepted);
	const [checkisDeliveryEnabled, updateCheckisDeliveryEnabled] = React.useState(
		item.isDeliveryEnabled
	);
	// const [checkisGooglePayAccepted, updateisGooglePayAccepted] = React.useState(
	// 	item.isGooglePayAccepted
	// );
	// const [checkisKioskEnabled, updateCheckisKioskEnabled] = React.useState(
	// 	item.isKioskEnabled
	// );
	const [checkisOpen, updateCheckisOpen] = React.useState(item.isOpen);
	const [checkisPickupEnabled, updateCheckisPickupEnabled] = React.useState(
		item.isPickupEnabled
	);
	const [slug, updateSlug] = React.useState(item.slug);
	const [name, updateName] = React.useState(item.name);
	const [timeZone, updateTimeZone] = React.useState(item.timeZone);
	// const [delivery, updateDelivery] = React.useState(
	// 	item.serviceEstimates.delivery
	// );
	// const [increment, updateIncrement] = React.useState(
	// 	item.serviceEstimates.increment
	// );
	const [threshold, updateThreshold] = React.useState(
		item.serviceEstimates.threshold
	);
	// const [prep, updatePrep] = React.useState(item.serviceEstimates.prep);
	const [location, updateLocation] = React.useState();
	const [street1Err, setStreet1Err] = React.useState(false);
	const [street2Err, setStreet2Err] = React.useState(false);
	const [phoneErr, setPhoneErr] = React.useState(false);
	const [locationErr, setLocationErr] = React.useState(false);
	const [slugErr, setSlugErr] = React.useState(false);
	const [zipErr, setZipErr] = React.useState(false);
	const [cityErr, setCityErr] = React.useState(false);
	const [stateErr, setStateErr] = React.useState(false);
	const [latErr, setLatErr] = React.useState(false);
	const [lngErr, setLngErr] = React.useState(false);
	const [emailErr, setEmailErr] = React.useState(false);
	const [fssaiErr, setFssaiErr] = React.useState(false);
	const testPhoneNumber = (value) => {
		let pattern = /^\d{10}$/;
		console.log("Value is ", value);
		updatePhone(value);
		if (pattern.test(value) == true) {
			setPhoneErr(false);
		} else {
			setPhoneErr(true);
		}
		console.log("is valid is ", phoneErr);
	};
	console.log("Packing charges is ", packing);
	const Inputdata = {
		serviceEstimates: {
			// delivery: parseInt(delivery),
			// increment: parseInt(increment),
			// prep: parseInt(prep),
			threshold: threshold,
			packingCharges: parseInt(packing),
		},
		fssaiLicense: fssai,
		packingCharges: parseInt(packing),
		// isApplePayAccepted: checkApple,
		// isCashAccepted: checkCash,
		isDeliveryEnabled: checkisDeliveryEnabled,
		// isGooglePayAccepted: checkisGooglePayAccepted,
		// isKioskEnabled: checkisKioskEnabled,
		isOpen: checkisOpen,
		isPickupEnabled: checkisPickupEnabled,
		slug: slug,
		timeZone: timeZone,
		address: {
			street2: street2,
			street1: street1,
			geo: {
				lat: parseFloat(lat),
				long: parseFloat(long),
			},
			city: city,
			state: stateVal,
			zip: zip,
		},
		name: name,

		phone: {
			value: phone,
			isVerified: verified,
		},
		isPreorderRequired: preorder,
		distanceInMiles: miles,
	};

	const getLocation = async () => {
		await axios
			.get(`${SERVER_API_URL}${API_URL_LOCATIONS}/${route.params.id}`)
			.then((res) => updateLocation(res.data))
			.catch((err) => console.log(err));
	};

	const loadNext = async () => {
		if (street1 == "") {
			setStreet1Err(true);
		}
		if (slug == "") {
			setSlugErr(true);
		}
		if (street2 == "") {
			setStreet2Err(true);
		}

		if (name == "") {
			setLocationErr(true);
		}
		if (zip == "") {
			setZipErr(true);
		}
		if (city == "") {
			setCityErr(true);
		}
		if (stateVal == "") {
			setStateErr(true);
		}
		console.log(
			"end value is ",
			street1Err &&
				street2Err &&
				slugErr &&
				locationErr &&
				zipErr &&
				locationErr &&
				stateErr &&
				cityErr
		);
		if (
			(street1Err &&
				street2Err &&
				slugErr &&
				locationErr &&
				zipErr &&
				locationErr &&
				stateErr &&
				cityErr) == false
		) {
			await axios
				.patch(
					`${SERVER_API_URL}${API_URL_LOCATIONS}/${route.params.id}`,
					Inputdata,
					{
						headers: {
							Authorization:
								state?.user?.signInUserSession?.idToken["jwtToken"],
						},
					}
				)
				.then((res) => console.log(res))
				.catch((err) => console.log(err));

			return navigation.navigate("editResaurant");
		}
	};
	useFocusEffect(
		React.useCallback(() => {
			getLocation();
			return () => {
				// Do something when the screen is unfocused
				// Useful for cleanup functions
			};
		}, [])
	);
	const services = [
		{ label: "10", value: "10" },
		{ label: "20", value: "20" },
		{ label: "30", value: "30" },
		{ label: "40", value: "40" },
		{ label: "50", value: "50" },
		{ label: "60", value: "60" },
		{ label: "70", value: "70" },
		{ label: "80", value: "80" },
		{ label: "90", value: "90" },
		{ label: "100", value: "100" },
		{ label: "110", value: "110" },
		{ label: "120", value: "120" },
	];

	const packingChargesList = [
		{ label: "0", value: 0 },
		{ label: "10", value: 10 },
		{ label: "20", value: 20 },
		{ label: "30", value: 30 },
		{ label: "40", value: 40 },
		{ label: "50", value: 50 },
		{ label: "60", value: 60 },
		{ label: "70", value: 70 },
		{ label: "80", value: 80 },
		{ label: "90", value: 90 },
		{ label: "100", value: 100 },
		{ label: "110", value: 110 },
		{ label: "120", value: 120 },
	];

	return (
		<>
			<ScrollView style={{ flex: 1 }}>
				<View>
					<Text style={styles.mainLabel}>Address</Text>
					<View style={styles.mainView}>
						<Text style={styles.mainLabel}>Location Details</Text>
						<Text style={styles.label}>Location name</Text>
						<Input
							placeholder='location Name'
							defaultValue={name}
							onChangeText={(value) => {
								updateName(value);

								updateSlug(value.replace(/\s+/g, "-").toLowerCase());
							}}></Input>

						{locationErr == true ? (
							<Text style={styles.errortext}>Location is required </Text>
						) : (
							<></>
						)}

						<Text style={styles.label}>Enter Slug </Text>

						<Input
							placeholder='Slug'
							defaultValue={slug}
							onChangeText={(value) =>
								updateSlug(value.replace(/\s+/g, "-").toLowerCase())
							}></Input>
						{slugErr == true ? (
							<Text style={styles.errortext}>Slug is required </Text>
						) : (
							<></>
						)}
					</View>

					<View style={styles.mainView}>
						<Text style={styles.mainLabel}>Address Details</Text>
						<Text style={styles.label}>Enter Street1 </Text>
						<Input
							placeholder='street1'
							defaultValue={street1}
							onChangeText={(value) => updateStreet1(value)}></Input>
						{street1Err == true ? (
							<Text style={styles.errortext}>Street is required </Text>
						) : (
							<></>
						)}
						<Text style={styles.label}>Enter Street2 </Text>

						<Input
							placeholder='street2'
							defaultValue={street2}
							onChangeText={(value) => updateStreet2(value)}></Input>
						{street2Err == true ? (
							<Text style={styles.errortext}>Street is required </Text>
						) : (
							<></>
						)}
						<Text style={styles.label}>Enter City </Text>

						<Input
							placeholder='city'
							defaultValue={city}
							onChangeText={(value) => updateCity(value)}></Input>
						{cityErr == true ? (
							<Text style={styles.errortext}>City is required </Text>
						) : (
							<></>
						)}
						<Text style={styles.label}>Enter State </Text>

						<Input
							placeholder='state'
							defaultValue={stateVal}
							onChangeText={(value) => updateStateVal(value)}></Input>
						{stateErr == true ? (
							<Text style={styles.errortext}>State is required </Text>
						) : (
							<></>
						)}
						<Text style={styles.label}>Enter Pincode </Text>

						<Input
							placeholder='zip'
							defaultValue={zip}
							onChangeText={(value) => updateZip(value)}></Input>
						{zipErr == true ? (
							<Text style={styles.errortext}>Pin is required </Text>
						) : (
							<></>
						)}

						<Text style={styles.label}>Enter Latitude </Text>
						<Input
							defaultValue={lat}
							placeholder='Latitude'
							onChangeText={(value) => setLat(value)}></Input>
						{latErr == true ? (
							<Text style={styles.errortext}>Latitude is required </Text>
						) : (
							<></>
						)}
						<Text style={styles.label}>Enter Longitude </Text>
						<Input
							value={long}
							placeholder='Longitude'
							onChangeText={(value) => setLong(value)}></Input>
						{lngErr == true ? (
							<Text style={styles.errortext}>Longitude is required </Text>
						) : (
							<></>
						)}
						<Text style={styles.label}>Enter Phone </Text>

						<Input
							placeholder='Phone'
							defaultValue={phone}
							onChangeText={(value) => testPhoneNumber(value)}></Input>

						{phoneErr == true ? (
							<Text style={styles.errortext}>Invalid Phone Number </Text>
						) : (
							<></>
						)}

						<View style={styles.settingView}>
							<Text style={styles.label}>Phone Verified ?</Text>
							<Switch
								trackColor={{ false: "#767577", true: "#81b0ff" }}
								thumbColor={verified ? "#f5dd4b" : "#f4f3f4"}
								ios_backgroundColor='#3e3e3e'
								onValueChange={() => updateVerified(!verified)}
								value={verified}
							/>
						</View>
						<Text style={styles.label}>Enter Email </Text>
						<Input
							defaultValue={email}
							placeholder='Email'
							keyboardType='email-address'
							onChangeText={(value) => setEmail(value)}></Input>
						{emailErr == true ? (
							<Text style={styles.errortext}>Email is required </Text>
						) : (
							<></>
						)}
					</View>

					<View style={styles.mainView}>
						<Text style={styles.mainLabel}>Restaurant Details</Text>
						<Text style={styles.label}>Select Time Zone </Text>

						<RNPickerSelect
							fixAndroidTouchableBug={true}
							useNativeAndroidPickerStyle={false}
							style={pickerSelectStyles}
							placeholder={{ label: "Select Time Zone" }}
							items={[
								{ label: "Asia/Kolkata", value: "Asia/Kolkata" },
								{ label: "America/Winnipeg", value: "America/Winnipeg" },
								{ label: "America/Vancouver", value: "America/Vancouver" },
								{ label: "Australia/Melbourne", value: "Australia/Melbourne" },
								{ label: "Australia/Sydney", value: "Australia/Sydney" },
							]}
							onValueChange={(value) => updateTimeZone(value)}
							value={timeZone.toString()}
						/>
						{/* <Text style={styles.label}>Enter Delivery Time </Text>

						<RNPickerSelect
							fixAndroidTouchableBug={true}
							useNativeAndroidPickerStyle={false}
							style={pickerSelectStyles}
							placeholder={{ label: "delivery" }}
							items={services}
							onValueChange={(value) => updateDelivery(value)}
							value={delivery.toString()}
						/> */}
						{/* <Text style={styles.label}> Increment Time </Text>

						<RNPickerSelect
							fixAndroidTouchableBug={true}
							useNativeAndroidPickerStyle={false}
							style={pickerSelectStyles}
							placeholder={{ label: "increment" }}
							items={services}
							onValueChange={(value) => updateIncrement(value)}
							value={increment.toString()}
						/> */}
						{/* <Text style={styles.label}>Enter Preparation Time </Text>

						<RNPickerSelect
							fixAndroidTouchableBug={true}
							useNativeAndroidPickerStyle={false}
							style={pickerSelectStyles}
							placeholder={{ label: "preparation" }}
							items={services}
							onValueChange={(value) => updatePrep(value)}
							value={prep.toString()}
						/> */}
						<Text style={styles.label}>Enter Threshold Time </Text>

						{/* <RNPickerSelect
							fixAndroidTouchableBug={true}
							useNativeAndroidPickerStyle={false}
							style={pickerSelectStyles}
							placeholder={{ label: "threshold" }}
							items={services}
							onValueChange={(value) => updateThreshold(value)}
							value={threshold.toString()}
						/> */}

						<Input
							value={threshold}
							placeholder='Threshold'
							onChangeText={(value) => updateThreshold(value)}></Input>

						<Text style={styles.label}>Enter Packing Charges </Text>
						<RNPickerSelect
							fixAndroidTouchableBug={true}
							style={pickerSelectStyles}
							useNativeAndroidPickerStyle={false}
							items={packingChargesList}
							onValueChange={(value) => {
								console.log(" value is", value);
								setPacking(value);
							}}
							value={packing}
						/>
						<Text style={styles.label}>Enter FSSAI License </Text>
						<Input
							value={fssai}
							placeholder='FSSAI'
							onChangeText={(value) => setFssai(value)}></Input>
						{fssaiErr == true ? (
							<Text style={styles.errortext}>FSSAI License is required </Text>
						) : (
							<></>
						)}
					</View>

					<View style={styles.mainView}>
						<Text style={styles.mainLabel}>Settings</Text>

						{/* <View style={styles.settingView}>
							<Text style={styles.label}>Apple Pay</Text>
							<Switch
								trackColor={{ false: "#767577", true: "#81b0ff" }}
								thumbColor={checkApple ? "#f5dd4b" : "#f4f3f4"}
								ios_backgroundColor='#3e3e3e'
								onValueChange={() => updateCheckApple(!checkApple)}
								value={checkApple}
							/>
						</View>
						<View style={styles.settingView}>
							<Text style={styles.label}>Cash Accepted ?</Text>
							<Switch
								trackColor={{ false: "#767577", true: "#81b0ff" }}
								thumbColor={checkCash ? "#f5dd4b" : "#f4f3f4"}
								ios_backgroundColor='#3e3e3e'
								onValueChange={() => updateCheckCash(!checkCash)}
								value={checkCash}
							/>
						</View> */}

						<View style={styles.settingView}>
							<Text style={styles.label}>Delivery Enabled ?</Text>
							<Switch
								trackColor={{ false: "#767577", true: "#81b0ff" }}
								thumbColor={checkisDeliveryEnabled ? "#f5dd4b" : "#f4f3f4"}
								ios_backgroundColor='#3e3e3e'
								onValueChange={() =>
									updateCheckisDeliveryEnabled(!checkisDeliveryEnabled)
								}
								value={checkisDeliveryEnabled}
							/>
						</View>
						{/* 
						<View style={styles.settingView}>
							<Text style={styles.label}>Google Pay Accepted ?</Text>
							<Switch
								trackColor={{ false: "#767577", true: "#81b0ff" }}
								thumbColor={checkisGooglePayAccepted ? "#f5dd4b" : "#f4f3f4"}
								ios_backgroundColor='#3e3e3e'
								onValueChange={() =>
									updateisGooglePayAccepted(!checkisGooglePayAccepted)
								}
								value={checkisGooglePayAccepted}
							/>
						</View>

						<View style={styles.settingView}>
							<Text style={styles.label}>Kiosk Enabled ?</Text>
							<Switch
								trackColor={{ false: "#767577", true: "#81b0ff" }}
								thumbColor={checkisKioskEnabled ? "#f5dd4b" : "#f4f3f4"}
								ios_backgroundColor='#3e3e3e'
								onValueChange={() =>
									updateCheckisKioskEnabled(!checkisKioskEnabled)
								}
								value={checkisKioskEnabled}
							/>
						</View> */}

						<View style={styles.settingView}>
							<Text style={styles.label}> Open ?</Text>
							<Switch
								trackColor={{ false: "#767577", true: "#81b0ff" }}
								thumbColor={checkisOpen ? "#f5dd4b" : "#f4f3f4"}
								ios_backgroundColor='#3e3e3e'
								onValueChange={() => updateCheckisOpen(!checkisOpen)}
								value={checkisOpen}
							/>
						</View>

						<View style={styles.settingView}>
							<Text style={styles.label}>PickUp Enabled ?</Text>
							<Switch
								trackColor={{ false: "#767577", true: "#81b0ff" }}
								thumbColor={checkisPickupEnabled ? "#f5dd4b" : "#f4f3f4"}
								ios_backgroundColor='#3e3e3e'
								onValueChange={() =>
									updateCheckisPickupEnabled(!checkisPickupEnabled)
								}
								value={checkisPickupEnabled}
							/>
						</View>

						<View style={styles.settingView}>
							<Text style={styles.label}>Pre-Order Enabled ?</Text>
							<Switch
								trackColor={{ false: "#767577", true: "#81b0ff" }}
								thumbColor={preorder ? "#f5dd4b" : "#f4f3f4"}
								ios_backgroundColor='#3e3e3e'
								onValueChange={() => updatePreorder(!preorder)}
								value={preorder}
							/>
						</View>
					</View>
				</View>
				<TouchableOpacity
					onPress={() => loadNext()}
					style={{
						flex: 1,
						flex: 1,
						marginVertical: 10,

						justifyContent: "center",
						alignItems: "center",
					}}>
					<Entypo name='save' size={40} color='green'></Entypo>
					<Text>Save</Text>
				</TouchableOpacity>
			</ScrollView>
		</>
	);
};

const pickerSelectStyles = StyleSheet.create({
	inputIOS: {
		fontSize: 12,
		marginHorizontal: 5,
		marginVertical: 10,

		borderWidth: 1,
		borderColor: "gray",
		borderRadius: 4,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon
		padding: 5,
	},
	inputAndroid: {
		fontSize: 12,
		marginHorizontal: 5,
		marginVertical: 10,
		borderWidth: 0.5,
		borderColor: "black",
		borderRadius: 8,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon

		padding: 5, // to ensure the text is never behind the icon
	},
});
const styles = StyleSheet.create({
	mainView: {
		margin: 10,
		marginHorizontal: 15,
		padding: 10,
		backgroundColor: "white",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.36,
		shadowRadius: 6.68,

		elevation: 11,
	},
	mainLabel: {
		fontSize: 16,
		marginVertical: 8,
		textAlign: "center",
		fontWeight: "bold",
	},
	label: {
		marginTop: 6,
		marginBottom: 10,
		fontSize: 16,
		fontWeight: "bold",
	},
	errortext: {
		color: "red",
		fontSize: 12,
	},
	settingView: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingHorizontal: 10,
		marginBottom: 15,
	},
});
