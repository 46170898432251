import {
	StyleSheet,
	Text,
	View,
	Dimensions,
	TouchableOpacity,
	Platform,
} from "react-native";
import * as Print from "expo-print";
import { shareAsync } from "expo-sharing";
import * as FileSystem from "expo-file-system";
import React, { useContext } from "react";
import QRCode from "react-native-qrcode-svg";
import { UserContext } from "../src/context/userContext";
import { FontAwesome } from "@expo/vector-icons";

const GetQr = ({ navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	const assignedClient = state.user.attributes["custom:client"];
	const htmlContent = `<!DOCTYPE html>
    <html>
        <head>
            <style>
                * {
                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;
                }
                .qr-code {
                    max-width: 200px;
                    margin: 10px;
                    margin-left: 50%;
                    transform: translateX(-50%);
                }
    
                .card {
                    height: 500px;
                    width: 400px;
                    border-radius: 8;
                    border: 1px solid black;
                    position: relative;
                    border-radius: 10px;
                }
                .cardUpper {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 200px;
                    width: 100%;
                    background-color: #136eb4;
                    border-radius: 10px;
                }
                .cardUpper h1 {
                    text-align: center;
                    color: white;
                    margin: 10px 0;
                }
                .cardUpper p {
                    text-align: center;
                    color: white;
                    margin: 10px 0;
                }
                .cardLower {
                    position: absolute;
                    bottom: 30%;
                    left: 50%;
                    transform: translate(-50%);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                .cardLower h1 {
                    font-weight: bold;
                    margin: 10px 0;
                    /* color: white; */
                }
                a {
                    text-decoration: none;
                    color: #136eb4;
                    font-size: 17px;
                    font-weight: bold;
                    margin-top: 10px;
                }
                .parent {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 100vh;
                }
            </style>
    
            <title>Your QR</title>
        </head>
    
        <body>
            <div class="parent">
                <div class="card">
                    <div class="cardUpper">
                        <h1>Order Online</h1>
                        <p>Scan QR to check our products</p>
                        <img
                            src="https://chart.googleapis.com/chart?cht=qr&chl='+https://${assignedClient}.delishtic.com +
            '&chs=160x160&chld=L|0"
                            class="qr-code"
                        />
                    </div>
                    <div class="cardLower">
                        <h1>Chocolonge</h1>
    
                        <a href="https://${assignedClient}.delishtic.com"
                            >https://${assignedClient}.delishtic.com</a
                        >
                    </div>
                </div>
            </div>
    
            <script src="https://code.jquery.com/jquery-3.5.1.js"></script>
        </body>
    </html>
    
`;

	const printToFile = async (content) => {
		// On iOS/android prints the given html. On web prints the HTML from the current page.
		console.log("it runs");
		try {
			const { uri } = await Print.printToFileAsync({
				html: content == undefined ? htmlContent : content,
			});

			await shareAsync(uri, {
				UTI: ".pdf",
				mimeType: "application/pdf",
				dialogTitle: "Share Product",
			})
				.then((res) => {
					console.log("res=", res);
				})
				.catch((err) => {
					console.log(err);
				});
		} catch (error) {
			console.log("error=", error);
		}
	};
	return (
		<View
			style={{
				justifyContent: "center",
				alignItems: "center",
				minHeight: Dimensions.get("screen").height - 200,
			}}>
			<View style={styles.card}>
				<View style={styles.cardUpper}>
					<Text
						style={{
							fontSize: 30,
							fontWeight: "bold",
							color: "white",
							textAlign: "center",
						}}>
						Order Online!
					</Text>
					<Text
						style={{
							fontSize: 15,
							color: "white",
							textAlign: "center",
							marginTop: 15,
							fontWeight: "600",
						}}>
						Scan QR to check our products.
					</Text>
					<View
						style={{
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "row",
							marginTop: 20,
						}}>
						<QRCode
							size={Platform.OS == "web" ? 120 : 140}
							value={`https://${assignedClient}.delishtic.com`}
						/>
					</View>
				</View>
				<View style={styles.cardLower}>
					<Text
						style={{
							fontSize: 18,
							fontWeight: "bold",
							color: "black",
							textAlign: "center",
							marginTop: 14,
							textTransform: "capitalize",
						}}>
						{assignedClient}
					</Text>
					<TouchableOpacity
						onPress={() =>
							navigation.navigate("Browser", {
								url: `https://${assignedClient}.delishtic.com`,
							})
						}>
						<Text
							style={{
								fontSize: 14,
								fontWeight: "bold",
								color: "#136eb4",
								textAlign: "center",
								marginTop: 14,
							}}>
							{`https://${assignedClient}.delishtic.com`}
						</Text>
					</TouchableOpacity>
				</View>
			</View>
			<View>
				<TouchableOpacity
					onPress={() => printToFile()}
					style={{
						backgroundColor: "#136eb4",
						padding: 15,
						borderRadius: 8,
						paddingHorizontal: 30,
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "row",
						marginTop: 30,
					}}>
					<FontAwesome name='file-pdf-o' size={24} color='white' />
					<Text
						style={{
							color: "white",
							fontSize: 15,
							fontWeight: "bold",
							marginLeft: 10,
						}}>
						Print PDF
					</Text>
				</TouchableOpacity>
			</View>
		</View>
	);
};

export default GetQr;

const styles = StyleSheet.create({
	card: {
		height: 400,
		width: 340,
		backgroundColor: "#fff",
		borderRadius: 5,
		padding: 22,
		margin: 10,
		paddingLeft: 10,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.8,
		shadowRadius: 2,
	},
	cardUpper: {
		height: 150,
		width: 340,

		backgroundColor: "#136eb4",
		position: "absolute",
		top: 0,
		left: 0,
		borderRadius: 5,
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
		paddingVertical: 20,
	},
	cardLower: {
		height: 150,
		width: 340,
		backgroundColor: "white",
		position: "absolute",
		left: 0,
		borderRadius: 5,
		bottom: 0,
	},
});
