import { StatusBar } from "expo-status-bar";
import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Image,
  Keyboard,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  Button,
  TouchableWithoutFeedback,
  View,
  Platform,
} from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { Formik } from "formik";
import * as yup from "yup";
import { UserContext } from "../src/context/userContext";
import { Auth } from "aws-amplify";
import * as SecureStore from "expo-secure-store";
import packageInfo from "../package.json";
import { ActivityIndicator } from "react-native-paper";
import AnimatedLottieView from "lottie-react-native";

// Validation using yup
const formSchema = yup.object({
  userName: yup.string().required("User Name is required"),
  password: yup.string().required("Password is required"),
});

//Main functional component
const Login = ({ navigation }) => {
  const [passwordSeen, setPasswordSeen] = useState(false);
  const [eyeIcon, setEyeIcon] = useState("eye-slash");

  const { state, dispatch } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState("");
  let ExponentPushToken = state.ExponentPushToken;
  console.log("Push token==", ExponentPushToken);
  //Toggle the password seen nd unseen
  function tooglePasswordSeen() {
    console.log("passwordSeen", passwordSeen);
    if (passwordSeen === true) {
      setEyeIcon("eye-slash");
    } else {
      setEyeIcon("eye");
    }
    setPasswordSeen(!passwordSeen);
  }

  //Takes to the onboarding page
  function handlePress() {
    navigation.navigate("Delishtic Merchant");
  }

  //Login functionality
  async function signIn(values, actions) {
    try {
      actions.resetForm();
      setLoading(true);
      console.log("before sign in");

      const user = await Auth.signIn(values.userName, values.password);
      console.log("user", user);
      console.log(user.signInUserSession.accessToken["jwtToken"]);
      console.log("after");

      save("refreshTokens", user.signInUserSession.refreshToken["token"]);
      save("accessToken", user.signInUserSession.accessToken["jwtToken"]);
      save("idToken", user.signInUserSession.idToken["jwtToken"]);

      if (Platform.OS == "web") {
        localStorage.setItem(
          "refreshTokens",
          user.signInUserSession.refreshToken["token"]
        );
      }
      const updatedUser = await Auth.currentAuthenticatedUser();
      if(ExponentPushToken)
      {
        await Auth.updateUserAttributes(updatedUser, {
          "custom:ExponentPushToken": ExponentPushToken,
        });
      }

      console.log("Updated user is ", user?.attributes["custom:owner_name"]);
      let groups = user.signInUserSession.idToken.payload["cognito:groups"];
      console.log("Groups", groups);
      let isAdmin = false;
      if (groups) {
        let adminGroup = groups.filter((group) => group === "MerchantAdmin")[0];
        console.log("adminGroup", adminGroup);
        isAdmin = adminGroup ? true : false;
      }
      console.log("isAdmin", isAdmin);
      if (isAdmin) {
        dispatch({ type: "adminLogin", payload: user });
        dispatch({ type: "isLoggedIn", payload: true });
        if (Platform.OS == "web") {
          localStorage.setItem("userType", "Admin");
        }
        navigation.navigate("Admin screen");
        setLoading(false);
      } else {
        const assignedRole = user?.attributes["custom:role"];
        let role;
        if (assignedRole === "merchant") {
          role = "Merchant";
        } else if (assignedRole === "dataentry") {
          role = "DataEntry";
        }
        console.log("assignedRole", assignedRole, "role", role);
        if (Platform.OS === "web") {
          localStorage.setItem("userType", role);
        }
        dispatch({ type: assignedRole + "Login", payload: user });
        dispatch({ type: "isLoggedIn", payload: true });
        navigation.navigate(role);
        setLoading(false);
      }
    } catch (error) {
      setWarning(error.message);
      setTimeout(() => {
        setWarning("");
      }, 3000);
      console.log("error signing in", error);
      setLoading(false);
    }
    actions.resetForm();
  }

  // saving auth token
  async function save(key, value) {
    if (Platform.OS !== "web") await SecureStore.setItemAsync(key, value);
  }

  return (
    <View style={styles.container}>
      <StatusBar style="auto" />

      {loading == true ? (
        Platform.OS == "web" ? (
          <>
            <Text
              style={{
                textAlign: "center",
                fontSize: 15,
                marginBottom: 20,
                color: "green",
              }}
            >
              Sit back and relax while we are connecting....
            </Text>
            <ActivityIndicator size="large" color="#00ff00" />
          </>
        ) : (
          <AnimatedLottieView
            source={require("../assets/loading.json")}
            autoPlay
            loop
          />
        )
      ) : (
        <>
          <Image source={require("../assets/logo.png")} style={styles.logo} />

          <Formik
            initialValues={{ userName: "", password: "" }}
            validationSchema={formSchema}
            onSubmit={(values, actions) => signIn(values, actions)}
          >
            {(props) => (
              <View style={{ marginTop: 30, marginBottom: 130 }}>
                <Text style={styles.label}>Enter User Name</Text>
                <TextInput
                  value={props.values.userName}
                  onChangeText={props.handleChange("userName")}
                  onBlur={props.handleBlur("userName")}
                  style={styles.input}
                  placeholder="User Name"
                  autoCapitalize="none"
                />

                <Text style={styles.errortext}>
                  {props.touched.userName && props.errors.userName}
                </Text>
                <Text style={styles.label}>Enter Password</Text>

                <View>
                  <TextInput
                    secureTextEntry={!passwordSeen}
                    value={props.values.password}
                    style={styles.input}
                    placeholder="Password"
                    onChangeText={props.handleChange("password")}
                    onBlur={props.handleBlur("password")}
                    autoCapitalize="none"
                  />
                  <FontAwesome
                    onPress={() => tooglePasswordSeen()}
                    style={{
                      position: "absolute",
                      right: 20,
                      top: 14,
                    }}
                    name={eyeIcon}
                    size={20}
                    color={"black"}
                  />
                </View>

                <Text style={styles.errortext}>
                  {props.touched.password && props.errors.password}
                </Text>
                {warning == "" ? null : (
                  <Text
                    style={{
                      marginVertical: 10,
                      color: "red",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {warning}
                  </Text>
                )}
                <TouchableOpacity
                  style={{
                    backgroundColor: "rgb(56,81,126)",
                    padding: 10,
                    borderRadius: 10,
                    marginTop: 20,
                  }}
                  onPress={props.handleSubmit}
                >
                  <Text
                    style={{
                      color: "white",
                      textAlign: "center",
                      textTransform: "capitalize",
                      fontSize: 18,
                    }}
                  >
                    submit
                  </Text>
                </TouchableOpacity>
                <View
                  style={{
                    marginVertical: 30,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text>Not a merchant</Text>
                  <TouchableOpacity onPress={() => handlePress()}>
                    <Text style={{ fontWeight: "bold" }}>
                      Click here to sign up
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}
          </Formik>
        </>
      )}

      <View style={styles.versionContainer}>
        <Text style={styles.versionText}>v{packageInfo.version}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    borderWidth: 1,
    padding: 10,
    width: 300,
    borderRadius: 10,
  },
  label: {
    marginVertical: 10,
    fontSize: 20,
  },
  logo: {
    width: 200,
    resizeMode: "contain",
  },
  errortext: {
    color: "red",
    fontSize: 12,
  },
  versionContainer: {
    position: "absolute",
    right: 20,
    bottom: 20,
  },
  versionText: {
    fontSize: 8,
    textAlign: "right",
    color: "gray",
    marginRight: 5,
  },
});

export default Login;
