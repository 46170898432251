import {
	Platform,
	ScrollView,
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
} from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { BottomSheet } from "react-native-btr";
import { Entypo } from "@expo/vector-icons";
import { ActivityIndicator, TextInput } from "react-native-paper";
import axios from "axios";
import {
	API_URL_CLIENT,
	API_URL_LOCATIONS,
	API_URL_RESTAURANT,
	SERVER_API_URL,
} from "../config/config";
import { useToast } from "react-native-toast-notifications";

import { UserContext } from "../src/context/userContext";
import RNPickerSelect from "react-native-picker-select";

const DeliveryTime = () => {
	const toast = useToast();

	const { state, dispatch } = useContext(UserContext);
	const assignedClient = state.user.attributes["custom:client"];
	const timeData = [
		"10 Minutes",
		"20 Minutes",
		"30 Minutes",
		"40 Minutes",
		"50 Minutes",
		"1 Hour",
		"2 Hours",
		"3 Hours",
		"4 Hours",
		"Same day",
		"Next day",
		"Same day or Next day",
		"1 to 3 days",
		"3 to 5 days",
		"5 to 10 days",
	];
	const toggleBottomNavigationView = () => {
		setVisible(!visible);
	};
	const toggleCustomTimeSheet = () => {
		setCustomTimeSheet(!customTimeSheet);
	};
	const [restaurantId, setRestaurantId] = useState("");
	const [visible, setVisible] = useState(false);
	const [customTimeSheet, setCustomTimeSheet] = useState(false);
	const [deliveryTime, setDeliveryTime] = useState("30 Minutes");
	const [selected, setSelected] = useState("minutes");
	const [customTime, setCustomTime] = useState("");
	const [location, setLocation] = useState("");
	const [locations, setLocations] = useState([]);
	const [loading, setLoading] = useState(false);
	const restructureLocation = (item) => {
		let labels = [];
		if (item.length) {
			for (let i = 0; i < item.length; i++) {
				labels[i] = { label: item[i].name, value: item[i].id };
			}
		}

		setLocations(labels);
		setLocation(labels[0].value);
		// console.log("labels=", labels)

		console.log("labels=", locations);
	};

	const getRestaurants = async () => {
		setLoading(true);
		await axios
			.get(`${SERVER_API_URL}${API_URL_RESTAURANT}${API_URL_CLIENT}`, {
				headers: {
					client: assignedClient,
				},
			})
			.then((res) => {
				console.log("data is ", res.data[0].id);
				setRestaurantId(res.data[0].id);
				loadlocations(res.data[0].id);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};

	const loadlocations = async (value) => {
		setLoading(true);
		await axios
			.get(`${SERVER_API_URL}${API_URL_LOCATIONS}`, {
				headers: {
					restaurantGroup: value,
				},
			})
			.then((res) => {
				console.log("clients=", res.data[0].serviceEstimates.threshold);
				restructureLocation(res.data);
				setDeliveryTime(res.data[0].serviceEstimates.threshold);
				setLoading(false);
			})
			.catch((err) => {
				console.log("err3=", err);
				setLocations([]);
				setLoading(false);
			});
	};

	const addThresholdTime = async () => {
		await axios
			.patch(
				`${SERVER_API_URL}${API_URL_LOCATIONS}/${location}`,
				{
					serviceEstimates: {
						threshold: deliveryTime,
					},
				},
				{
					headers: {
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => {
				toast.show("Time added successfully", {
					type: "success",
					placement: "top",
					duration: 2000,
					offset: 30,
					animationType: "slide-in",
				});
				console.log("success===", res.data);
			})
			.catch((err) => {
				toast.show("Something went wrong !!", {
					type: "danger",
					placement: "top",
					duration: 2000,
					offset: 30,
					animationType: "slide-in",
				});
				console.log(err);
			});
	};

	useEffect(() => {
		getRestaurants();
		// loadlocations(restaurantId);
	}, []);
	return (
		<View>
			<BottomSheet
				visible={visible}
				onBackButtonPress={toggleBottomNavigationView}
				onBackdropPress={toggleBottomNavigationView}>
				<View style={{ backgroundColor: "#fff", width: "100%", height: 500 }}>
					<View>
						<View
							style={{
								paddingVertical: 8,
								margin: 10,
								marginHorizontal: 5,
								borderBottomWidth: 0.2,
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								position: "relative",
								borderColor: "lightgrey",
							}}>
							<Text
								style={{
									fontSize: 20,
									textAlign: "center",
									fontWeight: "bold",
								}}>
								Delivery Time{" "}
							</Text>

							<Entypo
								onPress={() => {
									// setCategory(elem.name);
									toggleBottomNavigationView();
								}}
								style={{ position: "absolute", right: 10 }}
								name='cross'
								size={24}
								color='black'
							/>
						</View>
					</View>
					<ScrollView>
						{timeData.map((elem, index) => {
							return (
								<TouchableOpacity
									onPress={() => {
										// setCategory(elem.name);
										setDeliveryTime(elem);
										toggleBottomNavigationView();
									}}
									style={{
										paddingVertical: 8,
										margin: 10,
										paddingHorizontal: 10,
										marginHorizontal: 5,
										borderBottomWidth: 0.2,
										flexDirection: "row",
										alignItems: "center",
										position: "relative",
										borderColor: "lightgrey",
									}}>
									<Text
										style={{
											fontSize: 15,
											textAlign: "center",
											fontWeight: "bold",
										}}>
										{elem}
									</Text>
								</TouchableOpacity>
							);
						})}
					</ScrollView>
					<TouchableOpacity
						onPress={() => {
							toggleBottomNavigationView();
							toggleCustomTimeSheet();
							setDeliveryTime("");
							setCustomTime("");
						}}
						style={{
							borderWidth: 2,
							borderColor: "#e18846",
							padding: 10,
							paddingHorizontal: 20,
							margin: 10,
							marginBottom: 20,
							marginHorizontal: 20,
							borderRadius: 8,
						}}>
						<Text
							style={{
								textAlign: "center",
								color: "#e18846",
								fontWeight: "bold",
								fontSize: 16,
							}}>
							Add Custom Time +
						</Text>
					</TouchableOpacity>
				</View>
			</BottomSheet>
			<BottomSheet
				visible={customTimeSheet}
				onBackButtonPress={toggleCustomTimeSheet}
				onBackdropPress={toggleCustomTimeSheet}>
				<View
					style={{
						backgroundColor: "#fff",
						width: "100%",
						height: 400,
					}}>
					<View
						style={{
							paddingVertical: 8,
							margin: 10,
							marginHorizontal: 5,
							borderBottomWidth: 0.2,
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
							position: "relative",
							borderColor: "lightgrey",
						}}>
						<Text
							style={{
								fontSize: 20,
								textAlign: "center",
								fontWeight: "bold",
							}}>
							Add Custom Time{" "}
						</Text>

						<Entypo
							onPress={() => {
								// setCategory(elem.name);
								toggleCustomTimeSheet();
							}}
							style={{ position: "absolute", right: 10 }}
							name='cross'
							size={24}
							color='black'
						/>
					</View>
					<View
						style={{
							padding: 10,
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "flex-end",
						}}>
						<TextInput
							style={styles.textInput}
							underlineColorAndroid='transparent'
							label='Time*'
							autoCapitalize='none'
							keyboardType='numeric'
							onChangeText={(value) => {
								// setProductCategoryName(value)

								setCustomTime(value);
							}}
							theme={{ colors: { primary: "#136eb4" } }}
							// value={productCategoryName}
						/>
						<Text
							style={{
								fontSize: 17,
								// textAlign: "center",
								marginBottom: 8,
								marginHorizontal: 5,
								color: "#136eb4",
							}}>
							{selected}
						</Text>
					</View>
					<View style={{ flexDirection: "row" }}>
						<TouchableOpacity
							onPress={() => {
								setSelected("minutes");
							}}
							style={
								{
									...styles.btn,
									borderColor: selected === "minutes" ? "#136eb4" : "white",
									borderWidth: 2,
									marginLeft: Platform.OS == "web" ? 10 : 0,
									padding: 10,
									borderRadius: 8,
								}
								// change the border if minutes is selected
							}>
							<Text style={styles.btnText}>minutes</Text>
						</TouchableOpacity>
						<TouchableOpacity
							onPress={() => {
								setSelected("hours");
							}}
							style={{
								...styles.btn,
								borderColor: selected === "hours" ? "#136eb4" : "white",
								borderWidth: 2,
								marginLeft: Platform.OS == "web" ? 10 : 0,
								padding: 10,
								borderRadius: 8,
							}}>
							<Text style={styles.btnText}>hours</Text>
						</TouchableOpacity>
						<TouchableOpacity
							onPress={() => {
								setSelected("days");
							}}
							style={{
								...styles.btn,
								borderColor: selected === "days" ? "#136eb4" : "white",
								borderWidth: 2,
								marginLeft: Platform.OS == "web" ? 10 : 0,
								padding: 10,
								borderRadius: 8,
							}}>
							<Text style={styles.btnText}>days</Text>
						</TouchableOpacity>
					</View>
					{customTime.length > 0 && selected.length > 0 ? (
						<Text
							style={{
								fontSize: 15,
								// textAlign: "center",
								marginVertical: 8,
								marginHorizontal: 5,
								color: "grey",
							}}>
							Preview: Delivery happens within {customTime} {selected}
						</Text>
					) : (
						<></>
					)}
					<TouchableOpacity
						disabled={customTime.length === 0 || selected.length === 0}
						onPress={() => {
							toggleCustomTimeSheet(false);
							setDeliveryTime(customTime + " " + selected);
						}}
						style={{
							borderWidth: 0,

							backgroundColor:
								customTime.length === 0 || selected.length === 0
									? "lightgrey"
									: "#136eb4",

							padding: 15,
							paddingHorizontal: 20,
							margin: 10,
							marginTop: 100,
							borderRadius: 8,
						}}>
						<Text
							style={{
								textAlign: "center",
								color: "white",
								fontWeight: "bold",
								fontSize: 16,
							}}>
							+ Add custom time{" "}
						</Text>
					</TouchableOpacity>
				</View>
			</BottomSheet>

			{loading ? (
				<ActivityIndicator size='large' color='#00ff00' />
			) : (
				<>
					<RNPickerSelect
						fixAndroidTouchableBug={true}
						style={pickerSelectStyles}
						useNativeAndroidPickerStyle={false}
						items={locations}
						value={location}
						placeholder={{
							label: "Enter your location",
							value: "",
						}}
						onValueChange={(value) => {
							setLocation(value);
							console.log(" value is", value);
						}}
					/>
					<View
						style={{
							padding: 10,
							backgroundColor: "lightgrey",
							borderRadius: 10,
							margin: 10,
						}}>
						<Text
							style={{
								fontSize: 17,
								color: "#136eb4",
								marginTop: 14,
								textTransform: "capitalize",
								fontWeight: "700",
							}}>
							Delivery Time:
						</Text>
						<Text
							style={{
								fontSize: 15,
							}}>
							{deliveryTime}{" "}
						</Text>
					</View>
					<TouchableOpacity
						onPress={() => {
							setVisible(true);
						}}
						style={{
							marginTop: 14,
							backgroundColor: "#136eb4",
							padding: 10,
							borderRadius: 10,
							marginHorizontal: 10,
						}}>
						<Text
							style={{
								fontSize: 15,
								color: "white",

								textTransform: "capitalize",
								fontWeight: "700",
								textAlign: "center",
							}}>
							Set Delivery Time
						</Text>
					</TouchableOpacity>
					<TouchableOpacity
						onPress={() => {
							addThresholdTime();
						}}
						style={{
							marginTop: 14,
							backgroundColor: "#136eb4",
							padding: 10,
							borderRadius: 10,
							marginHorizontal: 10,
						}}>
						<Text
							style={{
								fontSize: 15,
								color: "white",

								textTransform: "capitalize",
								fontWeight: "700",
								textAlign: "center",
							}}>
							Submit
						</Text>
					</TouchableOpacity>
				</>
			)}
		</View>
	);
};

export default DeliveryTime;

const styles = StyleSheet.create({
	textInput: {
		backgroundColor: "white",

		marginBottom: 10,
		flexGrow: 1,
	},
	btn: {
		backgroundColor: "white",
		borderWidth: 1,
		padding: 10,
		paddingHorizontal: 20,
		marginHorizontal: 10,
		borderRadius: 8,
	},
	btnText: {
		fontWeight: "600",
		fontSize: 17,
	},
});
const pickerSelectStyles = StyleSheet.create({
	inputIOS: {
		fontSize: 12,
		marginHorizontal: 5,
		borderWidth: 1,
		borderColor: "gray",
		borderRadius: 4,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon
		padding: 5,
		marginVertical: 10,
	},
	inputAndroid: {
		fontSize: 12,
		marginHorizontal: 5,
		borderWidth: 0.5,
		borderColor: "black",
		borderRadius: 8,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon

		padding: 5, // to ensure the text is never behind the icon
		marginVertical: 10,
	},
});
