import { Dimensions, StyleSheet, Text, View, Image } from "react-native";
import React, { useState } from "react";
import { TextInput } from "react-native-paper";
import { TouchableOpacity } from "react-native-gesture-handler";
import RNPickerSelect from "react-native-picker-select";

const GetStarted = ({ navigation }) => {
	const [country, setCountry] = useState("India");
	const [number, setNumber] = useState("");
	const [error, setError] = useState(false);
	return (
		<View style={styles.mainView}>
			<Text style={styles.title}>Enter Business details</Text>
			<View>
				{/* <TextInput
					style={styles.textInput}
					underlineColorAndroid='transparent'
					label='Country'
					autoCapitalize='none'
					//   onChangeText={this.handleEmail}
					theme={{ colors: { primary: "#136eb4" } }}
				/> */}
				<View style={{ flexDirection: "row" }}>
					<Image
						source={require("../../assets/flagIndia.jpg")}
						style={{ height: 35, width: 45, borderRadius: 5 }}
					/>
					<View style={{ flexGrow: 1 }}>
						<RNPickerSelect
							fixAndroidTouchableBug={true}
							style={pickerSelectStyles}
							placeholder={{ label: "Select Country", value: null }}
							placeholderTextColor='black'
							useNativeAndroidPickerStyle={false}
							items={[
								{ label: "India (+91)", value: "India" },
								// { label: "Shop", value: "shop" },
							]}
							value={country}
							onValueChange={(value) => {
								console.log(" value is", value);
								setCountry(value);
							}}
						/>
					</View>
				</View>
				<View style={{ marginTop: 20 }}>
					{error && (
						<Text style={{ color: "red", fontSize: 12 }}>
							Please enter valid mobile number
						</Text>
					)}
					<TextInput
						style={styles.textInput}
						underlineColorAndroid='transparent'
						label='Mobile Number'
						autoCapitalize='none'
						keyboardType='number-pad'
						onChangeText={(text) => {
							console.log("text is", text.length);
							if (text.length > 10 || text.length < 10) {
								setError(true);
							} else {
								setError(false);
								setNumber(text.length);
							}
						}}
						theme={{ colors: { primary: "#136eb4" } }}
					/>
				</View>
				<TouchableOpacity
					disabled={error || number.length < 10}
					onPress={() => navigation.navigate("Enter OTP")}
					style={{
						backgroundColor: error || number.length < 10 ? "#ccc" : "#136eb4",
						padding: 17,
						borderRadius: 8,
						marginTop: 30,
					}}>
					<Text style={{ color: "white", textAlign: "center", fontSize: 18 }}>
						Continue
					</Text>
				</TouchableOpacity>
				{/* <Text
					style={{
						textAlign: "center",
						marginVertical: 10,
						fontSize: 20,
						color: "grey",
					}}>
					or
				</Text>
				<TouchableOpacity style={styles.continueBtn}>
					<Image
						source={require("../../assets/truecaller.png")}
						style={styles.logo}
					/>

					<Text style={{ color: "black", textAlign: "center", fontSize: 18 }}>
						Continue with truecaller
					</Text>
				</TouchableOpacity>
				<TouchableOpacity style={styles.continueBtn}>
					<Image
						source={require("../../assets/gmail.png")}
						style={styles.logo}
					/>
					<Text style={{ color: "black", textAlign: "center", fontSize: 18 }}>
						Continue with Email
					</Text>
				</TouchableOpacity>
				<TouchableOpacity style={styles.continueBtn}>
					<Image
						source={require("../../assets/google.png")}
						style={styles.logo}
					/>
					<Text style={{ color: "black", textAlign: "center", fontSize: 18 }}>
						Continue with Google
					</Text>
				</TouchableOpacity>
				<TouchableOpacity style={styles.continueBtn}>
					<Image
						source={require("../../assets/facebook.png")}
						style={styles.logo}
					/>
					<Text style={{ color: "black", textAlign: "center", fontSize: 18 }}>
						Continue with Facebook
					</Text>
				</TouchableOpacity> */}
			</View>
		</View>
	);
};

export default GetStarted;

const styles = StyleSheet.create({
	mainView: {
		backgroundColor: "white",
		height: Dimensions.get("screen").height,
		paddingHorizontal: 15,
	},
	title: {
		fontSize: 20,
		fontWeight: "bold",
		marginVertical: 10,
	},
	textInput: {
		backgroundColor: "white",
		marginBottom: 10,
	},
	logo: {
		width: 30,
		height: 30,
		marginRight: 10,
		resizeMode: "contain",
	},
	continueBtn: {
		borderWidth: 1,
		borderColor: "lightgrey",
		padding: 15,
		borderRadius: 8,
		marginVertical: 10,
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "row",
	},
});
const pickerSelectStyles = StyleSheet.create({
	inputIOS: {
		fontSize: 12,
		marginHorizontal: 5,
		borderWidth: 1,
		borderColor: "gray",
		borderRadius: 4,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon
		padding: 5,
	},
	inputAndroid: {
		fontSize: 12,
		marginHorizontal: 5,
		borderWidth: 0.5,
		borderColor: "black",
		borderRadius: 8,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon

		padding: 5, // to ensure the text is never behind the icon
	},
});
