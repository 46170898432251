// import firebase from "firebase/compat/app";

import { initializeApp } from "firebase/app";
// import "@firebase/messaging";

const firebaseConfig = {
	apiKey: "AIzaSyDd0AsL9pt1dVhgjgFX5VxrjDMokANkepU",
	authDomain: "delishtic-business.firebaseapp.com",
	projectId: "delishtic-business",
	storageBucket: "delishtic-business.appspot.com",
	messagingSenderId: "63042283554",
	appId: "1:63042283554:web:daa90ddee484655b56027b",
	measurementId: "G-HQ7F6Q7PC0",
};
const app = initializeApp(firebaseConfig);

export { app };
