import * as React from "react";
import { View, Text, StyleSheet, Image, ScrollView } from "react-native";
import { Card, Input, Button, Icon } from "react-native-elements";
import axios from "axios";
import * as ImagePicker from "expo-image-picker";
import RNPickerSelect from "react-native-picker-select";
import SelectBox from "react-native-multi-selectbox";
import { xorBy } from "lodash";
import {
    SERVER_API_URL,
	API_URL_RESTAURANT
} from "../config/config";

const shopValues = [
	{
		item: "Grocery",
		id: "grocery",
	},
	{
		item: "Fruits veg",
		id: "fruitsveg",
	},
	{
		item: "Chocolate",
		id: "chocolate",
	},
	{
		item: "Sweetsnack",
		id: "sweetsnack",
	},
	{
		item: "Meat",
		id: "meat",
	},
	{
		item: "Medicines",
		id: "medicines",
	},
	{
		item: "Personalcare",
		id: "personalcare",
	},
	{
		item: "Gifts",
		id: "gifts",
	},
];
const foodValues = [
	{
		item: "Biryani",
		id: "biryani",
	},
	{
		item: "Pizza",
		id: "pizza",
	},
	{
		item: "Chicken",
		id: "chicken",
	},
	{
		item: "Mutton",
		id: "mutton",
	},
	{
		item: "Burger",
		id: "burger",
	},
	{
		item: "Fish",
		id: "fish",
	},
	{
		item: "Parotta",
		id: "parotta",
	},
	{
		item: "Meals",
		id: "meals",
	},
	{
		item: "Rolls",
		id: "rolls",
	},
	{
		item: "Icecream",
		id: "icecream",
	},
];
export const EditRestaurantInfo = ({ route, navigation }) => {
	const {
		id,
		name,
		description,
		collections,
		businessType,
		restaurantBanner,
		restaurantLogo,
		showCollection,
	} = route.params;
	console.log("restaurantBanner is", restaurantBanner);
	console.log(showCollection);

	console.log("business type is ", businessType);
	// const [name, updateName] = React.useState("");
	const [newBusinessType, updateBusinessType] = React.useState(businessType);
	const [logoImage, setLogoImage] = React.useState(restaurantLogo);
	const [bannerImage, setBannerImage] = React.useState(restaurantBanner);
	const [logoUri, setLogoUri] = React.useState(restaurantLogo);
	const [bannerUri, setBannerUri] = React.useState(restaurantBanner);
	const [preName, updatePreName] = React.useState(name);
	const [preDesc, updatePreDesc] = React.useState(description);
	const [newCollections, setCollections] = React.useState(
		businessType == "food"
			? foodValues.filter((elem) =>
					collections.includes(elem.item.toLowerCase())
			  )
			: shopValues.filter((elem) =>
					collections.includes(elem.item.toLowerCase())
			  )
	);

	const [errorMessage, updateErrorMessage] = React.useState("Enter Valid Name");
	console.log("route is ", route.params);
	function onMultiChange() {
		return (item) => {
			setCollections(xorBy(newCollections, [item], "id"));
			// setCollections(collections.push(item));
		};
	}

	let collectionsArr = collections;
	Object.keys(newCollections).forEach(function (key) {
		console.log(newCollections[key].id);
		collectionsArr.push(newCollections[key].id);
		collectionsArr = [...new Set(collectionsArr)];
	});
	console.log("collection arr is ", collectionsArr);
	React.useEffect(() => {
		newBusinessType == "food"
			? setCollections(
					foodValues.filter((elem) =>
						collections.includes(elem.item.toLowerCase())
					)
			  )
			: setCollections(
					shopValues.filter((elem) =>
						collections.includes(elem.item.toLowerCase())
					)
			  );
	}, [newBusinessType]);

	console.log(
		"filterd array is",
		// foodValues.filter((elem) => elem.item == collections.map((elem) => elem))
		foodValues.filter((elem) => !collections.includes(elem.item.toLowerCase()))
	);

	// console.log(collectionsArr);
	React.useEffect(() => {
		(async () => {
			if (Platform.OS !== "web") {
				const { status } =
					await ImagePicker.requestMediaLibraryPermissionsAsync();
				if (status !== "granted") {
					alert("Sorry, we need camera roll permissions to make this work!");
				}
			}
		})();
	}, []);
	const pickImage = async () => {
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.All,
			allowsEditing: true,
			aspect: [4, 3],
			quality: 1,
		});

		console.log(result);

		if (!result.cancelled) {
			setLogoImage(result.uri);
			setLogoUri(result.uri);
		}
	};
	const pickBannerImage = async () => {
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.All,
			allowsEditing: true,
			aspect: [4, 3],
			quality: 1,
		});

		console.log(result);

		if (!result.cancelled) {
			setBannerImage(result.uri);
			setBannerUri(result.uri);
		}
	};

	const editDetails = async () => {
		console.log(preDesc);
		console.log(id);
		if (name == "") {
			alert("Please Enter A Valid Name ");
			return;
		}
		await axios
			.patch(
				`${SERVER_API_URL}${API_URL_RESTAURANT}/${id}`,
				{
					name: preName,
					description: preDesc,
					media: {
						restaurantUnselectedIcon: logoUri,
						restaurantLogo: logoUri,
						restaurantSelectedIcon: logoUri,
						restaurantBanner: bannerUri,
						restaurantIcon: logoUri,
						restaurantThumbnail: logoUri,
					},
					businessType: newBusinessType,
					collections: [...new Set(collectionsArr)],
				}
			)
			.then((res) =>
				// navigation.navigate("TabNavigator", { screen: "restaurantInfo" },{id:res.data.id,name:res.data.name,clientId:res.data.clientName})
				console.log(res.data)
			)
			.catch((err) => console.log(err));
		alert(`${preName} Saved SuccessFully `);
	};
	console.log("Previous desc is ", preDesc);
	console.log("Previous name is ", preName);
	return (
		<ScrollView style={{ flex: 1 }}>
			<Card>
				<Card.Title>Enter Details For Restaurant</Card.Title>
				<View>
					<Text style={styles.label}>Restaurant</Text>

					<Input
						defaultValue={preName}
						onChangeText={(change) => updatePreName(change)}></Input>
				</View>
				<View>
					<Text style={styles.label}>Description</Text>

					<Input
						placeholder=' '
						defaultValue={preDesc}
						onChangeText={(change) => updatePreDesc(change)}></Input>
				</View>
				<View>
					<Text style={styles.label}>Business Type</Text>
					<RNPickerSelect
						fixAndroidTouchableBug={true}
						style={pickerSelectStyles}
						useNativeAndroidPickerStyle={false}
						items={[
							{ label: "Food", value: "food" },
							{ label: "Shop", value: "shop" },
						]}
						value={newBusinessType}
						onValueChange={(value) => {
							console.log(" value is", value);
							updateBusinessType(value);

							setCollections([]);
						}}
					/>
				</View>
				<View>
					<View>
						<Text style={styles.label}>Logo Image</Text>
						<Button title='Choose image from Gallery' onPress={pickImage} />

						{logoImage !== null ? (
							<Image
								source={{ uri: logoImage }}
								style={{ width: 200, height: 200, marginVertical: 20 }}
							/>
						) : (
							<></>
						)}
					</View>
				</View>

				<View>
					<View>
						<Text style={styles.label}>Banner image</Text>
						<Button
							title='Choose image from Gallery'
							onPress={pickBannerImage}
						/>

						{bannerImage !== null ? (
							<Image
								source={{ uri: bannerImage }}
								style={{ width: 200, height: 200, marginVertical: 20 }}
							/>
						) : (
							<></>
						)}
					</View>
				</View>

				<View>
					{/* <Text style={styles.label}>Previously selected</Text>
					<View style={{ flexDirection: "row", flexWrap: "wrap" }}>
						{showCollection.map((elem) => (
							<Text
								style={{
									backgroundColor: "purple",
									padding: 6,
									textTransform: "capitalize",
									borderRadius: 5,
									marginHorizontal: 5,
									fontSize: 15,
									color: "white",
								}}>
								{elem}
							</Text>
						))}
					
					</View> */}

					<SelectBox
						listOptionProps={{ nestedScrollEnabled: true }}
						label='Collections'
						labelStyle={styles.label}
						options={newBusinessType == "food" ? foodValues : shopValues}
						selectedValues={newCollections}
						onMultiSelect={onMultiChange()}
						onTapClose={onMultiChange()}
						isMulti
					/>
				</View>
				<View style={{ flexDirection: "row", marginVertical: 30 }}>
					<Button
						title='Done'
						onPress={() => editDetails()}
						icon={
							<Icon
								type='material-community'
								name='check-outline'
								size={30}
								color='white'
							/>
						}
						titleStyle={{ marginLeft: 10 }}
						buttonStyle={{
							backgroundColor: "#3562ab",
							width: 100,
							borderRadius: 90,
							width: 120,
							padding: 5,
						}}></Button>
					<Button
						title='Cancel'
						onPress={() => navigation.navigate("restaurantInfo")}
						icon={
							<Icon
								type='material-community'
								name='close-outline'
								size={30}
								color='white'
							/>
						}
						titleStyle={{ marginLeft: 10 }}
						buttonStyle={{
							backgroundColor: "#bd0b29",
							width: 100,
							borderRadius: 90,
							width: 120,
							padding: 5,
							marginLeft: 50,
						}}></Button>
				</View>
			</Card>
		</ScrollView>
	);
};
const pickerSelectStyles = StyleSheet.create({
	inputIOS: {
		fontSize: 12,
		marginHorizontal: 5,
		borderWidth: 1,
		borderColor: "gray",
		borderRadius: 4,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon
		padding: 5,
	},
	inputAndroid: {
		fontSize: 12,
		marginHorizontal: 5,

		borderWidth: 0.5,
		borderColor: "black",
		borderRadius: 8,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon

		padding: 5, // to ensure the text is never behind the icon
	},
});
const styles = StyleSheet.create({
	label: {
		marginTop: 10,
		marginBottom: 6,
		fontSize: 16,
		fontWeight: "bold",
	},
});
