import axios from "axios";
import {
    SERVER_API_URL,
	API_URL_LOCATIONS,
	API_URL_PROMOTION,
	API_URL_RESTAURANT,
	API_URL_CLIENT
} from "../config/config";

export const createPromotion = async (promotionValues, client, idToken) => {
	return await axios.post(
		`${SERVER_API_URL}${API_URL_PROMOTION}`,
		promotionValues,
		{
			headers: {
				client,
				Authorization: idToken,
			},
		}
	);
};

export const getPromotionsList = async (header) => {
	console.log("header==", header);
	return await axios.get(`${SERVER_API_URL}${API_URL_PROMOTION}`, {
		headers: header,
	});
};

export const updatePromotion = async (promotionId, promotionValues, idToken) => {
	console.log("Values===", promotionValues);
	return await axios.patch(
		`${SERVER_API_URL}${API_URL_PROMOTION}/${promotionId}`,
		promotionValues,
		{
			headers: {
				Authorization: idToken,
			},
		}
	);
};
export const deletePromotion = async (promotionId, idToken) => {
	return await axios.delete(
		`${SERVER_API_URL}${API_URL_PROMOTION}/${promotionId}`, 
		{
			headers: {
				Authorization: idToken,
			},
		}
	);
};
export const getRestaurantsList = async (client) => {
	console.log("client===", client);
	return await axios.get(
		`${SERVER_API_URL}${API_URL_RESTAURANT}${API_URL_CLIENT}`,
		{
			headers: { client },
		}
	);
};
export const getLocationsList = async (restaurantGroup) => {
	return await axios.get(`${SERVER_API_URL}${API_URL_LOCATIONS}`, {
		headers: { restaurantGroup },
	});
};
