import { View, Image, StyleSheet, Dimensions, Platform } from "react-native";
import React from "react";
import { ActivityIndicator } from "react-native-paper";
import AnimatedLottieView from "lottie-react-native";

const LoadingScreen = () => {
	return (
		<View style={styles.screen}>
			{Platform.OS === "web" ? (
				<ActivityIndicator size='large' color='#00ff00' />
			) : (
				<AnimatedLottieView
					source={require("../assets/loading.json")}
					autoPlay
					loop
				/>
			)}
		</View>
	);
};

export default LoadingScreen;

const styles = StyleSheet.create({
	screen: {
		justifyContent: "center",
		alignItems: "center",
		height: Dimensions.get("screen").height,
		width: Dimensions.get("screen").width,
	},
});
