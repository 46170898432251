const businessTypeFood = [
	"Biryani",
	"Pizza",
	"North Indian",
	"South Indian",
	"Burger",
	"Sandwich",
	"Tea & Beverages",
	"Cakes & Desserts",
	"Sweets & Snacks",
	"Home Food",
	"Healthy Food",
	"Fast Food",
	"Chinese",
	"Kebab",
];

const businessSubTypeShop = [
	"Grocery",
	"Fruits & Vegetables",
	"Imported Chocolates",
	"Imported Snacks",
	"Indian Chocolates",
	"Ice Cream",
	"Cheese",
	"Meat & Sea Food",
	"Pet Care",
	"Pharmacy",
	"Beauty",
	"Fashion",
	"Electronics",
	"Sweets & Snacks",
	"Cakes & Desserts",
	"Gifts & Lifestyles",
	"Books",
	"Stationery",
	"Sporting Goods",
	"Toys",
];

export { businessTypeFood, businessSubTypeShop };
