import React, { useContext, useState } from "react";
import { Card, Input, Icon, Button, Header } from "react-native-elements";
import {
	View,
	TouchableOpacity,
	FlatList,
	Text,
	StyleSheet,
	SafeAreaView,
	ActivityIndicator,
	Dimensions,
} from "react-native";
import { NavigationContainer, useFocusEffect } from "@react-navigation/native";
import axios from "axios";
import { ScrollView } from "react-native-gesture-handler";
import { SERVER_API_URL, API_URL_LOCATIONS } from "../../config/config";
import { UserContext } from "../../src/context/userContext";
import { Feather, MaterialIcons } from "@expo/vector-icons";

export const editRestaurant = ({ route, navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	console.log("route is ", route.params.id);
	const [location, updateLocation] = useState([]);
	const [refresh, updateRefresh] = useState(false);
	const [loading, setLoading] = useState(false);
	const getLocation = async () => {
		setLoading(true);
		await axios
			.get(SERVER_API_URL + API_URL_LOCATIONS, {
				headers: {
					restaurantgroup: route.params.id,
					client: route.params.clientId,
				},
			})
			.then((res) => {
				if (res.data) {
					updateLocation(res.data);
					setLoading(false);
				}
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};

	const deleteLocation = async (id) => {
		await axios
			.delete(`${SERVER_API_URL}${API_URL_LOCATIONS}/${id}`, {
				headers: {
					Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				},
			})
			.then((res) => alert("Location Deleted"))
			.catch((err) => console.log(err));

		updateRefresh(!refresh);
	};
	const setWorkingHours = (location) => {
		console.log("location is ", location);
		navigation.navigate("editLocations", {
			id: location.id,
			item: location,
		});
	};
	useFocusEffect(
		React.useCallback(() => {
			getLocation();
			return () => {
				// Do something when the screen is unfocused
				// Useful for cleanup functions
			};
		}, [refresh])
	);

	const renderItem = ({ item }) => {
		console.log("Location Id==", item);
		return (
			<View style={styles.card}>
				<View style={styles.flex}>
					<Text style={styles.label}>Location:</Text>
					<Text style={{ fontSize: 18 }}>{item.name}</Text>
				</View>
				<Card.Divider></Card.Divider>
				<Text style={{ fontSize: 20, marginBottom: 10 }}>Address :</Text>

				<View style={styles.flex}>
					<Text style={styles.label}>street1:</Text>
					<Text>{item.address.street1}</Text>
				</View>
				<View style={styles.flex}>
					<Text style={styles.label}>street2:</Text>
					<Text>{item.address.street2}</Text>
				</View>
				<View style={styles.flex}>
					<Text style={styles.label}>state:</Text>
					<Text>{item.address.state}</Text>
				</View>
				<Card.Divider></Card.Divider>

				<View style={styles.flex}>
					<Text style={styles.label}>Pin code:</Text>
					<Text>{item.address.zip}</Text>
				</View>
				<View style={styles.flex}>
					<Text style={styles.label}>Phone:</Text>
					<Text>{item.phone.value}</Text>
				</View>
				{/* <Card.Title>Phone:{item.fssaiLicense}</Card.Title> */}

				<View style={styles.buttonContainer}>
					<TouchableOpacity
						onPress={() =>
							navigation.navigate("InputEdit", { item: item, id: item.id })
						}>
						<Feather name='edit' size={30} color='green' />
						<Text>Edit</Text>
					</TouchableOpacity>
					<TouchableOpacity
						style={{ marginLeft: 20 }}
						onPress={() => deleteLocation(item.id)}>
						{/* <Icon name='delete' size={30} color='red'></Icon> */}
						<MaterialIcons name='delete-outline' size={30} color='red' />
						<Text>Delete</Text>
					</TouchableOpacity>
				</View>
				<TouchableOpacity
					style={{
						marginVertical: 10,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "lightgreen",
						padding: 10,
					}}
					onPress={() => setWorkingHours(item)}>
					<Text style={{ color: "white", fontSize: 18 }}>
						Set Working Hours
					</Text>
				</TouchableOpacity>
			</View>
		);
	};

	return (
		<>
			{/* <Header
				centerComponent={{
					text: "Edit Restaurants",
					style: { color: "#fff", fontSize: 20 },
				}}
				rightComponent={{
					text: "Back",
					style: { color: "#fff", fontSize: 20 },
					onPress: () => navigation.navigate("Restaurants"),
				}}></Header> */}
			<View style={{ paddingVertical: 20 }}>
				<Card.Title>Add/Edit Location of the Restaurant</Card.Title>

				<Button
					title='Add Location'
					onPress={() =>
						navigation.navigate("AddRestaurantLocation", {
							params: {
								id: route.params.id,
								clientId: route.params.clientId,
							},
						})
					}
					icon={
						<Icon
							type='material-community'
							name='map-marker-plus'
							size={30}
							color='white'
						/>
					}
					titleStyle={{ marginLeft: 10 }}
					buttonStyle={{
						backgroundColor: "olive",
						borderRadius: 80,
						width: 200,
						alignSelf: "center",
						padding: 5,
					}}></Button>
			</View>
			{loading == true ? (
				<ActivityIndicator size='large' color='#00ff00' />
			) : (
				<FlatList data={location} renderItem={renderItem}></FlatList>
			)}
		</>
	);
};

const styles = StyleSheet.create({
	card: {
		marginVertical: 10,
		marginHorizontal: 10,
		padding: 10,
		backgroundColor: "white",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.36,
		shadowRadius: 6.68,

		elevation: 11,
	},
	label: {
		marginRight: 10,
		fontSize: 16,
		fontWeight: "bold",
	},
	flex: {
		flexDirection: "row",
		alignItems: "center",
		marginVertical: 5,
	},
	buttonContainer: {
		flex: 1,
		flexDirection: "row",
		// padding: 40,
		justifyContent: "center",
		alignItems: "center",
		marginVertical: 10,
	},
});
