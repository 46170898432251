//Config for Development
// export const SERVER_API_URL = "https://dev-api.delishtic.com";
// export const DUNZO_API_URL = SERVER_API_URL + "/dunzo"; //https://apis-staging.dunzo.in
// export const BORZO_API_URL = SERVER_API_URL + "/borzo"; //https://robotapitest-in.borzodelivery.com/api/business/1.1

//Config for Production
export const SERVER_API_URL = "https://api.delishtic.com";
export const DUNZO_API_URL = SERVER_API_URL + "/dunzo"; //https://api.dunzo.in
export const BORZO_API_URL = SERVER_API_URL + "/borzo"; //https://robot-in.borzodelivery.com/api/business/1.1

//api for client
export const API_URL_CLIENT = "/client";

//api for locations
export const API_URL_LOCATIONS = "/locations";

//api for restaurantgroup
export const API_URL_RESTAURANT = "/restaurantgroups";

//api for menugroups
export const API_URL_MENU_GROUPS = "/menugroups";

//api for menuitems
export const API_URL_MENU_ITEMS = "/menuitems";

//api for menus
export const API_URL_MENUS = "/menus";

//api for full menus
export const API_URL_FULL_MENUS = "/fullmenus";

//api for stock
export const API_URL_STOCK = "/stock";

//api for customer
export const API_URL_CUSTOMER = "/customer";

//api for customer address
export const API_URL_CUSTOMER_ADDRESS = "/customeraddress";

//api for orders
export const API_URL_ORDER = "/orders";

//api for order details
export const API_URL_ORDER_DETAIL = "/orders/details";

//api for order summary by date
export const API_URL_ORDER_SUMMARY_DATE = "/ordersummary/date";

//api for order summary by month
export const API_URL_ORDER_SUMMARY_MONTH = "/ordersummary/month";

//api for promotion
export const API_URL_PROMOTION = "/promotion";
