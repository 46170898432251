import {
  Platform,
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  Dimensions,
} from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../src/context/userContext";
import * as SecureStore from "expo-secure-store";
import { Auth } from "aws-amplify";
import { useNavigation } from "@react-navigation/native";
import AnimatedLottieView from "lottie-react-native";

const SignOut = () => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useContext(UserContext);

  useEffect(() => {
    signOut();
  }, []);
  async function signOut() {
    setLoading(true);
    if (Platform.OS !== "web") {
      SecureStore.deleteItemAsync("refreshTokens");
      SecureStore.deleteItemAsync("accessToken");
      SecureStore.deleteItemAsync("idToken");
      try {
        await Auth.signOut();
        console.log("runs");
        dispatch({ type: "isLoggedIn", payload: false });
        setLoading(false);
        // navigation.navigate("Login");
      } catch (error) {
        setLoading(false);
        console.log("error signing out: ", error);
      }
    } else {
      localStorage.removeItem("refreshTokens");
      localStorage.removeItem("userType");
      try {
        await Auth.signOut();
        console.log("web runs");
        dispatch({ type: "isLoggedIn", payload: false });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error signing out: ", error);
      }
      // navigation.navigate("Login");
    }
  }

  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        height: Dimensions.get("screen").height,
      }}
    >
      {loading && Platform.OS !== "web" && (
        <AnimatedLottieView
          source={require("../assets/loading.json")}
          autoPlay
          loop
        />
      )}
    </View>
  );
};

export default SignOut;

const styles = StyleSheet.create({});
