import {
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
	ScrollView,
	Image,
} from "react-native";
import React, { useState } from "react";
import BannerData from "./BannerData";
import { useNavigation } from "@react-navigation/native";
import BannerDataEditable from "./BannerImageEditable";

const PromotionalBanner = () => {
	const [filter, setFilter] = useState("editable");
	const [previewBannerId, setPreviewBannerId] = useState({});
	const navigation = useNavigation();
	return (
		<View style={{ flexDirection: "row", justifyContent: "space-between" }}>
			<div>
				<div
					style={{
						backgroundColor: "#f5f5f5",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						background: "white",
					}}
					className='filterButtons'>
					<button
						onClick={() => setFilter("editable")}
						style={{
							backgroundColor: filter == "editable" ? "red" : "#f5f5f5",
							border: "1px solid #d3d3d3",
							borderRadius: "8px",
							color: "#000",
							fontSize: "12px",
							fontWeight: "bold",
							padding: "8px",
							textAlign: "center",
							width: "100%",
							margin: 10,
							marginRight: 30,
						}}>
						Editable
					</button>
					<button
						onClick={() => setFilter("non-editable")}
						style={{
							backgroundColor: filter == "non-editable" ? "red" : "#f5f5f5",
							border: "1px solid #d3d3d3",
							borderRadius: "8px",
							color: "#000",
							fontSize: "12px",
							fontWeight: "bold",
							padding: "8px",
							textAlign: "center",
							width: "100%",
							margin: 10,
						}}>
						Non Editable
					</button>
				</div>

				<div
					style={{
						display: filter == "editable" ? "flex" : "none",
						flexWrap: "wrap",
					}}
					className='bannerList'>
					{BannerDataEditable.map((elem) => (
						<TouchableOpacity
							key={elem.id}
							onPress={() => {
								console.log("first", elem.url);
								// setPreviewBannerId(elem);
								navigation.navigate("Preview Banner", {
									index: elem.id,
									filter: filter,
								});
							}}>
							<Image
								source={elem.url}
								style={{
									height: 200,
									width: 200,
									marginHorizontal: 10,
									borderRadius: 8,
									resizeMode: "cover",
									marginVertical: 7,
								}}
							/>
						</TouchableOpacity>
					))}
				</div>
				<div
					style={{
						display: filter == "non-editable" ? "flex" : "none",
						flexWrap: "wrap",
					}}
					className='bannerList'>
					{BannerData.map((elem) => (
						<TouchableOpacity
							key={elem.id}
							onPress={() => {
								// setPreviewBannerId(elem);
								navigation.navigate("Preview Banner", {
									index: elem.id,
									filter: filter,
								});
							}}>
							<Image
								source={elem.url}
								style={{
									height: 200,
									width: 200,
									marginHorizontal: 10,
									borderRadius: 8,
									resizeMode: "cover",
									marginVertical: 7,
								}}
							/>
						</TouchableOpacity>
					))}
				</div>
			</div>
		</View>
	);
};

export default PromotionalBanner;

const styles = StyleSheet.create({});
