import React, { useContext } from "react";
import { Button, StyleSheet, Text, TextInput, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { UserContext } from "../src/context/userContext";
import { RadioButton } from "react-native-paper";

//Main functional component
const NewMerchantAccount = ({ navigation }) => {
	//Function to handle createAccount (it takes certain arguments and the pass them to Account create page as a navigation props)
	const createAccount = (
		clientName,
		restaurantName,
		mobileNumber,
		managerName,
		notificationEmail,
		onBoardingDate,
		id,
		ownerName,
		ownerEmailId
	) => {
		navigation.navigate("New Account", {
			clientName: clientName,
			restaurantName: restaurantName,
			mobileNumber: mobileNumber,
			managerName: managerName,
			notificationEmail: notificationEmail,
			onBoardingDate: onBoardingDate,
			id: id,
			ownerName: ownerName,
			ownerEmailId: ownerEmailId,
		});
	};
	const [checked, setChecked] = React.useState("first");

	const { state } = useContext(UserContext);
	let data = state.merchantDetails;

	return (
		<FlatList
			data={data}
			keyExtractor={(item) => item.mobileNumber}
			renderItem={({ item }) => {
				console.log(item);
				return item.managerName === "" ? (
					<Text></Text>
				) : (
					<View style={styles.main_container}>
						<View style={styles.item_view}>
							<Text style={styles.title}>Client Name</Text>
							<Text> {item.clientName && `${item.clientName}`} </Text>
						</View>
						<View style={styles.item_view}>
							<Text style={styles.title}>Restaurant Name</Text>
							<Text>{item.restaurantName && `${item.restaurantName}`}</Text>
						</View>
						<View style={styles.item_view}>
							<Text style={styles.title}>Manager Name is</Text>
							<Text> {item.managerName && `${item.managerName}`}</Text>
						</View>
						<View style={styles.item_view}>
							<Text style={styles.title}>Manager Mobile is</Text>
							<Text>{item.mobileNumber && `${item.mobileNumber}`}</Text>
						</View>
						<View style={styles.item_view}>
							<Text style={styles.title}>Manager Email</Text>

							<Text>{item.notificationMail && `${item.notificationMail}`}</Text>
						</View>
						<View style={styles.item_view}>
							<Text style={styles.title}>Onboarding Date</Text>
							<Text>{item.onBoardingDate && `${item.onBoardingDate}`}</Text>
						</View>
						<View style={styles.item_view}>
							<Text style={styles.title}>Registration Status</Text>
							<Text>
								{item.registrationStatus == false ? "Pending" : "Approved"}
							</Text>
						</View>
						{item.registrationStatus == false ? (
							<>
								<View
									style={{ flexDirection: "row", justifyContent: "flex-end" }}>
									<RadioButton
										value='first'
										status={checked === "first" ? "checked" : "unchecked"}
										onPress={() => setChecked("first")}
									/>
								</View>
								<Button
									onPress={() =>
										createAccount(
											item.clientName,
											item.restaurantName,
											item.mobileNumber,
											item.managerName,
											item.notificationMail,
											item.onBoardingDate,
											item.id,
											item.ownerName,
											item.ownerEmailId
										)
									}
									title='Create Account'
								/>
							</>
						) : (
							<Text></Text>
						)}
					</View>
				);
			}}
		/>
	);
};

export default NewMerchantAccount;

const styles = StyleSheet.create({
	title: {
		fontWeight: "bold",
		fontSize: 18,
	},
	item_view: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginVertical: 5,
	},
	main_container: {
		marginHorizontal: 5,
		padding: 20,
		marginVertical: 10,
		backgroundColor: "#fff",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.36,
		shadowRadius: 6.68,

		elevation: 11,
	},
});
