import {
	StyleSheet,
	Text,
	View,
	Image,
	TouchableOpacity,
	Platform,
} from "react-native";
import React from "react";
import {
	MaterialIcons,
	AntDesign,
	MaterialCommunityIcons,
} from "@expo/vector-icons";
import packageInfo from "../../package.json";
import { ScrollView } from "react-native-gesture-handler";

const Welcome = ({ navigation }) => {
	return (
		<ScrollView style={styles.mainView}>
			<View
				style={styles.logoContainer}>
				<Image source={require("../../assets/logo.png")} style={styles.logo} />
			</View>
			<Text style={styles.title}>Trusted by many restaurants & retailers</Text>
			<Text
				style={styles.heading}>
				Manage your work seamlessly{" "}
			</Text>
			<View>
				<View
					style={{
						...styles.badge,
						shadowColor: "#94e19d",
						// top: Platform.OS !== "web" ? 20 : 24,
						// left: Platform.OS !== "web" ? 20 : "34%",
					}}>
					<MaterialIcons
						name='payment'
						size={24}
						color='green'
						style={styles.icon}
					/>
					<Text style={{ fontSize: 16 }}>Online Payment</Text>
				</View>
				<View
					style={{
						...styles.badge,
						// top: Platform.OS !== "web" ? 90 : 100,
						// right: Platform.OS !== "web" ? 20 : "-59%",
						shadowColor: "red",
					}}>
					<MaterialIcons
						name='bike-scooter'
						size={24}
						color='red'
						style={styles.icon}
					/>
					<Text>Book delivery agent</Text>
				</View>
				<View
					style={{
						...styles.badge,
						// top: Platform.OS !== "web" ? 160 : "133px",
						// left: Platform.OS !== "web" ? 20 : "34%",
						shadowColor: "#7b86be",
					}}>
					<AntDesign
						name='book'
						size={24}
						color='#7b86be'
						style={styles.icon}
					/>
					<Text>Manage restaurants</Text>
				</View>
				<View
					style={{
						...styles.badge,
						// top: Platform.OS !== "web" ? 230 : 300,
						// right: Platform.OS !== "web" ? 20 : "59%",
						shadowColor: "#166bae",
					}}>
					<MaterialCommunityIcons
						name='dots-horizontal-circle-outline'
						size={24}
						color='#166bae'
						style={styles.icon}
					/>
					<Text>Manage the store.</Text>
				</View> 
				<View
					style={{
						...styles.badge,
						// top: Platform.OS !== "web" ? 290 : 350,
						// left: Platform.OS !== "web" ? 20 : "34%",
						shadowColor: "#fbb73c",
					}}>
					<MaterialCommunityIcons
						name='dots-horizontal-circle-outline'
						size={24}
						color='#fbb73c'
						style={styles.icon}
					/>
					<Text>And many more...</Text>
				</View>

				<TouchableOpacity
					onPress={() => navigation.navigate("Delishtic Merchant")}
					style={{
						backgroundColor: "#136eb4",
						padding: 17,
						borderRadius: 8,
						marginTop: 40,
					}}>
					<Text style={{ color: "white", textAlign: "center", fontSize: 18 }}>
						Get started for FREE
					</Text>
				</TouchableOpacity>
				<View
					style={{
						marginVertical: 30,
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "row",
					}}>
					<Text>Already have an account ?</Text>
					<TouchableOpacity onPress={() => navigation.navigate("Login")}>
						<Text
							style={{ fontWeight: "bold", color: "#548eb4", marginLeft: 7 }}>
							Login
						</Text>
					</TouchableOpacity>
				</View>
			</View>
			<View style={styles.versionContainer}>
				<Text style={styles.versionText}>v{packageInfo.version}</Text>
			</View>
		</ScrollView>
	);
};

export default Welcome;

const styles = StyleSheet.create({
	mainView: {
		marginTop: 10,
		padding: 10,
		overflow: Platform.OS == "web" ? "hidden" : "visible",
	},
	logoContainer: {
		justifyContent: "center",
		alignItems: "center",
		marginTop: 30,
	},
	logo: {
		width: 200,
		resizeMode: "contain",
	},
	heading: {
		marginVertical: 18,
		fontSize: 30,
		textAlign: "center",
		fontWeight: "bold",
	},
	title: {
		fontSize: 18,
		// fontWeight: "bold",
		textAlign: "center",
		marginTop: 10,
	},
	badge: {
		flexDirection: "row",
		alignItems: "center",
		alignSelf: "center",
		justifyContent: "center",
		backgroundColor: "#fff",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.36,
		shadowRadius: 6.68,
		padding: 10,
		paddingHorizontal: 35,
		borderRadius: 8,
		margin: 8,
		width: Platform.OS === "web" ? '40%' : "100%",
		height: 50,
	},
	icon: {
		marginRight: 10,
	},
	versionContainer: {
		position: "absolute",
		right: 20,
		bottom: 20,
	  },
	  versionText: {
		fontSize: 8,
		textAlign: "right",
		color: "gray",
		marginRight: 5,
	  },
});
