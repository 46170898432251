const BannerData = [
	{
		id: 1,
		url: require("../assets/banner1.jpg"),
		imgPath: "../assets/banner1.jpg",
	},
	{
		id: 2,
		url: require("../assets/banner2.jpg"),
		imgPath: "../assets/banner2.jpg",
	},
	{
		id: 3,
		url: require("../assets/banner3.jpg"),
		imgPath: "../assets/banner3.jpg",
	},
	{
		id: 4,
		url: require("../assets/banner4.jpg"),
		imgPath: "../assets/banner4.jpg",
	},
	{
		id: 5,
		url: require("../assets/banner5.jpg"),
		imgPath: "../assets/banner5.jpg",
	},
	{
		id: 6,
		url: require("../assets/banner6.jpg"),
		imgPath: "../assets/banner6.jpg",
	},
	{
		id: 7,
		url: require("../assets/banner7.png"),
		imgPath: "../assets/banner7.png",
	},
];
export default BannerData;
