import React, { useContext } from "react";
import {
	View,
	Text,
	Image,
	StyleSheet,
	FlatList,
	ActivityIndicator,
} from "react-native";
import {
	Card,
	ListItem,
	Button,
	ThemeProvider,
	Overlay,
	Input,
} from "react-native-elements";
import { TouchableOpacity } from "react-native";
import axios from "axios";
import { Header } from "react-native-elements";
import { NavigationContainer, useFocusEffect } from "@react-navigation/native";
import DropDownPicker from "react-native-dropdown-picker";
import RNPickerSelect from "react-native-picker-select";
import {
	SERVER_API_URL,
	API_URL_CLIENT,
	API_URL_RESTAURANT,
} from "../config/config";
import { UserContext } from "../src/context/userContext";

//import { Picker } from "@react-native-picker/picker";

export const restaurant = ({ route, navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	console.log("route is ", route);
	const [visible1, setVisible1] = React.useState(false);
	const [visible2, setVisible2] = React.useState(false);
	const [id, updateId] = React.useState("");
	const [restaurants, updateRestaurants] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [client, updateClient] = React.useState([]);
	const [selectClient, updateClientSelect] = React.useState("");
	const [restructuredClient, updateRestructureClient] = React.useState([]);

	const restructure = (item) => {
		let labels = [];
		for (var i = 0; i < item.length; i++) {
			labels[i] = { label: item[i].clientName, value: item[i].slug };
		}
		updateRestructureClient(labels);
	};
	const getClient = async () => {
		await axios
			.get(SERVER_API_URL + API_URL_CLIENT, {
				headers: {
					Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				},
			})
			.then((res) => {
				updateClient(res.data), restructure(res.data);
			})
			.catch((err) => console.log(err));
	};
	useFocusEffect(
		React.useCallback(() => {
			getClient();
			getRestaurants();
			return () => {
				// Do something when the screen is unfocused
				// Useful for cleanup functions
			};
		}, [selectClient])
	);

	const getRestaurants = async () => {
		setLoading(true);
		await axios
			.get(`${SERVER_API_URL}${API_URL_RESTAURANT}${API_URL_CLIENT}`, {
				headers: {
					client: selectClient,
				},
			})
			.then((res) => {
				// console.log("data is ", res.data);
				updateRestaurants(res.data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};

	const renderItem = ({ item }) => {
		// console.log("item is ", item);
		// console.log("item", item.media.restaurantBanner);
		return (
			<TouchableOpacity
				style={{ flex: 1 }}
				key={item.id}
				onPress={() =>
					navigation.navigate("TabNavigator", {
						params: {
							id: item.id,
							name: item.name,
							clientId: selectClient,
							description: item.description,
							collections: item.collections,
							bannerImage: item.media.restaurantBanner,
							logoImage: item.media.restaurantLogo,
							client: item.client,
							primaryColorCode: item?.UIConfig?.primaryColorCode,
							secondaryColorCode: item?.UIConfig?.secondaryColorCode,
							deliveryRatePerKM: item?.generalConfig?.deliveryRatePerKM,
							masterSwitchOff: item?.generalConfig?.masterSwitchOff,
							minDeliveryCharges: item?.generalConfig?.minDeliveryCharges,
							businessType: item.businessType,
							businessSubType: item?.businessSubType,
						},
						screen: "restaurantInfo",
					})
				}>
				<View style={styles.mainView}>
					<View style={{ minHeight: 200 }}>
						<Card.Title style={{ height: 60, marginBottom: 0 }}>
							{item.name}
						</Card.Title>
						{item.media && (
							<Card.Image
								source={{ uri: item.media.restaurantBanner }}
								resizeMode='cover'
								style={{ borderRadius: 10 }}></Card.Image>
						)}
						<Card.Divider></Card.Divider>
					</View>
				</View>
			</TouchableOpacity>
		);
	};
	return (
		<ThemeProvider>
			<View style={{ flex: 1 }}>
				<Header
					leftComponent={{
						icon: "menu",
						color: "#fff",
						onPress: () => navigation.openDrawer(),
					}}
					centerComponent={{
						text: "RESTAURANTS / SHOPS",
						style: { color: "#fff" },
					}}
					rightComponent={{
						icon: "home",
						color: "#fff",
						onPress: () => navigation.navigate("Dashboard"),
					}}></Header>

				<Button
					icon={{ name: "add", size: 20, color: "white" }}
					buttonStyle={{
						width: "100%",
						borderRadius: 35,
						backgroundColor: "olive",
					}}
					containerStyle={{ margin: 20 }}
					title='Add Restaurant / Shop'
					onPress={() =>
						navigation.navigate("AddRestaurantGroup", {
							type: "add",
						})
					}
				/>

				<Card
					containerStyle={{
						backgroundColor: "lightblue",
						borderRadius: 20,
						elevation: 5,
					}}>
					<View
						style={{
							// minHeight: 180,
							zIndex: 1000,
							position: "relative",
							FlexStyle: "relative",
						}}>
						{/* <DropDownPicker
							items={restructuredClient}
							placeholder='Select Client'
							containerStyle={{ height: 40, width: "90%", alignSelf: "center" }}
							style={{ backgroundColor: "#fafafa" }}
							itemStyle={{ justifyContent: "flex-start" }}
							dropDownStyle={{ backgroundColor: "#fafafa" }}
							onChangeItem={(item) => updateClientSelect(item.value)}
							zIndex={2000}
						/> */}

						<View style={{ padding: 20 }}>
							<RNPickerSelect
								fixAndroidTouchableBug={true}
								style={pickerSelectStyles}
								useNativeAndroidPickerStyle={false}
								placeholder={{
									label: "Select client",
									color: "black",
								}}
								items={restructuredClient}
								onValueChange={(value) => {
									console.log("status value is", value);
									updateClientSelect(value);
								}}
							/>
						</View>
					</View>
				</Card>
				{loading ? (
					<ActivityIndicator size='large' color='#00ff00' />
				) : (
					<FlatList
						data={restaurants}
						renderItem={renderItem}
						// numColumns={}
						style={{ marginBottom: 20 }}
					/>
				)}
			</View>
		</ThemeProvider>
	);
};
const styles = StyleSheet.create({
	mainView: {
		margin: 10,
		marginHorizontal: 15,
		padding: 10,
		backgroundColor: "white",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.36,
		shadowRadius: 6.68,

		elevation: 11,
		borderRadius: 10,
	},
});
const pickerSelectStyles = StyleSheet.create({
	inputIOS: {
		fontSize: 12,
		marginHorizontal: 5,
		borderWidth: 1,
		borderColor: "gray",
		borderRadius: 4,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon
		padding: 5,
	},
	inputAndroid: {
		fontSize: 12,
		marginHorizontal: 5,

		borderWidth: 0.5,
		borderColor: "black",
		borderRadius: 8,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon

		padding: 5, // to ensure the text is never behind the icon
	},
});
