import React, { useState, useContext } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	StyleSheet,
	Button,
	Platform,
} from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { Feather } from "@expo/vector-icons";
import { Card } from "react-native-elements";
import Entypo from "react-native-vector-icons/Entypo";
import axios from "axios";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import { Dimensions } from "react-native";
import { format } from "date-fns";
import { SERVER_API_URL, API_URL_LOCATIONS } from "../../config/config";
import { UserContext } from "../../src/context/userContext";
const windowWidth = Dimensions.get("window").width;

const EditLocations = ({ route, navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	const id = route.params.id;
	// let dataExtracted = route.params.data;
	// console.log("route is ", dataExtracted);
	console.log("id is ", id);
	const item = route.params.item;
	const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
	const [type, setType] = useState("");
	const [dayType, setDaytype] = useState("");
	const [pickupOpenDay1, setPickupOpenDay1] = useState(
		item.hours[0].pickupOpen
	);
	const [pickupCloseDay1, setPickupCloseDay1] = useState(
		item.hours[0].pickupClose
	);
	const [deliveryOpenDay1, setDeliveryOpenDay1] = useState(
		item.hours[0].deliveryOpen
	);
	const [deliveryCloseDay1, setDeliveryCloseDay1] = useState(
		item.hours[0].deliveryClose
	);
	const [diningOpenDay1, setDiningOpenDay1] = useState(
		item.hours[0].diningOpen
	);
	const [diningCloseDay1, setDiningCloseDay1] = useState(
		item.hours[0].diningClose
	);

	const [pickupOpenDay2, setPickupOpenDay2] = useState(
		item.hours[1].pickupOpen
	);
	const [pickupCloseDay2, setPickupCloseDay2] = useState(
		item.hours[1].pickupClose
	);
	const [deliveryOpenDay2, setDeliveryOpenDay2] = useState(
		item.hours[1].deliveryOpen
	);
	const [deliveryCloseDay2, setDeliveryCloseDay2] = useState(
		item.hours[1].deliveryClose
	);
	const [diningOpenDay2, setDiningOpenDay2] = useState(
		item.hours[1].diningOpen
	);
	const [diningCloseDay2, setDiningCloseDay2] = useState(
		item.hours[1].diningClose
	);

	// const [pickupOpenDay3, setPickupOpenDay3] = useState("");
	// const [pickupCloseDay3, setPickupCloseDay3] = useState("");
	// const [deliveryOpenDay3, setDeliveryOpenDay3] = useState("");
	// const [deliveryCloseDay3, setDeliveryCloseDay3] = useState("");
	// const [diningOpenDay3, setDiningOpenDay3] = useState("");
	// const [diningCloseDay3, setDiningCloseDay3] = useState("");

	const [pickupOpenDay3, setPickupOpenDay3] = useState(
		item.hours[2].pickupOpen
	);
	const [pickupCloseDay3, setPickupCloseDay3] = useState(
		item.hours[2].pickupClose
	);
	const [deliveryOpenDay3, setDeliveryOpenDay3] = useState(
		item.hours[2].deliveryOpen
	);
	const [deliveryCloseDay3, setDeliveryCloseDay3] = useState(
		item.hours[2].deliveryClose
	);
	const [diningOpenDay3, setDiningOpenDay3] = useState(
		item.hours[2].diningOpen
	);
	const [diningCloseDay3, setDiningCloseDay3] = useState(
		item.hours[2].diningClose
	);

	// const [pickupOpenDay4, setPickupOpenDay4] = useState("");
	// const [pickupCloseDay4, setPickupCloseDay4] = useState("");
	// const [deliveryOpenDay4, setDeliveryOpenDay4] = useState("");
	// const [deliveryCloseDay4, setDeliveryCloseDay4] = useState("");
	// const [diningOpenDay4, setDiningOpenDay4] = useState("");
	// const [diningCloseDay4, setDiningCloseDay4] = useState("");

	const [pickupOpenDay4, setPickupOpenDay4] = useState(
		item.hours[3].pickupOpen
	);
	const [pickupCloseDay4, setPickupCloseDay4] = useState(
		item.hours[3].pickupClose
	);
	const [deliveryOpenDay4, setDeliveryOpenDay4] = useState(
		item.hours[3].deliveryOpen
	);
	const [deliveryCloseDay4, setDeliveryCloseDay4] = useState(
		item.hours[3].deliveryClose
	);
	const [diningOpenDay4, setDiningOpenDay4] = useState(
		item.hours[3].diningOpen
	);
	const [diningCloseDay4, setDiningCloseDay4] = useState(
		item.hours[3].diningClose
	);

	// const [pickupOpenDay5, setPickupOpenDay5] = useState("");
	// const [pickupCloseDay5, setPickupCloseDay5] = useState("");
	// const [deliveryOpenDay5, setDeliveryOpenDay5] = useState("");
	// const [deliveryCloseDay5, setDeliveryCloseDay5] = useState("");
	// const [diningOpenDay5, setDiningOpenDay5] = useState("");
	// const [diningCloseDay5, setDiningCloseDay5] = useState("");

	const [pickupOpenDay5, setPickupOpenDay5] = useState(
		item.hours[4].pickupOpen
	);
	const [pickupCloseDay5, setPickupCloseDay5] = useState(
		item.hours[4].pickupClose
	);
	const [deliveryOpenDay5, setDeliveryOpenDay5] = useState(
		item.hours[4].deliveryOpen
	);
	const [deliveryCloseDay5, setDeliveryCloseDay5] = useState(
		item.hours[4].deliveryClose
	);
	const [diningOpenDay5, setDiningOpenDay5] = useState(
		item.hours[4].diningOpen
	);
	const [diningCloseDay5, setDiningCloseDay5] = useState(
		item.hours[4].diningClose
	);

	// const [pickupOpenDay6, setPickupOpenDay6] = useState("");
	// const [pickupCloseDay6, setPickupCloseDay6] = useState("");
	// const [deliveryOpenDay6, setDeliveryOpenDay6] = useState("");
	// const [deliveryCloseDay6, setDeliveryCloseDay6] = useState("");
	// const [diningOpenDay6, setDiningOpenDay6] = useState("");
	// const [diningCloseDay6, setDiningCloseDay6] = useState("");

	const [pickupOpenDay6, setPickupOpenDay6] = useState(
		item.hours[5].pickupOpen
	);
	const [pickupCloseDay6, setPickupCloseDay6] = useState(
		item.hours[5].pickupClose
	);
	const [deliveryOpenDay6, setDeliveryOpenDay6] = useState(
		item.hours[5].deliveryOpen
	);
	const [deliveryCloseDay6, setDeliveryCloseDay6] = useState(
		item.hours[5].deliveryClose
	);
	const [diningOpenDay6, setDiningOpenDay6] = useState(
		item.hours[5].diningOpen
	);
	const [diningCloseDay6, setDiningCloseDay6] = useState(
		item.hours[5].diningClose
	);

	// const [pickupOpenDay7, setPickupOpenDay7] = useState("");
	// const [pickupCloseDay7, setPickupCloseDay7] = useState("");
	// const [deliveryOpenDay7, setDeliveryOpenDay7] = useState("");
	// const [deliveryCloseDay7, setDeliveryCloseDay7] = useState("");
	// const [diningOpenDay7, setDiningOpenDay7] = useState("");
	// const [diningCloseDay7, setDiningCloseDay7] = useState("");

	const [pickupOpenDay7, setPickupOpenDay7] = useState(
		item.hours[6].pickupOpen
	);
	const [pickupCloseDay7, setPickupCloseDay7] = useState(
		item.hours[6].pickupClose
	);
	const [deliveryOpenDay7, setDeliveryOpenDay7] = useState(
		item.hours[6].deliveryOpen
	);
	const [deliveryCloseDay7, setDeliveryCloseDay7] = useState(
		item.hours[6].deliveryClose
	);
	const [diningOpenDay7, setDiningOpenDay7] = useState(
		item.hours[6].diningOpen
	);
	const [diningCloseDay7, setDiningCloseDay7] = useState(
		item.hours[6].diningClose
	);

	const showDatePicker = (val, day) => {
		setDatePickerVisibility(true);
		setType(val);
		setDaytype(day);
	};

	const hideDatePicker = () => {
		setDatePickerVisibility(false);
	};

	const handleConfirm = (date, timeType = "", val = "") => {
		console.log("date===", date);
		if (Platform.OS == "web") {
			setDaytype(val);
			setType(timeType);
		}

		if (type == "pickupopen") {
			if (dayType == "day1") {
				setPickupOpenDay1(Platform.OS !== "web" ? format(date, "HH:mm") : date);
			} else if (dayType == "day2") {
				setPickupOpenDay2(Platform.OS !== "web" ? format(date, "HH:mm") : date);
			} else if (dayType == "day3") {
				setPickupOpenDay3(Platform.OS !== "web" ? format(date, "HH:mm") : date);
			} else if (dayType == "day4") {
				setPickupOpenDay4(Platform.OS !== "web" ? format(date, "HH:mm") : date);
			} else if (dayType == "day5") {
				setPickupOpenDay5(Platform.OS !== "web" ? format(date, "HH:mm") : date);
			} else if (dayType == "day6") {
				setPickupOpenDay6(Platform.OS !== "web" ? format(date, "HH:mm") : date);
			} else if (dayType == "day7") {
				setPickupOpenDay7(Platform.OS !== "web" ? format(date, "HH:mm") : date);
			}
		} else if (type == "pickupclose") {
			if (dayType == "day1") {
				setPickupCloseDay1(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day2") {
				setPickupCloseDay2(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day3") {
				setPickupCloseDay3(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day4") {
				setPickupCloseDay4(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day5") {
				setPickupCloseDay5(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day6") {
				setPickupCloseDay6(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day7") {
				setPickupCloseDay7(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			}
		} else if (type == "deliveryOpen") {
			if (dayType == "day1") {
				setDeliveryOpenDay1(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day2") {
				setDeliveryOpenDay2(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day3") {
				setDeliveryOpenDay3(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day4") {
				setDeliveryOpenDay4(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day5") {
				setDeliveryOpenDay5(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day6") {
				setDeliveryOpenDay6(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day7") {
				setDeliveryOpenDay7(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			}
		} else if (type == "deliveryClose") {
			if (dayType == "day1") {
				setDeliveryCloseDay1(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day2") {
				setDeliveryCloseDay2(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day3") {
				setDeliveryCloseDay3(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day4") {
				setDeliveryCloseDay4(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day5") {
				setDeliveryCloseDay5(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day6") {
				setDeliveryCloseDay6(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day7") {
				setDeliveryCloseDay7(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			}
		} else if (type == "diningOpen") {
			if (dayType == "day1") {
				setDiningOpenDay1(Platform.OS !== "web" ? format(date, "HH:mm") : date);
			} else if (dayType == "day2") {
				setDiningOpenDay2(Platform.OS !== "web" ? format(date, "HH:mm") : date);
			} else if (dayType == "day3") {
				setDiningOpenDay3(Platform.OS !== "web" ? format(date, "HH:mm") : date);
			} else if (dayType == "day4") {
				setDiningOpenDay4(Platform.OS !== "web" ? format(date, "HH:mm") : date);
			} else if (dayType == "day5") {
				setDiningOpenDay5(Platform.OS !== "web" ? format(date, "HH:mm") : date);
			} else if (dayType == "day6") {
				setDiningOpenDay6(Platform.OS !== "web" ? format(date, "HH:mm") : date);
			} else if (dayType == "day7") {
				setDiningOpenDay7(Platform.OS !== "web" ? format(date, "HH:mm") : date);
			}
		} else if (type == "diningClose") {
			if (dayType == "day1") {
				setDiningCloseDay1(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day2") {
				setDiningCloseDay2(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day3") {
				setDiningCloseDay3(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day4") {
				setDiningCloseDay4(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day5") {
				setDiningCloseDay5(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day6") {
				setDiningCloseDay6(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			} else if (dayType == "day7") {
				setDiningCloseDay7(
					Platform.OS !== "web" ? format(date, "HH:mm") : date
				);
			}
		}
		hideDatePicker();
	};
	let hours = [
		{
			pickupOpen: pickupOpenDay1,
			pickupClose: pickupCloseDay1,
			deliveryOpen: deliveryOpenDay1,
			deliveryClose: deliveryCloseDay1,
			diningOpen: diningOpenDay1,
			diningClose: diningCloseDay1,
			day: 0,
		},
		{
			pickupOpen: pickupOpenDay2,
			pickupClose: pickupCloseDay2,
			deliveryOpen: deliveryOpenDay2,
			deliveryClose: deliveryCloseDay2,
			diningOpen: diningOpenDay2,
			diningClose: diningCloseDay2,
			day: 1,
		},
		{
			pickupOpen: pickupOpenDay3,
			pickupClose: pickupCloseDay3,
			deliveryOpen: deliveryOpenDay3,
			deliveryClose: deliveryCloseDay3,
			diningOpen: diningOpenDay3,
			diningClose: diningCloseDay3,
			day: 2,
		},
		{
			pickupOpen: pickupOpenDay4,
			pickupClose: pickupCloseDay4,
			deliveryOpen: deliveryOpenDay4,
			deliveryClose: deliveryCloseDay4,
			diningOpen: diningOpenDay4,
			diningClose: diningCloseDay4,
			day: 3,
		},
		{
			pickupOpen: pickupOpenDay5,
			pickupClose: pickupCloseDay5,
			deliveryOpen: deliveryOpenDay5,
			deliveryClose: deliveryCloseDay5,
			diningOpen: diningOpenDay5,
			diningClose: diningCloseDay5,
			day: 4,
		},
		{
			pickupOpen: pickupOpenDay6,
			pickupClose: pickupCloseDay6,
			deliveryOpen: deliveryOpenDay6,
			deliveryClose: deliveryCloseDay6,
			diningOpen: diningOpenDay6,
			diningClose: diningCloseDay6,
			day: 5,
		},
		{
			pickupOpen: pickupOpenDay7,
			pickupClose: pickupCloseDay7,
			deliveryOpen: deliveryOpenDay7,
			deliveryClose: deliveryCloseDay7,
			diningOpen: diningOpenDay7,
			diningClose: diningCloseDay7,
			day: 6,
		},
	];
	console.log(hours);

	const dataDays = {
		// address: {
		// 	city: dataExtracted.address.city,
		// 	geo: {
		// 		lat: dataExtracted.address.geo.lat,
		// 		long: dataExtracted.address.geo.long,
		// 	},
		// 	state: dataExtracted.address.state,
		// 	street1: dataExtracted.address.street1,
		// 	street2: dataExtracted.address.street2,
		// 	zip: dataExtracted.address.zip,
		// },
		// distanceInMiles: dataExtracted.distanceInMiles,
		// isApplePayAccepted: dataExtracted.isApplePayAccepted,
		// isCashAccepted: dataExtracted.isCashAccepted,
		// isDeliveryEnabled: dataExtracted.isDeliveryEnabled,
		// isGooglePayAccepted: dataExtracted.isGooglePayAccepted,
		// isKioskEnabled: dataExtracted.isKioskEnabled,
		// isOpen: dataExtracted.isOpen,
		// isPickupEnabled: dataExtracted.isPickupEnabled,
		// isPreorderRequired: dataExtracted.isPreorderRequired,
		// name: dataExtracted.name,
		// phone: {
		// 	value: dataExtracted.phone.value,
		// 	isVerified: dataExtracted.phone.isVerified,
		// },
		// serviceEstimates: {
		// 	delivery: dataExtracted.serviceEstimates.delivery,
		// 	increment: dataExtracted.serviceEstimates.increment,
		// 	prep: dataExtracted.serviceEstimates.prep,
		// 	threshold: dataExtracted.serviceEstimates.threshold,
		// 	packingCharges: route.params.data.serviceEstimates.packingCharges,
		// },
		// slug: dataExtracted.slug,
		// timeZone: dataExtracted.timeZone,
		hours: hours,
	};

	const handleEditLocations = async () => {
		console.log("hours==", hours);
		await axios
			.patch(`${SERVER_API_URL}${API_URL_LOCATIONS}/${id}`, dataDays, {
				headers: {
					Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				},
			})
			.then((res) => {
				navigation.navigate("editResaurant");
				console.log("success===", res.data);
			})
			.catch((err) => console.log(err));
	};

	console.log("route is ", route);
	return (
		<>
			<ScrollView
				nestedScrollEnabled={true}
				horizontal={true}
				style={{ flex: 1 }}
				scrollEventThrottle={16}>
				<ScrollView style={styles.mainView}>
					<Card.Title>Sunday (Day 0)</Card.Title>

					<View
						style={
							Platform.OS === "web" && {
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "row",
								flexWrap: "wrap",
							}
						}>
						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{pickupOpenDay1 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Pickup open time : {pickupOpenDay1}
								</Text>
							)}
							{Platform.OS !== "web" ? (
								<Button
									title='Set Pickup open'
									onPress={() => {
										showDatePicker("pickupopen", "day1");
									}}
								/>
							) : (
								<input
									value={pickupOpenDay1}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) => setPickupOpenDay1(e.target.value)}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{pickupCloseDay1 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Pickup close time : {pickupCloseDay1}
								</Text>
							)}
							{Platform.OS !== "web" ? (
								<Button
									title='Set Pickup close'
									onPress={() => {
										showDatePicker("pickupclose", "day1");
									}}
								/>
							) : (
								<input
									value={pickupCloseDay1}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "pickupclose", "day1")
										setPickupCloseDay1(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{deliveryOpenDay1 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Delivery open time : {deliveryOpenDay1}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set delivery open'
									onPress={() => {
										showDatePicker("deliveryOpen", "day1");
									}}
								/>
							) : (
								<input
									value={deliveryOpenDay1}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "deliveryOpen", "day1")
										setDeliveryOpenDay1(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{deliveryCloseDay1 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Delivery Close time : {deliveryCloseDay1}
								</Text>
							)}
							{Platform.OS !== "web" ? (
								<Button
									title='Set delivery Close'
									onPress={() => {
										showDatePicker("deliveryClose", "day1");
									}}
								/>
							) : (
								<input
									value={deliveryCloseDay1}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "deliveryClose", "day1")
										setDeliveryCloseDay1(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>

							{diningOpenDay1 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Dine-In Open : {diningOpenDay1}
								</Text>
							)}
							{Platform.OS !== "web" ? (
								<Button
									title='Set Dine-In Open'
									onPress={() => {
										showDatePicker("diningOpen", "day1");
									}}
								/>
							) : (
								<input
									value={diningOpenDay1}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "diningOpen", "day1")
										setDiningOpenDay1(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{diningCloseDay1 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Dine-In Close : {diningCloseDay1}
								</Text>
							)}
							{Platform.OS !== "web" ? (
								<Button
									title='Set Dine-In Close'
									onPress={() => {
										showDatePicker("diningClose", "day1");
									}}
								/>
							) : (
								<input
									value={diningCloseDay1}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "diningClose", "day1")
										setDiningCloseDay1(e.target.value)
									}
								/>
							)}
						</View>
					</View>
					<Text
						style={{
							textAlign: "center",
							marginVertical: 20,
							fontWeight: "bold",
							fontSize: 20,
						}}>
						Scroll right
					</Text>
				</ScrollView>

				<ScrollView style={styles.mainView}>
					<Card.Title>Monday (Day 1)</Card.Title>
					<View
						style={
							Platform.OS === "web" && {
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "row",
								flexWrap: "wrap",
							}
						}>
						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{pickupOpenDay2 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Pickup open time : {pickupOpenDay2}
								</Text>
							)}
							{Platform.OS !== "web" ? (
								<Button
									title='Set Pickup open'
									onPress={() => {
										showDatePicker("pickupopen", "day2");
									}}
								/>
							) : (
								<input
									value={pickupOpenDay2}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "pickupOpen", "day2")
										setPickupOpenDay2(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{pickupCloseDay2 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Pickup close time : {pickupCloseDay2}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Pickup close'
									onPress={() => {
										showDatePicker("pickupclose", "day2");
									}}
								/>
							) : (
								<input
									value={pickupCloseDay2}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "pickupclose", "day2")
										setPickupCloseDay2(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{deliveryOpenDay2 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Delivery open time : {deliveryOpenDay2}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set delivery open'
									onPress={() => {
										showDatePicker("deliveryOpen", "day2");
									}}
								/>
							) : (
								<input
									value={deliveryOpenDay2}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "deliveryOpen", "day2")
										setDiningOpenDay2(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{deliveryCloseDay2 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Delivery Close time : {deliveryCloseDay2}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set delivery Close'
									onPress={() => {
										showDatePicker("deliveryClose", "day2");
									}}
								/>
							) : (
								<input
									value={deliveryCloseDay2}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "deliveryClose", "day2")
										setDiningCloseDay1(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>

							{diningOpenDay2 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Dine-In Open : {diningOpenDay2}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Dine-In Open'
									onPress={() => {
										showDatePicker("diningOpen", "day2");
									}}
								/>
							) : (
								<input
									value={diningOpenDay2}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "diningOpen", "day2")
										setDiningOpenDay2(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{diningCloseDay2 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Dine-In Close : {diningCloseDay2}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Dine-In Close'
									onPress={() => {
										showDatePicker("diningClose", "day2");
									}}
								/>
							) : (
								<input
									value={diningCloseDay2}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "diningClose", "day2")
										setDiningCloseDay2(e.target.value)
									}
								/>
							)}
						</View>
					</View>
					<Text
						style={{
							textAlign: "center",
							marginVertical: 20,
							fontWeight: "bold",
							fontSize: 20,
						}}>
						Scroll right
					</Text>
				</ScrollView>

				<ScrollView style={styles.mainView}>
					<Card.Title>Tuesday (Day 2)</Card.Title>
					<View
						style={
							Platform.OS === "web" && {
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "row",
								flexWrap: "wrap",
							}
						}>
						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{pickupOpenDay3 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Pickup open time : {pickupOpenDay3}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Pickup open'
									onPress={() => {
										showDatePicker("pickupopen", "day3");
									}}
								/>
							) : (
								<input
									value={pickupOpenDay3}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "pickupOpen", "day3")
										setPickupOpenDay3(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{pickupCloseDay3 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Pickup close time : {pickupCloseDay3}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Pickup close'
									onPress={() => {
										showDatePicker("pickupclose", "day3");
									}}
								/>
							) : (
								<input
									value={pickupCloseDay3}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "pickupclose", "day3")
										setPickupCloseDay3(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{deliveryOpenDay3 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Delivery open time : {deliveryOpenDay3}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set delivery open'
									onPress={() => {
										showDatePicker("deliveryOpen", "day3");
									}}
								/>
							) : (
								<input
									value={deliveryOpenDay3}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "deliveryOpen", "day3")
										setDeliveryOpenDay3(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{deliveryCloseDay3 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Delivery Close time : {deliveryCloseDay3}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set delivery Close'
									onPress={() => {
										showDatePicker("deliveryClose", "day3");
									}}
								/>
							) : (
								<input
									value={deliveryCloseDay3}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "deliveryClose", "day3")
										setDeliveryCloseDay3(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>

							{diningOpenDay3 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Dine-In Open : {diningOpenDay3}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Dine-In Open'
									onPress={() => {
										showDatePicker("diningOpen", "day3");
									}}
								/>
							) : (
								<input
									value={diningOpenDay3}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "diningOpen", "day3")
										setDiningOpenDay3(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{diningCloseDay3 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Dine-In Close : {diningCloseDay3}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Dine-In Close'
									onPress={() => {
										showDatePicker("diningClose", "day3");
									}}
								/>
							) : (
								<input
									value={diningCloseDay3}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "diningClose", "day3")
										setDiningCloseDay3(e.target.value)
									}
								/>
							)}
						</View>
					</View>
					<Text
						style={{
							textAlign: "center",
							marginVertical: 20,
							fontWeight: "bold",
							fontSize: 20,
						}}>
						Scroll right
					</Text>
				</ScrollView>

				<ScrollView style={styles.mainView}>
					<Card.Title>Wednesday (Day 3)</Card.Title>
					<View
						style={
							Platform.OS === "web" && {
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "row",
								flexWrap: "wrap",
							}
						}>
						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{pickupOpenDay4 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Pickup open time : {pickupOpenDay4}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Pickup open'
									onPress={() => {
										showDatePicker("pickupopen", "day4");
									}}
								/>
							) : (
								<input
									value={pickupOpenDay4}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "pickupOpen", "day4")
										setPickupOpenDay4(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{pickupCloseDay4 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Pickup close time : {pickupCloseDay4}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Pickup close'
									onPress={() => {
										showDatePicker("pickupclose", "day4");
									}}
								/>
							) : (
								<input
									value={pickupCloseDay4}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "pickupclose", "day4")
										setPickupCloseDay4(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{deliveryOpenDay4 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Delivery open time : {deliveryOpenDay4}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set delivery open'
									onPress={() => {
										showDatePicker("deliveryOpen", "day4");
									}}
								/>
							) : (
								<input
									value={deliveryOpenDay4}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "deliveryOpen", "day4")
										setDeliveryOpenDay4(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{deliveryCloseDay4 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Delivery Close time : {deliveryCloseDay4}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set delivery Close'
									onPress={() => {
										showDatePicker("deliveryClose", "day4");
									}}
								/>
							) : (
								<input
									value={deliveryCloseDay4}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "deliveryClose", "day4")
										setDeliveryCloseDay4(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>

							{diningOpenDay4 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Dine-In Open : {diningOpenDay4}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Dine-In Open'
									onPress={() => {
										showDatePicker("diningOpen", "day4");
									}}
								/>
							) : (
								<input
									value={diningOpenDay4}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "diningOpen", "day4")
										setDiningOpenDay4(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{diningCloseDay4 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Dine-In Close : {diningCloseDay4}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set delivery Close'
									onPress={() => {
										showDatePicker("diningClose", "day4");
									}}
								/>
							) : (
								<input
									value={diningCloseDay4}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "diningClose", "day4")
										setDiningCloseDay4(e.target.value)
									}
								/>
							)}
						</View>
					</View>
					<Text
						style={{
							textAlign: "center",
							marginVertical: 20,
							fontWeight: "bold",
							fontSize: 20,
						}}>
						Scroll right
					</Text>
				</ScrollView>

				<ScrollView style={styles.mainView}>
					<Card.Title>Thursday (Day 4)</Card.Title>
					<View
						style={
							Platform.OS === "web" && {
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "row",
								flexWrap: "wrap",
							}
						}>
						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{pickupOpenDay5 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Pickup open time : {pickupOpenDay5}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Pickup open'
									onPress={() => {
										showDatePicker("pickupopen", "day5");
									}}
								/>
							) : (
								<input
									value={pickupOpenDay5}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "pickupOpen", "day5")
										setPickupOpenDay5(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{pickupCloseDay5 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Pickup close time : {pickupCloseDay5}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Pickup close'
									onPress={() => {
										showDatePicker("pickupclose", "day5");
									}}
								/>
							) : (
								<input
									value={pickupCloseDay5}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "pickupclose", "day5")
										setPickupCloseDay5(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{deliveryOpenDay5 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Delivery open time : {deliveryOpenDay5}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set delivery open'
									onPress={() => {
										showDatePicker("deliveryOpen", "day5");
									}}
								/>
							) : (
								<input
									value={deliveryOpenDay5}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "deliveryOpen", "day5")
										setDeliveryOpenDay5(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{deliveryCloseDay5 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Delivery Close time : {deliveryCloseDay5}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set delivery Close'
									onPress={() => {
										showDatePicker("deliveryClose", "day5");
									}}
								/>
							) : (
								<input
									value={deliveryCloseDay5}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "deliveryClose", "day5")
										setDeliveryCloseDay5(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>

							{diningOpenDay5 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Dine-In Open : {diningOpenDay5}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Dine-In Close'
									onPress={() => {
										showDatePicker("diningOpen", "day5");
									}}
								/>
							) : (
								<input
									value={diningOpenDay5}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "diningOpen", "day5")
										setDiningOpenDay5(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{diningCloseDay5 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Dine-In Close : {diningCloseDay5}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Dine-in Close'
									onPress={() => {
										showDatePicker("diningClose", "day5");
									}}
								/>
							) : (
								<input
									value={diningCloseDay5}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "diningClose", "day5")
										setDiningCloseDay5(e.target.value)
									}
								/>
							)}
						</View>
					</View>
					<Text
						style={{
							textAlign: "center",
							marginVertical: 20,
							fontWeight: "bold",
							fontSize: 20,
						}}>
						Scroll right
					</Text>
				</ScrollView>

				<ScrollView style={styles.mainView}>
					<Card.Title>Friday (Day 5)</Card.Title>
					<View
						style={
							Platform.OS === "web" && {
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "row",
								flexWrap: "wrap",
							}
						}>
						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{pickupOpenDay6 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Pickup open time : {pickupOpenDay6}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Pickup open'
									onPress={() => {
										showDatePicker("pickupopen", "day6");
									}}
								/>
							) : (
								<input
									value={pickupOpenDay6}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "pickupOpen", "day6")
										setPickupOpenDay6(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{pickupCloseDay6 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Pickup close time : {pickupCloseDay6}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Pickup close'
									onPress={() => {
										showDatePicker("pickupclose", "day6");
									}}
								/>
							) : (
								<input
									value={pickupCloseDay6}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										handleConfirm(e.target.value, "pickupclose", "day6")
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{deliveryOpenDay6 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Delivery open time : {deliveryOpenDay6}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set delivery open'
									onPress={() => {
										showDatePicker("deliveryOpen", "day6");
									}}
								/>
							) : (
								<input
									value={deliveryOpenDay6}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "deliveryOpen", "day6")
										setDeliveryOpenDay6(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{deliveryCloseDay6 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Delivery Close time : {deliveryCloseDay6}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set delivery Close'
									onPress={() => {
										showDatePicker("deliveryClose", "day6");
									}}
								/>
							) : (
								<input
									value={deliveryCloseDay6}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "deliveryClose", "day6")
										setDeliveryCloseDay6(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>

							{diningOpenDay6 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Dine-In Open : {diningOpenDay6}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Dine-In Open'
									onPress={() => {
										showDatePicker("diningOpen", "day6");
									}}
								/>
							) : (
								<input
									value={diningOpenDay6}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "diningOpen", "day6")
										setDiningOpenDay6(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{diningCloseDay6 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Dine-In Close : {diningCloseDay6}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Dine-In Close'
									onPress={() => {
										showDatePicker("diningClose", "day6");
									}}
								/>
							) : (
								<input
									value={diningCloseDay6}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "diningClose", "day6")
										setDiningCloseDay6(e.target.value)
									}
								/>
							)}
						</View>
					</View>
					<Text
						style={{
							textAlign: "center",
							marginVertical: 20,
							fontWeight: "bold",
							fontSize: 20,
						}}>
						Scroll right
					</Text>
				</ScrollView>
				<ScrollView style={styles.mainView}>
					<Card.Title>Saturday (Day 6)</Card.Title>
					<View
						style={
							Platform.OS === "web" && {
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "row",
								flexWrap: "wrap",
							}
						}>
						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{pickupOpenDay7 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Pickup open time : {pickupOpenDay7}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Pickup open'
									onPress={() => {
										showDatePicker("pickupopen", "day7");
									}}
								/>
							) : (
								<input
									value={pickupOpenDay7}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "pickupOpen", "day7")
										setPickupOpenDay7(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{pickupCloseDay7 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Pickup close time : {pickupCloseDay7}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Pickup close'
									onPress={() => {
										showDatePicker("pickupclose", "day7");
									}}
								/>
							) : (
								<input
									value={pickupCloseDay7}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "pickupclose", "day7")
										setPickupCloseDay7(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{deliveryOpenDay7 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Delivery open time : {deliveryOpenDay7}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set delivery open'
									onPress={() => {
										showDatePicker("deliveryOpen", "day7");
									}}
								/>
							) : (
								<input
									value={deliveryOpenDay7}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "deliveryOpen", "day7")
										setDeliveryOpenDay7(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{deliveryCloseDay7 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Delivery Close time : {deliveryCloseDay7}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set delivery Close'
									onPress={() => {
										showDatePicker("deliveryClose", "day7");
									}}
								/>
							) : (
								<input
									value={deliveryCloseDay7}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "deliveryClose", "day7")
										setDeliveryCloseDay7(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>

							{diningOpenDay7 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Dine-In Open : {diningOpenDay7}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Dine-In Open'
									onPress={() => {
										showDatePicker("diningOpen", "day7");
									}}
								/>
							) : (
								<input
									value={diningOpenDay7}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "diningOpen", "day7")
										setDiningOpenDay7(e.target.value)
									}
								/>
							)}
						</View>

						<View style={styles.pickerView}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='time'
								is24Hour={false}
								onConfirm={(value) => handleConfirm(value)}
								onCancel={hideDatePicker}
							/>
							{diningCloseDay7 == "" ? (
								<></>
							) : (
								<Text style={styles.labelText}>
									Dine-In Close : {diningCloseDay7}
								</Text>
							)}

							{Platform.OS !== "web" ? (
								<Button
									title='Set Dine-In Close'
									onPress={() => {
										showDatePicker("diningClose", "day7");
									}}
								/>
							) : (
								<input
									value={diningCloseDay7}
									type='time'
									style={{ outline: "none", padding: 10, width: "300px" }}
									onChange={(e) =>
										// handleConfirm(e.target.value, "diningClose", "day7")
										setDiningCloseDay7(e.target.value)
									}
								/>
							)}
						</View>
					</View>
					<Text style={styles.save}>Click save</Text>
				</ScrollView>
			</ScrollView>
			<TouchableOpacity
				style={{
					justifyContent: "center",
					alignItems: "center",
				}}
				onPress={() => handleEditLocations()}>
				<Feather name='download-cloud' size={24} color='black' />
				<Text>Save</Text>
			</TouchableOpacity>
		</>
	);
};

export default EditLocations;

const styles = StyleSheet.create({
	labelText: { marginVertical: 9, fontWeight: "bold", fontSize: 18 },
	mainView: {
		width: Platform.OS !== "web" ? windowWidth - 20 : windowWidth / 2 - 40,
		margin: 10,
		marginHorizontal: 15,
		padding: 10,
		backgroundColor: "white",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.36,
		shadowRadius: 6.68,

		elevation: 11,
	},
	pickerView: {
		marginVertical: Platform.OS === "web" ? 20 : 10,
		marginRight: Platform.OS === "web" ? 10 : 0,
	},
	save: {
		marginVertical: 9,
		fontWeight: "bold",
		fontSize: 18,
		textAlign: "center",
	},
});
