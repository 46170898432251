import React, { useContext, useEffect, useState } from "react";
import {
	View,
	Text,
	StyleSheet,
	ScrollView,
	Platform,
	Image,
	Linking,
} from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Auth } from "aws-amplify";
import * as SecureStore from "expo-secure-store";
import { Button } from "react-native";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import RNPickerSelect from "react-native-picker-select";
import { Overlay } from "react-native-elements";
import {
	FontAwesome,
	MaterialCommunityIcons,
	MaterialIcons,
	Entypo,
	SimpleLineIcons,
	AntDesign,
} from "@expo/vector-icons";
import { format } from "date-fns";
import axios from "axios";
import { CheckBox } from "react-native-elements";
import { UserContext } from "../src/context/userContext";
import {
	SERVER_API_URL,
	API_URL_ORDER_SUMMARY_DATE,
	API_URL_ORDER_SUMMARY_MONTH,
	API_URL_CLIENT,
	API_URL_RESTAURANT,
	API_URL_LOCATIONS,
} from "../config/config";

const Dashboard = ({ navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	const [checked, setChecked] = React.useState("Date");
	const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
	const [date, setDate] = useState(format(new Date(), "MMddyyyy"));
	const [mainDate, setMainDate] = useState(new Date());
	const [restructuredClient, updateRestructureClient] = React.useState([]);

	const [apiMonth, setapiMonth] = useState(format(new Date(), "MM"));
	const [apiYear, setapiYear] = useState(format(new Date(), "yyyy"));

	const [apiMonthYear, setApiMonthYear] = useState(
		format(new Date(), "MMyyyy")
	);
	const [restaurants, setRestaurants] = useState([{ name: "All", id: "All" }]);
	const [locations, setLocations] = useState([]);
	const [restaurantValue, setrestaurantValue] = useState("All");
	const [restaurantId, setRestaurantId] = useState("All");
	const [locationValue, setlocationValue] = useState("All");
	const [data, setData] = useState({});
	const [clientData, setClientData] = useState([]);
	const [isModalVisible, setModalVisible] = useState(false);
	const toggleModal = () => {
		setModalVisible(!isModalVisible);
	};
	const [visible, setVisible] = useState(false);

	const toggleOverlay = () => {
		setVisible(!visible);
	};

	const [dropDownYear, setDropDownYear] = useState(format(new Date(), "yyyy"));
	const [api, setApi] = useState(
		`${SERVER_API_URL}${API_URL_ORDER_SUMMARY_DATE}/${date}`
	);
	const [restaurantApi, setRestaurantApi] = useState(
		`${SERVER_API_URL}${API_URL_RESTAURANT}${API_URL_CLIENT}`
	);
	const [loactionApi, setLocationApi] = useState(
		`${SERVER_API_URL}${API_URL_LOCATIONS}`
	);

	let ownerName = state?.user?.attributes["custom:owner_name"];
	const [client, setClient] = useState(
		state.userType !== "Admin" ? state?.user?.attributes["custom:client"] : ""
	);
	// let client = state.user.attributes["custom:client"];

	const userType = state.userType;
	// console.log("Client Allocated", client);

	const showDatePicker = () => {
		setDatePickerVisibility(true);
	};

	const hideDatePicker = () => {
		setDatePickerVisibility(false);
	};

	const handleConfirm = (date) => {
		// console.log("date is ", new Date(date));
		// console.log("date is ", format(new Date(date), "MMddyyyy"));
		setMainDate(date);
		let apiDate;
		if (Platform.OS !== "web") {
			apiDate = format(date, "MMddyyyy");
		} else {
			apiDate = format(new Date(date), "MMddyyyy");
		}

		setDate(apiDate);

		setApi(`${SERVER_API_URL}${API_URL_ORDER_SUMMARY_DATE}/${apiDate}`);

		hideDatePicker();
	};

	async function signOut() {
		if (Platform.OS !== "web") {
			SecureStore.deleteItemAsync("refreshTokens");
			SecureStore.deleteItemAsync("accessToken");
			SecureStore.deleteItemAsync("idToken");
			try {
				await Auth.signOut();
				dispatch({ type: "isLoggedIn", payload: false });
				navigation.navigate("Login");
			} catch (error) {
				console.log("error signing out: ", error);
			}
		} else {
			dispatch({ type: "isLoggedIn", payload: false });
			navigation.navigate("Login");
		}
	}
	const monthYearApi = () => {
		console.log(apiMonth, apiYear);
		setApi(
			`${SERVER_API_URL}${API_URL_ORDER_SUMMARY_MONTH}/${apiMonth}${apiYear}`
		);
		console.log(api);
		toggleOverlay();
	};
	const restructure = (item) => {
		let labels = [];
		for (var i = 0; i < item.length; i++) {
			labels[i] = { label: item[i].clientName, value: item[i].slug };
		}
		updateRestructureClient(labels);
		console.log("Labels are", labels);
	};
	useEffect(() => {
		async function getData() {
			try {
				// console.log("Restaurant grp is", restaurantValue);
				// console.log("lOCATION  ID IS ", locationValue);
				const response = await axios.get(api, {
					headers: {
						client,
						restaurantGroup: restaurantValue == "All" ? "" : restaurantValue,
						locationId: locationValue == "All" ? "" : locationValue,
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				});
				setData(response.data);
			} catch (error) {
				console.error(error);
			}
		}

		const getClient = async () => {
			console.log("getClient in client");
			await axios
				.get(SERVER_API_URL + API_URL_CLIENT, {
					headers: {
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				})
				.then((res) => {
					setClientData(res.data);
					restructure(res.data);
					console.log("client data is ", res.data);
				})
				.catch((err) => {
					console.log("client error is ", err);
					console.log(err);
				});
		};
		async function getRestaurants() {
			try {
				const response = await axios.get(restaurantApi, {
					headers: {
						client,
					},
				});
				setRestaurants(response.data);
			} catch (error) {
				console.log(error);
			}
		}
		async function getLocations() {
			try {
				// console.log("Location is ", restaurantId);
				const response = await axios.get(loactionApi, {
					headers: {
						restaurantGroup: restaurantId == "All" ? "" : restaurantId,
						client: client,
					},
				});
				setLocations(response.data);
				// console.log("Response is \n", response.data);
				// console.log(locations);
			} catch (error) {
				console.log(error);
			}
		}

		getData();
		getRestaurants();
		getLocations();
		getClient();
	}, [api, restaurantValue, locationValue, client]);
	return (
		<View style={styles.container}>
			{/* <View style={styles.header}>
				<Text style={{ fontSize: 20 }}>
					Welcome {userType}
					<Text style={{ textTransform: "uppercase" }}> {ownerName}</Text>{" "}
				</Text>
			
			</View> */}

			<ScrollView style={styles.dashboard_container}>
				{state.userType !== "Admin" && (
					<View
						style={{
							...styles.dashboard_card,
							flexDirection: "column",
							justifyContent: "flex-start",
							alignItems: "flex-start",
							padding: Platform.OS == "web" ? 30 : 0,
							margin: Platform.OS == "web" ? 20 : 20,
							paddingVertical: 20,
							borderRadius: 8,
							backgroundColor: "#fff",
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 5,
							},
							shadowOpacity: 0.36,
							shadowRadius: 6.68,

							elevation: 11,
						}}>
						<Text
							style={{
								fontSize: 20,
								fontWeight: "bold",
								marginBottom: 10,
							}}>
							Share store link
						</Text>
						<Text
							style={{
								color: "grey",
								fontSize: 16,
							}}>
							Customers can visit the following link and place their orders
						</Text>
						<TouchableOpacity
							onPress={() =>
								Linking.openURL(`https://${client}.delishtic.com`)
							}>
							<Text
								style={{
									color: "orange",
									marginVertical: 7,
									fontWeight: "bold",
									fontSize: 16,

									textDecorationLine: "underline",
								}}>{`https://${client}.delishtic.com`}</Text>
						</TouchableOpacity>
						<View
							style={{
								flexDirection: "row",
								marginVertical: 10,
							}}>
							<Text
								style={{
									fontSize: 18,
									marginTop: 5,
									marginRight: 20,
								}}>
								Share via
							</Text>
							<TouchableOpacity
								onPress={() => {
									Linking.openURL(
										`https://wa.me/?text=Hi%20there!%0a%0aYou%20can%20now%20order%20online%20from%20${clientData[0].clientName}%20using%20this%20link:%20https://${client}.delishtic.com%0a%0aContact%20us%20on%20${clientData[0].phoneNumber}%20if%20you%20need%20any%20help%20with%20ordering%20online.%0a%0aThank%20you.`
									);
								}}
								style={{ marginRight: 10 }}>
								<Image
									source={require("../assets/whatsapp.png")}
									style={{
										height: 35,
										width: 45,
										borderRadius: 5,
										resizeMode: "contain",
									}}
								/>
							</TouchableOpacity>
							<TouchableOpacity
								onPress={() => {
									Linking.openURL(
										`https://www.facebook.com/sharer/sharer.php?u=https://${client}.delishtic.com&p[title]=Hi%20there!%0a%0aYou%20can%20now%20order%20online%20from%20${clientData[0].clientName}%20using%20this%20link:%20https://${client}.delishtic.com%0a%0aContact%20us%20on%20${clientData[0].phoneNumber}%20if%20you%20need%20any%20help%20with%20ordering%20online.%0a%0aThank%20you.`
									);
								}}
								style={{ marginRight: 10 }}>
								<Image
									source={require("../assets/facebook.png")}
									style={{
										height: 35,
										width: 45,
										borderRadius: 5,
										resizeMode: "cover",
									}}
								/>
							</TouchableOpacity>
							<TouchableOpacity
								onPress={() => {
									Linking.openURL(
										`https://twitter.com/intent/tweet?text=Hi%20there!%0a%0aYou%20can%20now%20order%20online%20from%20${clientData[0].clientName}%20using%20this%20link:%20https://${client}.delishtic.com%0a%0aContact%20us%20on%20${clientData[0].phoneNumber}%20if%20you%20need%20any%20help%20with%20ordering%20online.%0a%0aThank%20you.`
									);
								}}
								style={{ marginRight: 10 }}>
								<Image
									source={require("../assets/twitter.png")}
									style={{
										height: 35,
										width: 45,
										borderRadius: 10,
										resizeMode: "contain",
									}}
								/>
							</TouchableOpacity>

							<TouchableOpacity
								onPress={() => {
									Linking.openURL(
										`sms:+91${clientData[0].phoneNumber}?&body=Hi%20there!%0a%0aYou%20can%20now%20order%20online%20from%20${clientData[0].clientName}%20using%20this%20link:%20https://${client}.delishtic.com%0a%0aContact%20us%20on%20${clientData[0].phoneNumber}%20if%20you%20need%20any%20help%20with%20ordering%20online.%0a%0aThank%20you.`
									);
								}}
								style={{ marginRight: 10 }}>
								<Image
									source={require("../assets/sms.jpg")}
									style={{
										height: 40,
										width: 45,
									}}
								/>
							</TouchableOpacity>
						</View>
					</View>
				)}
				<View style={styles.filter_style}>
					<Text style={{ fontSize: 17 }}>View By</Text>
					<View style={{ flexDirection: "row" }}>
						<View style={{ flexDirection: "row", alignItems: "center" }}>
							<DateTimePickerModal
								isVisible={isDatePickerVisible}
								mode='date'
								onConfirm={handleConfirm}
								onCancel={hideDatePicker}
							/>
							<View style={{ justifyContent: "center", alignItems: "center" }}>
								<CheckBox
									onPress={() => {
										setApi(
											`${SERVER_API_URL}${API_URL_ORDER_SUMMARY_DATE}/${date}`
										);
										setChecked("Date");

										showDatePicker();
									}}
									center
									checked={checked == "Date" ? true : false}
									title='Date'
									checkedIcon='dot-circle-o'
									uncheckedIcon='circle-o'
									containerStyle={{
										backgroundColor: "white",
										borderColor: "white",
									}}
								/>
								{checked == "Date" ? (
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
										}}>
										{Platform.OS !== "web" ? (
											<>
												<AntDesign
													style={{ marginHorizontal: 5 }}
													name='calendar'
													size={24}
													color='black'
													onPress={showDatePicker}
												/>
												<Text>{format(mainDate, "dd/MM/yyyy")}</Text>
											</>
										) : (
											<>
												<input
													defaultValue={new Date().toISOString().substr(0, 10)}
													type='date'
													onChange={(e) => handleConfirm(e.target.value)}
												/>
											</>
										)}
									</View>
								) : (
									<Text style={{ fontSize: 20 }}> </Text>
								)}
							</View>
						</View>
						<View style={{ flexDirection: "row", alignItems: "center" }}>
							<View style={{ justifyContent: "center", alignItems: "center" }}>
								<Overlay
									overlayStyle={{
										backgroundColor: "white",
										padding: 100,
										borderRadius: 10,
									}}
									isVisible={visible}
									onBackdropPress={toggleOverlay}
									backdropStyle={{
										backgroundColor: "rgba(155, 155, 155, 0.5)",
									}}>
									<View>
										<RNPickerSelect
											style={{
												inputIOS: {
													fontSize: 16,
													paddingVertical: 12,
													paddingHorizontal: 10,
													borderWidth: 1,
													borderColor: "gray",

													borderRadius: 4,
													color: "black",
													paddingRight: 30,
													marginVertical: 10,
												},
												inputAndroid: {
													fontSize: 16,
													// paddingHorizontal: 10,
													paddingVertical: 8,
													borderWidth: 0.5,
													borderColor: "black",
													borderRadius: 8,
													color: "black",
													padding: 10,
													marginVertical: 10,
												},
											}}
											placeholder={{
												label: "Select Month",
												value: null,
											}}
											fixAndroidTouchableBug={true}
											useNativeAndroidPickerStyle={false}
											onValueChange={(value) => setapiMonth(value)}
											items={[
												{ label: "January", value: "01" },
												{ label: "Febrauray", value: "02" },
												{ label: "March", value: "03" },
												{ label: "April", value: "04" },
												{ label: "May", value: "05" },
												{ label: "June", value: "06" },
												{ label: "July", value: "07" },
												{ label: "August", value: "08" },
												{ label: "September", value: "09" },
												{ label: "October", value: "10" },
												{ label: "November", value: "11" },
												{ label: "December", value: "12" },
											]}
											value={apiMonth}
										/>

										<RNPickerSelect
											style={pickerSelectStyles}
											placeholder={{
												label: "Select Year",
												value: null,
											}}
											fixAndroidTouchableBug={true}
											useNativeAndroidPickerStyle={false}
											onValueChange={(value) => setapiYear(value)}
											items={[
												{ label: `${dropDownYear}`, value: `${dropDownYear}` },
												{
													label: `${dropDownYear - 1}`,
													value: `${dropDownYear - 1}`,
												},
											]}
											value={apiYear}
										/>
										<View style={{ marginVertical: 20 }}>
											<Button title='Done' onPress={monthYearApi} />
										</View>
									</View>
								</Overlay>
								<CheckBox
									onPress={() => {
										console.log(apiMonthYear);
										setApi(
											`${SERVER_API_URL}${API_URL_ORDER_SUMMARY_MONTH}/${apiMonthYear}`
										);
										setChecked("Month");
										toggleModal();
										toggleOverlay();
									}}
									center
									checked={checked == "Date" ? false : true}
									title='Month'
									checkedIcon='dot-circle-o'
									uncheckedIcon='circle-o'
									containerStyle={{
										backgroundColor: "white",
										borderColor: "white",
									}}
								/>
								{/* <Button title='Show modal' onPress={toggleModal} /> */}

								{checked == "Month" ? (
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
										}}>
										<AntDesign
											style={{ marginHorizontal: 5 }}
											name='calendar'
											size={24}
											color='black'
											onPress={toggleOverlay}
										/>
										<Text>
											{apiMonth}/{apiYear}
										</Text>
									</View>
								) : (
									<Text style={{ fontSize: 20 }}> </Text>
								)}
							</View>
						</View>
					</View>
				</View>
				<View style={styles.filter_style}>
					{state.userType == "Admin" && (
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
								marginTop: 20,
							}}>
							<Text style={{ marginHorizontal: 5 }}>Client</Text>
							<View>
								<RNPickerSelect
									fixAndroidTouchableBug={true}
									style={pickerSelectStyles}
									placeholder={{ label: "All", value: "All" }}
									useNativeAndroidPickerStyle={false}
									onValueChange={(value) => {
										setClient(value);
										setrestaurantValue("All");
									}}
									items={restructuredClient.map((res) => ({
										label: res.label,
										value: res.value,
									}))}
								/>
							</View>
						</View>
					)}
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							marginTop: 20,
						}}>
						<Text style={{ marginRight: 3 }}>Restaurant/Shop</Text>
						<View>
							<RNPickerSelect
								fixAndroidTouchableBug={true}
								style={pickerSelectStyles}
								placeholder={{ label: "All", value: "All" }}
								useNativeAndroidPickerStyle={false}
								onValueChange={(value) => {
									console.log(value);
									setrestaurantValue(value);
									setRestaurantId(value);
									setlocationValue("All");
								}}
								items={restaurants.map((res) => ({
									label: res.name,
									value: res.id,
								}))}
							/>
						</View>
					</View>

					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							marginTop: 20,
						}}>
						<Text style={{ marginHorizontal: 5 }}>Location</Text>
						<View>
							<RNPickerSelect
								fixAndroidTouchableBug={true}
								style={pickerSelectStyles}
								placeholder={{ label: "All", value: "All" }}
								useNativeAndroidPickerStyle={false}
								onValueChange={(value) => {
									console.log(value);
									setlocationValue(value);
								}}
								items={locations.map((res) => ({
									label: res.name,
									value: res.id,
								}))}
							/>
						</View>
					</View>
				</View>

				<View style={styles.dashboard_card}>
					<View style={styles.icon_width}>
						<FontAwesome name='first-order' size={35} color='orange' />
					</View>
					<View style={{ marginHorizontal: 30 }}>
						<Text style={styles.amount}>
							{data.numberOfOrders && data.numberOfOrders}
						</Text>
						<Text style={styles.dashboard_card_title}>Orders</Text>
					</View>
				</View>
				<View style={styles.dashboard_card}>
					<View style={styles.icon_width}>
						<FontAwesome name='rupee' size={35} color='orange' />
					</View>
					<View style={{ marginHorizontal: 30 }}>
						<Text style={styles.amount}>
							{data.totalOrderAmount && data.totalOrderAmount.toFixed(2)}
						</Text>
						<Text style={styles.dashboard_card_title}>Total Order Amount</Text>
					</View>
				</View>

				<View style={styles.dashboard_card}>
					<View style={styles.icon_width}>
						<MaterialIcons name='supervisor-account' size={35} color='orange' />
					</View>
					<View style={{ marginHorizontal: 30 }}>
						<Text style={styles.amount}>
							{data.numberOfCustomer && data.numberOfCustomer}
						</Text>
						<Text style={styles.dashboard_card_title}>Customers</Text>
					</View>
				</View>

				<View style={styles.dashboard_card}>
					<View style={styles.icon_width}>
						<Entypo name='time-slot' size={35} color='orange' />
					</View>
					<View style={{ marginHorizontal: 30 }}>
						<Text style={styles.amount}>
							{data.pendingOrderCount && data.pendingOrderCount}
						</Text>
						<Text style={styles.dashboard_card_title}>Pending Order Count</Text>
					</View>
				</View>
				<View style={styles.dashboard_card}>
					<View style={styles.icon_width}>
						<MaterialIcons name='delivery-dining' size={35} color='orange' />
					</View>
					<View style={{ marginHorizontal: 30 }}>
						<Text style={styles.amount}>
							{data.deliveredOrderCount && data.deliveredOrderCount}
						</Text>
						<Text style={styles.dashboard_card_title}>
							Delivered Order Count
						</Text>
					</View>
				</View>
				<View style={styles.dashboard_card}>
					<View style={styles.icon_width}>
						<SimpleLineIcons name='handbag' size={35} color='orange' />
					</View>
					<View style={{ marginHorizontal: 30 }}>
						<Text style={styles.amount}>
							{data.pickupOrderCount && data.pickupOrderCount}
						</Text>
						<Text style={styles.dashboard_card_title}>
							Total Pickup Order Count
						</Text>
					</View>
				</View>
				<View style={styles.dashboard_card}>
					<View style={styles.icon_width}>
						<MaterialIcons name='delivery-dining' size={35} color='orange' />
					</View>
					<View style={{ marginHorizontal: 30 }}>
						<Text style={styles.amount}>
							{data.deliveryOrderCount && data.deliveryOrderCount}
						</Text>
						<Text style={styles.dashboard_card_title}>
							Total Delivery Order Count
						</Text>
					</View>
				</View>
			</ScrollView>
		</View>
	);
};
const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
		// alignItems: "center",
		// justifyContent: "center",
	},
	header: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		padding: 10,
		paddingVertical: 5,
		backgroundColor: "#fff",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.36,
		shadowRadius: 6.68,
		elevation: 11,
	},
	dashboard_card: {
		flexDirection: "row",
		backgroundColor: "#fff",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.36,
		shadowRadius: 6.68,

		elevation: 11,
		padding: 40,
		paddingLeft: 20,
		margin: 20,
		alignItems: "center",
		borderRadius: 10,
	},
	amount: {
		fontSize: 35,
		fontWeight: "bold",
	},
	dashboard_card_title: {
		fontSize: 18,
	},
	filter_style: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		flexWrap: "wrap",
		padding: 10,
		paddingHorizontal: 19,
	},
	icon_width: {
		width: 35,
	},
	inputIOS: {
		fontSize: 16,
		paddingVertical: 12,
		paddingHorizontal: 10,
		borderWidth: 1,
		borderColor: "gray",
		borderRadius: 4,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon
	},
	inputAndroid: {
		fontSize: 16,
		paddingHorizontal: 10,
		paddingVertical: 8,
		borderWidth: 0.5,
		borderColor: "purple",
		borderRadius: 8,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon
	},
});

const pickerSelectStyles = StyleSheet.create({
	inputIOS: {
		fontSize: 16,
		paddingVertical: 12,
		paddingHorizontal: 10,
		borderWidth: 1,
		borderColor: "gray",
		borderRadius: 4,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon
	},
	inputAndroid: {
		fontSize: 16,
		// paddingHorizontal: 10,
		paddingVertical: 8,
		borderWidth: 0.5,
		borderColor: "black",
		borderRadius: 8,
		color: "black",
		padding: 10, // to ensure the text is never behind the icon
	},
});
export default Dashboard;
