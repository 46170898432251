import React, { useContext } from "react";
import {
	View,
	Text,
	FlatList,
	TouchableOpacity,
	StyleSheet,
	Button,
	Linking,
	ScrollView,
	TextInput,
	Dimensions,
	Platform,
	ActivityIndicator,
} from "react-native";
import { Card, Header, Icon } from "react-native-elements";
import { NavigationContainer, useFocusEffect } from "@react-navigation/native";
import { v4 as uuidv4 } from "uuid";
// import Toast from "react-native-root-toast";
import { useToast } from "react-native-toast-notifications";

import axios from "axios";
import Entypo from "react-native-vector-icons/Entypo";
import Feather from "react-native-vector-icons/Feather";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import { format } from "date-fns";
import openMap from "react-native-open-maps";
import Modal from "react-native-modal";
import RNPickerSelect from "react-native-picker-select";
import { Pressable } from "react-native";
import { Snackbar } from "react-native-paper";
import { capitalize, GetDescSortOrder } from "../../utils/utils";
import {
	SERVER_API_URL,
	API_URL_LOCATIONS,
	API_URL_RESTAURANT,
	API_URL_CUSTOMER,
	API_URL_ORDER,
	API_URL_ORDER_DETAIL,
} from "../../config/config";
import { UserContext } from "../../src/context/userContext";

export const orders = ({ navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	const [orderData, updateOrderData] = React.useState([]);
	const [restaurants, setRestaurants] = React.useState([]);
	const [restaurantList, setRestaurantList] = React.useState([]);
	const [locations, setLocations] = React.useState([]);
	const [selectedRestaurant, setSelectedRestaurant] = React.useState("");
	const [customerId, setCustomerId] = React.useState();
	const [customerDetails, setCustomerDetails] = React.useState();
	const [selectedLocation, setSelectedLocation] = React.useState();
	const [orderStatus, setOrderStatus] = React.useState("");
	const [deliveryProvider, setDeliveryProvider] = React.useState("");
	const [deliveryTracking, setDeliveryTracking] = React.useState("");
	const sheetRef = React.useRef(null);
	const [isModalVisible, setModalVisible] = React.useState(false);
	const [visible, setVisible] = React.useState(false);
	const [snackBarMessage, setSnackBarMessage] = React.useState("");
	const [loading, setLoading] = React.useState(false);
	const [defaultLocation, setDefaultLocation] = React.useState("");
	const onToggleSnackBar = () => setVisible(!visible);
	const [deliveryTrackingText, setDeliveryTrackingText] = React.useState("");
	const [showLocation, setShowLocation] = React.useState("");
	const [toastVisible, setToastVisible] = React.useState(false);
	const [toastMessage, setToastMessage] = React.useState("");
	const onDismissSnackBar = () => setVisible(false);
	// const onDismissDunzoSnackBar = () => setDunzoSnackVisible(false);
	// const [orderStatusValue, setOrderStatusValue] = React.useState(undefined);
	// const [deliveryAgentButton, setDeliveryAgentButton] = React.useState(false);
	// const [street1, setStreet1] = React.useState("");
	// const [street2, setStreet2] = React.useState("");
	// const [city, setCity] = React.useState("");
	// const [state, setState] = React.useState("");
	// const [zip, setZip] = React.useState("");
	// const [lat, setLat] = React.useState("");
	// const [lng, setLng] = React.useState("");
	// const [customerFirstName, setCustomerFirstName] = React.useState("");
	// const [phoneNumber, setCustomerPhoneNumber] = React.useState("");
	const toggleModal = () => {
		setModalVisible(!isModalVisible);
	};

	const restructure = (data) => {
		let array = [];
		for (var i = 0; i < data.length; i++) {
			array[i] = { label: data[i].name, value: data[i].id };
		}
		setRestaurants(array);
		if (array.length > 0) {
			// console.log("setting Selected Restaurant", array[0].value);
			setSelectedRestaurant(array[0].value);
			getLocations(array[0].value);
		}
	};

	const getRestaurants = async () => {
		await axios
			.get(`${SERVER_API_URL}${API_URL_RESTAURANT}`, {
				headers: {
					//client: selectClient
				},
			})
			.then((res) => {
				setRestaurantList(res.data);
				restructure(res.data);
			})
			.catch((err) => console.log(err));
	};

	const getLocations = async (restaurantId) => {
		console.log("getLocations" + restaurantId);
		await axios
			.get(`${SERVER_API_URL}${API_URL_LOCATIONS}`, {
				headers: {
					restaurantgroup: restaurantId,
				},
			})
			.then((res) => {
				// console.log("getLocations Response", res.data);
				setLocations(res.data);
				if (res.data.length > 0) {
					setSelectedLocation(res.data[0].id);
				}
			})
			.catch((err) => console.log(err));
	};

	const getCustomerDetails = async (customerId) => {
		await axios
			.get(`${SERVER_API_URL}${API_URL_CUSTOMER}/` + customerId)
			.then((res) => {
				// console.log("customer response", res.data);

				setCustomerDetails(res.data);
			})
			.catch((err) => console.log(err));
	};

	const getOrders = async () => {
		setLoading(true);
		// console.log("getting orders for ", selectedRestaurant, selectedLocation);
		updateOrderData([]);
		let selectedRestaurantDetails = restaurantList.filter(
			(restaurant) => restaurant.id === selectedRestaurant
		)[0];
		console.log("selectedRestaurantDetails", selectedRestaurantDetails);
		// console.log("Location default is : ", defaultLocation);
		// setDefaultLocation(showLocation);
		let endPoint = `${SERVER_API_URL}${API_URL_ORDER_DETAIL}`;
		await axios
			.post(
				endPoint,
				{
					resGrpId: selectedRestaurant,
					locationId: selectedLocation,
				},
				{
					headers: {
						client: selectedRestaurantDetails.client,
					},
				}
			)
			.then((res) => {
				// console.log("ORDERS ARE ", res.data);
				let orderArray = res.data.sort(GetDescSortOrder("orderNo"));
				updateOrderData(orderArray);
				setCustomerId(undefined);
				setCustomerDetails(undefined);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.log("Error getting orders" + err);
			});
	};

	const updateOrderApi = async (
		orderStatus,
		itemId,
		itemOrderStatus,
		itemDeliveryProvider,
		trackingText
	) => {
		console.log(itemId);
		console.log(deliveryProvider);
		console.log("deliveryTrackingText is ", deliveryTrackingText);
		const res = await axios
			.patch(
				`${SERVER_API_URL}${API_URL_ORDER}/${itemId}`,
				{
					orderStatus: orderStatus == "" ? itemOrderStatus : orderStatus,
					deliveryProvider:
						deliveryProvider == "" ? itemDeliveryProvider : deliveryProvider,
					deliveryTracking: trackingText,
				},
				{
					headers: {
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => {
				console.log(res.data);
				onToggleSnackBar();

				setSnackBarMessage(`Successfully Updated `);
			})
			.catch((err) => {
				onToggleSnackBar();

				setSnackBarMessage(`OOPS!! Error : ${err}`);
			});
	};

	const renderItem = ({ item, index }) => {
		let bgColor = index % 2 === 0 ? "#FEF8DD" : "#CAF1DE";
		const changeHandler = (val) => {
			setDeliveryTrackingText(val);
		};
		console.log(item.data.orderDate);
		// console.log("orderStatus is ", item.data.orderStatus);

		// console.log("Items are ", orderApt,orderDeliveryAddress);
		return (
			<>
				<View
					key={item.id}
					/* onPress={() =>
			navigation.navigate(
				"TabNavigator", { params: { id: item.id, name: item.name }, screen: "restaurantInfo" })} */
				>
					{toastVisible &&
						toast.show(toastMessage, {
							type: "normal | success",
							placement: "top",
							duration: 2000,
							offset: 30,
							animationType: "slide-in",
						})}

					<Card containerStyle={{ backgroundColor: bgColor }}>
						<View>
							<Card.Title>
								<Text> Order #: {item.orderNo} </Text>
							</Card.Title>
							<Button
								onPress={() => {
									updateOrderApi(
										orderStatus,
										item.id,
										item.data.orderStatus,
										item.data.deliveryProvider
									);
								}}
								title='Save'
							/>

							<Snackbar
								visible={visible}
								onDismiss={onDismissSnackBar}
								duration={1000}
								action={{
									label: "Done",
									onPress: () => {
										onDismissSnackBar();
									},
								}}>
								{snackBarMessage}
							</Snackbar>
						</View>

						<Card.Divider></Card.Divider>
						<View style={styles.row}>
							<Text>
								<Text style={styles.bold}>Location: </Text>
								{item.locationId}
							</Text>
						</View>
						<Card.Divider></Card.Divider>
						<View style={styles.row}>
							<View style={{ flexDirection: "row", alignItems: "center" }}>
								<Text>
									<Text style={styles.bold}>Order Status: </Text>
								</Text>
								<RNPickerSelect
									fixAndroidTouchableBug={true}
									style={pickerSelectStyles}
									useNativeAndroidPickerStyle={false}
									placeholder={{
										label: item.data.orderStatus,
										value: item.data.orderStatus,
										color: "black",
									}}
									items={[
										{ label: "Pending", value: "pending" },
										{ label: "Accepted", value: "accepted" },
										{ label: "Processing", value: "processing" },
										{ label: "Ready", value: "ready" },
										{ label: "Out for delivery", value: "outfordelivery" },
										{ label: "Delivered", value: "delivered" },
										{ label: "Cancelled", value: "cancelled" },
									]}
									onValueChange={(value) => {
										console.log("status value is", value);
										setOrderStatus(value);
									}}
								/>
							</View>
							<View style={{ flexDirection: "row", alignItems: "center" }}>
								<Text style={styles.bold}>Order Type: </Text>

								<Text> {capitalize(item.data.orderType)}</Text>
							</View>
						</View>
						{item.data.orderType == "delivery" ? (
							<View>
								<View
									style={{
										flexDirection: "row",
										marginVertical: 5,
										alignItems: "center",
									}}>
									<Text style={styles.bold}>Delivery Provider :</Text>
									<RNPickerSelect
										fixAndroidTouchableBug={true}
										style={pickerSelectStyles}
										useNativeAndroidPickerStyle={false}
										onValueChange={(value) => {
											setDeliveryProvider(
												value !== "" ? value : item.data.deliveryProvider
											);
										}}
										placeholder={{
											color: "black",
											label: `${
												item.data.deliveryProvider == null
													? "Select"
													: item.data.deliveryProvider
											}`,
											value: `${
												item.data.deliveryProvider == null
													? "Select"
													: item.data.deliveryProvider
											}`,
										}}
										items={[
											{ label: "Self Delivery", value: "Self Delivery" },
											{ label: "Dunzo", value: "Dunzo" },
											{ label: "Borzo", value: "Borzo" },
											{ label: "Shadowfax", value: "Shadowfax" },
										]}
									/>
								</View>
								<View
									style={{
										flexDirection: "row",
										marginVertical: 5,
										alignItems: "center",
									}}>
									<Text style={styles.bold}>Delivery Tracking :</Text>
									<TextInput
										style={styles.input}
										placeholder='Delivery Tracking'
										onChangeText={changeHandler}
										// defaultValue={
										// 	item.data.deliveryTracking == null
										// 		? deliveryTrackingText
										// 		: item.data.deliveryTracking

										// }
										defaultValue={
											deliveryTrackingText !== ""
												? deliveryTrackingText
												: item.data.deliveryTracking
										}
										multiline={true}
									/>
								</View>
							</View>
						) : (
							<></>
						)}

						<View style={styles.row}>
							<Text>
								<Text style={styles.bold}>Order Date: </Text>
								{format(new Date(item.data.orderDate), "dd/MM/yyyy hh:mm aa")}
							</Text>
						</View>

						<View style={styles.row}>
							{item.data.scheduledDate.indexOf("ASAP") >= 0 ? (
								<Text>
									<Text style={styles.bold}>Scheduled Date: </Text>
									{item?.data?.scheduledDate}
								</Text>
							) : (
								<></>
							)}
							{item.data.scheduledDate.indexOf("ASAP") < 0 ? (
								<Text>
									<Text style={styles.bold}>Scheduled Date: </Text>
									{format(
										new Date(item?.data?.scheduledDate),
										"dd/MM/yyyy hh:mm aa"
									)}
								</Text>
							) : (
								<></>
							)}
						</View>
						<Card.Divider></Card.Divider>
						<View style={styles.row}>
							<Text>
								<Text style={styles.bold}>Total: </Text>₹{" "}
								{item.data?.total?.toFixed(2)}
							</Text>
							<Text>
								<Text style={styles.bold}>Sub Total: </Text>₹{" "}
								{item.data?.subTotal?.toFixed(2)}
							</Text>
						</View>
						<View style={styles.row}>
							<Text>
								<Text style={styles.bold}>Delivery Fee: </Text>₹{" "}
								{item.data?.deliveryFee?.toFixed(2)}
							</Text>
							<Text>
								<Text style={styles.bold}>Tax: </Text>₹{" "}
								{item.data?.tax?.toFixed(2)}
							</Text>
							<Text>
								<Text style={styles.bold}>Discount: </Text>₹{" "}
								{typeof item.data.discount == "string"
									? item.data.discount
									: item?.data?.discount?.toFixed(2)}
							</Text>
						</View>
						<Card.Divider></Card.Divider>
						<TouchableOpacity
							onPress={() => {
								setCustomerId(item.customerId);
								toggleModal();
								getCustomerDetails(item.customerId);
							}}>
							<View style={styles.simpleRow}>
								<Text style={styles.bold}>View Customer Details</Text>
								<Icon
									name='info'
									type='font-awesome-5'
									size={20}
									color='blue'
									style={{ paddingLeft: 10 }}
								/>
							</View>
						</TouchableOpacity>
						<Card.Divider></Card.Divider>
						<View style={styles.row}>
							<Text>
								<Text style={styles.bold}>Delivery Address: </Text>
							</Text>
							{item.data.deliveryPoint !== null ? (
								<TouchableOpacity
									onPress={() => {
										openMap({
											end: ` ${item.data.deliveryPoint.lat}, ${item.data.deliveryPoint.long}`,
											travelType: "drive",
										});
									}}>
									<Icon
										name='map-marker-alt'
										type='font-awesome-5'
										size={30}
										color='green'
									/>
								</TouchableOpacity>
							) : (
								<></>
							)}
						</View>
						<Text>{item.data.deliveryAddress}</Text>
						<Card.Divider></Card.Divider>
						{item.data.deliveryProvider !== null ? (
							<>
								<Text>
									<Text style={styles.bold}>Delivery Provider: </Text>
									{item.data.deliveryProvider}
								</Text>
								<Text>
									<Text style={styles.bold}>Tracking: </Text>
									{item.data.deliveryTracking}
								</Text>
								<Card.Divider></Card.Divider>
							</>
						) : (
							<></>
						)}
						<View style={styles.buttons}>
							<TouchableOpacity
								style={styles.button}
								onPress={() =>
									navigation.navigate("EditOrder", { params: { item: item } })
								}>
								<View style={styles.buttonView}>
									<Text style={styles.buttonText}>Edit</Text>
									<Entypo name='edit' size={30} color='green'></Entypo>
								</View>
							</TouchableOpacity>
							<TouchableOpacity
								/* onPress={() => deleteCustomer(item.id)} */
								style={styles.button}>
								<View style={styles.buttonView}>
									<Text style={styles.buttonText}>Delete</Text>
									<Feather name='delete' size={30} color='red'></Feather>
								</View>
							</TouchableOpacity>

							<TouchableOpacity
								style={styles.button}
								// onPress={
								// 	(() => navigation.navigate("Order Details"),
								// 	{ params: { name: "Hllo" }, screen: "restaurantInfo" })
								// }
								// onPress={() => navigation.navigate("Order Details")}

								onPress={() => {
									navigation.navigate("Order Details", {
										customerId: item.customerId,
										orderId: item.id,
										orderStatus: item.data.orderStatus,
										orderNo: item.orderNo,
										orderDate: format(
											new Date(item.data.orderDate),
											"dd/MM/yyyy hh:mm aaaaa'm'"
										),
										orderType: item.data?.orderType,
										scheduledDate: item.data?.scheduledDate,
										apt: item.data?.apt,
										deliveryAddress: item.data?.deliveryAddress,
										street2: item.data?.street2,
										city: item.data?.city,
										state: item.data?.state,
										zip: item.data?.zip,
										latitude: item.data?.deliveryPoint?.lat,
										long: item.data?.deliveryPoint?.long,
										customerId: item.customerId,
										itemId: item.id,
										locationId: item.locationId,
									});
								}}>
								<View style={styles.buttonView}>
									<Text style={styles.buttonText}>Order Details</Text>
									<MaterialCommunityIcons
										name='card-account-details'
										size={30}
										color='black'
									/>
								</View>
							</TouchableOpacity>
						</View>
					</Card>
				</View>
			</>
		);
	};
	// useFocusEffect(React.useCallback(() => {
	//     getRestaurants();
	//     if(selectedRestaurant)
	//     {
	//         getOrders();
	//     }
	//     return () => {
	//         // Do something when the screen is unfocused
	//         // Useful for cleanup functions
	//     };
	// }, [selectedRestaurant]));

	useFocusEffect(
		React.useCallback(() => {
			getRestaurants();
			return () => {
				// Do something when the screen is unfocused
				// Useful for cleanup functions
			};
		}, [])
	);

	const renderContent = () => {
		// console.log("renderContent");
		if (customerId && customerDetails) {
			return (
				<View style={{ margin: 20 }}>
					<Card>
						<Text style={styles.modalText}>
							Customer Name:{" "}
							<Text style={styles.bold}>{customerDetails.name}</Text>{" "}
						</Text>
						<View style={styles.row}>
							<Text style={styles.modalText}>
								Phone Number:{" "}
								<Text style={styles.bold}>{customerDetails.phoneNumber}</Text>{" "}
							</Text>
							<TouchableOpacity
								onPress={() => {
									Linking.openURL(`tel:+91-${customerDetails.phoneNumber}`);
								}}>
								<Icon
									name='mobile-alt'
									type='font-awesome-5'
									size={26}
									color={"green"}
									style={{ alignContent: "flex-end", paddingLeft: 40 }}
								/>
							</TouchableOpacity>
						</View>
						<View style={styles.row}>
							<Text style={styles.modalText}>
								Email: <Text style={styles.bold}>{customerDetails.email}</Text>
							</Text>
							<TouchableOpacity
								onPress={() => {
									Linking.openURL(`mailto:${customerDetails.email}`);
								}}>
								<Icon
									name='envelope'
									type='font-awesome-5'
									size={26}
									color={"green"}
									style={{ alignContent: "flex-end", paddingLeft: 40 }}
								/>
							</TouchableOpacity>
						</View>
						<Text style={styles.modalText}>Customer Id: {customerId}</Text>
					</Card>
				</View>
			);
		} else {
			return null;
		}
	};

	return (
		<View>
			<Header
				leftComponent={{
					icon: "menu",
					color: "#fff",
					onPress: () => navigation.openDrawer(),
				}}
				centerComponent={{ text: "ORDERS", style: { color: "#fff" } }}
				rightComponent={{ icon: "home", color: "#fff" }}></Header>
			{restaurants !== null ? (
				<Card>
					<Text>Select Restaurant / Shops</Text>
					{/* <Picker
                        itemStyle={{ height: 100 }}
                        selectedValue={selectedRestaurant}
                        onValueChange={(itemValue, itemIndex) => {
                            setSelectedRestaurant(itemValue);
                            getLocations(itemValue);
                        }}>
                        {restaurants.map((restaurant, index) => (
                            <Picker.Item label={restaurant.label} value={restaurant.value} />
                        ))}
                    </Picker> */}
					<RNPickerSelect
						style={pickerSelectStyles}
						fixAndroidTouchableBug={true}
						useNativeAndroidPickerStyle={false}
						onValueChange={(itemValue, itemIndex) => {
							setSelectedRestaurant(itemValue);
							getLocations(itemValue);
							setDefaultLocation("");
						}}
						items={restaurants.map((res) => ({
							label: res.label,
							value: res.value,
						}))}
					/>

					<Text>Select Location</Text>
					{/* <Picker
                        itemStyle={{ height: 100 }}
                        selectedValue={selectedLocation}
                        onValueChange={(itemValue, itemIndex) =>
                            setSelectedLocation(itemValue)
                        }>
                        {locations.map((location, index) => (
                            <Picker.Item label={location.name} value={location.id} />
                        ))}
                    </Picker> */}
					<RNPickerSelect
						fixAndroidTouchableBug={true}
						style={pickerSelectStyles}
						useNativeAndroidPickerStyle={false}
						onValueChange={(itemValue, itemIndex) => {
							setSelectedLocation(itemValue);
							setShowLocation(itemValue);
						}}
						value={selectedLocation}
						items={locations.map((res) => ({
							label: res.name,
							value: res.id,
						}))}
					/>
					<Pressable
						style={{
							backgroundColor: "rgb(56,81,126)",
							padding: 10,
							borderRadius: 10,
							marginTop: 10,
						}}
						onPress={getOrders}>
						<Text style={{ textAlign: "center", color: "white" }}>
							View Orders
						</Text>
					</Pressable>
				</Card>
			) : (
				<></>
			)}

			{loading == true ? (
				<ActivityIndicator size='large' color='#00ff00' />
			) : (
				<FlatList
					data={orderData}
					renderItem={renderItem}
					// style={{ paddingBottom: 100 }}
					contentContainerStyle={{ paddingBottom: 300 }}
				/>
			)}
			{orderData.length === 0 ? (
				<View style={{ alignItems: "center" }}>
					<Text style={styles.bold}>No orders found!</Text>
				</View>
			) : (
				<></>
			)}
			<Modal
				isVisible={isModalVisible}
				onBackdropPress={() => setModalVisible(false)}>
				<View style={{ backgroundColor: "white" }}>
					{renderContent()}
					<Button title='Close' onPress={toggleModal} />
				</View>
			</Modal>
		</View>
	);
};
const styles = StyleSheet.create({
	text: {
		fontSize: 26,
		color: "green",
	},
	addButton: {
		flex: 1,
		paddingLeft: 140,
		paddingTop: 30,
	},
	CardText: {
		fontWeight: "bold",
		color: "red",
		fontSize: 15,
	},
	buttons: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "center",
	},
	button: {
		marginLeft: 10,
		borderWidth: 0.5,
		borderRadius: 20,
		// width: 100,
		padding: 5,
		alignItems: "center",
	},
	buttonView: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	buttonText: {
		marginRight: 15,
	},
	row: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: 10,
	},
	simpleRow: {
		flexDirection: "row",
	},
	bold: {
		fontWeight: "bold",
	},
	bottomPad: {
		paddingBottom: 10,
	},
	input: {
		height: 40,
		margin: 12,
		borderWidth: 1,
		padding: 10,
		borderRadius: 8,
		width: 200,
	},
	picker: {
		backgroundColor: "#fafafa",
	},
	pickerContainer: {
		height: 30,
	},
	pickerItem: {
		justifyContent: "flex-start",
	},
	dropdown: {
		backgroundColor: "#fafafa",
	},
	modalText: {
		margin: 5,
	},
});
const pickerSelectStyles = StyleSheet.create({
	inputIOS: {
		fontSize: 12,
		marginHorizontal: 5,
		borderWidth: 1,
		borderColor: "gray",
		borderRadius: 4,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon
		padding: 5,
	},
	inputAndroid: {
		fontSize: 12,
		marginHorizontal: 5,
		borderWidth: 0.5,
		borderColor: "black",
		borderRadius: 8,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon

		padding: 5, // to ensure the text is never behind the icon
	},
});
