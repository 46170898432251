import React, { useContext, useEffect, useRef, useState } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	StyleSheet,
	Image,
	Dimensions,
	Platform,
	Alert,
	Modal,
	Pressable,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Input } from "react-native-elements";
import { Entypo, Ionicons, Feather } from "@expo/vector-icons";
import axios from "axios";
import { useFocusEffect } from "@react-navigation/native";
import * as ImagePicker from "expo-image-picker";
import RNPickerSelect from "react-native-picker-select";
import SelectBox from "react-native-multi-selectbox";
import { xorBy } from "lodash";
import {
	SERVER_API_URL,
	API_URL_RESTAURANT,
	API_URL_MENU_ITEMS,
} from "../../config/config";
import { UserContext } from "../../src/context/userContext";
import imageData from "../../imageData";
import { Camera } from "expo-camera";
import { manipulateAsync, FlipType, SaveFormat } from "expo-image-manipulator";
import resizeImage from "../../utils/resizeImage";

const restrictionValues = [
	{
		item: "Vegitarian",
		id: "veg",
	},
	{
		item: "Non-Vegetarian",
		id: "nonveg",
	},
	{
		item: "Egg",
		id: "egg",
	},
	{
		item: "Vegan",
		id: "vegan",
	},
];
const windowHeight = Dimensions.get("window").height;

export const CreateMenuItem = ({ navigation, route }) => {
	const { state, dispatch } = useContext(UserContext);
	const [hasPermission, setHasPermission] = useState(null);
	const [cameraType, setCameraType] = useState(Camera.Constants.Type.back);
	const [flash, setFlash] = useState(Camera.Constants.FlashMode.off);
	const [selfieModal, setSelfieModal] = useState(false);
	const screenType = route.params.type;
	const [done, setDone] = useState(false);
	const cam = useRef();

	React.useEffect(() => {
		if (screenType == "add") {
			navigation.setOptions({
				title: "Add Menu Item",
			});
		} else {
			navigation.setOptions({
				title: "Edit Menu Item",
			});
		}
	}, [navigation, screenType]);
	console.log(screenType);

	const id = route.params.menugroupId;
	const restaurantId = route.params.restaurantId;
	const clientId = route.params.clientId;
	// console.log("Id===", route.params);
	const [restaurants, updateRestaurants] = React.useState([]);
	const [labels, updateLabels] = React.useState();
	const [description, updateDescription] = React.useState(
		screenType == "add" ? "" : route.params.item?.description
	);
	const [price, updatePrice] = React.useState(
		screenType == "add" ? 0 : route.params.item?.price
	);
	const [name, updateName] = React.useState(
		screenType == "add" ? "" : route.params.item?.name
	);

	const [calories, updateCalories] = React.useState(
		screenType == "add" ? "" : route.params.item?.calories
	);
	const [priceRange, updatePriceRange] = React.useState(
		screenType == "add" ? 0 : route.params.item?.priceRange
	);
	const [priority, updatePriority] = React.useState(
		screenType == "add" ? "" : route.params.item?.priority
	);
	const [image, setImage] = React.useState(
		screenType == "add" ? [] : route.params.item?.images
	);
	const [type, UpdateType] = React.useState(
		screenType == "add"
			? route.params.businessType == "shop"
				? "product"
				: "food"
			: route.params.item?.itemType
	);
	const [collections, setCollections] = React.useState(
		screenType == "add"
			? []
			: restrictionValues.filter((elem) =>
					route.params.item?.dietaryRestrictions.includes(
						elem.item.toLowerCase()
					)
			  )
	);
	const [additiona1, setAdditional1] = React.useState(
		screenType == "add" ? "" : route.params.item?.additionalDescriptions[0]
	);
	const [additiona2, setAdditional2] = React.useState(
		screenType == "add" ? "" : route.params.item?.additionalDescriptions[1]
	);
	const [additiona3, setAdditional3] = React.useState(
		screenType == "add" ? "" : route.params.item?.additionalDescriptions[2]
	);

	const [modalVisible, setModalVisible] = useState(false);

	const [imageCategory, setImageCategory] = useState([]);
	const [categoryLabels, updateCategoryLabels] = React.useState();
	const [menuItemImages, setMenuItemImages] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [categoryFilter, setCategoryFilter] = useState("");
	const [selectImageModal, setSelectImageModal] = useState(false);
	useEffect(() => {
		(async () => {
			const { status } = await Camera.requestCameraPermissionsAsync();

			setHasPermission(status === "granted");
		})();
		setMenuItemImages(imageData);
		let temp = [];
		imageData.forEach((data) => {
			temp.push(data.category);
		});
		setImageCategory([...new Set(temp)]);
		categoryRestructure([...new Set(temp)]);
	}, []);

	const _takePicture = async () => {
		setDone(false);
		if (cam.current) {
			const options = { quality: 0.5, base64: true, skipProcessing: true };
			let photo = await cam.current.takePictureAsync(options);
			const source = photo.uri;
			try {
				if (source) {
					cam.current.pausePreview();

					console.log("Picture source is ", source);
					let img = await resizeImage(source, 200, { height: 400, width: 400 });
					console.log("img is ", img);

					setImage([...image, img]);

					setDone(true);
				}
			} catch (error) {
				console.log("Error is ", error);
			}
		}
	};
	const filterImageData = (category) => {
		if (category == "all" || category == "") {
			return;
		}
		console.log("Category", category);

		let arr = imageData.filter((elem) => elem.category == category);

		setMenuItemImages(arr);
	};

	useEffect(() => {
		filterImageData(categoryFilter);

		if (searchText == "") {
			return;
		} else {
			let arr = imageData.filter((elem) =>
				elem.name.toLowerCase().includes(searchText.toLowerCase())
			);
			setMenuItemImages(arr);
		}
	}, [searchText]);
	React.useEffect(() => {
		screenType == "add"
			? setCollections([])
			: setCollections(
					restrictionValues.filter((elem) =>
						route.params.item?.dietaryRestrictions.includes(elem.id)
					)
			  );
	}, []);
	// console.log("My collections is", collections);
	let array = [];
	const restructure = (data) => {
		array = [];
		for (var i = 0; i < data.length; i++) {
			array[i] = { label: data[i].name, value: data[i].id };
		}
		updateLabels(array);
	};

	const categoryRestructure = (data) => {
		let categoryArr = [];
		for (var i = 0; i < data.length; i++) {
			categoryArr[i] = { label: data[i], value: data[i] };
		}
		updateCategoryLabels(categoryArr);
	};

	// if (screenType == "edit") {
	// 	console.log(
	// 		"filterd array is",
	// 		// foodValues.filter((elem) => elem.item == collections.map((elem) => elem))
	// 		restrictionValues.filter((elem) =>
	// 			route.params.item?.dietaryRestrictions.includes(elem.id)
	// 		)
	// 	);
	// }
	function onMultiChange() {
		return (item) => {
			setCollections(xorBy(collections, [item], "id"));
			// setCollections(collections.push(item));
		};
	}
	// console.log("after change ", collections);
	let collectionsArr = [];

	Object.keys(collections).forEach(function (key) {
		console.log(collections[key].id);
		collectionsArr.push(collections[key].id);
		collectionsArr = [...new Set(collectionsArr)];
	});
	// console.log("selectedItems are, ", collectionsArr);
	const getRestaurants = async () => {
		await axios
			.get(`${SERVER_API_URL}${API_URL_RESTAURANT}`)
			.then((res) => {
				updateRestaurants(res.data), restructure(res.data);
			})
			.catch((err) => console.log(err));
	};

	const sendData = async () => {
		let additional = [];
		if (additiona1.length > 0) {
			additional.push(additiona1);
		}
		if (additiona2.length > 0) {
			additional.push(additiona2);
		}
		if (additiona3.length > 0) {
			additional.push(additiona3);
		}

		console.log(
			"Values are",
			// name,
			// type,
			// price,
			// description,
			// priceRange,
			// calories,
			// collectionsArr,
			// priority
			additional
		);
		console.log("Images is ", image);

		await axios
			.post(
				`${SERVER_API_URL}${API_URL_MENU_ITEMS}`,
				{
					name: name,
					itemType: type,
					images: [image],
					price: parseInt(price),
					description: description,
					priceRange: parseInt(priceRange),
					options: [],
					additionalDescriptions: additional,
					calories: calories,
					dietaryRestrictions: collectionsArr,
					priority: priority,
					options: [],
				},
				{
					headers: {
						restaurantgroup: restaurantId,
						menugroupid: id,
						client: clientId,
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => {
				alert("Menu item created Check respective Restaurant");

				console.log(res.data);
			})
			.catch((err) => console.log(err));

		return navigation.navigate("menuRestaurantGroup");
	};
	const updateData = async () => {
		let additional = [];
		console.log("my values", additiona1, additiona2, additiona3);
		if (additiona1?.length > 0) {
			additional.push(additiona1);
		}
		if (additiona2?.length > 0) {
			console.log("additional2 is ", additiona2);
			additional.push(additiona2);
		}
		if (additiona3?.length > 0) {
			additional.push(additiona3);
		}

		console.log("additional is", additional);
		await axios
			.patch(
				`${SERVER_API_URL}${API_URL_MENU_ITEMS}/${route.params.item?.id}`,
				{
					name: name,
					itemType: type,
					images: [image],
					price: parseInt(price),
					description: description,
					priceRange: parseInt(priceRange),
					options: [],
					additionalDescriptions: additional,
					calories: calories,
					dietaryRestrictions: collectionsArr,
					priority: priority,
					options: [],
				},
				{
					headers: {
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => {
				alert("Info changed");

				console.log(res.data);
			})
			.catch((err) => console.log(err));

		return navigation.navigate("menuRestaurantGroup");
	};

	useFocusEffect(
		React.useCallback(() => {
			getRestaurants();
			return () => {
				// Do something when the screen is unfocused
				// Useful for cleanup functions
			};
		}, [])
	);

	// React.useEffect(() => {
	// 	(async () => {
	// 		if (Platform.OS !== "web") {
	// 			const { status } =
	// 				await ImagePicker.requestMediaLibraryPermissionsAsync();
	// 			if (status !== "granted") {
	// 				alert("Sorry, we need camera roll permissions to make this work!");
	// 			}
	// 		}
	// 	})();
	// }, []);

	React.useEffect(async () => {
		if (Platform.OS !== "web") {
			const { status } =
				await ImagePicker.requestMediaLibraryPermissionsAsync();
			if (status !== "granted") {
				alert("Sorry, we need camera roll permissions to make this work!");
			}
		}
	}, []);
	const pickImage = async () => {
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.All,
			allowsEditing: true,
			aspect: [4, 3],
			quality: 1,
		});
		// console.log("file size is ", filesize);
		if (!result.cancelled) {
			let img = await resizeImage(result.uri, 200, { height: 400, width: 400 });
			console.log("img is ", img);
			setImage([...image, img]);
		}
	};
	const removeImage = (element) => {
		let filteredImage = image.filter((elem) => elem !== element);
		setImage(filteredImage);
	};
	const input = React.createRef();
	const input2 = React.createRef();
	return (
		<View style={{ flex: 1 }}>
			<ScrollView style={{ padding: 20 }}>
				<View
					style={{ padding: 10, borderRadius: 10, backgroundColor: "white" }}>
					<View style={styles.input}>
						<Input
							defaultValue={name.toString()}
							label='Name'
							labelStyle={{ color: "black" }}
							placeholder='name'
							onChangeText={(value) => updateName(value)}></Input>
					</View>
					<View style={styles.input}>
						<Text style={styles.label}>Type</Text>
						<RNPickerSelect
							fixAndroidTouchableBug={true}
							style={pickerSelectStyles}
							useNativeAndroidPickerStyle={false}
							placeholder={{
								label: "Type",
								color: "black",
							}}
							value={type}
							items={[
								{ label: "Product", value: "product" },
								{ label: "Food", value: "food" },
							]}
							onValueChange={(value) => {
								console.log("status value is", value);
								UpdateType(value);
							}}
						/>
					</View>

					<View style={styles.input}>
						<Input
							label='Price'
							labelStyle={{ color: "black" }}
							placeholder='price'
							onChangeText={(value) => updatePrice(value)}
							value={price.toString()}></Input>
					</View>

					{/* {image && (
						<View>
							<Image
								source={{ uri: image }}
								style={{ width: 70, height: 70, marginVertical: 20 }}
							/>
						</View>
					)} */}
					<View style={{ flexDirection: "row", flexWrap: "wrap" }}>
						{image?.map((elem) => (
							<View
								style={{
									marginHorizontal: 5,
									borderColor: "black",
									borderWidth: 1,
									padding: 7,
									borderStyle: "dashed",
									borderRadius: 10,
									position: "relative",
									marginBottom: 10,
								}}>
								<TouchableOpacity
									onPress={() => removeImage(elem)}
									style={{
										position: "absolute",
										top: 0,
										right: 0,
										zIndex: 10,
									}}>
									<Entypo name='cross' size={24} color='black' />
								</TouchableOpacity>
								{/* {console.log("Image is ", elem)} */}
								<Image
									source={{ uri: `${Array.isArray(elem) ? elem[0] : elem}` }}
									style={{ width: 80, height: 80 }}
								/>
							</View>
						))}
					</View>

					{/* {image?.length > 0 && (
						<TouchableOpacity
							onPress={() => setSelectImageModal(true)}
							style={{
								borderStyle: "dashed",
								borderWidth: 1,
								borderColor: "black",
								borderRadius: 5,
								padding: 30,
								justifyContent: "center",
								alignItems: "center",
							}}>
							<>
								<Text
									style={{
										textAlign: "center",
										fontSize: 18,
										fontWeight: "bold",
										marginBottom: 10,
									}}>
									Add more image
								</Text>
								<AntDesign name='upload' size={24} color='black' />
							</>
						</TouchableOpacity>
					)} */}
					<Modal
						animationType='slide'
						transparent={true}
						visible={selfieModal}
						onRequestClose={() => {
							setSelfieModal(!selfieModal);
						}}>
						<View style={styles.cameraView}>
							<View
								style={{
									margin: 20,
									backgroundColor: "white",
									borderRadius: 20,
									padding: 20,
									alignItems: "center",
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 2,
									},
									shadowOpacity: 0.25,
									shadowRadius: 4,
									elevation: 5,
									height: Platform.OS == "web" ? 600 : 430,
								}}>
								<Camera
									flashMode={flash}
									style={{ height: 380, width: 300, backgroundColor: "white" }}
									ref={cam}
									type={type}>
									<View style={styles.buttonContainer}>
										<TouchableOpacity
											onPress={() => {
												setCameraType(
													cameraType === Camera.Constants.Type.back
														? Camera.Constants.Type.front
														: Camera.Constants.Type.back
												);
											}}>
											<Ionicons name='camera-reverse' size={34} color='white' />
										</TouchableOpacity>
										<TouchableOpacity
											style={{ position: "absolute", left: 38 }}
											onPress={() => {
												setFlash(
													flash === Camera.Constants.FlashMode.off
														? Camera.Constants.FlashMode.torch
														: Camera.Constants.FlashMode.off
												);
											}}>
											<Entypo name='flash' size={34} color='white' />
										</TouchableOpacity>
										<TouchableOpacity
											style={{
												position: "absolute",
												bottom: -330,
												left: 130,
											}}
											onPress={() => {
												_takePicture();
											}}>
											<Feather name='aperture' size={35} color='white' />
										</TouchableOpacity>
									</View>
								</Camera>

								{done && (
									<TouchableOpacity
										style={{
											marginVertical: 10,
											backgroundColor: "lightgreen",
											padding: 10,
											paddingHorizontal: 20,
											borderRadius: 10,
										}}
										onPress={() => setSelfieModal(false)}>
										<Text style={{ color: "white" }}>Done</Text>
									</TouchableOpacity>
								)}

								{/* {next == true && (
									<Pressable
										style={[styles.button, styles.buttonClose]}
										onPress={() => {
											setSelfieModal(!selfieModal);
											navigation.navigate("Live Task", {
												itemId: id,
											});
										}}>
										<Text style={styles.textStyle}>Next</Text>
									</Pressable>
								)}
								{next == false && (
									<Pressable
										style={[styles.button, styles.buttonClose]}
										onPress={() => {
											setSelfieModal(!selfieModal);
										}}>
										<Text style={styles.textStyle}>Try again</Text>
									</Pressable>
								)} */}
							</View>
						</View>
					</Modal>
					<View style={styles.centeredView}>
						<Modal
							animationType='slide'
							transparent={true}
							visible={modalVisible}
							onRequestClose={() => {
								// Alert.alert("Modal has been closed.");
								setModalVisible(!modalVisible);
							}}>
							<View style={styles.centeredView}>
								<View style={styles.modalView}>
									<View style={styles.input}>
										<Text style={styles.label}>Select category</Text>

										<RNPickerSelect
											fixAndroidTouchableBug={true}
											style={pickerSelectStyles}
											useNativeAndroidPickerStyle={false}
											placeholder={{
												label: "All",
												color: "black",
												value: "all",
											}}
											// value={type}
											// items={[{
											// 	label:image
											// }]}
											items={categoryLabels}
											onValueChange={(value) => {
												console.log("status value is", value);
												// UpdateType(value);
												// setCategoryFilter(value)
												filterImageData(value);
											}}
										/>
									</View>

									<View style={styles.input}>
										<Input
											label='Search'
											labelStyle={{ color: "black" }}
											placeholder='search...'
											onChangeText={(value) => setSearchText(value)}
											value={searchText}></Input>
									</View>
									{menuItemImages.length > 0 ? (
										<ScrollView>
											{menuItemImages?.map((elem) => (
												// <Text>{elem.image}</Text>
												<TouchableOpacity
													onPress={() => {
														// setImage(elem.image);
														setImage([...image, elem.image]);

														setModalVisible(false);
													}}
													style={{
														flexDirection: "row",
														alignItems: "center",
														marginVertical: 5,
														borderBottomColor: "black",
														borderBottomWidth: 0.5,
													}}>
													<Image
														style={styles.tinyLogo}
														source={{
															uri: elem.image,
														}}
													/>
													<Text
														style={{
															marginLeft: 20,
															textTransform: "capitalize",
															width: 200,
														}}>
														{elem.name}
													</Text>
												</TouchableOpacity>
											))}
										</ScrollView>
									) : (
										<Text>No result found!!</Text>
									)}

									<Pressable
										style={[styles.button, styles.buttonClose]}
										onPress={() => setModalVisible(!modalVisible)}>
										<Text style={styles.textStyle}>Cancel</Text>
									</Pressable>
								</View>
							</View>
						</Modal>
					</View>
					<View style={styles.input}>
						<Text style={styles.label}>Images</Text>

						<TouchableOpacity
							style={{
								backgroundColor: "rgb(56,81,126)",
								padding: 10,
								borderRadius: 10,
								marginTop: 10,
							}}
							onPress={() => pickImage()}>
							<Text
								style={{
									color: "white",
									textAlign: "center",
									textTransform: "capitalize",
									fontSize: 18,
								}}>
								{image?.length <= 0 ? "Select Photo" : "Select more images"}
							</Text>
						</TouchableOpacity>
						<Text style={{ textAlign: "center", marginVertical: 10 }}>OR</Text>
						<TouchableOpacity
							style={{
								backgroundColor: "rgb(56,81,126)",
								padding: 10,
								borderRadius: 10,
							}}
							onPress={() => setModalVisible(true)}>
							<Text
								style={{
									color: "white",
									textAlign: "center",
									textTransform: "capitalize",
									fontSize: 15,
									fontWeight: "bold",
								}}>
								Link existing image from catalog{" "}
							</Text>
						</TouchableOpacity>
						<Text style={{ textAlign: "center", marginVertical: 10 }}>OR</Text>
						<TouchableOpacity
							onPress={() => {
								// setDone(false);
								// setSelfieModal(true);
								ImagePicker.launchCameraAsync({
									allowsEditing: true,
									aspect: [4, 4],
								})
									.then((res) => {
										if (res.cancelled == true) {
											return;
										}
										console.log("Res is ", res);
										setImage([...image, res.uri]);
									})
									.catch((err) => {
										console.log("first err is ", err);
									});
							}}
							style={{
								backgroundColor: "rgb(56,81,126)",
								padding: 10,
								borderRadius: 10,
							}}>
							<Text
								style={{
									color: "white",
									textAlign: "center",
									textTransform: "capitalize",
									fontSize: 15,
									fontWeight: "bold",
								}}>
								Take picture from camera
							</Text>
						</TouchableOpacity>
					</View>
					<View style={styles.input}>
						<Input
							value={calories.toString()}
							label='Calories'
							keyboardType='number-pad'
							labelStyle={{ color: "black" }}
							placeholder='calories'
							onChangeText={(value) => updateCalories(value)}></Input>
					</View>
					<View style={styles.input}>
						<SelectBox
							listOptionProps={{ nestedScrollEnabled: true }}
							label='Dietary Restrictions'
							labelStyle={styles.label}
							options={restrictionValues}
							selectedValues={collections}
							onMultiSelect={onMultiChange()}
							onTapClose={onMultiChange()}
							isMulti
						/>
					</View>
					<View style={styles.input}>
						<Input
							value={description}
							label='Description'
							labelStyle={{ color: "black" }}
							placeholder='description'
							onChangeText={(value) => updateDescription(value)}></Input>
					</View>

					<View style={styles.input}>
						<Input
							value={priority.toString()}
							label='Priority'
							labelStyle={{ color: "black" }}
							placeholder='Priority'
							onChangeText={(value) => updatePriority(value)}></Input>
					</View>
					<View style={styles.input}>
						<Input
							value={priceRange.toString()}
							label='Price Range'
							labelStyle={{ color: "black" }}
							placeholder='Price Range'
							onChangeText={(value) => updatePriceRange(value)}></Input>
					</View>
					<View style={styles.input}>
						<Input
							defaultValue={additiona1}
							label='Additional Discription'
							labelStyle={{ color: "black" }}
							placeholder='Additional description'
							onChangeText={(value) => setAdditional1(value)}></Input>
						<Input
							defaultValue={additiona2}
							labelStyle={{ color: "black" }}
							placeholder='Additional description'
							onChangeText={(value) => setAdditional2(value)}></Input>
						<Input
							defaultValue={additiona3}
							labelStyle={{ color: "black" }}
							placeholder='Additional description'
							onChangeText={(value) => setAdditional3(value)}></Input>
					</View>
					{screenType == "add" ? (
						<TouchableOpacity
							style={{
								backgroundColor: "rgb(56,81,126)",
								padding: 10,
								borderRadius: 10,
								marginTop: 10,
								marginBottom: 30,
							}}
							onPress={() => sendData()}>
							<Text
								style={{
									color: "white",
									textAlign: "center",
									textTransform: "capitalize",
									fontSize: 15,
								}}>
								Submit
							</Text>
						</TouchableOpacity>
					) : (
						<TouchableOpacity
							style={{
								backgroundColor: "rgb(56,81,126)",
								padding: 10,
								borderRadius: 10,
								marginTop: 10,
								marginBottom: 30,
							}}
							onPress={() => updateData()}>
							<Text
								style={{
									color: "white",
									textAlign: "center",
									textTransform: "capitalize",
									fontSize: 15,
								}}>
								Save
							</Text>
						</TouchableOpacity>
					)}
				</View>
			</ScrollView>
		</View>

		/*  <View style={{ flex: 1 }}>
             <Header
 
                 leftComponent={{ icon: 'menu', color: '#fff', onPress: () => navigation.openDrawer() }}
                 centerComponent={{ text: 'MY TITLE', style: { color: '#fff' } }}
                 rightComponent={{ icon: 'home', color: '#fff' }}
             ></Header>
             <Card>
                 <Card.Title>MENU</Card.Title>
                 <Card.Divider></Card.Divider>
                 <Icon
                     name="food-bank"
                     size={45}
                     color="#03fcc2"
                     style={{ paddingLeft: 140, backgroundColor: "#3b5998" }}
                 ></Icon>
                 <ScrollView style={{ flexDirection: 'column' }}>
                     <Text style={styles.menuText}>
                         Italian
                     <View >
                             <Icon.Button name="edit" size={30} color="black" backgroundColor="white" ></Icon.Button>
                         </View>
                     </Text>
                     <Text style={styles.menuText}>Chinese <Icon.Button name="edit" size={30} color="black" backgroundColor="white" style={{ marginLeft: 0 }}></Icon.Button></Text>
                     <Text style={styles.menuText}>Mexican <Icon.Button name="edit" size={30} color="black" backgroundColor="white" style={{ marginLeft: 0 }}></Icon.Button></Text>
                     <Text style={styles.menuText}>Indian <Icon.Button name="edit" size={30} color="black" backgroundColor="white" style={{ marginLeft: 0 }}></Icon.Button></Text>
                     <Text style={styles.menuText}>South Indian <Icon.Button name="edit" size={30} color="black" backgroundColor="white" style={{ marginLeft: 0 }}></Icon.Button></Text>
                     <Text style={styles.menuText}>Continental <Icon.Button name="edit" size={30} color="black" backgroundColor="white" style={{ marginLeft: 0 }}></Icon.Button></Text>
                     <Text style={styles.menuText}>Bengali <Icon.Button name="edit" size={30} color="black" backgroundColor="white" style={{ marginLeft: 0 }}></Icon.Button></Text>
                 </ScrollView>
             </Card>
         </View>
     ) */
	);
};
/*
const styles = StyleSheet.create({
    menuText: {
        fontSize: 30,
        paddingLeft: 30,
        fontWeight: "100",
        paddingBottom: 10,
        paddingTop: 10,
        color: "#2b1ba8"
    }
}) */
const styles = StyleSheet.create({
	tinyLogo: {
		width: 50,
		height: 50,
	},
	label: {
		color: "black",
		marginBottom: 6,
		marginLeft: 10,
		fontSize: 16,
		fontWeight: "bold",
	},
	input: {
		marginVertical: 10,
	},
	centeredView: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		marginTop: 22,
	},
	cameraView: { flex: 1, justifyContent: "center" },
	modalView: {
		margin: 20,
		backgroundColor: "white",
		borderRadius: 20,
		padding: 15,
		paddingVertical: 35,
		// alignItems: "center",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		width: 300,
		// height: ,
		height: windowHeight - 100,
		// paddingBottom: 30,
	},
	buttonContainer: {
		position: "relative",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	button: {
		borderRadius: 20,
		padding: 10,
		elevation: 2,
		marginTop: 20,
	},
	buttonOpen: {
		backgroundColor: "#F194FF",
	},
	buttonClose: {
		backgroundColor: "#2196F3",
	},
	textStyle: {
		color: "white",
		fontWeight: "bold",
		textAlign: "center",
	},
});
const pickerSelectStyles = StyleSheet.create({
	inputIOS: {
		fontSize: 12,
		marginHorizontal: 5,
		borderWidth: 1,
		borderColor: "gray",
		borderRadius: 4,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon
		padding: 5,
	},
	inputAndroid: {
		fontSize: 12,
		marginHorizontal: 5,

		borderWidth: 0.5,
		borderColor: "black",
		borderRadius: 8,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon

		padding: 5, // to ensure the text is never behind the icon
	},
});
