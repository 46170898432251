import {
	Dimensions,
	Linking,
	Platform,
	StyleSheet,
	Text,
	TextInput,
	TouchableOpacity,
	View,
} from "react-native";
import React, { useEffect, useMemo, useRef, useState } from "react";

const Otp = ({ navigation }) => {
	const firstTextInputRef = useRef(null);
	const secondTextInputRef = useRef(null);
	const thirdTextInputRef = useRef(null);
	const fourthTextInputRef = useRef(null);
	const fifthTextInputRef = useRef(null);
	const sixthTextInputRef = useRef(null);
	const [otpArray, setOtpArray] = useState(["", "", "", "", "", ""]);
	const [resendTime, setresendTime] = useState(30);

	// setInterval(() => setresendTime(resendTime - 1), 1000);
	useEffect(() => {
		const timer =
			resendTime > 0 && setInterval(() => setresendTime(resendTime - 1), 1000);
		return () => clearInterval(timer);
	}, [resendTime]);
	const onOtpChange = (index) => {
		return (value) => {
			if (isNaN(Number(value))) {
				// do nothing when a non digit is pressed
				return;
			}
			const otpArrayCopy = otpArray.concat();
			otpArrayCopy[index] = value;
			setOtpArray(otpArrayCopy);

			// auto focus to next InputText if value is not blank
			if (value !== "") {
				if (index === 0) {
					secondTextInputRef.current.focus();
				} else if (index === 1) {
					thirdTextInputRef.current.focus();
				} else if (index === 2) {
					fourthTextInputRef.current.focus();
				} else if (index === 3) {
					fifthTextInputRef.current.focus();
				} else if (index === 4) {
					sixthTextInputRef.current.focus();
				}
			}
		};
	};
	useMemo(() => {
		// do something
		//convert array into text
		const otpText = otpArray.join("");
		if (otpText.length === 6) {
			if (otpText == "123456") {
				navigation.navigate("Onboarding");
			}
		}
	}, [otpArray]);

	const onOtpKeyPress = (index) => {
		return ({ nativeEvent: { key: value } }) => {
			// auto focus to previous InputText if value is blank and existing value is also blank
			if (value === "Backspace" && otpArray[index] === "") {
				if (index === 1) {
					firstTextInputRef.current.focus();
				} else if (index === 2) {
					secondTextInputRef.current.focus();
				} else if (index === 3) {
					thirdTextInputRef.current.focus();
				} else if (index === 4) {
					fourthTextInputRef.current.focus();
				} else if (index === 5) {
					fifthTextInputRef.current.focus();
				} else if (index === 6) {
					sixthTextInputRef.current.focus();
				}

				/**
				 * clear the focused text box as well only on Android because on mweb onOtpChange will be also called
				 * doing this thing for us
				 * todo check this behaviour on ios
				 */
				if (Platform.OS == "android" && index > 0) {
					const otpArrayCopy = otpArray.concat();
					otpArrayCopy[index - 1] = ""; // clear the previous box which will be in focus
					setOtpArray(otpArrayCopy);
				}
			}
		};
	};
	return (
		<View
			style={{
				justifyContent: "center",
				paddingHorizontal: 20,
				// alignItems: "center",
				// height: Dimensions.get("window").height - 120,
			}}>
			<Text
				style={{
					marginBottom: 5,
					fontSize: 20,
					fontWeight: "600",
					paddingTop: 10,
				}}>
				Please enter the 6-digit code sent to +91-98627282928
			</Text>

			<View style={{ flexDirection: "row" }}>
				<TextInput
					onKeyPress={onOtpKeyPress(0)}
					ref={firstTextInputRef}
					onChangeText={onOtpChange(0)}
					style={styles.textInput}
					maxLength={1}
					keyboardType='numeric'
				/>
				<TextInput
					onKeyPress={onOtpKeyPress(1)}
					ref={secondTextInputRef}
					onChangeText={onOtpChange(1)}
					style={styles.textInput}
					maxLength={1}
					keyboardType='numeric'
				/>
				<TextInput
					onKeyPress={onOtpKeyPress(2)}
					ref={thirdTextInputRef}
					onChangeText={onOtpChange(2)}
					style={styles.textInput}
					maxLength={1}
					keyboardType='numeric'
				/>
				<TextInput
					onKeyPress={onOtpKeyPress(3)}
					ref={fourthTextInputRef}
					onChangeText={onOtpChange(3)}
					style={styles.textInput}
					maxLength={1}
					keyboardType='numeric'
				/>

				<TextInput
					onKeyPress={onOtpKeyPress(4)}
					ref={fifthTextInputRef}
					onChangeText={onOtpChange(4)}
					style={styles.textInput}
					maxLength={1}
					keyboardType='numeric'
				/>
				<TextInput
					onKeyPress={onOtpKeyPress(5)}
					ref={sixthTextInputRef}
					onChangeText={onOtpChange(5)}
					style={styles.textInput}
					maxLength={1}
					keyboardType='numeric'
				/>
			</View>
			<View style={{ flexDirection: "row", marginVertical: 10 }}>
				<Text style={{ fontSize: 16 }}>
					Resend code in{" "}
					<Text style={{ fontWeight: "bold" }}> {resendTime}</Text>
				</Text>
				{resendTime <= 0 && (
					<TouchableOpacity
						onPress={() => {
							setresendTime(30);
						}}>
						<Text style={{ color: "#00a8ff", fontSize: 16, marginLeft: 10 }}>
							Resend code
						</Text>
					</TouchableOpacity>
				)}
			</View>
		</View>
	);
};

export default Otp;

const styles = StyleSheet.create({
	textInput: {
		height: 50,
		borderColor: "gray",
		borderWidth: 0,
		marginBottom: 10,
		borderBottomWidth: 1,

		width: 50,
		borderRadius: 8,
		marginRight: 10,
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		// center input items
		textAlign: "center",
	},
});
