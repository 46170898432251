import {
  useState,
  useContext,
  useRef,
  useEffect,
} from "react";
import * as React from "react";
import { ToastProvider } from "react-native-toast-notifications";
import 'react-native-gesture-handler';
// import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Platform, StyleSheet, LogBox, View, Text } from "react-native";

// PWA notification imports

import * as firebase from "firebase/app";
import { fireabase as firebaseInit } from "./firebase/config";

//All screens and components imports
import AdminScreen from "./screens/AdminScreen";
import Login from "./screens/LoginForm";
import AdminDashBoard from "./screens/AdminDashboard";
import Dashboard from "./screens/Dashboard";
import NewMerchantAccount from "./screens/NewMerchantAccount";
import OnBoarding from "./screens/OnBoarding/OnBoarding";
import AccountCreate from "./screens/AccountCreate";
import { client } from "./screens/Client/client";
import { AddRestaurantGroup } from "./screens/Restaurants/AddRestaurantGroup";
import { CreateClient } from "./screens/Client/CreateClient";
import OrderDetails from "./screens/orderDetails";
import CreatePromotion from "./screens/CreatePromotion";
import PromotionsList from "./screens/PromotionsList";
import { InputEdit } from "./screens/LocationDetails/InputEdit";
import EditLocations from "./screens/LocationDetails/EditLocations";
import { AddMenuGroup } from "./screens/Menu/AddMenuGroup";
import { CreateMenuItem } from "./screens/Menu/CreateMenuItem";
import CreateEditMenu from "./screens/Menu/CreateEditMenu";
import StoreManagement from "./screens/StoreManagement";
import OrderManagement from "./screens/OrderManagement";
import { MenuItem } from "./screens/Menu/MenuItem";
// import MerchantScreen from "./screens/MerchantScreen";
import { customerView } from "./components/customer/customerView";
import { orders } from "./components/orders/orders";
import { EditRestaurantInfo } from "./components/EditRestaurantInfo";
import StockUpdate from "./components/StockUpdate";
import { CustomerDetails } from "./components/customer/CustomerDetails";
import { LocationForm } from "./components/LocationForm";
import { EditOrder } from "./components/orders/EditOrder";
import { TabNavigator } from "./components/TabNavigator";
import { restaurant } from "./components/restaurants";
import Settings from "./screens/Settings";
//Context imports
import { UserContext, UserProvider } from "./src/context/userContext";
import packageInfo from "./package.json";

//Navigation imports
import { NavigationContainer } from "@react-navigation/native";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
} from "@react-navigation/drawer";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();
const Drawer = createDrawerNavigator();

//AWS imports
import Amplify, { Auth, Storage } from "aws-amplify";
import * as SecureStore from "expo-secure-store";
import AMPLIFY_CONFIG from "./aws_exports";
Amplify.configure(AMPLIFY_CONFIG);

//Notification imports
import * as Notifications from "expo-notifications";
import Constants from "expo-constants";
import { ActivityIndicator } from "react-native";
import AddEditItems from "./screens/AddEditItems";
import ApproveMerchantChanges from "./screens/ApproveMerchantChanges";
import BulkUpload from "./screens/BulkUpload";
import SignOut from "./functions/SignOut";
import LoadingScreen from "./screens/LoadingScreen";
import GetStarted from "./screens/OnBoarding/GetStarted";
import Welcome from "./screens/OnBoarding/Welcome";
import SetupStore from "./screens/OnBoarding/SetupStore";
import Browser from "./screens/OnBoarding/Browser";
import AddEditProducts from "./screens/AddProducts/AddEditProducts";
import AddVariant from "./screens/AddProducts/AddVariant";
import MenuItems from "./screens/MenuItems";
import Otp from "./screens/OnBoarding/Otp";
import GetQr from "./screens/GetQr";
import DeliveryTime from "./screens/DeliveryTime";
import SocialProfile from "./screens/SocialProfile";
import AboutUs from "./screens/AboutUs";
import ZoneBasedDelivery from "./screens/ZoneBasedDelivery";
import PromotionalBanner from "./screens/PromotionalBanner";
import PreviewBanner from "./screens/PreviewBanner";
import SendNotification from "./screens/SendNotification";

//Admin  screen stack
function AdminScreenStack() {
  return (
    <Stack.Navigator initialRouteName="Delishtic Admin">
      <Stack.Screen
        options={{
          headerShown: false,
        }}
        name="Delishtic Admin"
        component={Dashboard}
      />
      <Stack.Screen
        options={{
          headerShown: false,
        }}
        name="Admin Dashboard"
        component={AdminDashBoard}
      />
      {/* 
            <Stack.Screen
                options={{
                    headerShown: false,
                }}
                name='Order Details'
                component={OrderDetails}
            /> */}
      <Stack.Screen
        name="EditOrder"
        component={EditOrder}
        options={{ headerTitle: "Edit Order" }}

        // options={{ headerShown: false }}
      />
      <Stack.Screen
        name="CustomerDetails"
        component={CustomerDetails}
        options={{ headerTitle: "Customer Details" }}
      />
    </Stack.Navigator>
  );
}

// Admin screen drawer
function AdminStack() {
  return (
    <Drawer.Navigator drawerContent={props => <CustomDrawerContent {...props} />}>
      <Drawer.Screen name="Admin" component={AdminScreenStack} />
      <Drawer.Screen name="Merchant Accounts" component={NewMerchantAccount} />
      <Drawer.Screen name="Store Management" component={StoreManagement} />
      <Drawer.Screen name="Order Management" component={OrderManagement} />
      <Drawer.Screen
        options={{ headerShown: false }}
        name="Client"
        component={client}
      />
      <Drawer.Screen
        options={{ headerShown: false }}
        name="Restaurants/Shops"
        component={restaurant}
      />
      <Drawer.Screen
        options={{ headerShown: false }}
        name="Orders"
        component={orders}
      />
      <Drawer.Screen
        options={{ headerShown: false }}
        name="Stock Update"
        component={StockUpdate}
      />
      <Drawer.Screen
        options={{ headerShown: false }}
        name="Customers"
        component={customerView}
      />
      <Drawer.Screen
        // options={{ headerShown: false }}
        name="Promotions"
        component={PromotionsList}
      />
      <Drawer.Screen
        // options={{ headerShown: false }}
        name="Approve Merchant Changes"
        component={ApproveMerchantChanges}
      />
      <Drawer.Screen
        // options={{ headerShown: false }}
        name="Send Notification"
        component={SendNotification}
      />
      {Platform.OS == "web" && (
        <Drawer.Screen
          // options={{ headerShown: false }}
          name="Bulk Upload"
          component={BulkUpload}
        />
      )}
      <Drawer.Screen
        options={{ headerShown: false }}
        name="Logout"
        component={SignOut}
      />
    </Drawer.Navigator>
  );
}

//Merchant screen drawer
function MerchantStack() {
  return (
    <Drawer.Navigator drawerContent={props => <CustomDrawerContent {...props} />}>
      <Drawer.Screen name="Dashboard" component={Dashboard} />
      <Drawer.Screen name="Setup Store" component={SetupStore} />
      <Drawer.Screen name="Order Management" component={OrderManagement} />
      <Drawer.Screen name="Store Management" component={StoreManagement} />
      <Drawer.Screen
        options={{ headerShown: false }}
        name="Stock Update"
        component={StockUpdate}
      />
      <Drawer.Screen name="Promotions" component={PromotionsList} />
      <Drawer.Screen name="Add/Edit Items" component={AddEditItems} />
      <Drawer.Screen name="Menu items" component={MenuItems} />
      <Drawer.Screen name="Settings" component={Settings} />
      {Platform.OS == "web" && (
        <Drawer.Screen
          // options={{ headerShown: false }}
          name="Bulk Upload"
          component={BulkUpload}
        />
      )}
      <Drawer.Screen
        options={{ headerShown: false }}
        name="LogOut"
        component={SignOut}
      />
      {/* <Drawer.Screen name="Image Upload Test" component={MerchantScreen} /> */}
    </Drawer.Navigator>
  );
}

//Data Entry screen drawer
function DataEntryStack() {
  return (
    <Drawer.Navigator drawerContent={props => <CustomDrawerContent {...props} />}>
      <Drawer.Screen name="Add/Edit Items" component={AddEditItems} />
      <Drawer.Screen
        options={{ headerShown: false }}
        name="LogOut"
        component={SignOut}
      />
    </Drawer.Navigator>
  );
}

function CustomDrawerContent(props) {
  return (
      <DrawerContentScrollView {...props}>
        <DrawerItemList {...props} />
        <View style={styles.versionContainer}>
          <Text style={styles.versionText}>v{packageInfo.version}</Text>
        </View>
      </DrawerContentScrollView>
  );
}

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: false,
  }),
});

function App() {
  const { state, dispatch } = useContext(UserContext);
  const [expoPushToken, setExpoPushToken] = useState("");
  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();
  // const [userType, setUserType] = useState("");

  const [initialRouteName, setInitialRouteName] = useState("Login");
  const [loading, setLoading] = useState(false);
  let isLoggedIn = state.isLoggedIn;
  let userType = state.userType;
  //useEffect to get expo push token for Notification
  if (Platform.OS !== "web") {
    useEffect(() => {
      registerForPushNotificationsAsync().then((token) => {
        setExpoPushToken(token);
        dispatch({ type: "setExpoPushToken", payload: token });
      });

      notificationListener.current =
        Notifications.addNotificationReceivedListener((notification) => {
          setNotification(notification);
        });

      responseListener.current =
        Notifications.addNotificationResponseReceivedListener((response) => {
          console.log(response);
        });

      return () => {
        Notifications.removeNotificationSubscription(
          notificationListener.current
        );
        Notifications.removeNotificationSubscription(responseListener.current);
      };
    }, []);
  }

  // Expo notification for PWAs
  if (Platform.OS == "web") {
    let { getMessaging } = require("firebase/messaging");
    let { getToken } = require("firebase/messaging");
    let { onMessage } = require("firebase/messaging");
    const messaging = getMessaging();

    getToken(messaging, {
      vapidKey:
        "BNaiD7HyS2-YE6JSskiirjZY2nlpinS93bfRnTut7_J55ZmQbv3919oaDpdZboGqRk-kvEm2hjtLVS_bW_WJXHk",
    })
      .then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // ...

          console.log("Current token= ", currentToken);
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // ...
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // ...
      });

    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      const { title, ...options } = payload.notification;
      navigator.serviceWorker.register("firebase-messaging-sw.js");
      function showNotification() {
        Notification.requestPermission(function (result) {
          if (result === "granted") {
            navigator.serviceWorker.ready.then(function (registration) {
              registration.showNotification(payload.notification.title, {
                body: payload.notification.body,
                tag: payload.notification.tag,
              });
            });
          }
        });
      }
      showNotification();
    });
  }

  //Checking if user is logged in or not
  async function getValueFor(key) {
    if (Platform.OS !== "web") {
      let result = await SecureStore.getItemAsync(key);
      if (result) {
        dispatch({ type: "isLoggedIn", payload: true });
      } else {
        // setInitialRouteName("Login");
        // setLoading(false);
        dispatch({ type: "isLoggedIn", payload: false });
      }
    } else {
      if (localStorage.getItem("refreshTokens") !== null) {
        dispatch({ type: "isLoggedIn", payload: true });
        // if (localStorage.getItem("userType") == "Admin") {
        // 	dispatch({ type: "Admin", payload: "Admin" });
        // 	dispatch({ type: "adminLogin", payload: user });
        // } else {
        // 	dispatch({ type: "Merchant", payload: "Merchant" });
        // }
      } else {
        dispatch({ type: "isLoggedIn", payload: false });
      }
    }
  }

  //Checking if logged in user is admin or merchant
  async function getUserData() {
    try {
      console.log("Inside getUserData");
      setLoading(true);
      getValueFor("refreshTokens");
      let user = await Auth.currentAuthenticatedUser();
      console.log("getUserData user", user);
      if (user) {
        let groups = user.signInUserSession.idToken.payload["cognito:groups"];
        let isAdmin = false;

        if (groups) {
          let adminGroup = groups.filter(
            (group) => group === "MerchantAdmin"
          )[0];

          console.log("Admin group ", adminGroup);
          isAdmin = adminGroup ? true : false;
        }
        if (isAdmin) {
          dispatch({ type: "adminLogin", payload: user });
          dispatch({ type: "Admin", payload: "Admin" });
        } else {
          const assignedRole = user.attributes["custom:role"];
          dispatch({ type: assignedRole + "Login", payload: user });
          if (assignedRole === "merchant") {
            dispatch({ type: "Merchant", payload: "Merchant" });
          } else if (assignedRole === "dataentry") {
            dispatch({ type: "DataEntry", payload: "DataEntry" });
          }
        }
        const { attributes } = user;
        console.log("attributes are", attributes);
        // setUserType(attributes["custom:role"]);
        setLoading(false);
      }
    } catch (e) {
      console.error("error is ", e);
      dispatch({ type: "isLoggedIn", payload: false });
      setLoading(false);
    }
  }
  // const getUserDataWeb = () => {
  // 	setLoading(true);
  // 	console.log("web runs");
  // 	if (localStorage.getItem("refreshTokens") !== null) {
  // 		dispatch({ type: "isLoggedIn", payload: true });
  // 		if (localStorage.getItem("userType") == "Admin") {
  // 			dispatch({ type: "Admin", payload: "Admin" });
  // 			dispatch({ type: "adminLogin", payload: user });
  // 		} else {
  // 			dispatch({ type: "Merchant", payload: "Merchant" });
  // 		}
  // 	} else {
  // 		dispatch({ type: "isLoggedIn", payload: false });
  // 	}
  // 	setLoading(false);
  // };
  useEffect(() => {
    // setUserType(state.userType);
    getUserData();
    // if (Platform.OS == "web") {
    // 	getUserDataWeb();
    // }
  }, []);

  LogBox.ignoreLogs(["Warning: ..."]); // Ignore log notification by message
  LogBox.ignoreAllLogs();
  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <>
    {/* <GestureHandlerRootView style={{ flex: 1 }}> */}
      <NavigationContainer>
        {console.log("Hi user type is ", isLoggedIn, userType)}

        <Stack.Navigator
          // initialRouteName={
          // 	isLoggedIn == true
          // 		? userType === "admin"
          // 			? "Admin screen"
          // 			: "Merchant"
          // 		: "Login"
          // }
          screenOptions={{
            headerBackTitleVisible: false,
          }}
        >
          <>
            {isLoggedIn == false ? (
              <>
                <Stack.Screen
                  name="Welcome"
                  component={Welcome}
                  options={{ headerShown: false }}
                />

                <Stack.Screen name="Login" component={Login} />
                <Stack.Screen
                  options={{ headerTitle: "Get Started" }}
                  name="Delishtic Merchant"
                  component={GetStarted}
                />
                <Stack.Screen
                  options={{ headerTitle: "Business Details" }}
                  name="Onboarding"
                  component={OnBoarding}
                />

                <Stack.Screen
                  options={{ headerTitle: "Setup Store" }}
                  name="SetupStore"
                  component={SetupStore}
                />
                <Stack.Screen name="Enter OTP" component={Otp} />
              </>
            ) : (
              <>
                {userType.toLowerCase() == "admin" ? (
                  <>
                    {console.log("hello admin")}

                    <Stack.Screen
                      name="Admin screen"
                      component={AdminStack}
                      options={{ headerShown: false }}
                    />
                  </>
                ) : userType.toLowerCase() == "merchant" ? (
                  <>
                    {console.log("hello merchant")}
                    <Stack.Screen
                      options={{ headerShown: false }}
                      name="Merchant"
                      component={MerchantStack}
                    />
                    <Stack.Screen
                      name="Add Product"
                      component={AddEditProducts}
                      options={({ route }) => ({ title: route.params?.title })}
                    />
                    <Stack.Screen name="Add Variants" component={AddVariant} />
                    <Stack.Screen
                      name="GetQr"
                      component={GetQr}
                      options={{ headerTitle: "Get your QR code" }}
                    />
                    <Stack.Screen
                      name="Delivery Time"
                      component={DeliveryTime}
                    />
                    <Stack.Screen
                      name="Social Profiles"
                      component={SocialProfile}
                    />

                    <Stack.Screen name="About Us" component={AboutUs} />
                    <Stack.Screen
                      name="Zone Based Delivery"
                      component={ZoneBasedDelivery}
                    />
                    <Stack.Screen
                      name="Promotional Banner"
                      component={PromotionalBanner}
                      options={{ headerTitle: "Select Banner Image" }}
                    />
                    <Stack.Screen
                      name="Preview Banner"
                      component={PreviewBanner}
                    />
                  </>
                ) : (
                  <>
                    {console.log("hello data entry")}
                    <Stack.Screen
                      options={{ headerShown: false }}
                      name="DataEntry"
                      component={DataEntryStack}
                    />
                    <Stack.Screen
                      name="Add Product"
                      component={AddEditProducts}
                      options={({ route }) => ({ title: route.params?.title })}
                    />
                  </>
                )}

                <Stack.Screen
                  name="Promotion"
                  component={CreatePromotion}
                  options={{ headerShown: true }}
                />
                <Stack.Screen
                  options={{ headerTitle: "" }}
                  name="Browser"
                  component={Browser}
                />
                <Stack.Screen
                  name="CustomerDetails"
                  component={CustomerDetails}
                  options={{ headerTitle: "Customer Details" }}
                />
                <Stack.Screen name="CreateClient" component={CreateClient} />
                <Stack.Screen
                  name="Order Details"
                  component={OrderDetails}
                  // options={{ headerShown: false }}
                />
                <Stack.Screen
                  name="AddRestaurantGroup"
                  component={AddRestaurantGroup}
                  options={{ headerShown: false }}
                />
                <Stack.Screen
                  name="TabNavigator"
                  component={TabNavigator}
                  options={{ headerShown: false }}
                />
                <Stack.Screen
                  name="EditRestaurantInfo"
                  component={EditRestaurantInfo}
                  options={{ headerTitle: "Edit Restaurant" }}
                />
                <Stack.Screen
                  name="AddRestaurantLocation"
                  component={LocationForm}
                  options={{ headerTitle: "Add Locations" }}
                />
                <Stack.Screen
                  name="InputEdit"
                  component={InputEdit}
                  options={{ headerTitle: "Edit Locations" }}
                />
                <Stack.Screen
                  name="editLocations"
                  component={EditLocations}
                  options={{ headerTitle: "Edit Timings" }}
                />
                <Stack.Screen
                  name="AddMenuGroup"
                  component={AddMenuGroup}
                  // options={{ headerTitle: "Add Menu Group" }}
                />
                <Stack.Screen
                  name="RestaurantMenuItem"
                  component={MenuItem}
                  options={{ headerTitle: "Menu Item" }}
                />
                <Stack.Screen
                  name="CreateEditMenu"
                  component={CreateEditMenu}
                  // options={{ headerTitle: "Add menu" }}
                />
                <Stack.Screen name="createMenu" component={CreateMenuItem} />
                {/* <Stack.Screen name='Login' component={Login} /> */}
                <Stack.Screen
                  options={{ headerShown: false }}
                  name="New Account"
                  component={AccountCreate}
                />
                <Stack.Screen
                  name="EditOrder"
                  component={EditOrder}
                  options={{ headerTitle: "Edit Order" }}
                />
              </>
            )}
          </>
        </Stack.Navigator>
      </NavigationContainer>
    {/* </GestureHandlerRootView> */}
    </>
  );
}
export default () => {
  return (
    // <RootSiblingParent>
    <ToastProvider>
      <UserProvider>
        <App />
        {/* <Button
                    title='Press to schedule a notification'
                    onPress={async () => {
                        await schedulePushNotification();
                    }}
                /> */}
      </UserProvider>
      {/* // </RootSiblingParent> */}
    </ToastProvider>
  );
};

async function schedulePushNotification() {
  await Notifications.scheduleNotificationAsync({
    content: {
      title: "You've got mail! 📬",
      body: "Here is the notification body",
      data: { data: "goes here" },
    },
    trigger: { seconds: 2 },
  });
}

async function registerForPushNotificationsAsync() {
  let token;
  if (Constants.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      alert("Failed to get push token for push notification!");
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync()).data;
    console.log(token);
  } else {
    alert("Must use physical device for Push Notifications");
  }

  if (Platform.OS === "android") {
    Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  return token;
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  horizontal: {
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 10,
  },
  versionContainer: {
    marginTop: 50,
  },
  versionText: {
    fontSize: 8,
    textAlign: "right",
    color: "gray",
    marginRight: 5,
  },
});
