import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Button,
  ScrollView,
  Linking,
} from "react-native";
import React, { useContext, useEffect, useState } from "react";
import CircularProgress from "react-native-circular-progress-indicator";
import StepIndicator from "react-native-step-indicator";
import { UserContext } from "../../src/context/userContext";
import {
  API_URL_CLIENT,
  API_URL_LOCATIONS,
  API_URL_RESTAURANT,
  SERVER_API_URL,
} from "../../config/config";
import axios from "axios";

const labels = {
  data: [
    {
      title: "Create online store",
      status: "Congratulations on opening your new online store",
    },
    {
      title: "Add products",
      status: "Create your first product by adding the product name and image",
    },
    {
      title: "Share on whatsapp  ",
      status: "",
    },
  ],
};
const customStyles = {
  stepIndicatorSize: 30,
  currentStepIndicatorSize: 30,
  separatorStrokeWidth: 2,
  currentStepStrokeWidth: 3,
  stepStrokeCurrentColor: "#136eb4",
  stepStrokeWidth: 3,

  stepStrokeFinishedColor: "#136eb4",
  stepStrokeUnFinishedColor: "#aaaaaa",
  separatorFinishedColor: "#136eb4",
  separatorUnFinishedColor: "#aaaaaa",
  stepIndicatorFinishedColor: "#136eb4",
  stepIndicatorUnFinishedColor: "#ffffff",
  stepIndicatorCurrentColor: "#ffffff",
  stepIndicatorLabelFontSize: 13,
  currentStepIndicatorLabelFontSize: 13,
  stepIndicatorLabelCurrentColor: "#136eb4",
  stepIndicatorLabelFinishedColor: "#ffffff",
  stepIndicatorLabelUnFinishedColor: "#aaaaaa",
  labelColor: "#999999",
  labelSize: 13,
  currentStepLabelColor: "#fe7013",
};

const SetupStore = ({ navigation }) => {
  const { state, dispatch } = useContext(UserContext);
  console.log("user is ", state?.user);
  console.log("userType is ", state.userType);
  const [client, setClient] = useState(
    state.userType !== "Admin" ? state?.user?.attributes["custom:client"] : ""
  );
  const [currentPosition, setCurrentPosition] = useState(1);
  const [location, updateLocation] = useState([]);
  const [restaurants, updateRestaurants] = React.useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const whatsappMessage = `Hello!

	We have launched our online store and if you would like to check our website,
	please visit: https://${client}.delishtic.com

	If you have any questions about ordering online, please contact us on ${phoneNumber} and we would be happy to help you.

	Thank you
	${client}`;

  const getRestaurants = async () => {
    // setLoading(true);
    await axios
      .get(`${SERVER_API_URL}${API_URL_RESTAURANT}${API_URL_CLIENT}`, {
        headers: {
          client: client,
        },
      })
      .then((res) => {
        console.log("data is ", res.data);
        updateRestaurants(res.data);
        getLocation(res.data.id);
        // setLoading(false);
      })
      .catch((err) => {
        // setLoading(false);
        console.log(err);
      });
  };

  const getLocation = async (id) => {
    // setLoading(true);
    await axios
      .get(SERVER_API_URL + API_URL_LOCATIONS, {
        headers: {
          restaurantgroup: id,
          client: client,
        },
      })
      .then((res) => {
        console.log("location data is ", res.data[0].phone.value);
        setPhoneNumber(res.data[0].phone.value);
        updateLocation(res.data);
        // setLoading(false);
      })
      .catch((err) => {
        // setLoading(false);
        console.log("error is ", err);
      });
  };
  useEffect(() => {
    getRestaurants();
  }, []);

  return (
    <ScrollView>
      <View
        style={{
          height: 350,
          backgroundColor: "#136eb4",

          alignItems: "center",
          // position: "relative",
        }}
      >
        <View style={{ marginTop: 30, alignItems: "center" }}>
          <CircularProgress
            value={60}
            radius={70}
            duration={1500}
            textColor={"white"}
            maxValue={100}
            inActiveStrokeColor={"#5faad2"}
            activeStrokeColor={"white"}
            title={"%"}
            titleColor={"white"}
            titleStyle={{ fontWeight: "bold" }}
          />
          <Text
            style={{
              color: "white",
              marginTop: 10,
              fontSize: 18,
              fontWeight: "700",
            }}
          >
            Store setup is completed
          </Text>
        </View>
        <Text
          style={{
            marginTop: 40,
            color: "white",
            fontSize: 15,
            letterSpacing: 1.5,
          }}
        >
          Finish the following steps to unlock features
        </Text>
      </View>
      <View
        style={{
          backgroundColor: "white",
          height: 400,
          width: 380,
          top: -50,
          marginLeft: 5,
          borderRadius: 9,
          padding: 10,
        }}
      >
        <StepIndicator
          direction="vertical"
          stepCount={3}
          customStyles={customStyles}
          currentPosition={currentPosition}
          labels={labels.data.map((label, idx) => (
            <>
              <View>
                <Text
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  {label.title}
                </Text>
                <Text
                  style={{
                    color: "grey",
                    width: 300,
                    fontSize: 14,
                    // marginTop: 10,
                  }}
                >
                  {label.status}
                </Text>
                {idx === 0 && (
                  <TouchableOpacity
                    onPress={() =>
                      navigation.navigate("Browser", {
                        url: `https://${client}.delishtic.com`,
                      })
                    }
                  >
                    <Text style={{ color: "#136eb4", fontSize: 13 }}>
                      Visit Store
                    </Text>
                  </TouchableOpacity>
                )}
                {idx == 1 && (
                  <TouchableOpacity
                    onPress={() =>
                      navigation.navigate("Add Product", {
                        title: "Add Product",
                      })
                    }
                    style={{
                      backgroundColor: "#136eb4",
                      padding: 10,
                      paddingHorizontal: 5,
                      marginVertical: 8,
                      borderRadius: 8,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 15,
                        color: "white",
                        // color: "red",
                        textAlign: "center",
                      }}
                    >
                      Add Product
                    </Text>
                  </TouchableOpacity>
                )}

                {idx === 2 && (
                  <TouchableOpacity
                    onPress={() =>
                      Linking.openURL(`whatsapp://send?text=${whatsappMessage}`)
                    }
                  >
                    <Text style={{ color: "#136eb4", fontSize: 15 }}>
                      Share now
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            </>
          ))}
        />
      </View>
    </ScrollView>
  );
};

export default SetupStore;

const styles = StyleSheet.create({});
