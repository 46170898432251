import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";
var date = new Date();
export const initialState = {
  userType: "",
  merchantDetails: [
    {
      clientName: "Client",
      managerName: "Karan",
      mobileNumber: "88277378923",
      notificationMail: "joshikaran392@gmail.com",
      onBoardingDate: `${date.toDateString()} ${date.toLocaleTimeString()}`,
      clientName: "Client",
      restaurantName: "Restaurant Name",
      registrationStatus: false,

      id: uuidv4(),
      ownerName: "Owner",
      ownerEmailId: "Owner@gmail.com",
    },
  ],
  user: {},
  isLoggedIn: false,
  ExponentPushToken: "",
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "DataEntry":
      return { ...state, userType: action.payload };
    case "Merchant":
      return { ...state, userType: action.payload };
    case "Admin":
      return { ...state, userType: action.payload };
    case "AddContact":
      return {
        ...state,
        merchantDetails: [
          ...state.merchantDetails,
          {
            managerName: action.payload.managerName,
            mobileNumber: action.payload.mobileNumber,
            notificationMail: action.payload.notificationEmail,
            onBoardingDate: action.payload.onBoardingDate,
            clientName: action.payload.clientName,
            restaurantName: action.payload.restaurantName,
            registrationStatus: false,
            id: uuidv4(),
            ownerName: action.payload.ownerName,
            ownerEmailId: action.payload.ownerEmailId,
          },
        ],
      };
    case "submitMerchant":
      let updatedState = state.merchantDetails.map((item) => {
        if (item.id == action.payload) {
          return { ...item, registrationStatus: true };
        }

        return item;
      });
      return {
        ...state,
        merchantDetails: updatedState,
      };
    case "dataentryLogin":
      return {
        ...state,
        user: action.payload,
        userType: "DataEntry",
        isLoggedIn: true,
      };
    case "merchantLogin":
      return {
        ...state,
        user: action.payload,
        userType: "Merchant",
        isLoggedIn: true,
      };
    case "adminLogin":
      return {
        ...state,
        user: action.payload,
        userType: "Admin",
        isLoggedIn: true,
      };
    case "isLoggedIn":
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case "setExpoPushToken":
      return {
        ...state,
        ExponentPushToken: action.payload,
      };
  }
  return state;
};
