import React, { useContext, useEffect, useState } from "react";
import { View, Text, Button, Image, Platform, ScrollView } from "react-native";
import { Card, Input, CheckBox } from "react-native-elements";
import { NavigationContainer, useFocusEffect } from "@react-navigation/native";
import DropDownPicker from "react-native-dropdown-picker";
import RNPickerSelect from "react-native-picker-select";
import SelectBox from "react-native-multi-selectbox";
import { manipulateAsync, FlipType, SaveFormat } from "expo-image-manipulator";
import Slider from "@react-native-community/slider";
import Resizer from "react-image-file-resizer";

import axios from "axios";
import * as ImagePicker from "expo-image-picker";
import {
	SERVER_API_URL,
	API_URL_MENU_ITEMS,
	API_URL_MENU_GROUPS,
} from "../../config/config";
import { UserContext } from "../../src/context/userContext";

export const AddMenuGroup = ({ route, navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	const type = route.params.type;
	const [comparisonLevel, setComparisonLevel] = useState(1);
	const [previewImage, setPreviewImage] = useState(null);
	React.useEffect(() => {
		if (type == "add") {
			navigation.setOptions({
				title: "Add Menu",
			});
		} else {
			navigation.setOptions({
				title: "Edit Menu",
			});
		}
	}, [navigation, type]);
	console.log("Type is: ", type);
	if (type == "edit") {
		console.log("pararms are ", route.params.item.priority);
	}

	const [menuItem, updateItem] = React.useState([]);
	const [labels, updateLabels] = React.useState();
	const [multiLabel, updateMultiLabel] = React.useState();
	const [selectedMenu, updateSelectedMenu] = React.useState([]);
	const [select, updateSelect] = React.useState("");
	const [description, updateDescription] = React.useState(
		type == "add" ? "" : route.params.item.description
	);
	const [max, updateMax] = React.useState(
		type == "add" ? "" : route.params.item.max
	);
	const [min, updateMin] = React.useState(
		type == "add" ? "" : route.params.item.min
	);
	const [name, updateName] = React.useState(
		type == "add" ? "" : route.params.item.name
	);
	const [priority, updatePriority] = React.useState(
		type == "add" ? "" : parseInt(route.params.item.priority, 10)
	);
	const [item, updateSelectedItem] = React.useState(multiLabel);
	const [image, setImage] = React.useState(
		type == "add" ? "" : route.params.item.images
	);
	const [isSingleSelectionOnly, updateSingle] = React.useState(false);
	const [imageSource, setImageSource] = useState("");
	/*   const [items, setItems] = React.useState([
          { label: 'Apple', value: 'apple' },
          { label: 'Banana', value: 'banana' }
      ]); */

	const id = route.params.id;
	console.log("Id is ", id);
	const clientId = route.params.clientId;
	console.log("client id is ", clientId);
	let array = [];

	let labelForMultiSelect;
	const restructure = (data) => {
		array = [];
		labelForMultiSelect = [];
		for (var i = 0; i < data.length; i++) {
			array[i] = { label: data[i].name, value: data[i].id };
			labelForMultiSelect[i] = { value: data[i].id };
		}
		updateLabels(array);
		updateMultiLabel(labelForMultiSelect);
	};

	const getMenuItems = async () => {
		await axios
			.get(`${SERVER_API_URL}${API_URL_MENU_ITEMS}`, {
				headers: {
					resturantgroup: id,
					client: clientId,
				},
			})
			.then((res) => {
				updateItem(res.data), restructure(res.data);
			})
			.catch((err) => console.log(err));
	};

	const sendData = async () => {
		console.log("description is", image);

		if (name === "") {
			alert("Please enter name");
			return;
		}
		console.log("Priority is ", {
			name: name,
			description: description,
			priority: parseInt(priority, 10),
			images: previewImage ? previewImage : image ? image : [],

			subgroups: [],
			min: null,
			max: 1,
			isSingleSelectionOnly: false,
		});
		await axios
			.post(
				`${SERVER_API_URL}${API_URL_MENU_GROUPS}`,
				{
					name: name,
					description: description,
					priority: parseInt(priority, 10),
					images: previewImage ? previewImage : image ? image : [],

					subgroups: [],
					min: null,
					max: 1,
					isSingleSelectionOnly: false,
				},
				{
					headers: {
						restaurantgroup: id,
						client: clientId,
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => {
				alert("Succesfully created");
			})
			.catch((err) => console.log(err));

		return navigation.navigate("menuRestaurantGroup");
	};

	const updateData = async () => {
		if (name === "") {
			alert("Please enter name");
			return;
		}

		let itemMenu = [];
		/* for (var i = 0; i < item.length; i++) {
            itemMenu[i] = item[i].value;
        } */
		console.log("name is", name);
		console.log("description is", previewImage, image);

		await axios
			.patch(
				`${SERVER_API_URL}${API_URL_MENU_GROUPS}/${route.params.item.id}`,
				{
					name: name,
					description: description,
					priority: parseInt(priority, 10),
					images: previewImage ? previewImage : image ? image : [],
					subgroups: [],
					min: null,
					max: 1,
					isSingleSelectionOnly: false,
				},
				{
					headers: {
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => console.log(res))
			.catch((err) => console.log(err));

		return navigation.navigate("menuRestaurantGroup");
	};
	useFocusEffect(
		React.useCallback(() => {
			getMenuItems();
			return () => {
				// Do something when the screen is unfocused
				// Useful for cleanup functions
			};
		}, [])
	);
	React.useEffect(() => {
		(async () => {
			if (Platform.OS !== "web") {
				const { status } =
					await ImagePicker.requestMediaLibraryPermissionsAsync();
				if (status !== "granted") {
					alert("Sorry, we need camera roll permissions to make this work!");
				}
			}
		})();
	}, []);
	const resizeImage = async () => {
		if (typeof image !== "string") {
			return;
		} else {
			const manipResult = await manipulateAsync(
				image,
				[
					{
						resize: { height: 200, width: 200 },
					},
				],
				{ compress: comparisonLevel, format: SaveFormat.JPEG }
			);

			console.log("result is ", manipResult);
			// setImage([...image, manipResult.uri]);
			// setImage(manipResult.uri);
			setPreviewImage(manipResult.uri);
		}
	};

	useEffect(() => {
		resizeImage();

		// Resizer.imageFileResizer(
		// 	image,
		// 	200,
		// 	200,
		// 	"JPEG",
		// 	comparisonLevel,

		// 	(uri) => {
		// 		console.log(uri);
		// 	},
		// 	"base64"
		// );
	}, [comparisonLevel]);

	const pickImage = async () => {
		try {
			let result = await ImagePicker.launchImageLibraryAsync({
				mediaTypes: ImagePicker.MediaTypeOptions.All,
				allowsEditing: true,
				aspect: [4, 3],
				quality: 1,
			});

			if (!result.cancelled) {
				console.log("Image is ", result.uri);
				console.log("type is ", typeof result.uri);
				setImage(result.uri);
			}
		} catch (error) {
			console.log(error);
		}
	};
	return (
		<ScrollView>
			<Card>
				<Card.Title>Data For Menu Group</Card.Title>
				<Input
					defaultValue={name}
					label='Name'
					placeholder='name'
					labelStyle={{ color: "black" }}
					onChangeText={(value) => updateName(value)}
				/>

				<Input
					defaultValue={description}
					label='Description'
					placeholder='description'
					labelStyle={{ color: "black" }}
					onChangeText={(value) => updateDescription(value)}
				/>
				{/* <DropDownPicker
					items={labels}
					placeholder='Select Menu Items'
					containerStyle={{ height: 40 }}
					style={{ backgroundColor: "#fafafa" }}
					itemStyle={{
						justifyContent: "flex-start",
					}}
					defaultValue={"this.state.countries"}
					dropDownStyle={{ backgroundColor: "#fafafa" }}
					multiple={true}
					multipleText='%d items have been selected.'
					min={0}
					max={10}
					onChangeItemMultiple={(item) => updateSelectedItem(item)}
                /> */}

				{/* <Input

                    placeholder='min' onChangeText={(value) => updateMin(value)} />
				<Input placeholder='max' onChangeText={(value) => updateMax(value)} /> */}
				<Input
					defaultValue={priority?.toString()}
					label='Priority'
					keyboardType='number-pad'
					labelStyle={{ color: "black" }}
					placeholder='priority'
					onChangeText={(value) => updatePriority(value)}
				/>
				<View style={{ flexDirection: "row", justifyContent: "space-between" }}>
					{image !== null ? (
						<View>
							<Image
								source={{ uri: `${image ? image : ""}` }}
								style={{
									width: 150,
									height: 150,
									marginVertical: 20,
									resizeMode: "cover",
								}}
							/>
							<Text>Original Image</Text>
						</View>
					) : (
						<></>
					)}
					{previewImage && (
						<View>
							<Image
								source={{ uri: `${previewImage ? previewImage : ""}` }}
								style={{
									height: 150,
									width: 150,
									marginVertical: 20,
									resizeMode: "cover",
								}}
							/>
							<Text>Compressed Image</Text>
						</View>
					)}
				</View>
				<Text style={{ marginTop: 20, fontWeight: "bold" }}>
					Adjust compression level
				</Text>
				<Slider
					value={comparisonLevel}
					style={{ width: 200, height: 40 }}
					minimumValue={0}
					maximumValue={1}
					minimumTrackTintColor='black'
					maximumTrackTintColor='black'
					onSlidingComplete={(val) => {
						setComparisonLevel(val);
					}}
				/>
				<Button title='Select Photo' onPress={() => pickImage()}></Button>
				<View style={{ marginVertical: 10 }}></View>

				{/* 
				<CheckBox
					title='is CashAccepted True'
					checkedIcon='dot-circle-o'
					uncheckedIcon='circle-o'
					onIconPress={() => updateSingle(!isSingleSelectionOnly)}
					checked={isSingleSelectionOnly}

				/> */}
				{type == "add" ? (
					<Button title='Submit' onPress={() => sendData()} />
				) : (
					<Button title='Save' onPress={() => updateData()} />
				)}
			</Card>
		</ScrollView>
	);
};
