import {
	Dimensions,
	ScrollView,
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
} from "react-native";
import React, { useState, useEffect, useContext } from "react";
import { ActivityIndicator, TextInput } from "react-native-paper";
import validator from "validator";
import axios from "axios";
import {
	API_URL_CLIENT,
	API_URL_RESTAURANT,
	SERVER_API_URL,
} from "../config/config";
import { UserContext } from "../src/context/userContext";

const SocialProfile = () => {
	const { state, dispatch } = useContext(UserContext);
	const assignedClient = state.user.attributes["custom:client"];
	const validatePhoneNumber = (number) => {
		if (!number.match("[0-9]{10}")) {
			return false;
		} else {
			return true;
		}
	};

	const validateUrl = (url) => {
		if (!validator.isURL(url)) {
			return false;
		} else {
			return true;
		}
	};
	const [restaurantId, setRestaurantId] = useState("");
	const [loading, setLoading] = useState(false);
	const [whatsappNumber, setWhatsappNumber] = useState("");
	const [facebookUrl, setFaceBookUrl] = useState("");
	const [instagramUrl, setInstagramUrl] = useState("");
	const [googleUrl, setGoogleUrl] = useState("");
	const [linkedinUrl, setLinkedinUrl] = useState("");
	const [youtubeUrl, setYoutubeUrl] = useState("");
	const [twitterUrl, setTwitterUrl] = useState("");
	const [phoneError, setPhoneError] = useState(false);
	const [facebookError, setFacebookError] = useState(false);
	const [instagramError, setInstagramError] = useState(false);
	const [googleError, setGoogleError] = useState(false);
	const [linkedinError, setLinkedinError] = useState(false);
	const [youtubeError, setYoutubeError] = useState(false);
	const [twitterError, setTwitterError] = useState(false);

	const getRestaurants = async () => {
		setLoading(true);
		await axios
			.get(`${SERVER_API_URL}${API_URL_RESTAURANT}${API_URL_CLIENT}`, {
				headers: {
					client: assignedClient,
				},
			})
			.then((res) => {
				console.log("data is ", res.data[0].id);
				setRestaurantId(res.data[0].id);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};
	useEffect(() => {
		getRestaurants();
	}, []);
	const submitData = async () => {
		// console.log(preDesc);
		// console.log(id);
		console.log("hello");
		await axios
			.patch(
				`${SERVER_API_URL}${API_URL_RESTAURANT}/${restaurantId}`,
				{
					socialProfile: {
						whatsApp: whatsappNumber,
						facebook: facebookUrl,
						instagram: instagramUrl,
						google: googleUrl,
						linkedIn: linkedinUrl,
						youtube: youtubeUrl,
						twitter: twitterUrl,
					},
				},
				{
					headers: {
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => console.log(res.data))
			.catch((err) => console.log(err));
	};
	return (
		<View style={{ padding: 10 }}>
			{loading ? (
				<ActivityIndicator size='large' color='#00ff00' />
			) : (
				<>
					<ScrollView
						style={{
							backgroundColor: "white",
							padding: 10,
							paddingBottom: 20,
							borderRadius: 8,
						}}>
						{phoneError && (
							<Text
								style={{
									color: "red",
								}}>
								Please enter a valid phone number
							</Text>
						)}
						<TextInput
							onChangeText={(value) => {
								// validation for phone number

								let valid = validatePhoneNumber(value);
								setPhoneError(!valid);

								setWhatsappNumber(value);
							}}
							style={{ ...styles.textInput }}
							underlineColorAndroid='transparent'
							label='WhatsApp Number'
							autoCapitalize='none'
							theme={{ colors: { primary: "#136eb4" } }}
							value={whatsappNumber}
							keyboardType='numeric'
						/>
						{facebookError && (
							<Text
								style={{
									color: "red",
								}}>
								Please enter a valid url
							</Text>
						)}
						<TextInput
							onChangeText={(value) => {
								let valid = validateUrl(value);
								setFacebookError(!valid);
								setFaceBookUrl(value);
							}}
							style={{ ...styles.textInput }}
							underlineColorAndroid='transparent'
							label='Facebook URL'
							autoCapitalize='none'
							theme={{ colors: { primary: "#136eb4" } }}
							value={facebookUrl}
							keyboardType='url'
						/>
						{instagramError && (
							<Text
								style={{
									color: "red",
								}}>
								Please enter a valid url
							</Text>
						)}
						<TextInput
							onChangeText={(value) => {
								let valid = validateUrl(value);
								setInstagramError(!valid);

								setInstagramUrl(value);
							}}
							style={{ ...styles.textInput }}
							underlineColorAndroid='transparent'
							label='Instagram URL'
							autoCapitalize='none'
							theme={{ colors: { primary: "#136eb4" } }}
							value={instagramUrl}
							keyboardType='url'
						/>
						{googleError && (
							<Text
								style={{
									color: "red",
								}}>
								Please enter a valid url
							</Text>
						)}
						<TextInput
							onChangeText={(value) => {
								let valid = validateUrl(value);
								setGoogleError(!valid);
								setGoogleUrl(value);
							}}
							style={{ ...styles.textInput }}
							underlineColorAndroid='transparent'
							label='Google URL'
							autoCapitalize='none'
							theme={{ colors: { primary: "#136eb4" } }}
							value={googleUrl}
							keyboardType='url'
						/>
						{linkedinError && (
							<Text
								style={{
									color: "red",
								}}>
								Please enter a valid url
							</Text>
						)}
						<TextInput
							onChangeText={(value) => {
								let valid = validateUrl(value);
								setLinkedinError(!valid);
								setLinkedinUrl(value);
							}}
							style={{ ...styles.textInput }}
							underlineColorAndroid='transparent'
							label='Linkedin URL'
							autoCapitalize='none'
							theme={{ colors: { primary: "#136eb4" } }}
							value={linkedinUrl}
							keyboardType='url'
						/>
						{youtubeError && (
							<Text
								style={{
									color: "red",
								}}>
								Please enter a valid url
							</Text>
						)}
						<TextInput
							onChangeText={(value) => {
								let valid = validateUrl(value);
								setYoutubeError(!valid);
								setYoutubeUrl(value);
							}}
							style={{ ...styles.textInput }}
							underlineColorAndroid='transparent'
							label='Youtube URL'
							autoCapitalize='none'
							theme={{ colors: { primary: "#136eb4" } }}
							value={youtubeUrl}
							keyboardType='url'
						/>
						{twitterError && (
							<Text
								style={{
									color: "red",
								}}>
								Please enter a valid url
							</Text>
						)}
						<TextInput
							onChangeText={(value) => {
								let valid = validateUrl(value);
								setTwitterError(!valid);
								setTwitterUrl(value);
							}}
							style={{ ...styles.textInput }}
							underlineColorAndroid='transparent'
							label='Twitter URL'
							autoCapitalize='none'
							theme={{ colors: { primary: "#136eb4" } }}
							value={twitterUrl}
							keyboardType='url'
						/>
					</ScrollView>
					<TouchableOpacity
						onPress={() => {
							submitData();
						}}
						disabled={
							phoneError ||
							facebookError ||
							instagramError ||
							googleError ||
							linkedinError ||
							youtubeError ||
							twitterError
						}
						style={{
							//change background color when diabled
							backgroundColor:
								phoneError ||
								facebookError ||
								instagramError ||
								googleError ||
								linkedinError ||
								youtubeError ||
								twitterError
									? "#d3d3d3"
									: "#136eb4",
							// backgroundColor: "#136eb4",
							padding: 15,
							borderRadius: 8,
							paddingHorizontal: 30,
							alignItems: "center",
							justifyContent: "center",
							flexDirection: "row",
							marginTop: 20,
						}}>
						<Text
							style={{
								color: "white",
								fontSize: 15,
								fontWeight: "bold",
								marginLeft: 10,
							}}>
							Submit
						</Text>
					</TouchableOpacity>
				</>
			)}
		</View>
	);
};

export default SocialProfile;

const styles = StyleSheet.create({
	textInput: {
		backgroundColor: "white",
		marginVertical: 10,
	},
});
