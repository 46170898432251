import { ScrollView, StyleSheet, Text, View, Platform } from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../src/context/userContext";
import {
	SERVER_API_URL,
	API_URL_CLIENT,
	API_URL_LOCATIONS,
	API_URL_RESTAURANT,
	API_URL_STOCK,
	API_URL_FULL_MENUS,
	API_URL_MENU_ITEMS,
} from "../config/config";
import RNPickerSelect from "react-native-picker-select";
import axios from "axios";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Feather } from "@expo/vector-icons";

const ApproveMerchantChanges = () => {
	const { state, dispatch } = useContext(UserContext);
	const userType = state.userType;
	const assignedClient = state.user.attributes["custom:client"];
	console.log("assigned", userType);
	const [clients, setClients] = useState([]);
	const [client, setClient] = useState(assignedClient ? assignedClient : "");
	const [restaurants, setRestaurants] = useState([]);
	const [restaurant, setRestaurant] = useState("");
	const loadRestaurants = async (value) => {
		await axios
			.get(`${SERVER_API_URL}${API_URL_RESTAURANT}${API_URL_CLIENT}`, {
				headers: {
					client: value,
				},
			})
			.then((res) => {
				restructureRestaurant(res.data);
			})
			.catch((err) => {
				console.log("err2=", err);
				setRestaurants([]);
			});
	};
	const loadClients = async () => {
		await axios
			.get(SERVER_API_URL + API_URL_CLIENT, {
				headers: {
					Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				},
			})
			.then((res) => {
				restructureclient(res.data);
			})
			.catch((err) => {
				console.log("err1=", err);
			});
	};
	const restructureclient = (item) => {
		let labels = [];
		if (item.length) {
			for (let i = 0; i < item.length; i++) {
				//  labels[i] = { label: item[i].name, value: item[i].id };
				labels[i] = { label: item[i].clientName, value: item[i].slug };
			}
		}
		setClients(labels);

		// console.log("labels=", labels)

		// console.log("labels=", clients)
	};
	// const loadlocations = async (value) => {
	// 	await axios
	// 		.get(`${SERVER_API_URL}${API_URL_LOCATIONS}`, {
	// 			headers: {
	// 				restaurantGroup: value,
	// 			},
	// 		})
	// 		.then((res) => {
	// 			// console.log("clients=", res.data)
	// 			restructureLocation(res.data);
	// 			setData([]);
	// 		})
	// 		.catch((err) => {
	// 			console.log("err3=", err);
	// 			// setLocations([]);
	// 		});
	// };
	const restructureRestaurant = (item) => {
		let labels = [];
		if (item.length) {
			for (let i = 0; i < item.length; i++) {
				//  labels[i] = { label: item[i].name, value: item[i].id };
				labels[i] = { label: item[i].name, value: item[i].id };
			}
		}
		setRestaurants(labels);
		// console.log("labels=", labels)
		setRestaurant(labels[0].value);
		console.log("labels=", restaurants);
	};
	useEffect(() => {
		loadClients();
		if (assignedClient) {
			loadRestaurants(assignedClient);
		}
	}, []);
	return (
		<View style={styles.areaview}>
			<View style={styles.container}>
				{userType === "Admin" && (
					<View style={styles.dropdown}>
						<Text style={styles.text}>Client</Text>
						<View style={styles.menu}>
							<RNPickerSelect
								onValueChange={(value) => {
									setClient(value);
									console.log("changed value1=", value);
									loadRestaurants(value);
								}}
								value={client}
								items={clients}
								placeholder={"Select a client"}
								useNativeAndroidPickerStyle={false}
								fixAndroidTouchableBug={true}
								style={pickerSelectStyles}
							/>
						</View>
					</View>
				)}
				{client ? (
					<View style={styles.dropdown}>
						<Text style={styles.text}>Restaurant / Shop</Text>
						<View style={styles.menu}>
							<RNPickerSelect
								onValueChange={(value) => {
									setRestaurant(value);
									console.log("changed value2=", value);
									// loadlocations(value);
								}}
								items={restaurants}
								value={restaurant}
								placeholder='Select a restaurant or shop'
								useNativeAndroidPickerStyle={false}
								fixAndroidTouchableBug={true}
								style={pickerSelectStyles}
							/>
						</View>
					</View>
				) : (
					<Text></Text>
				)}
				<View style={{ justifyContent: "center", alignItems: "center" }}>
					<TouchableOpacity
						style={{
							backgroundColor: "lightgreen",
							padding: 10,
							paddingHorizontal: 15,
							borderRadius: 10,
						}}
						onPress={() => console.log("waiting for api")}>
						<Text style={{ fontWeight: "bold", fontSize: 16 }}>Show Items</Text>
					</TouchableOpacity>
				</View>
			</View>
			<ScrollView style={{ padding: 20 }}>
				<View style={styles.itemView}>
					<View>
						<Text style={{ fontSize: 18, fontWeight: "700", width: 250 }}>
							Test name
						</Text>
						<Text style={{ color: "grey" }}>₹ 20</Text>
					</View>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							marginVertical: 10,
						}}>
						<TouchableOpacity
							style={{
								backgroundColor: "lightgreen",
								padding: 10,
								paddingHorizontal: 15,
								borderRadius: 15,
							}}>
							<Text style={{ fontWeight: "700" }}>Approve</Text>
						</TouchableOpacity>
						<TouchableOpacity
							style={{
								backgroundColor: "red",
								padding: 10,
								paddingHorizontal: 15,
								borderRadius: 15,
							}}>
							<Text style={{ fontWeight: "700", color: "white" }}>Reject</Text>
						</TouchableOpacity>
					</View>
				</View>
			</ScrollView>
		</View>
	);
};

export default ApproveMerchantChanges;

const styles = StyleSheet.create({
	areaview: {
		flex: 1,
		// alignItems: 'center',
		//  width: 500,
		//  backgroundColor: "pink",
		paddingVertical: Platform.OS !== "web" ? "5%" : 20,
		paddingHorizontal: Platform.OS !== "web" ? "5%" : 20,
		//  marginBottom: 5,
	},
	container: {
		borderWidth: 1,
		borderColor: "grey",
		borderRadius: 15,
		flexDirection: "column",
		paddingVertical: Platform.OS !== "web" ? "4%" : 20,
		paddingHorizontal: Platform.OS !== "web" ? "4%" : 20,
		marginBottom: 8,
		// justifyContent: "space-between",
		// alignItems: "center",
		//  backgroundColor: "red",
	},
	dropdown: {
		// flexDirection: "row",
		// justifyContent: "space-between",
		// backgroundColor: "yellow",
		// alignItems: "center",
		marginBottom: Platform.OS !== "web" ? 25 : 15,
	},
	itemView: {
		// margin: 10,
		borderLeftColor: "lightgreen",
		borderLeftWidth: 10,

		padding: 15,
		backgroundColor: "white",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.36,
		shadowRadius: 6.68,

		elevation: 11,
		// borderRadius: 10,
		// flexDirection: "row",
		justifyContent: "space-between",
	},
	text: {
		fontSize: 18,
		fontWeight: "bold",
	},
	menu: {
		// width: "70%",
	},
	button: {
		//flex: 1,
		backgroundColor: "green",
		width: 150,
		borderRadius: 5,
		height: 40,
		justifyContent: "center",
		alignItems: "center",
	},
	buttonText: {
		color: "white",
		textAlign: "center",
		fontSize: 20,
		fontWeight: "bold",
	},
	heading: {
		//  backgroundColor: "red",
		textAlign: "center",
		fontSize: 20,
		fontWeight: "bold",
		marginBottom: 5,
	},
	subitems: {
		flexDirection: "row",
		// backgroundColor: "pink",
		borderWidth: 1,
		borderColor: "grey",
		borderRadius: 15,
		padding: 10,
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 10,
	},
	switch: {
		// justifyContent: "center",
		alignItems: "center",
		backgroundColor: "yellow",
		// paddingRight: 6,
		//  paddingBottom: 1,
		//  marginRight: 0,
		// marginTop: 3,
		// marginBottom: 10,
	},
});
const pickerSelectStyles = StyleSheet.create({
	inputIOS: {
		borderColor: "grey",
		// borderWidth: 1,
		borderBottomWidth: 1,
		paddingVertical: 1.5,
		paddingHorizontal: 4,
		paddingRight: 30,
		color: "black",
		fontSize: 17,
	},
	inputAndroid: {
		borderColor: "grey",
		color: "black",
		borderBottomWidth: 1,

		// borderWidth: 1,
		paddingVertical: 2,
		paddingHorizontal: 5,
		paddingRight: 30,
		fontSize: 17,
	},
});
