import React, { useState, useEffect, useContext } from "react";
import {
	ScrollView,
	StyleSheet,
	Text,
	View,
	Linking,
	Image,
	Platform,
} from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { FontAwesome5, Feather, Entypo, Ionicons } from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import axios from "axios";
import openMap from "react-native-open-maps";
// import Toast from "react-native-root-toast";
import { useToast } from "react-native-toast-notifications";

import { v4 as uuidv4 } from "uuid";
import StepIndicator from "react-native-step-indicator";
import { Button, Header } from "react-native-elements";
import {
  SERVER_API_URL,
	API_URL_ORDER,
	API_URL_CUSTOMER,
} from "../config/config";
import { UserContext } from "../src/context/userContext";

const OrderDetails = ({ route, navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	const {
		customerId,
		orderId,
		orderStatus,
		orderNo,
		orderDate,
		orderType,
		scheduledDate,
		apt,
		deliveryAddress,
		street2,
		city,
		zip,
		latitude,
		long,
		itemId,
		locationId,
	} = route.params;
	const stateVal = route.params.state;

	const [orderStatusValue, setOrderStatusValue] = useState(
		orderStatus ? orderStatus : ""
	);
	console.log(orderStatus);
	const [toastVisible, setToastVisible] = React.useState(false);
	const [toastMessage, setToastMessage] = React.useState("");
	const [customerDetails, setCustomerDetails] = React.useState([]);
	const [itemSize, setItemSize] = useState([]);
	const [itemName, setItemName] = useState([]);
	const [itemPrice, setItemPrice] = useState([]);
	const [itemQty, setItemQty] = useState([]);
	const [itemTotal, setItemTotal] = useState([]);
	const [itemTax, setItemTax] = useState(0);
	const [total, setTotal] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [extra, setExtra] = useState([]);
	const [address, setAddress] = useState("");
	const [lat, setLat] = useState("");
	const [lng, setLng] = useState("");
	const [subTotal, setSubTotal] = useState(0);
	const [deliveryFee, setDeliveryFee] = useState(0);
	const [deliveryProvider, setDeliveryProvider] = useState("");
	const [deliveryTracking, setDeliveryTracking] = useState("");
	const [currentPosition, setCurrentPosition] = useState(0);
	const [deliveryPersonName, setDeliveryPersonName] = useState("");
	const [deliveryPersonPhone, setDeliveryPersonPhone] = useState("");
	const [locationTrackingLink, setLocationTrackingLink] = useState("");
	let itemNames = [];
	let itemPrices = [];
	let itemQtys = [];
	let itemSizes = [];
	let itemExtra = [];
	let itemsTotal = [];

	const deliveryStepLabelData = {
		data: [
			{
				title: "Order Placed",
				status: "Your order has been placed",
			},
			{
				title: "Order Accepted",
				status: "Your order has been accepted",
			},
			{
				title: "Processing",
				status: "Your order is being prepared",
			},
			{
				title: "Ready for Delivery",
				status: "Your order is ready for delivery",
			},
			{
				title: "Out for Delivery",
				status: "Your order is out for delivery",
			},
			{
				title: "Delivered",
				status: "Your order has been delivered",
			},
		],
	};

	const pickupStepLabelData = {
		data: [
			{
				title: "Order Placed",
				status: "Your order has been placed",
			},
			{
				title: "Order Accepted",
				status: "Your order has been accepted",
			},
			{
				title: "Processing",
				status: "Your order is being prepared",
			},
			{
				title: "Ready for Pickup",
				status: "Your order is ready for pickup",
			},
			{
				title: "Completed",
				status: "Your order has been picked up",
			},
		],
	};
	const customStyles = {
		stepIndicatorSize: 30,
		currentStepIndicatorSize: 40,
		separatorStrokeWidth: 3,
		currentStepStrokeWidth: 5,
		separatorFinishedColor: "#4aae4f",
		separatorUnFinishedColor: "#a4d4a5",
		stepIndicatorFinishedColor: "#4aae4f",
		stepIndicatorUnFinishedColor: "#a4d4a5",
		stepIndicatorCurrentColor: "#ffffff",
		stepIndicatorLabelFontSize: 15,
		currentStepIndicatorLabelFontSize: 15,
		stepIndicatorLabelCurrentColor: "#000000",
		stepIndicatorLabelFinishedColor: "#ffffff",
		stepIndicatorLabelUnFinishedColor: "rgba(255,255,255,0.5)",
		labelColor: "#666666",
		labelSize: 11,
		currentStepLabelColor: "#4aae4f",
	};

	async function updateOrderApi(orderStatusVal) {
		console.log("runnn");
		let orderStatusValue =
			orderStatusVal == "cancelled" ? "cancelled" : orderStatusVal;
		console.log("orderStatusValue", orderStatusValue);
		await axios
			.patch(
				`${SERVER_API_URL}${API_URL_ORDER}/${orderId}`,
				{
					orderStatus: orderStatusValue,
					deliveryProvider: deliveryProvider,
					deliveryTracking: deliveryTracking,
				},
				{
					headers: {
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => {
				console.log("After patch is", res.data);
				setToastVisible(true);

				setToastMessage("Success!!");
				setTimeout(() => {
					setToastVisible(false);
				}, 1000);
			})
			.catch((err) => {
				console.log(err);
				setToastVisible(true);
				setToastMessage("Failed try again!!!!");
				setTimeout(() => {
					setToastVisible(false);
				}, 1000);
			});
	}

	async function getCustomerDetails() {
		try {
			const response = await axios.get(
				`${SERVER_API_URL}${API_URL_CUSTOMER}/${customerId}`
			);
			setCustomerDetails(response.data);
		} catch (error) {
			console.error(error);
		}
	}
	async function getOrderDetails() {
		try {
			const response = await axios.get(
				`${SERVER_API_URL}${API_URL_ORDER}/${orderId}`
			);
			console.log("data is ", response.data);
			setDeliveryPersonName(
				response.data?.deliveryPersonName
					? response.data?.deliveryPersonName
					: "TBD"
			);
			setDeliveryPersonPhone(
				response.data.deliveryPersonContact
					? response.data.deliveryPersonContact
					: "+91-8754494846"
			);
			console.log(response.data.deliveryTracking);
			setLocationTrackingLink(
				`https://www.dunzo.com/tracking/${response.data.deliveryTracking}`
			);
			setOrderStatusValue(response.data.orderStatus);
			console.log(orderStatusValue, response.data.orderStatus);
			if (orderStatusValue.toLowerCase() == "pending") {
				setCurrentPosition(0);
			} else if (response.data.orderStatus.toLowerCase() == "accepted") {
				setCurrentPosition(1);
			} else if (response.data.orderStatus.toLowerCase() == "processing") {
				setCurrentPosition(2);
			} else if (response.data.orderStatus.toLowerCase() == "ready") {
				setCurrentPosition(3);
			} else if (response.data.orderStatus.toLowerCase() == "outfordelivery") {
				setCurrentPosition(4);
			} else if (response.data.orderStatus.toLowerCase() == "delivered") {
				setCurrentPosition(5);
			}

			setAddress(response.data.deliveryAddress);
			setLat(response.data.deliveryPoint?.lat);
			setLng(response.data.deliveryPoint?.long);
			let itemsarr = response.data.items;
			console.log(itemsarr.length);
			Object.keys(itemsarr).forEach(function (key) {
				itemNames.push(itemsarr[key]?.itemName);
				itemPrices.push(itemsarr[key]?.itemPrice);
				itemQtys.push(itemsarr[key]?.itemQty);
				itemSizes.push(itemsarr[key]?.options[key]?.name);
				itemExtra.push(itemsarr[key]?.options[key]?.price);
				itemsTotal.push(itemsarr[key]?.itemTotal);
				// console.log(itemTotal);
				setItemName(itemNames);
				setItemPrice(itemPrices);
				setItemQty(itemQtys);
				setItemSize(itemSizes);
				setExtra(itemExtra);
				setItemTotal(itemsTotal);
			});

			setTotal(response.data.total);
			setItemTax(response.data.tax);
			console.log("provider is ", response.data.deliveryProvider);
			setDeliveryProvider(response.data.deliveryProvider);
			setDeliveryTracking(response.data.deliveryTracking);
			setSubTotal(response.data.subTotal);
			setDeliveryFee(response.data.deliveryFee);
			setDiscount(response.data.discount);
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		getCustomerDetails(), getOrderDetails();
	}, [customerId, orderId]);

	return (
		<>
			{toastVisible &&
				toast.show(toastMessage, {
					type: "success",
					placement: "top",
					duration: 2000,
					offset: 30,
					animationType: "slide-in",
				})}

			<View style={styles.main}>
				<View style={styles.header}>
					<TouchableOpacity
						style={styles.cancelButton}
						onPress={() => updateOrderApi("cancelled")}>
						<Text style={{ color: "red" }}>Cancel Order</Text>
					</TouchableOpacity>

					<RNPickerSelect
						fixAndroidTouchableBug={true}
						style={pickerSelectStyles}
						useNativeAndroidPickerStyle={false}
						value={orderStatusValue}
						items={[
							{ label: "Pending", value: "pending" },
							{ label: "Accepted", value: "accepted" },
							{ label: "Processing", value: "processing" },
							{ label: "Ready", value: "ready" },
							{ label: "Out for delivery", value: "outfordelivery" },
							{ label: "Delivered", value: "delivered" },
							{ label: "Cancelled", value: "cancelled" },
						]}
						onValueChange={(value) => {
							console.log("valuee is changed", value);
							setOrderStatusValue(value);
							updateOrderApi(value);
						}}
					/>
				</View>
				<ScrollView contentContainerStyle={{ paddingBottom: 200 }}>
					<View style={styles.orderDetailsCard}>
						<Text
							style={{
								textAlign: "center",
								fontWeight: "bold",
								fontSize: 25,
								marginBottom: 10,
							}}>
							Order Details
						</Text>
						<View>
							<View style={styles.orderDetails}>
								<Text style={styles.orderDetailsHeading}>Order id</Text>
								<Text style={{ width: 150, textAlign: "right" }}>
									{orderId}
								</Text>
							</View>
							<View style={styles.orderDetails}>
								<Text style={styles.orderDetailsHeading}>Order no.</Text>
								<Text>{orderNo}</Text>
							</View>

							<View style={styles.orderDetails}>
								<Text style={styles.orderDetailsHeading}>Order Date</Text>
								<Text>{orderDate}</Text>
							</View>
							<View style={styles.orderDetails}>
								<Text style={styles.orderDetailsHeading}>Order Type</Text>
								<Text>{orderType}</Text>
							</View>
							<View style={styles.orderDetails}>
								<Text style={styles.orderDetailsHeading}>Scheduled Date</Text>
								<Text>{scheduledDate}</Text>
							</View>
						</View>
					</View>
					<View style={styles.orderDetailsCard}>
						<Text
							style={{
								textAlign: "center",
								fontWeight: "bold",
								fontSize: 25,
								marginBottom: 10,
							}}>
							Order Summary
						</Text>
						<View>
							<View style={styles.orderDetails}>
								<Text style={styles.orderSummaryHeading}>Total</Text>
								<Text>₹ {total.toFixed(2)}</Text>
							</View>
							<View style={styles.orderDetails}>
								<Text style={styles.orderSummaryHeading}>Sub Total</Text>
								<Text>₹ {subTotal.toFixed(2)}</Text>
							</View>

							<View style={styles.orderDetails}>
								<Text style={styles.orderSummaryHeading}>Delivery Fee</Text>
								<Text>₹ {deliveryFee.toFixed(2)}</Text>
							</View>
							<View style={styles.orderDetails}>
								<Text style={styles.orderSummaryHeading}>Tax</Text>
								<Text>₹ {itemTax.toFixed(2)}</Text>
							</View>
							<View style={styles.orderDetails}>
								<Text style={styles.orderSummaryHeading}>Discount</Text>
								<Text>₹ {discount.toFixed(2)}</Text>
							</View>
						</View>
					</View>

					<View style={styles.orderDetailsCard}>
						<Text
							style={{
								textAlign: "center",
								fontWeight: "bold",
								fontSize: 25,
								marginBottom: 10,
							}}>
							Delivery
						</Text>

						<Image
							style={{ height: 100, width: 100 }}
							source={require("../assets/avatar.png")}
						/>
						<View>
							<View style={styles.orderDetails}>
								<Text style={styles.orderSummaryHeading}>
									Delivery Provider
								</Text>
								<Text>
									{deliveryProvider == undefined ? "none" : deliveryProvider}
								</Text>
							</View>

							<View style={styles.orderDetails}>
								<Text style={styles.orderSummaryHeading}>
									Delivery Tracking
								</Text>
								<Text
									style={{
										width: deliveryTracking == undefined ? 0 : 100,
										// fontSize: 12,
										fontSize: deliveryTracking == undefined ? 15 : 12,
									}}>
									{deliveryTracking == undefined ? "none" : deliveryTracking}
								</Text>
							</View>
							<View style={styles.orderDetails}>
								<Text style={styles.orderSummaryHeading}>Person name</Text>
								<Text>{deliveryPersonName}</Text>
							</View>
							<View style={styles.orderDetails}>
								<Text style={styles.orderSummaryHeading}>Phone</Text>

								<TouchableOpacity
									style={{ flexDirection: "row", alignItems: "center" }}
									onPress={() => Linking.openURL(`tel:${deliveryPersonPhone}`)}>
									<Feather name='phone' size={24} color='black' />
									<Text style={{ marginLeft: 10 }}>{deliveryPersonPhone}</Text>
								</TouchableOpacity>

								{/* <Text>{deliveryPersonPhone}</Text> */}
							</View>
							{/* 
							{deliveryProvider?.toLowerCase() == "dunzo"&& deliveryTracking?.toLowerCase()!=="" ?(
								<View style={styles.orderDetails}>
									<Text style={styles.orderSummaryHeading}>
										Location Tracking Link
									</Text>

									<Entypo
										onPress={() => Linking.openURL(locationTrackingLink)}
										name='location'
										size={24}
										color='black'
									/>
								</View>
							):(<></>)
							
							)} */}

							{deliveryProvider?.toLowerCase() == "dunzo" &&
							deliveryTracking?.toLowerCase() !== undefined ? (
								<>
									<View style={styles.orderDetails}>
										<Text style={styles.orderSummaryHeading}>
											Location Tracking Link
										</Text>

										<Entypo
											onPress={() => Linking.openURL(locationTrackingLink)}
											name='location'
											size={24}
											color='black'
										/>
									</View>
								</>
							) : (
								<></>
							)}
						</View>
					</View>
					<View style={styles.orderDetailsCard}>
						<Text
							style={{
								textAlign: "center",
								marginVertical: 10,
								fontWeight: "bold",
								fontSize: 25,
								marginBottom: 10,
							}}>
							{customerDetails.name
								? customerDetails.name
								: customerDetails.firstName}
						</Text>
						<TouchableOpacity style={styles.cancelButton}>
							<Text style={{ color: "red" }}>Customer</Text>
						</TouchableOpacity>

						<View style={styles.details}>
							<TouchableOpacity
								style={styles.flex}
								onPress={() =>
									Linking.openURL(`tel:${customerDetails.phoneNumber}`)
								}>
								<Feather name='phone' size={24} color='black' />
								<Text style={{ marginLeft: 10 }}>
									{customerDetails.phoneNumber}
								</Text>
							</TouchableOpacity>
							<TouchableOpacity
								style={styles.flex}
								onPress={() => {
									openMap({
										end: ` ${lat}, ${lng}`,
										travelType: "drive",
									});
								}}>
								<Ionicons name='location' size={24} color='black' />
								<Text style={{ marginLeft: 10 }}>{address}</Text>
							</TouchableOpacity>
						</View>
					</View>

					<View style={styles.orderItem}>
						{/* <Text>{itemName}</Text> */}

						<View style={styles.itemHeader}>
							<Text style={{ color: "white", fontSize: 20 }}>Items</Text>
						</View>
						<View style={{ paddingVertical: 20, flexDirection: "row" }}>
							<View style={{ padding: 10, maxWidth: 250, marginRight: 0 }}>
								<Text style={styles.itemTitle}>Name</Text>

								{itemName.map((item) => (
									<Text style={styles.itemValue}>{item}</Text>
								))}
							</View>
							<View style={{ padding: 10, maxWidth: 250, marginRight: 0 }}>
								<Text style={styles.itemTitle}>Price</Text>

								{itemPrice.map((item) => (
									<Text style={styles.itemValue}>₹ {item.toFixed(2)}</Text>
								))}
							</View>
							<View style={{ padding: 10, maxWidth: 250, marginRight: 0 }}>
								<Text style={styles.itemTitle}>Qty</Text>
								{itemQty.map((item) => (
									<Text style={styles.itemValue}>{item}</Text>
								))}
							</View>
							{/* <View style={{ padding: 10, maxWidth: 250, marginRight: 20 }}>
						<Text style={styles.itemTitle}>Size</Text>
						{itemSize.map((item) => (
							<Text style={styles.itemValue}>{item}</Text>
						))}
					</View> */}
							<View
								style={{
									padding: 10,
									maxWidth: 250,
									marginRight: 20,
								}}>
								<Text style={styles.itemTitle}>Item total</Text>
								{itemTotal.map((item) => (
									<Text style={styles.itemValue}>₹ {item.toFixed(2)}</Text>
								))}
							</View>
							{/* <View style={{ padding: 10, maxWidth: 250, marginRight: 20 }}>
								<Text style={styles.itemTitle}>Discount</Text>
								<Text
									style={{
										fontSize: 20,
										fontWeight: "bold",
										paddingHorizontal: 0,
									}}>
									₹{discount}
								</Text>
							</View> */}
							{/* <View style={{ padding: 10, maxWidth: 250, marginRight: 20 }}>
								<Text style={styles.itemTitle}>Tax</Text>
								<Text
									style={{
										fontSize: 20,
										fontWeight: "bold",
										paddingHorizontal: 0,
									}}>
									₹{itemTax}
								</Text>
							</View> */}
							{/* <View style={{ padding: 10, maxWidth: 250, marginRight: 20 }}>
								<Text style={styles.itemTitle}>Total Amount</Text>
								<Text
									style={{
										fontSize: 20,
										fontWeight: "bold",
										paddingHorizontal: 0,
									}}>
									₹{total}
								</Text>
							</View> */}
						</View>
					</View>

					{orderType.toLowerCase() == "delivery" ? (
						<View style={styles.stepIndicator}>
							<Text
								style={{
									textAlign: "center",
									marginVertical: 10,
									fontWeight: "bold",
									fontSize: 25,
									marginBottom: 10,
								}}>
								Order status timeline
							</Text>
							<StepIndicator
								customStyles={customStyles}
								currentPosition={currentPosition}
								stepCount={6}
								labels={deliveryStepLabelData.data.map((label) => label.title)}
							/>
						</View>
					) : (
						<View style={styles.stepIndicator}>
							<Text
								style={{
									textAlign: "center",
									marginVertical: 10,
									fontWeight: "bold",
									fontSize: 25,
									marginBottom: 10,
								}}>
								Order status timeline
							</Text>
							<StepIndicator
								customStyles={customStyles}
								currentPosition={currentPosition}
								labels={pickupStepLabelData.data.map((label) => label.title)}
							/>
						</View>
					)}
				</ScrollView>
			</View>
		</>
	);
};

export default OrderDetails;

const styles = StyleSheet.create({
	main: {
		// backgroundColor: "white",
	},
	header: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		paddingHorizontal: 50,
		paddingVertical: 10,
		flexDirection: "row",
	},
	cancelButton: {
		// backgroundColor: "green",
		paddingVertical: 15,
		paddingHorizontal: 24,
		borderColor: "red",
		borderWidth: 1,
	},
	flex: {
		marginVertical: 10,
		flexDirection: "row",
		alignItems: "center",
	},
	orderDetailsCard: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: 40,
		paddingVertical: 30,
		margin: 20,
		backgroundColor: "#fff",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.36,
		shadowRadius: 6.68,

		elevation: 11,
	},
	stepIndicator: {
		marginVertical: 50,
		backgroundColor: "#fff",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.36,
		shadowRadius: 6.68,
		paddingVertical: 20,
		elevation: 11,
		marginHorizontal: 10,
	},
	itemHeader: {
		backgroundColor: "orange",
		padding: 20,
	},
	orderItem: {
		margin: 20,
		backgroundColor: "#fff",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.36,
		shadowRadius: 6.68,

		elevation: 11,
	},
	itemTitle: {
		fontSize: 17,
		marginBottom: 10,
		paddingHorizontal: 0,
	},
	itemValue: {
		fontSize: 14,
		fontWeight: "bold",
		paddingHorizontal: 0,
		marginBottom: 10,
	},
	orderDetails: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginVertical: 6,
	},
	orderDetailsHeading: {
		fontWeight: "bold",
		marginRight: 15,
		fontSize: 15,
	},
	orderSummaryHeading: {
		fontWeight: "bold",
		marginRight: 90,
		fontSize: 15,
	},
});
const pickerSelectStyles = StyleSheet.create({
	inputIOS: {
		fontSize: 12,
		marginHorizontal: 5,
		borderWidth: 1,
		borderRadius: 4,
		backgroundColor: "green",

		color: "white",
		paddingRight: 30, // to ensure the text is never behind the icon
		padding: 5,
		paddingVertical: 15,
		paddingHorizontal: 20,
	},
	inputAndroid: {
		fontSize: 15,
		marginHorizontal: 5,
		backgroundColor: "green",
		borderWidth: 0.5,
		borderColor: "black",
		borderRadius: 8,
		color: "white",

		padding: 5, // to ensure the text is never behind the icon
		paddingVertical: 15,
		paddingHorizontal: 20,
	},
});
