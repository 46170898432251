import {
	Platform,
	ScrollView,
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
} from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../src/context/userContext";
import RNPickerSelect from "react-native-picker-select";
import {
	API_URL_CLIENT,
	API_URL_LOCATIONS,
	API_URL_RESTAURANT,
	SERVER_API_URL,
} from "../config/config";
import axios from "axios";
import { ActivityIndicator, RadioButton, TextInput } from "react-native-paper";
import { CheckBox } from "react-native-elements";
import RangeSlider, { Slider } from "react-native-range-slider-expo";

const ZoneBasedDelivery = () => {
	const { state, dispatch } = useContext(UserContext);
	const assignedClient = state.user.attributes["custom:client"];
	const [location, setLocation] = useState("");
	const [locations, setLocations] = useState([]);
	const [loactionData, setLocationData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [restaurantId, setRestaurantId] = useState("");
	const [pinCode, setPinCode] = useState("");
	const [checked, setChecked] = useState("first");
	const [show, setShow] = useState(false);
	const [fromValue, setFromValue] = useState(0);
	const [toValue, setToValue] = useState(0);
	const [value, setValue] = useState(0);
	const [cities, setCities] = useState([]);
	const [cityName, setCityName] = useState("");
	const [states, setStates] = useState([]);
	const [stateName, setStateName] = useState("");
	const [pinCodes, setPinCodes] = useState([]);
	const [pinCodeValue, setPinCodeValue] = useState("");
	const [deliveryCharge, setDeliveryCharge] = useState("");
	const [deliveryChargeError, setDeliveryChargeError] = useState(false);
	const restructureLocation = (item) => {
		let labels = [];
		if (item.length) {
			for (let i = 0; i < item.length; i++) {
				labels[i] = { label: item[i].name, value: item[i].id };
			}
		}

		setLocations(labels);
		setLocation(labels[0].value);
		// console.log("labels=", labels)

		//  console.log("labels=", locations)
	};
	const getRestaurants = async () => {
		setLoading(true);
		await axios
			.get(`${SERVER_API_URL}${API_URL_RESTAURANT}${API_URL_CLIENT}`, {
				headers: {
					client: assignedClient,
				},
			})
			.then((res) => {
				// console.log("data is ", res.data[0].id);
				setRestaurantId(res.data[0].id);
				loadlocations(res.data[0].id);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};
	const loadlocations = async (value) => {
		await axios
			.get(`${SERVER_API_URL}${API_URL_LOCATIONS}`, {
				headers: {
					restaurantGroup: value,
				},
			})
			.then((res) => {
				// console.log("data=", res.data[0].address.zip);
				restructureLocation(res.data);
				setPinCode(res.data[0].address.zip);
				setLocationData(res.data);
			})
			.catch((err) => {
				console.log("err3=", err);
				setLocations([]);
			});
	};
	useEffect(() => {
		if (loactionData == []) {
			return;
		} else {
			console.log("locationData=", location);
			let pinCode = loactionData.filter((elem) => elem.id == location)[0]
				?.address?.zip;
			console.log("Pincode is ", pinCode);
			setPinCode(pinCode);
		}
	}, [location]);
	useEffect(() => {
		getRestaurants();
	}, []);
	const createZone = async () => {
		console.log("checked is ", checked);
		let data = {
			zoneType: checked,
			zoneValue:
				checked == "Distance"
					? `${fromValue} to ${toValue}`
					: checked == "City"
					? cities
					: checked == "State"
					? states
					: checked == "Pin Codes"
					? pinCodes
					: "",
			deliveryCharge: Number(deliveryCharge),
		};
		console.log(data);
		try {
			const res = await axios.post(`${SERVER_API_URL}/create-zone`, data, {
				headers: {
					locationId: location,
				},
			});
			console.log("res=", res);
		} catch (error) {
			console.log("error is ", error);
		}
	};
	return (
		<ScrollView style={{ paddingVertical: 10, paddingBottom: 50 }}>
			{loading ? (
				<ActivityIndicator size='large' color='#00ff00' />
			) : (
				<>
					<RNPickerSelect
						fixAndroidTouchableBug={true}
						style={pickerSelectStyles}
						useNativeAndroidPickerStyle={false}
						items={locations}
						value={location}
						placeholder={{
							label: "Enter your location",
							value: "",
						}}
						onValueChange={(value) => {
							setLocation(value);
							console.log(" value is", value);
						}}
					/>
					{location !== "" ? (
						<View
							style={{
								padding: 10,
							}}>
							<Text style={{ fontSize: 15 }}>
								Your store Pincode:{" "}
								<Text style={{ fontWeight: "700" }}>{pinCode}</Text>
							</Text>

							<View style={styles.zoneCard}>
								<View style={{ flexDirection: "row" }}>
									<Text style={{ marginRight: 10, fontWeight: "bold" }}>
										Zone 1
									</Text>
									<Text>Distance</Text>
								</View>
								<View style={styles.flex}>
									<Text style={styles.bold}>Range:</Text>
									<Text>Upto 5 km</Text>
								</View>
								<View style={styles.flex}>
									<Text style={styles.bold}>Delivery Charge:</Text>
									<Text>₹ 40</Text>
								</View>
							</View>
							<View style={styles.zoneCard}>
								<View style={{ flexDirection: "row" }}>
									<Text style={{ marginRight: 10, fontWeight: "bold" }}>
										Zone 1
									</Text>
									<Text>Distance</Text>
								</View>
								<View style={styles.flex}>
									<Text style={styles.bold}>Range:</Text>
									<Text>Upto 5 km</Text>
								</View>
								<View style={styles.flex}>
									<Text style={styles.bold}>Delivery Charge:</Text>
									<Text>₹ 40</Text>
								</View>
							</View>

							<TouchableOpacity
								onPress={() => setShow(true)}
								style={{
									padding: 10,
									marginTop: 20,
									borderRadius: 7,
									borderWidth: 1,
									borderColor: "grey",
									width: "100%",
								}}>
								<Text
									style={{
										textAlign: "center",
										fontSize: 14,
										fontWeight: "bold",
									}}>
									Create Zone
								</Text>
							</TouchableOpacity>
							{show && (
								<View
									style={{
										flexDirection: "row",
										flexWrap: "wrap",
										marginVertical: 20,
									}}>
									<CheckBox
										onPress={() => {
											setChecked("Distance");
										}}
										center
										checked={checked == "Distance" ? true : false}
										title='Distance'
										checkedIcon='dot-circle-o'
										uncheckedIcon='circle-o'
										containerStyle={{
											borderColor: "lightgrey",
											backgroundColor: "none",
										}}
									/>

									<CheckBox
										onPress={() => {
											setChecked("City");
										}}
										center
										checked={checked == "City" ? true : false}
										title='City'
										checkedIcon='dot-circle-o'
										uncheckedIcon='circle-o'
										containerStyle={{
											borderColor: "lightgrey",
											backgroundColor: "none",
										}}
									/>
									<CheckBox
										onPress={() => {
											setChecked("State");
										}}
										center
										checked={checked == "State" ? true : false}
										title='State'
										checkedIcon='dot-circle-o'
										uncheckedIcon='circle-o'
										containerStyle={{
											borderColor: "lightgrey",
											backgroundColor: "none",
										}}
									/>
									<CheckBox
										onPress={() => {
											setChecked("Pin Codes");
										}}
										center
										checked={checked == "Pin Codes" ? true : false}
										title='Pin Codes'
										checkedIcon='dot-circle-o'
										uncheckedIcon='circle-o'
										containerStyle={{
											borderColor: "lightgrey",
											backgroundColor: "none",
										}}
									/>
								</View>
							)}

							{checked == "Distance" && (
								<>
									<View>
										{
											Platform.OS !== "web" ? (
												<RangeSlider
													min={0}
													max={30}
													fromValueOnChange={(value) => setFromValue(value)}
													toValueOnChange={(value) => setToValue(value)}
													initialFromValue={0}
													// step={5}
												/>
											) : (
												<View
													style={{
														flexDirection: "row",
														justifyContent: "space-between",
														marginVertical: 10,
													}}>
													<View>
														<Text style={{ fontSize: 14, fontWeight: "bold" }}>
															From
														</Text>
														<input
															min={0}
															type='number'
															value={fromValue}
															onChange={(e) => setFromValue(e.target.value)}
														/>
													</View>
													<View>
														<Text style={{ fontSize: 14, fontWeight: "bold" }}>
															To
														</Text>
														<input
															max={30}
															type='number'
															value={toValue}
															onChange={(e) => setToValue(e.target.value)}
														/>
													</View>
												</View>
											)

											// <input type="range" min={0} max={30} value={fromValue} onChange={(e) => setFromValue(e.target.value)} />
										}
									</View>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "space-between",
										}}>
										<Text>from value: {fromValue}</Text>
										<Text>to value: {toValue}</Text>
									</View>
								</>
							)}
							{checked == "City" && (
								<>
									<View
										style={{
											flexDirection: "row",
											justifyContent: "center",
											alignItems: "center",
										}}>
										<TextInput
											style={styles.textInput}
											underlineColorAndroid='transparent'
											label='City'
											autoCapitalize='none'
											onChangeText={(value) => {
												setCityName(value);
											}}
											value={cityName}
											theme={{ colors: { primary: "#136eb4" } }}
										/>
										<TouchableOpacity
											disabled={cityName == "" ? true : false}
											style={{
												...styles.submitButton,
												backgroundColor: cityName == "" ? "#d3d3d3" : "#136eb4",
											}}
											onPress={() => {
												setCities([...cities, cityName]);
												setCityName("");
											}}>
											<Text style={{ color: "white" }}>Submit</Text>
										</TouchableOpacity>
									</View>
									<View
										style={{
											flexDirection: "row",
											flexWrap: "wrap",
											marginVertical: 10,
										}}>
										{cities.map((city, idx) => (
											<>
												<Text
													key={idx}
													style={{
														color: "black",
														fontSize: 16,
														textTransform: "capitalize",
													}}>
													{" "}
													{city},
												</Text>
											</>
										))}
									</View>
								</>
							)}

							{checked == "State" && (
								<>
									<View
										style={{
											flexDirection: "row",
											justifyContent: "center",
											alignItems: "center",
										}}>
										<TextInput
											style={styles.textInput}
											underlineColorAndroid='transparent'
											label='State'
											autoCapitalize='none'
											onChangeText={(value) => {
												setStateName(value);
											}}
											value={stateName}
											theme={{ colors: { primary: "#136eb4" } }}
										/>
										<TouchableOpacity
											disabled={stateName == "" ? true : false}
											style={{
												...styles.submitButton,
												backgroundColor:
													stateName == "" ? "#d3d3d3" : "#136eb4",
											}}
											onPress={() => {
												setStates([...states, stateName]);
												setStateName("");
											}}>
											<Text style={{ color: "white" }}>Submit</Text>
										</TouchableOpacity>
									</View>
									<View
										style={{
											flexDirection: "row",
											flexWrap: "wrap",
											marginVertical: 10,
										}}>
										{states.map((state, idx) => (
											<>
												<Text
													key={idx}
													style={{
														color: "black",
														fontSize: 16,
														textTransform: "capitalize",
													}}>
													{" "}
													{state},
												</Text>
											</>
										))}
									</View>
								</>
							)}

							{checked == "Pin Codes" && (
								<>
									<View
										style={{
											flexDirection: "row",
											justifyContent: "center",
											alignItems: "center",
										}}>
										<TextInput
											style={styles.textInput}
											underlineColorAndroid='transparent'
											label='Pin Codes'
											autoCapitalize='none'
											keyboardType='numeric'
											onChangeText={(value) => {
												setPinCodeValue(value);
											}}
											value={pinCodeValue}
											theme={{ colors: { primary: "#136eb4" } }}
										/>
										<TouchableOpacity
											disabled={pinCodeValue == "" ? true : false}
											style={{
												...styles.submitButton,
												backgroundColor:
													pinCodeValue == "" ? "#d3d3d3" : "#136eb4",
											}}
											onPress={() => {
												setPinCodes([...pinCodes, pinCodeValue]);
												setPinCodeValue("");
											}}>
											<Text style={{ color: "white" }}>Submit</Text>
										</TouchableOpacity>
									</View>
									<View
										style={{
											flexDirection: "row",
											flexWrap: "wrap",
											marginVertical: 10,
										}}>
										{pinCodes.map((pincode, idx) => (
											<>
												<Text
													key={idx}
													style={{
														color: "black",
														fontSize: 16,
														textTransform: "capitalize",
													}}>
													{" "}
													{pincode},
												</Text>
											</>
										))}
									</View>
								</>
							)}
							{console.log(
								"states length",
								show,
								show &&
									states.length > 0 &&
									cities.length > 0 &&
									pinCodes.length > 0 &&
									fromValue !== "" &&
									toValue !== ""
							)}
							{show && (
								<View
									style={{
										justifyContent: "center",
										alignItems: "center",
										marginVertical: 5,
									}}>
									<TouchableOpacity
										onPress={() => {
											setCities([]);
											setStates([]);
											setPinCodes([]);
										}}
										style={{
											backgroundColor: "#136eb4",
											borderRadius: 8,
											padding: 10,
										}}>
										<Text
											style={{
												color: "white",
												textAlign: "center",
												// textTransform: "uppercase",
											}}>
											Clear all values
										</Text>
									</TouchableOpacity>
								</View>
							)}
							{show && (
								<View style={{ marginVertical: 10 }}>
									{deliveryChargeError && (
										<Text
											style={{
												color: "red",
												marginLeft: 5,
											}}>
											Delivery Charge is mandatory
										</Text>
									)}
									<TextInput
										style={styles.textInput}
										underlineColorAndroid='transparent'
										label='Delivery Charge'
										autoCapitalize='none'
										keyboardType='numeric'
										onChangeText={(value) => {
											setDeliveryCharge(value);
											if (value == "") {
												setDeliveryChargeError(true);
											} else {
												setDeliveryChargeError(false);
											}
										}}
										value={deliveryCharge}
										theme={{ colors: { primary: "#136eb4" } }}
									/>
									<TouchableOpacity
										disabled={deliveryChargeError || deliveryCharge == ""}
										onPress={() => createZone()}
										style={{
											...styles.submitButton,
											backgroundColor:
												deliveryChargeError || deliveryCharge == ""
													? "#d3d3d3"
													: "#136eb4",
											// borderRadius: 5,
											// padding: 10,
											// marginTop: 10,
											// paddingVertical: 15,
										}}>
										<Text
											style={{
												color: "white",
												textAlign: "center",
												textTransform: "uppercase",
											}}>
											Create
										</Text>
									</TouchableOpacity>
								</View>
							)}
						</View>
					) : (
						<></>
					)}
				</>
			)}
		</ScrollView>
	);
};

export default ZoneBasedDelivery;

const styles = StyleSheet.create({
	flex: {
		justifyContent: "space-between",
		flexDirection: "row",
		marginVertical: 5,
	},
	bold: {
		fontWeight: "bold",
	},
	zoneCard: {
		backgroundColor: "white",
		borderColor: "lightgrey",
		borderWidth: 1,
		padding: 10,
		borderRadius: 8,
		marginVertical: 10,
	},
	submitButton: {
		borderRadius: 5,
		padding: 10,
		marginTop: 10,
		paddingVertical: 15,
	},
	textInput: {
		flexGrow: 1,
		marginRight: 10,
		borderRadius: 8,
		borderColor: "black",
	},
});
const pickerSelectStyles = StyleSheet.create({
	inputIOS: {
		fontSize: 12,
		marginHorizontal: 5,
		borderWidth: 1,
		borderColor: "gray",
		borderRadius: 4,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon
		padding: 5,
		marginVertical: 10,
	},
	inputAndroid: {
		fontSize: 12,
		marginHorizontal: 5,
		borderWidth: 0.5,
		borderColor: "black",
		borderRadius: 8,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon

		padding: 5, // to ensure the text is never behind the icon
		marginVertical: 10,
	},
});
