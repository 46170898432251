import React, { useState, useEffect, useContext } from "react";
import {
	ScrollView,
	StyleSheet,
	Text,
	View,
	StatusBar,
	TextInput,
	TouchableOpacity,
	SafeAreaView,
	Platform,
} from "react-native";
import { Formik } from "formik";
import RNPickerSelect from "react-native-picker-select";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import moment from "moment";
import * as yup from "yup";
import { Fontisto } from "@expo/vector-icons";
import axios from "axios";
import { format } from "date-fns";
import { useToast } from "react-native-toast-notifications";

import { createPromotion } from "../functions/Promotions";
import { updatePromotion } from "../functions/Promotions";
import { deletePromotion } from "../functions/Promotions";
import { getRestaurantsList } from "../functions/Promotions";
import { getLocationsList } from "../functions/Promotions";
import { UserContext } from "../src/context/userContext";
import { SERVER_API_URL, API_URL_CLIENT } from "../config/config";

export default function CreatePromotion({ navigation, route }) {
	const toast = useToast();
	console.log("add promotion");
	const { state } = useContext(UserContext);
	const userType = state.userType;
	const assignedClient = state.user.attributes["custom:client"];
	let promotionId = route.params.id;
	const {
		restaurantGroup,
		locationId,
		promotionName,
		code,
		discountAmount,
		discountPercent,
		maxDiscount,
		validFrom,
		validTo,
		id,
	} = route.params;
	console.log("Route params", route.params.id);
	const { from } = route.params;
	const { to } = route.params;
	console.log("From date ======", from, "  to date=====", to);
	// console.log("values", route.params)

	const [displayFrom, setDisplayFrom] = useState(from);
	const [displayTo, setDisplayTo] = useState(to);
	const [fromdate, setFromdate] = useState(validFrom);
	const [todate, setTodate] = useState(validTo);
	const [isValidFrom, setIsValidFrom] = useState(true);
	const [isValidTo, setIsValidTo] = useState(true);
	const [client, updateClient] = React.useState([]);
	const [restructuredClient, updateRestructureClient] = React.useState([]);
	const [selectClient, updateClientSelect] = React.useState(
		assignedClient ? assignedClient : route.params.client
	);
	const [selectRestaurant, updateRestaurantSelect] = React.useState(
		route.params.restaurantGroup
	);
	const [isDatePickerVisibleFrom, setDatePickerVisibilityFrom] =
		useState(false);
	const [isDatePickerVisibleTo, setDatePickerVisibilityTo] = useState(false);
	const [restaurants, setRestaurants] = useState([]);
	const [locations, setLocations] = useState([]);
	useEffect(() => {
		loadRestaurants();
		getClient();
	}, [selectClient]);

	const loadRestaurants = () => {
		getRestaurantsList(selectClient)
			.then((res) => {
				// console.log("restaurant data", res.data);
				restructureRestaurant(res.data);
			})
			.catch((err) => {
				console.log("err", err);
			});
	};

	const restructureClient = (item) => {
		let labels = [];
		for (var i = 0; i < item.length; i++) {
			labels[i] = { label: item[i].clientName, value: item[i].slug };
		}
		// console.log("valiue===", labels[0].value);
		updateRestructureClient(labels);
	};

	const getClient = async () => {
		await axios
			.get(SERVER_API_URL + API_URL_CLIENT, {
				headers: {
					Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				},
			})
			.then((res) => {
				// console.log("client data", res.data);
				updateClient(res.data), restructureClient(res.data);

				loadRestaurants();
			})
			.catch((err) => console.log(err));
	};

	const restructureRestaurant = (item) => {
		let labels = [];
		for (let i = 0; i < item.length; i++) {
			labels[i] = { label: item[i].name, value: item[i].id };
		}
		setRestaurants(labels);

		// console.log("restaurant list= ", restaurants);
	};

	const loadLocations = (value) => {
		getLocationsList(value)
			.then((res) => {
				// console.log("location list=", res.data)
				restructureLocation(res.data);
			})
			.catch((err) => {
				console.log("err", err);
			});
	};

	const restructureLocation = (item) => {
		let labels = [];
		for (let i = 0; i < item.length; i++) {
			labels[i] = { label: item[i].name, value: item[i].id };
		}
		console.log("labels=", labels);
		setLocations(labels);
	};

	const showDatePickerFrom = () => {
		setDatePickerVisibilityFrom(true);
	};

	const hideDatePickerFrom = () => {
		setDatePickerVisibilityFrom(false);
	};

	const handleConfirmFrom = (date) => {
		console.log("Date is", date);
		if (Platform.OS !== "web") {
			setDisplayFrom(
				`${date.getDate()}/${
					date.getMonth() + 1
				}/${date.getFullYear()} ${moment(date).format("hh:mm A")}`
			);

			setFromdate(Math.floor(date.getTime() / 1000.0));
		} else {
			// setDisplayFrom(moment(date).format("do-MM-YYYY:hh:mm"));
			console.log("Date is===", new Date(date).getDate());

			setDisplayFrom(
				`${new Date(date).getDate()}/${
					new Date(date).getMonth() + 1
				}/${new Date(date).getFullYear()} ${moment(new Date(date)).format(
					"hh:mm A"
				)}`
			);
			setFromdate(Math.floor(moment(date).toDate().getTime() / 1000.0));
		}

		hideDatePickerFrom();
		setIsValidFrom(true);
	};
	const showDatePickerTo = () => {
		setDatePickerVisibilityTo(true);
	};

	const hideDatePickerTo = () => {
		setDatePickerVisibilityTo(false);
	};

	const handleConfirmTo = (date) => {
		if (Platform.OS !== "web") {
			setDisplayTo(
				`${date.getDate()}/${
					date.getMonth() + 1
				}/${date.getFullYear()} ${moment(date).format("hh:mm A")}`
			);
			setTodate(Math.floor(date.getTime() / 1000.0));

			hideDatePickerTo();
		} else {
			// setDisplayTo(format(moment(date).toDate(), "MMMM dd yyyy, h:mm:ss a"));
			setDisplayTo(
				`${new Date(date).getDate()}/${
					new Date(date).getMonth() + 1
				}/${new Date(date).getFullYear()} ${moment(new Date(date)).format(
					"hh:mm A"
				)}`
			);
			setTodate(Math.floor(moment(date).toDate().getTime() / 1000.0));
		}

		setIsValidTo(true);
	};

	const handleDelete = (id) => {
		deletePromotion(id, state?.user?.signInUserSession?.idToken["jwtToken"])
			.then((res) => {
				console.log("del data ", res.data);

				// Platform.OS !== "web"
				// 	? toast.show("Promotion has been deleted succesfully", {
				// 			type: "normal",
				// 			placement: "top",
				// 			duration: 2000,
				// 			offset: 30,
				// 			animationType: "slide-in",
				// 	  })
				// 	: alert("Promotion has been deleted succesfully");

				toast.show("Promotion has been deleted succesfully", {
					type: "sucess",
					placement: "top",
					duration: 2000,
					offset: 30,
					animationType: "slide-in",
				});
				navigation.navigate("Promotions");
			})
			.catch((err) => {
				console.log("err", err);
				// Platform.OS !== "web"
				// 	? toast.show("Failed to delete promotion", {
				// 			type: "normal",
				// 			placement: "top",
				// 			duration: 2000,
				// 			offset: 30,
				// 			animationType: "slide-in",
				// 	  })
				// 	: alert("Failed to delete promotion");
				toast.show("Failed to delete promotion", {
					type: "danger",
					placement: "top",
					duration: 2000,
					offset: 30,
					animationType: "slide-in",
				});
			});
	};

	const reviewSchema = yup.object({
		promotionName: yup
			.string()
			.required("Promotion Name is a required field")
			.min(4, "Promotion Name must be atleast 4 characters"),
		code: yup
			.string()
			.required("Promotion Code is a required field")
			.min(4, "Promotion Code must be atleast 4 characters"),
		discountAmount: yup.string().nullable().notRequired(),
		discountPercent: yup.string().nullable().notRequired(),
	});

	return (
		<SafeAreaView style={styles.areaview}>
			<ScrollView style={styles.container}>
				<View style={styles.form}>
					{!promotionId ? (
						<Text style={styles.heading}>CREATE PROMOTION</Text>
					) : (
						<View>
							<Text style={styles.heading}>EDIT PROMOTION</Text>
							<TouchableOpacity
								style={styles.delbutton}
								onPress={() => handleDelete(id)}>
								<Text>DELETE PROMOTION</Text>
							</TouchableOpacity>
						</View>
					)}
					<Formik
						initialValues={{
							ClientName: "",
							restaurantGroup: restaurantGroup,
							locationId: locationId,
							promotionName: promotionName,
							code: code,
							discountAmount: discountAmount,
							discountPercent: discountPercent,
							maxDiscount: maxDiscount,
							validFrom: validFrom,
							validTo: validTo,
						}}
						validationSchema={reviewSchema}
						onSubmit={(values, actions) => {
							if (displayFrom) {
								setIsValidFrom(true);
							} else {
								setIsValidFrom(false);
								return;
							}
							if (displayTo) setIsValidTo(true);
							else {
								setIsValidTo(false);
								return;
							}
							let discAmt = values.discountAmount,
								discPer = values.discountPercent,
								maxDisc = values.maxDiscount;
							console.log(
								"discountAmount",
								discAmt,
								"discountPercent",
								discPer,
								"values",
								values
							);
							if (
								(!discAmt || discAmt === "") &&
								(!discPer || discPer === "")
							) {
								alert("Enter either Discount Amount or Discount Percentage");
								return;
							} else if (discAmt && discAmt !== "" && parseInt(discAmt) <= 0) {
								alert("Enter valid Discount Amount");
								return;
							} else if (
								discPer &&
								discPer !== "" &&
								parseInt(discPer) <= 0 &&
								parseInt(discPer) > 100
							) {
								alert("Enter valid Discount Percentage");
								return;
							} else if (
								discPer &&
								discPer !== "" &&
								maxDisc &&
								maxDisc !== "" &&
								parseInt(maxDisc) <= 0
							) {
								alert("Enter valid Maximum Discount");
								return;
							}
							values.validFrom = fromdate;
							values.validTo = todate;
							//   console.log("Values", values)

							actions.resetForm({
								values: {
									promotionName: "",
									restaurantGroup: "All",
									locationId: "All",
									promotionName: "",
									code: "",
									discountAmount: "",
									discountPercent: "",
									maxDiscount: "",
								},
							});

							setDisplayFrom("");
							setDisplayTo("");
							//   console.log(isValidDate)
							const {
								code,
								restaurantGroup,
								locationId,
								promotionName,
								discountAmount,
								discountPercent,
								maxDiscount,
								validFrom,
								validTo,
							} = values;
							if (id === "" || undefined) {
								let payload = {
									code,
									restaurantGroup,
									locationId,
									promotionName,
									discountAmount,
									discountPercent,
									maxDiscount,
									validFrom,
									validTo,
								};
								createPromotion(
									payload,
									selectClient,
									state?.user?.signInUserSession?.idToken["jwtToken"]
								)
									.then((res) => {
										console.log("create data", res.data);

										// Platform.OS !== "web"
										// 	? toast.show("New Promotion has been created", {
										// 			type: "normal | success",
										// 			placement: "top",
										// 			duration: 2000,
										// 			offset: 30,
										// 			animationType: "slide-in",
										// 	  })
										// 	: alert("New Promotion has been created");

										toast.show("New Promotion has been created", {
											type: "normal",
											placement: "top",
											duration: 2000,
											offset: 30,
											animationType: "slide-in",
										});
										navigation.navigate("Promotions", {});
									})
									.catch((err) => {
										console.log("err create", err);
										// Platform.OS !== "web"
										// 	? toast.show("Failed to create new promotion", {
										// 			type: "normal | danger",
										// 			placement: "top",
										// 			duration: 2000,
										// 			offset: 30,
										// 			animationType: "slide-in",
										// 	  })
										// 	: alert("Failed to create new promotion");
										toast.show("Failed to create new promotion", {
											type: "danger",
											placement: "top",
											duration: 2000,
											offset: 30,
											animationType: "slide-in",
										});
									});
							} else {
								let payload = {
									code,
									restaurantGroup,
									locationId,
									promotionName,
									discountAmount,
									discountPercent,
									maxDiscount,
									validFrom,
									validTo,
								};
								updatePromotion(
									id,
									payload,
									state?.user?.signInUserSession?.idToken["jwtToken"]
								)
									.then((res) => {
										console.log("update data", res.data);
										// Platform.OS !== "web"
										// 	? toast.show("Promition has been updated successfully", {
										// 			type: "sucess",
										// 			placement: "top",
										// 			duration: 2000,
										// 			offset: 30,
										// 			animationType: "slide-in",
										// 	  })
										// 	: alert("Promition has been updated successfully");
										toast.show("Promition has been updated successfully", {
											type: "sucess",
											placement: "top",
											duration: 2000,
											offset: 30,
											animationType: "slide-in",
										});
										navigation.navigate("Promotions", {});
									})
									.catch((err) => {
										console.log("err", err);
										// Platform.OS !== "web"
										// 	? toast.show("Failed to update promotion", {
										// 			type: "normal",
										// 			placement: "top",
										// 			duration: 2000,
										// 			offset: 30,
										// 			animationType: "slide-in",
										// 	  })
										// 	: alert("Failed to update promotion");
										toast.show("Failed to update promotion", {
											type: "danger",
											placement: "top",
											duration: 2000,
											offset: 30,
											animationType: "slide-in",
										});
									});
							}
						}}>
						{(props) => (
							<View>
								{userType === "Admin" && (
									<View style={styles.inputContainer}>
										<Text style={styles.text}>Client Name</Text>

										<RNPickerSelect
											fixAndroidTouchableBug={true}
											style={pickerSelectStyles}
											useNativeAndroidPickerStyle={false}
											placeholder={{
												label: "Select client",
												color: "black",
											}}
											value={selectClient}
											items={restructuredClient}
											onValueChange={(value) => {
												console.log("status value is", value);
												updateClientSelect(value);
											}}
											disabled={id ? true : false}
										/>
										<Text></Text>
									</View>
								)}
								<View style={styles.inputContainer}>
									<Text style={styles.text}>Restaurant / Shop Name</Text>
									{console.log(restaurants)}
									<RNPickerSelect
										onValueChange={(itemValue) => {
											props.handleChange("restaurantGroup");
											updateRestaurantSelect(itemValue);
											console.log("restaurant=", itemValue);
											loadLocations(itemValue);
										}}
										items={restaurants}
										placeholder={{}}
										useNativeAndroidPickerStyle={false}
										fixAndroidTouchableBug={true}
										style={pickerSelectStyles}
										value={id ? selectRestaurant : ""}
										disabled={id ? true : false}
									/>
									<Text style={styles.errorText}></Text>
								</View>

								<View style={styles.inputContainer}>
									<Text style={styles.text}>Location</Text>
									{console.log("Location==", locationId)}
									<RNPickerSelect
										onValueChange={props.handleChange("locationId")}
										items={locations}
										placeholder={{}}
										useNativeAndroidPickerStyle={false}
										fixAndroidTouchableBug={true}
										style={pickerSelectStyles}
										value={props.values.locationId}
										disabled={id ? true : false}
									/>
									<Text style={styles.errorText}></Text>
								</View>
								<View style={styles.inputContainer}>
									<Text style={styles.text}>Promotion Name</Text>
									{console.log("Name=====", props.values.promotionName)}
									<TextInput
										placeholder='Ex:IPLMANIA'
										style={styles.input}
										onChangeText={props.handleChange("promotionName")}
										value={props.values.promotionName}
										onBlur={props.handleBlur("promotionName")}
									/>
									<Text style={styles.errorText}>
										{props.touched.promotionName && props.errors.promotionName}
									</Text>
								</View>
								<View style={styles.inputContainer}>
									<Text style={styles.text}>Promotion Code</Text>
									<TextInput
										placeholder='Ex:FIRST100'
										style={styles.input}
										onChangeText={props.handleChange("code")}
										value={props.values.code}
										onBlur={props.handleBlur("code")}
									/>
									<Text style={styles.errorText}>
										{props.touched.code && props.errors.code}
									</Text>
								</View>
								<View style={styles.inputContainer}>
									<Text style={styles.text}>Discount Amount (Rs)</Text>
									<TextInput
										placeholder='Ex: 100'
										style={styles.input}
										onChangeText={props.handleChange("discountAmount")}
										value={props.values.discountAmount}
										keyboardType='numeric'
										onBlur={props.handleBlur("discountAmount")}
									/>
								</View>
								<View style={styles.inputContainer}>
									<Text style={styles.text}>Discount Percentage (%)</Text>
									<TextInput
										placeholder='Ex: 10'
										style={styles.input}
										onChangeText={props.handleChange("discountPercent")}
										value={props.values.discountPercent}
										keyboardType='numeric'
										onBlur={props.handleBlur("discountPercent")}
									/>
								</View>
								<View style={styles.inputContainer}>
									<Text style={styles.text}>Maximum Discount (Rs)</Text>
									<TextInput
										placeholder='Ex: 80'
										style={styles.input}
										onChangeText={props.handleChange("maxDiscount")}
										value={props.values.maxDiscount}
										keyboardType='numeric'
									/>
									<Text style={styles.errorText}></Text>
								</View>
								<View style={styles.datecontainer}>
									<View style={styles.inputContainer2}>
										<Text style={styles.from}>Valid From</Text>
										<View style={styles.dt}>
											{Platform.OS !== "web" ? (
												<>
													<TextInput
														editable={false}
														value={displayFrom}
														style={styles.dttext}
														placeholder='Choose start date & time'
													/>
													<TouchableOpacity onPress={showDatePickerFrom}>
														<Fontisto name='date' size={22} color='black' />
													</TouchableOpacity>

													<DateTimePickerModal
														isVisible={isDatePickerVisibleFrom}
														mode='datetime'
														onConfirm={handleConfirmFrom}
														onCancel={hideDatePickerFrom}
														is24Hour={false}
													/>
												</>
											) : (
												<>
													{/* <TextInput
														editable={false}
														value={displayFrom}
														style={styles.dttext}
														placeholder='Choose start date & time'
													/> */}
													<Text>{displayFrom}</Text>

													<input
														onChange={(e) => handleConfirmFrom(e.target.value)}
														type='datetime-local'
													/>
												</>
											)}
										</View>
									</View>
									{isValidFrom ? (
										<Text style={styles.errorText}></Text>
									) : (
										<Text style={styles.errorText}>
											Valid From is a required field
										</Text>
									)}
								</View>
								<View style={styles.datecontainer}>
									<View style={styles.inputContainer2}>
										<Text style={styles.from}>Valid To</Text>
										<View style={styles.dt}>
											{Platform.OS !== "web" ? (
												<>
													<TextInput
														editable={false}
														value={displayTo}
														style={styles.dttext}
														placeholder='Choose end date & time'
													/>
													<TouchableOpacity onPress={showDatePickerTo}>
														<Fontisto name='date' size={22} color='black' />
													</TouchableOpacity>
													<DateTimePickerModal
														isVisible={isDatePickerVisibleTo}
														mode='datetime'
														onConfirm={handleConfirmTo}
														onCancel={hideDatePickerTo}
													/>
												</>
											) : (
												<>
													{/* <TextInput
														editable={false}
														value={displayTo}
														style={styles.dttext}
														placeholder='Choose start date & time'
													/> */}

													<Text>{displayTo}</Text>

													<input
														onChangeCapture={(e) =>
															handleConfirmTo(e.target.value)
														}
														type='datetime-local'
													/>
												</>
											)}
										</View>
									</View>
									{isValidTo ? (
										<Text style={styles.errorText}></Text>
									) : (
										<Text style={styles.errorText}>
											Valid To is a required field
										</Text>
									)}
								</View>
								<TouchableOpacity
									onPress={() => props.handleSubmit()}
									style={styles.button}>
									<Text style={styles.buttontext}>Submit</Text>
								</TouchableOpacity>
							</View>
						)}
					</Formik>
				</View>
				<StatusBar style='auto' />
			</ScrollView>
		</SafeAreaView>
	);
}

const styles = StyleSheet.create({
	areaview: {
		flex: 1,

		paddingTop: StatusBar.currentHeight,
	},
	container: {
		flex: 1,
	},
	form: {
		paddingHorizontal: "15%",
	},
	heading: {
		textAlign: "center",
		fontSize: 22,
		fontWeight: "bold",
		marginTop: 20,
		marginBottom: 25,
	},
	inputContainer: {
		marginBottom: 2,
		maxWidth: 650,
	},
	input: {
		borderWidth: 1,
		paddingVertical: 2,
		paddingHorizontal: 5,
		borderColor: "grey",
	},
	text: {
		fontSize: 20,
	},
	errorText: {
		color: "red",
	},
	inputContainer2: {
		flex: 1,
		alignItems: "center",
		flexDirection: "row",
	},
	dt: {
		flexDirection: "row",
		height: 35,
		maxHeight: 35,
		flex: 1,
		borderWidth: 1,
		alignItems: "center",
		justifyContent: "space-between",
		paddingHorizontal: 3,
		marginRight: 0,
		borderColor: "grey",
		color: "black",
	},
	from: {
		width: 80,
		fontSize: 16,
	},
	button: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 35,
		backgroundColor: "green",
		padding: 6,
		fontWeight: "600",
		textAlign: "center",
		marginBottom: 20,
	},
	buttontext: {
		fontSize: 18,
		color: "white",
	},
	dttext: {
		color: "black",
	},
	datecontainer: {
		marginBottom: 8,
	},
	delbutton: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 15,
		backgroundColor: "crimson",
		padding: 6,
		fontWeight: "600",
		textAlign: "center",
		marginBottom: 20,
	},
});
const pickerSelectStyles = StyleSheet.create({
	inputIOS: {
		borderColor: "grey",
		borderWidth: 1,
		paddingVertical: 1.5,
		paddingHorizontal: 4,
		paddingRight: 30,
		color: "black",
	},
	inputAndroid: {
		borderColor: "grey",
		color: "black",
		borderWidth: 1,
		paddingVertical: 2,
		paddingHorizontal: 5,
		paddingRight: 30,
	},
});
