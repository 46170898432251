import { Storage } from "aws-amplify";

export function capitalize(string) {
  if (string && string.length > 0) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return "";
  }
}

//Comparer Function
export function GetSortOrder(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}

//Comparer Function
export function GetDescSortOrder(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return -1;
    } else if (a[prop] < b[prop]) {
      return 1;
    }
    return 0;
  };
}

export const uploadImage = async (fileName = "BannerImage", image) => {
  try {
    const img = await fetchImageFromUri(image);
    let fileType = "image/jpeg";
    let fileExtn = "";
    const imageTypeDataSearch = "data:";
    const imageTypeFileSearch = "file:";
    if (image.startsWith(imageTypeDataSearch)) {
      fileType = image.substring(
        imageTypeDataSearch.length,
        image.indexOf(";")
      );
      fileExtn = fileType.substring(fileType.indexOf("/") + 1);
      if (fileExtn === "jpeg") fileExtn = "jpg";
      console.log("fileType", fileType);
      fileName = fileName + "." + fileExtn;
      console.log("fileName", fileName);
    } else if (image.startsWith(imageTypeFileSearch)) {
      fileExtn = image.substring(image.lastIndexOf(".") + 1);
      console.log("fileExtn", fileExtn);
      fileExtn === "jpg" ? (fileType = "image/jpeg") : "image/" + fileExtn;
      fileName = fileName + "." + fileExtn;
    }
    const awsResult = await Storage.put(fileName, img, {
      contentType: fileType,
    });
    console.log("Aws result is ", awsResult);
    return (
      "https://s3.ap-south-1.amazonaws.com/images.delishtic.com/public/" +
      fileName
    );
  } catch (error) {
    console.log("err is ", error);
  }
};

export const fetchImageFromUri = async (uri) => {
  const response = await fetch(uri);
  const blob = await response.blob();
  return blob;
};
