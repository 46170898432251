import React, { useEffect, useContext } from "react";
import { Button, StyleSheet, Text, View, Switch, Platform } from "react-native";
import RNPickerSelect from "react-native-picker-select";
import { NavigationContainer, useFocusEffect } from "@react-navigation/native";
import axios from "axios";
import {
	SERVER_API_URL,
	API_URL_CLIENT,
	API_URL_LOCATIONS,
	API_URL_RESTAURANT,
} from "../config/config";
import { UserContext } from "../src/context/userContext";

const StoreManagement = () => {
	const { state } = useContext(UserContext);
	const userType = state.userType;
	const assignedClient = state.user.attributes["custom:client"];
	const [client, updateClient] = React.useState([]);
	const [restructuredClient, updateRestructureClient] = React.useState([]);
	const [selectedRestaurant, setSelectedRestaurant] = React.useState("");
	const [showDetails, setShowDetails] = React.useState({});
	// const [showLocation, setShowLocation] = React.useState("");
	const [checkisDeliveryEnabled, updateCheckisDeliveryEnabled] = React.useState(
		showDetails[0]?.isDeliveryEnabled
	);
	const [checkisPickupEnabled, updateCheckisPickupEnabled] =
		React.useState(true);
	const [checkisOpen, updateCheckisOpen] = React.useState(false);

	const [defaultLocation, setDefaultLocation] = React.useState("");
	const [locations, setLocations] = React.useState([]);
	const [selectedLocation, setSelectedLocation] = React.useState("");

	const [selectClient, updateClientSelect] = React.useState(
		assignedClient ? assignedClient : ""
	);
	const [restaurants, setRestaurants] = React.useState([]);
	const [visible, setVisible] = React.useState(false);
	const [refresh, updateRefresh] = React.useState(false);
	const [change, setChange] = React.useState(false);

	const restructure = (item) => {
		let labels = [];
		for (var i = 0; i < item.length; i++) {
			labels[i] = { label: item[i].clientName, value: item[i].slug };
		}
		updateRestructureClient(labels);
	};
	const restaurantRestructure = (data) => {
		let array = [];
		for (var i = 0; i < data.length; i++) {
			array[i] = { label: data[i].name, value: data[i].id };
		}
		setRestaurants(array);
		setSelectedRestaurant(array[0].value);
		if (array.length > 0) {
			// console.log("setting Selected Restaurant", array[0].value);
			getLocations(array[0].value);

			// console.log("anaother value", array[0].value);
		}
	};
	const getClient = async () => {
		await axios
			.get(SERVER_API_URL + API_URL_CLIENT, {
				headers: {
					Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				},
			})
			.then((res) => {
				// console.log("client is ", res.data[0].id);
				updateClient(res.data), restructure(res.data);
				updateClientSelect(res.data[0].id);
			})
			.catch((err) => console.log(err));
	};

	const getRestaurants = async () => {
		console.log("selected client is", selectClient);
		await axios
			.get(`${SERVER_API_URL}${API_URL_RESTAURANT}${API_URL_CLIENT}`, {
				headers: {
					client: selectClient,
				},
			})
			.then((res) => {
				restaurantRestructure(res.data);
				// console.log("Restaurants are", res.data);
			})
			.catch((err) => console.log(err));
	};
	const getLocations = async () => {
		console.log("getLocations" + selectedRestaurant);
		console.log("res is", selectedRestaurant);
		if (!selectedRestaurant || selectedRestaurant == null) return;

		await axios
			.get(`${SERVER_API_URL}${API_URL_LOCATIONS}`, {
				headers: {
					restaurantgroup: selectedRestaurant,
				},
			})
			.then((res) => {
				// console.log("getLocations Response", res.data);
				setLocations(res.data);
				setSelectedLocation(res.data[0].id);
			})
			.catch((err) => {
				console.log(err);
				setLocations([]);
			});
	};
	// console.log("Client is ", client);

	const showData = () => {
		let myData = locations.filter((elem) => elem.id == selectedLocation);
		console.log("details are", myData[0]?.isDeliveryEnabled);

		setShowDetails(locations.filter((elem) => elem.id == selectedLocation));
		if (myData[0]?.isDeliveryEnabled == undefined) {
			setVisible(false);
		} else {
			setVisible(true);
		}
		// console.log("locations are ", selectedLocation);
		console.log("showdelivery details are", showDetails);
		// console.log(showDetails);
		console.log(
			"is open are",
			locations.filter((elem) => elem.id == selectedLocation)[0]?.isOpen
		);
		console.log(
			"is pickup are",
			locations.filter((elem) => elem.id == selectedLocation)[0]
				?.isPickupEnabled
		);

		updateCheckisOpen(
			locations.filter((elem) => elem.id == selectedLocation)[0]?.isOpen
		);
		updateCheckisPickupEnabled(
			locations.filter((elem) => elem.id == selectedLocation)[0]
				?.isPickupEnabled
		);
		updateCheckisDeliveryEnabled(
			locations.filter((elem) => elem.id == selectedLocation)[0]
				?.isDeliveryEnabled
		);
	};

	const update = async () => {
		try {
			await axios
				.patch(
					`${SERVER_API_URL}${API_URL_LOCATIONS}/${showDetails[0]?.id}`,

					{
						isDeliveryEnabled: checkisDeliveryEnabled,
						isOpen: checkisOpen,
						isPickupEnabled: checkisPickupEnabled,
					},
					{
						headers: {
							Authorization:
								state?.user?.signInUserSession?.idToken["jwtToken"],
						},
					}
				)
				.then((res) => {
					alert("Updated");
					updateRefresh(!refresh);
					console.log("data update is", res.data);
				})
				.catch((err) => console.log(err));
		} catch (error) {
			console.log(error);
		}
	};
	useFocusEffect(
		React.useCallback(() => {
			getClient();

			return () => {
				// Do something when the screen is unfocused
				// Useful for cleanup functions
			};
		}, [refresh, selectClient])
	);

	useEffect(() => {
		getLocations();
	}, [selectedRestaurant, refresh]);
	useEffect(() => {
		getRestaurants();
	}, [selectClient]);

	return (
		<View>
			{userType === "Admin" && (
				<View style={styles.itemView}>
					<Text style={styles.label}>Select Client :</Text>
					<RNPickerSelect
						fixAndroidTouchableBug={true}
						style={pickerSelectStyles}
						useNativeAndroidPickerStyle={false}
						items={restructuredClient}
						onValueChange={(value) => {
							// console.log("status value is", value);
							updateClientSelect(value);
						}}
					/>
				</View>
			)}
			<View style={styles.itemView}>
				<Text style={styles.label}>Select Restaurant / Shop Group :</Text>

				<RNPickerSelect
					style={pickerSelectStyles}
					fixAndroidTouchableBug={true}
					useNativeAndroidPickerStyle={false}
					onValueChange={(itemValue, itemIndex) => {
						// console.log("values is ", itemValue);

						setSelectedRestaurant(itemValue);
						getLocations(itemValue);
						setDefaultLocation("");
					}}
					value={selectedRestaurant}
					items={restaurants.map((res) => ({
						label: res.label,
						value: res.value,
					}))}
				/>
			</View>
			<View style={styles.itemView}>
				<Text style={styles.label}>Select Location: </Text>

				<RNPickerSelect
					fixAndroidTouchableBug={true}
					style={pickerSelectStyles}
					useNativeAndroidPickerStyle={false}
					onValueChange={(itemValue, itemIndex) => {
						setSelectedLocation(itemValue);
						// setShowLocation(itemValue);
					}}
					value={selectedLocation}
					items={locations.map((res) => ({
						label: res.name,
						value: res.id,
					}))}
				/>
			</View>
			<View style={styles.itemView}>
				<Button onPress={() => showData()} title='Show Store' />
			</View>

			{visible == false ? (
				<></>
			) : (
				<>
					<View style={styles.itemView}>
						<View style={styles.detailsView}>
							<Text style={styles.itemLabel}>Name :</Text>
							<Text style={{ fontWeight: "600" }}>{showDetails[0]?.name}</Text>
						</View>
						<View style={styles.detailsView}>
							<Text style={styles.itemLabel}>Address :</Text>

							<Text style={{ fontWeight: "600" }}>
								{showDetails[0]?.address.city}
							</Text>
						</View>
						<View style={styles.detailsView}>
							<Text style={styles.itemLabel}>Phone</Text>
							<Text style={{ fontWeight: "600" }}>
								{showDetails[0]?.phone.value}
							</Text>
						</View>
					</View>
					<View style={styles.itemView}>
						<View style={styles.settingView}>
							<Text style={styles.label}>Delivery Enabled ?</Text>
							<Switch
								trackColor={{ false: "#767577", true: "#81b0ff" }}
								thumbColor={checkisDeliveryEnabled ? "#f5dd4b" : "#f4f3f4"}
								ios_backgroundColor='#3e3e3e'
								onValueChange={() =>
									updateCheckisDeliveryEnabled(!checkisDeliveryEnabled)
								}
								value={checkisDeliveryEnabled}
							/>
						</View>
						<View style={styles.settingView}>
							<Text style={styles.label}>PickUp Enabled ?</Text>
							<Switch
								trackColor={{ false: "#767577", true: "#81b0ff" }}
								thumbColor={checkisPickupEnabled ? "#f5dd4b" : "#f4f3f4"}
								ios_backgroundColor='#3e3e3e'
								onValueChange={() =>
									updateCheckisPickupEnabled(!checkisPickupEnabled)
								}
								value={checkisPickupEnabled}
							/>
						</View>
						<View style={styles.settingView}>
							<Text style={styles.label}> Open ?</Text>
							<Switch
								trackColor={{ false: "#767577", true: "#81b0ff" }}
								thumbColor={checkisOpen ? "#f5dd4b" : "#f4f3f4"}
								ios_backgroundColor='#3e3e3e'
								onValueChange={() => updateCheckisOpen(!checkisOpen)}
								value={checkisOpen}
							/>
						</View>
					</View>
					<View style={styles.itemView}>
						<Button onPress={() => update()} title='Update' />
					</View>
				</>
			)}
		</View>
	);
};

export default StoreManagement;

const styles = StyleSheet.create({
	itemView: {
		paddingHorizontal: 20,
		marginVertical: 10,
	},
	label: {
		marginTop: 10,
		marginBottom: 6,
		fontSize: 16,
		fontWeight: "bold",
	},
	itemLabel: {
		fontSize: 16,
		fontWeight: "bold",
		textAlign: "left",
	},
	detailsView: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginVertical: 6,
	},
	settingView: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		// paddingHorizontal: 10,
		marginBottom: 15,
	},
});
const pickerSelectStyles = StyleSheet.create({
	inputIOS: {
		fontSize: 12,
		marginHorizontal: 5,
		borderWidth: 1,
		borderColor: "gray",
		borderRadius: 4,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon
		padding: 5,
	},
	inputAndroid: {
		fontSize: 12,
		marginHorizontal: 5,

		borderWidth: 0.5,
		borderColor: "black",
		borderRadius: 8,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon

		padding: 5, // to ensure the text is never behind the icon
	},
});
