import * as React from "react";
import {
	View,
	Text,
	TouchableOpacity,
	StyleSheet,
	Dimensions,
	TextInput,
	Switch,
	Platform,
} from "react-native";
import { Card, Button, CheckBox, Input } from "react-native-elements";
import { ScrollView } from "react-native-gesture-handler";
import Entypo from "react-native-vector-icons/Entypo";
import { Formik } from "formik";
import * as yup from "yup";

import RNPickerSelect from "react-native-picker-select";
import { label } from "@aws-amplify/ui";
//Validtion using yup
const formSchema = yup.object({
	street1: yup
		.string("Street must be a string")
		.required("Street name is required"),
	phone: yup
		.string()
		.required("Mobile number name is required")
		.min(10, "Mobile number must be of 10 characters"),
	notificationEmail: yup
		.string()
		.required("Notification email is required")
		.email("Input must be a valid email address"),
	invoiceEmail: yup
		.string()
		.required("Invoice email is required")
		.email("Input must be a valid email address"),
	state: yup.string().required("State is required"),
	zip: yup.string().required("zip is required"),
	restaurantName: yup.string().required("Restaurant name is required"),
	city: yup.string().required("City is required"),
	street2: yup.string().required("Street2 is required"),
	slug: yup.string().required("Slug is required"),
	name: yup.string().required("Name is required"),
});
const windowHeight = Dimensions.get("window").height;

export const input = ({ route, navigation }) => {
	console.log(route);
	const [street1, updateStreet1] = React.useState("");
	const [city, updateCity] = React.useState("");
	const [state, updateState] = React.useState("");
	const [zip, updateZip] = React.useState("");
	const [phone, updatePhone] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [fssai, setFssai] = React.useState("");
	const [packing, setPacking] = React.useState("10");
	const [verified, updateVerified] = React.useState(false);
	const [preorder, updatePreorder] = React.useState(false);
	const [street2, updateStreet2] = React.useState("");
	// const [checkApple, updateCheckApple] = React.useState(false);
	// const [checkCash, updateCheckCash] = React.useState(false);
	const [lat, setLat] = React.useState("");
	const [long, setLong] = React.useState("");
	const [checkisDeliveryEnabled, updateCheckisDeliveryEnabled] =
		React.useState(true);
	// const [checkisGooglePayAccepted, updateisGooglePayAccepted] =
	// 	React.useState(false);
	// const [checkisKioskEnabled, updateCheckisKioskEnabled] =
	// 	React.useState(false);
	const [checkisOpen, updateCheckisOpen] = React.useState(false);
	const [checkisPickupEnabled, updateCheckisPickupEnabled] =
		React.useState(true);

	const [slug, updateSlug] = React.useState("");
	const [name, updateName] = React.useState("");
	const [timeZone, updateTimeZone] = React.useState("Asia/Kolkata");
	// const [delivery, updateDelivery] = React.useState("40");
	// const [increment, updateIncrement] = React.useState("10");
	const [threshold, updateThreshold] = React.useState("");
	// const [prep, updatePrep] = React.useState("20");
	const [street1Err, setStreet1Err] = React.useState(false);
	const [street2Err, setStreet2Err] = React.useState(false);
	const [phoneErr, setPhoneErr] = React.useState(false);
	const [locationErr, setLocationErr] = React.useState(false);
	const [slugErr, setSlugErr] = React.useState(false);
	const [zipErr, setZipErr] = React.useState(false);
	const [cityErr, setCityErr] = React.useState(false);
	const [stateErr, setStateErr] = React.useState(false);
	const [latErr, setLatErr] = React.useState(false);
	const [lngErr, setLngErr] = React.useState(false);
	const [emailErr, setEmailErr] = React.useState(false);
	const [fssaiErr, setFssaiErr] = React.useState(false);
	const testPhoneNumber = (value) => {
		let pattern = /^\d{10}$/;
		console.log("Value is ", value);
		updatePhone(value);
		if (pattern.test(value) == true) {
			setPhoneErr(false);
		} else {
			setPhoneErr(true);
		}
		console.log("is valid is ", phoneErr);
	};

	const Inputdata = {
		serviceEstimates: {
			// delivery: parseInt(delivery),
			// increment: parseInt(increment),
			// prep: parseInt(prep),
			threshold: threshold,
			packingCharges: parseInt(packing),
		},
		email: email,
		fssaiLicense: fssai,
		packingCharges: parseInt(packing),
		// isApplePayAccepted: checkApple,
		// isCashAccepted: checkCash,
		// isDeliveryEnabled: checkisDeliveryEnabled,
		// isGooglePayAccepted: checkisGooglePayAccepted,
		// isKioskEnabled: checkisKioskEnabled,
		isOpen: checkisOpen,
		isPickupEnabled: checkisPickupEnabled,
		slug: slug,
		timeZone: timeZone,
		address: {
			geo: {
				lat: parseFloat(lat),
				long: parseFloat(long),
			},
			street2: street2,
			street1: street1,
			// geo: {
			// 	lat: 34,
			// 	long: 34,
			// },
			city: city,
			state: state,
			zip: zip,
		},
		name: name,

		phone: {
			value: phone,
			isVerified: verified,
		},
		isPreorderRequired: preorder,
		// distanceInMiles: miles,
	};

	const loadNext = () => {
		// if (
		// 	(street1 &&
		// 		city &&
		// 		phone &&
		// 		state &&
		// 		zip &&
		// 		slug &&
		// 		name &&
		// 		timeZone &&
		// 		delivery &&
		// 		prep &&
		// 		threshold &&
		// 		increment) == ""
		// ) {
		// 	alert("Field blank");
		// 	return;
		// }

		if (street1 == "") {
			setStreet1Err(true);
		}
		if (slug == "") {
			setSlugErr(true);
		}
		if (street2 == "") {
			setStreet2Err(true);
		}

		if (name == "") {
			setLocationErr(true);
		}
		if (zip == "") {
			setZipErr(true);
		}
		if (city == "") {
			setCityErr(true);
		}
		if (state == true) {
			setStateErr(true);
		}
		if (lat == "") {
			setLatErr(true);
		}
		if (long == "") {
			setLngErr(true);
		}
		if (fssai == "") {
			setFssaiErr(true);
		}
		if (
			(street1Err &&
				street2Err &&
				slugErr &&
				locationErr &&
				zipErr &&
				locationErr &&
				stateErr &&
				cityErr) == false
		) {
			console.log("License is ", Inputdata.fssaiLicense);
			return navigation.navigate("day1", {
				data: Inputdata,
				id: route.params.id,
				clientId: route.params.clientId,
			});
		}
	};

	const services = [
		{ label: "10", value: "10" },
		{ label: "20", value: "20" },
		{ label: "30", value: "30" },
		{ label: "40", value: "40" },
		{ label: "50", value: "50" },
		{ label: "60", value: "60" },
		{ label: "70", value: "70" },
		{ label: "80", value: "80" },
		{ label: "90", value: "90" },
		{ label: "100", value: "100" },
		{ label: "110", value: "110" },
		{ label: "120", value: "120" },
	];

	const packingChargesList = [
		{ label: "0", value: 0 },
		{ label: "10", value: 10 },
		{ label: "20", value: 20 },
		{ label: "30", value: 30 },
		{ label: "40", value: 40 },
		{ label: "50", value: 50 },
		{ label: "60", value: 60 },
		{ label: "70", value: 70 },
		{ label: "80", value: 80 },
		{ label: "90", value: 90 },
		{ label: "100", value: 100 },
		{ label: "110", value: 110 },
		{ label: "120", value: 120 },
	];

	console.log(Inputdata);
	return (
		<ScrollView style={{ flex: 1 }}>
			<View>
				<View style={styles.mainView}>
					<Text style={styles.mainLabel}>Location Details</Text>

					<Text style={styles.label}>Location name</Text>

					<Input
						placeholder='Location Name'
						onChangeText={(value) => {
							updateName(value);

							updateSlug(value.replace(/\s+/g, "-").toLowerCase());
						}}></Input>

					{locationErr == true ? (
						<Text style={styles.errortext}>Location is required </Text>
					) : (
						<></>
					)}
					<Text style={styles.label}>Enter Slug </Text>

					<Input
						placeholder='Slug'
						value={slug}
						onChangeText={(value) => updateSlug(value)}></Input>
					{slugErr == true ? (
						<Text style={styles.errortext}>Slug is required </Text>
					) : (
						<></>
					)}
				</View>

				<View style={styles.mainView}>
					<Text style={styles.mainLabel}>Address Details</Text>
					<Text style={styles.label}>Enter Street1 </Text>
					<Input
						placeholder='Street1'
						onChangeText={(value) => updateStreet1(value)}></Input>
					{street1Err == true ? (
						<Text style={styles.errortext}>Street is required </Text>
					) : (
						<></>
					)}
					<Text style={styles.label}>Enter Street2 </Text>
					<Input
						placeholder='Street2'
						onChangeText={(value) => updateStreet2(value)}></Input>
					{street2Err == true ? (
						<Text style={styles.errortext}>Street is required </Text>
					) : (
						<></>
					)}
					<Text style={styles.label}>Enter City </Text>
					<Input
						placeholder='City'
						onChangeText={(value) => updateCity(value)}></Input>
					{cityErr == true ? (
						<Text style={styles.errortext}>City is required </Text>
					) : (
						<></>
					)}
					<Text style={styles.label}>Enter State </Text>
					<Input
						placeholder='State'
						onChangeText={(value) => updateState(value)}></Input>
					{stateErr == true ? (
						<Text style={styles.errortext}>State is required </Text>
					) : (
						<></>
					)}
					<Text style={styles.label}>Enter Pincode </Text>
					<Input
						placeholder='Pincode'
						onChangeText={(value) => updateZip(value)}></Input>
					{zipErr == true ? (
						<Text style={styles.errortext}>Pincode is required </Text>
					) : (
						<></>
					)}
					<Text style={styles.label}>Enter Latitude </Text>
					<Input
						placeholder='Latitude'
						onChangeText={(value) => setLat(value)}></Input>
					{latErr == true ? (
						<Text style={styles.errortext}>Latitude is required </Text>
					) : (
						<></>
					)}
					<Text style={styles.label}>Enter Longitude </Text>
					<Input
						placeholder='Longitude'
						onChangeText={(value) => setLong(value)}></Input>
					{lngErr == true ? (
						<Text style={styles.errortext}>Longitude is required </Text>
					) : (
						<></>
					)}
					<Text style={styles.label}>Enter Phone </Text>
					<Input
						placeholder='Phone'
						onChangeText={(value) => testPhoneNumber(value)}></Input>
					{phoneErr == true ? (
						<Text style={styles.errortext}>Invalid Phone Number </Text>
					) : (
						<></>
					)}
					<View style={styles.settingView}>
						<Text style={styles.label}>Phone Verified ?</Text>
						<Switch
							trackColor={{ false: "#767577", true: "#81b0ff" }}
							thumbColor={verified ? "#f5dd4b" : "#f4f3f4"}
							ios_backgroundColor='#3e3e3e'
							onValueChange={() => updateVerified(!verified)}
							value={verified}
						/>
					</View>
					<Text style={styles.label}>Enter Email </Text>
					<Input
						placeholder='Email'
						keyboardType='email-address'
						onChangeText={(value) => setEmail(value)}></Input>
					{emailErr == true ? (
						<Text style={styles.errortext}>Email is required </Text>
					) : (
						<></>
					)}
				</View>

				<View style={styles.mainView}>
					<Text style={styles.mainLabel}>Restaurant Details</Text>
					<Text style={styles.label}>Select Time Zone </Text>
					<RNPickerSelect
						fixAndroidTouchableBug={true}
						style={pickerSelectStyles}
						useNativeAndroidPickerStyle={false}
						value={timeZone}
						items={[
							{ label: "Asia/Kolkata", value: "Asia/Kolkata" },
							{ label: "America/Winnipeg", value: "America/Winnipeg" },
							{ label: "America/Vancouver", value: "America/Vancouver" },
							{ label: "Australia/Melbourne", value: "Australia/Melbourne" },
							{ label: "Australia/Sydney", value: "Australia/Sydney" },
						]}
						onValueChange={(value) => {
							console.log(" value is", value);
							updateTimeZone(value);
						}}
					/>
					{/* <Text style={styles.label}>Enter Delivery Time </Text>
					<RNPickerSelect
						fixAndroidTouchableBug={true}
						style={pickerSelectStyles}
						useNativeAndroidPickerStyle={false}
						value={delivery}
						items={services}
						onValueChange={(value) => {
							console.log(" value is", value);
							updateDelivery(value);
						}}
					/>
					<Text style={styles.label}>Increment Time </Text>
					<RNPickerSelect
						fixAndroidTouchableBug={true}
						style={pickerSelectStyles}
						useNativeAndroidPickerStyle={false}
						value={increment}
						items={services}
						onValueChange={(value) => {
							console.log(" value is", value);
							updateIncrement(value);
						}}
					/>
					<Text style={styles.label}>Enter Preparation Time </Text>
					<RNPickerSelect
						fixAndroidTouchableBug={true}
						style={pickerSelectStyles}
						useNativeAndroidPickerStyle={false}
						value={prep}
						items={services}
						onValueChange={(value) => {
							console.log(" value is", value);
							updatePrep(value);
						}}
					/> */}
					<Text style={styles.label}>Enter Threshold Time </Text>
					{/* <RNPickerSelect
						fixAndroidTouchableBug={true}
						style={pickerSelectStyles}
						useNativeAndroidPickerStyle={false}
						value={threshold}
						items={services}
						onValueChange={(value) => {
							console.log(" value is", value);
							updateThreshold(value);
						}}
					/> */}
					<Input
						value={threshold}
						placeholder='Threshold'
						onChangeText={(value) => updateThreshold(value)}></Input>
					<Text style={styles.label}>Enter Packing Charges </Text>
					<RNPickerSelect
						fixAndroidTouchableBug={true}
						style={pickerSelectStyles}
						useNativeAndroidPickerStyle={false}
						value={packing}
						items={packingChargesList}
						onValueChange={(value) => {
							console.log(" value is", value);
							setPacking(value);
						}}
					/>
					<Text style={styles.label}>Enter FSSAI License </Text>
					<Input
						placeholder='FSSAI'
						onChangeText={(value) => setFssai(value)}></Input>
					{fssaiErr == true ? (
						<Text style={styles.errortext}>FSSAI License is required </Text>
					) : (
						<></>
					)}
				</View>
				<View style={styles.mainView}>
					<Text style={styles.mainLabel}>Settings</Text>

					{/* <View style={styles.settingView}>
						<Text style={styles.label}>Apple Pay</Text>
						<Switch
							trackColor={{ false: "#767577", true: "#81b0ff" }}
							thumbColor={checkApple ? "#f5dd4b" : "#f4f3f4"}
							ios_backgroundColor='#3e3e3e'
							onValueChange={() => updateCheckApple(!checkApple)}
							value={checkApple}
						/>
					</View>
					<View style={styles.settingView}>
						<Text style={styles.label}>Cash Accepted ?</Text>
						<Switch
							trackColor={{ false: "#767577", true: "#81b0ff" }}
							thumbColor={checkCash ? "#f5dd4b" : "#f4f3f4"}
							ios_backgroundColor='#3e3e3e'
							onValueChange={() => updateCheckCash(!checkCash)}
							value={checkCash}
						/>
					</View> */}

					<View style={styles.settingView}>
						<Text style={styles.label}>Delivery Enabled ?</Text>
						<Switch
							trackColor={{ false: "#767577", true: "#81b0ff" }}
							thumbColor={checkisDeliveryEnabled ? "#f5dd4b" : "#f4f3f4"}
							ios_backgroundColor='#3e3e3e'
							onValueChange={() =>
								updateCheckisDeliveryEnabled(!checkisDeliveryEnabled)
							}
							value={checkisDeliveryEnabled}
						/>
					</View>

					{/* <View style={styles.settingView}>
						<Text style={styles.label}>Google Pay Accepted ?</Text>
						<Switch
							trackColor={{ false: "#767577", true: "#81b0ff" }}
							thumbColor={checkisGooglePayAccepted ? "#f5dd4b" : "#f4f3f4"}
							ios_backgroundColor='#3e3e3e'
							onValueChange={() =>
								updateisGooglePayAccepted(!checkisGooglePayAccepted)
							}
							value={checkisGooglePayAccepted}
						/>
					</View>

					<View style={styles.settingView}>
						<Text style={styles.label}>Kiosk Enabled ?</Text>
						<Switch
							trackColor={{ false: "#767577", true: "#81b0ff" }}
							thumbColor={checkisKioskEnabled ? "#f5dd4b" : "#f4f3f4"}
							ios_backgroundColor='#3e3e3e'
							onValueChange={() =>
								updateCheckisKioskEnabled(!checkisKioskEnabled)
							}
							value={checkisKioskEnabled}
						/>
					</View> */}

					<View style={styles.settingView}>
						<Text style={styles.label}> Open ?</Text>
						<Switch
							trackColor={{ false: "#767577", true: "#81b0ff" }}
							thumbColor={checkisOpen ? "#f5dd4b" : "#f4f3f4"}
							ios_backgroundColor='#3e3e3e'
							onValueChange={() => updateCheckisOpen(!checkisOpen)}
							value={checkisOpen}
						/>
					</View>

					<View style={styles.settingView}>
						<Text style={styles.label}>PickUp Enabled ?</Text>
						<Switch
							trackColor={{ false: "#767577", true: "#81b0ff" }}
							thumbColor={checkisPickupEnabled ? "#f5dd4b" : "#f4f3f4"}
							ios_backgroundColor='#3e3e3e'
							onValueChange={() =>
								updateCheckisPickupEnabled(!checkisPickupEnabled)
							}
							value={checkisPickupEnabled}
						/>
					</View>

					<View style={styles.settingView}>
						<Text style={styles.label}>Pre-Order Enabled ?</Text>
						<Switch
							trackColor={{ false: "#767577", true: "#81b0ff" }}
							thumbColor={preorder ? "#f5dd4b" : "#f4f3f4"}
							ios_backgroundColor='#3e3e3e'
							onValueChange={() => updatePreorder(!preorder)}
							value={preorder}
						/>
					</View>
				</View>
			</View>

			<TouchableOpacity
				onPress={() => loadNext()}
				style={{
					flex: 1,
					// marginLeft: 180,
					marginVertical: 10,

					justifyContent: "center",
					alignItems: "center",
				}}>
				<Entypo name='save' size={30} color='green'></Entypo>
				<Text>Save and Set Working Hours</Text>
			</TouchableOpacity>
		</ScrollView>
	);
};

const pickerSelectStyles = StyleSheet.create({
	inputIOS: {
		fontSize: 12,
		marginHorizontal: 5,
		marginVertical: 10,

		borderWidth: 1,
		borderColor: "gray",
		borderRadius: 4,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon
		padding: 5,
	},
	inputAndroid: {
		fontSize: 12,
		marginHorizontal: 5,
		marginVertical: 10,
		borderWidth: 0.5,
		borderColor: "black",
		borderRadius: 8,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon

		padding: 5, // to ensure the text is never behind the icon
	},
});

const styles = StyleSheet.create({
	mainView: {
		margin: 10,
		marginHorizontal: 15,
		padding: 10,
		backgroundColor: "white",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.36,
		shadowRadius: 6.68,

		elevation: 11,
	},
	mainLabel: {
		fontSize: 16,
		marginVertical: 8,
		textAlign: "center",
		fontWeight: "bold",
	},
	// label: {
	// 	marginVertical: 5,
	// 	fontSize: 17,
	// },
	input: {
		borderWidth: 1,
		padding: 10,
		width: 300,
		borderRadius: 10,
	},
	label: {
		marginTop: 10,
		marginBottom: 10,
		fontSize: 16,
		fontWeight: "bold",
	},
	formCard: {
		marginHorizontal: 5,
		padding: 20,
		marginVertical: 30,
		backgroundColor: "#fff",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.36,
		shadowRadius: 6.68,

		elevation: 11,
	},
	formTitle: {
		fontSize: 20,
		fontWeight: "bold",
		marginBottom: 20,
	},
	errortext: {
		color: "red",
		fontSize: 12,
		marginTop: -10,
	},
	settingView: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingHorizontal: 10,
		marginBottom: 15,
	},
	// label: {
	// 	marginTop: 10,
	// 	marginBottom: 6,
	// 	fontSize: 16,
	// 	fontWeight: "bold",
	// },
});
