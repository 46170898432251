import React, { useEffect, useState, useContext } from "react";
import {
	View,
	Text,
	SafeAreaView,
	StyleSheet,
	ScrollView,
	Modal,
	TouchableOpacity,
	Platform,
	TextInput,
	ActivityIndicator,
} from "react-native";
import RNPickerSelect from "react-native-picker-select";
import axios from "axios";
import { Switch, Header, Overlay, Button, Icon } from "react-native-elements";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { AntDesign } from "@expo/vector-icons";
import { UserContext } from "../src/context/userContext";
import {
	SERVER_API_URL,
	API_URL_CLIENT,
	API_URL_LOCATIONS,
	API_URL_RESTAURANT,
	API_URL_STOCK,
	API_URL_FULL_MENUS,
	API_URL_MENU_ITEMS,
	API_URL_MENU_GROUPS,
} from "../config/config";
import { Checkbox } from "react-native-paper";

export default function StockUpdate({ navigation }) {
	const { state, dispatch } = useContext(UserContext);
	const userType = state.userType;
	const assignedClient = state.user.attributes["custom:client"];
	const [clients, setClients] = useState([]);
	const [client, setClient] = useState(assignedClient ? assignedClient : "");
	const [restaurants, setRestaurants] = useState([]);
	const [restaurant, setRestaurant] = useState("");
	const [location, setLocation] = useState("");
	const [locations, setLocations] = useState([]);
	const [menuGroup, setMenuGroup] = useState("");
	const [menuGroupName, setMenuGroupName] = useState("");
	const [menuGroupList, setMenuGroupList] = useState([]);
	const [data, setData] = useState([]);
	const [menuItems, setMenuItems] = useState([]);
	const [stocks, setStocks] = useState([]);
	const [overlayItem, setOverlayItem] = useState({});
	const [itemPrice, setItemPrice] = useState(0);
	const [originalPrice, setOriginalPrice] = useState(0);
	const [removeOriginalPrice, setRemoveOriginalPrice] = useState(false);
	const [removeAvaiableStock, setRemoveAvailableStock] = useState(false);
	const [availableStock, setAvailableStock] = useState("");
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [menuGroupAvailable, setMenuGroupAvailable] = useState(false);
	const [menuGroupId, setMenuGroupId] = useState("");

	const toggleOverlay = () => {
		setVisible(!visible);
	};
	const toggleSwitch = () => {
		setMenuGroupAvailable(!menuGroupAvailable);
	};

	useEffect(() => {
		loadClients();
		if (assignedClient) {
			loadRestaurants(assignedClient);
		}
	}, []);
	const loadClients = async () => {
		await axios
			.get(SERVER_API_URL + API_URL_CLIENT, {
				headers: {
					Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				},
			})
			.then((res) => {
				// console.log("clients=", res.data);
				restructureclient(res.data);
			})
			.catch((err) => {
				console.log("err1=", err);
			});
	};
	const restructureclient = (item) => {
		let labels = [];
		if (item.length) {
			for (let i = 0; i < item.length; i++) {
				labels[i] = { label: item[i].clientName, value: item[i].slug };
			}
		}
		setClients(labels);

		// console.log("labels=", labels)
	};
	const restructureRestaurant = (item) => {
		let labels = [];
		if (item.length) {
			for (let i = 0; i < item.length; i++) {
				labels[i] = { label: item[i].name, value: item[i].id };
			}
		}
		setRestaurants(labels);
		// console.log("labels=", labels)
		setRestaurant(labels[0].value);
		console.log("labels=", restaurants);
	};
	const loadRestaurants = async (value) => {
		await axios
			.get(`${SERVER_API_URL}${API_URL_RESTAURANT}${API_URL_CLIENT}`, {
				headers: {
					client: value,
				},
			})
			.then((res) => {
				// console.log("clients=", res.data)
				restructureRestaurant(res.data);
			})
			.catch((err) => {
				console.log("err2=", err);
				setRestaurants([]);
			});
	};
	const loadlocations = async (value) => {
		await axios
			.get(`${SERVER_API_URL}${API_URL_LOCATIONS}`, {
				headers: {
					restaurantGroup: value,
				},
			})
			.then((res) => {
				// console.log("clients=", res.data)
				restructureLocation(res.data);
				setData([]);
			})
			.catch((err) => {
				console.log("err3=", err);
				setLocations([]);
			});
	};
	const loadMenuGroups = async (value) => {
		await axios
			.get(`${SERVER_API_URL}${API_URL_MENU_GROUPS}`, {
				headers: {
					restaurantGroup: value,
				},
			})
			.then((res) => {
				console.log("menuGroups=", res.data);
				restructureMenuGroups(res.data);
				setData([]);
			})
			.catch((err) => {
				console.log("err4=", err);
				setMenuGroupList([]);
				setData([]);
			});
	};
	const updateMenuGroup = async (id, value) => {
		console.log("Updates run", id);
		await axios
			.patch(
				`${SERVER_API_URL}${API_URL_MENU_GROUPS}/${id}`,
				{
					outOfStock: !value,
				},
				{
					headers: {
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => console.log("after updating", res.data))
			.catch((err) => console.log("err is ", err));
	};
	const updateStock = async (value, id) => {
		console.log("value is===", value);
		console.log("id is", id);
		console.log("client==", client);
		console.log("restaurant==", restaurant);
		console.log("Location==", location);
		await axios
			.post(
				`${SERVER_API_URL}${API_URL_STOCK}`,
				{
					items: [{ id: id, outOfStock: value }],
				},
				{
					headers: {
						client: client,
						restaurantGroup: restaurant,
						locationId: location,
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => {
				console.log("updated data are ", res.data);
			})
			.catch((err) => console.log("err==", err));
	};
	const updateStockItem = async (id) => {
		console.log(state?.user?.signInUserSession?.idToken["jwtToken"]);
		await axios
			.patch(
				`${SERVER_API_URL}${API_URL_MENU_ITEMS}/${id}`,
				{
					price: parseInt(itemPrice),
					originalPrice: removeOriginalPrice ? null : parseInt(originalPrice),
					availableStock: removeAvaiableStock ? null : parseInt(availableStock),
				},
				{
					headers: {
						client: client,
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => {
				alert("Info changed");
				showStocks();
				console.log("updated data is ", res.data);
				setVisible(false);
			})
			.catch((err) => {
				alert("Something went wrong");
				setVisible(false);
				console.log(err);
			});
	};
	const restructureLocation = (item) => {
		let labels = [];
		if (item.length) {
			for (let i = 0; i < item.length; i++) {
				labels[i] = { label: item[i].name, value: item[i].id };
			}
		}

		setLocations(labels);
		setLocation(labels[0].value);
		// console.log("labels=", labels)

		//  console.log("labels=", locations)
	};

	const restructureMenuGroups = (items) => {
		let labels = [];
		items.sort((a, b) => a.priority - b.priority);
		if (items.length) {
			for (let i = 0; i < items.length; i++) {
				labels[i] = { label: items[i].name, value: items[i].id };
			}
		}
		setMenuGroupList(labels);
		setMenuGroup(labels[0].value);
		setMenuGroupName(labels[0].label);
		setMenuGroupId(labels[0].value);
	};

	const showStocks = async () => {
		// console.log("client ", client);
		// console.log("location ", location);
		// console.log("restaurant ", restaurant);
		// console.log("menuGroup ", menuGroup);
		setLoading(true);
		console.log("show runs");
		await axios
			.get(`${SERVER_API_URL}${API_URL_MENU_GROUPS}${API_URL_MENU_ITEMS}`, {
				headers: {
					menugroupid: menuGroup,
				},
			})

			.then((res) => {
				setData(res.data);
				let itemList = res.data.items;
				itemList.sort((a, b) => a.priority - b.priority);
				setMenuItems(itemList);
				console.log("Menu Items are", itemList);

				axios
					.get(`${SERVER_API_URL}${API_URL_STOCK}`, {
						headers: {
							client: client,
							restaurantGroup: restaurant,
							locationId: location,
						},
					})
					.then((response) => {
						setStocks(response.data);
						console.log("Stocks are", response.data);
						setLoading(false);
					})
					.catch((err) => {
						setLoading(false);
						console.log("stocks err is ", err);
					});
			})
			.catch((err) => {
				console.log("menu err=", err);
				//setMenuGroups([]);
			});
	};

	console.log('render');
	return (
		<SafeAreaProvider>
			{/* <OverlayItem /> */}
			<Overlay
				isVisible={visible}
				onBackdropPress={toggleOverlay}
				ModalComponent={Modal}>
				<View style={{ padding: 10, width: 300 }}>
					<Text
						style={{ fontSize: 18, fontWeight: "bold", marginVertical: 10 }}>
						{overlayItem.name}
					</Text>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							marginVertical: 10,
						}}>
						<Text style={{ fontSize: 18, fontWeight: "bold" }}>Price</Text>
						<TextInput
							placeholder='Price'
							keyboardType='number-pad'
							defaultValue={itemPrice}
							style={{
								borderBottomWidth: 1,
								borderColor: "black",
								fontSize: 16,
								textAlign: "center",
							}}
							onChangeText={(value) => {
								setItemPrice(value);
							}}
						/>
					</View>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							marginVertical: 10,
						}}>
						<Text style={{ fontSize: 18, fontWeight: "bold" }}>
							Original Price
						</Text>
						<TextInput
							placeholder='Price'
							defaultValue={originalPrice}
							keyboardType='number-pad'
							style={{
								borderBottomWidth: 1,
								borderColor: "black",
								fontSize: 16,
								textAlign: "center",
							}}
							onChangeText={(value) => {
								setOriginalPrice(value);
							}}
						/>
					</View>

					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							marginVertical: 10,
						}}>
						<Text style={{ fontSize: 18, fontWeight: "bold" }}>
							Remove original price{" "}
						</Text>
						<Checkbox
							status={removeOriginalPrice ? "checked" : "unchecked"}
							onPress={() => {
								setRemoveOriginalPrice(!removeOriginalPrice);
							}}
							center
							checkedIcon='dot-circle-o'
							uncheckedIcon='circle-o'
							containerStyle={{
								backgroundColor: "white",
								borderColor: "white",
							}}
						/>
					</View>

					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							marginVertical: 10,
						}}>
						<Text style={{ fontSize: 18, fontWeight: "bold" }}>
							Available stock{" "}
						</Text>
						<TextInput
							keyboardType='number-pad'
							placeholder='eg. 5'
							defaultValue={availableStock}
							style={{
								borderBottomWidth: 1,
								borderColor: "black",
								fontSize: 16,
								textAlign: "center",
							}}
							onChangeText={(value) => {
								setAvailableStock(value);
							}}
						/>
					</View>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							marginVertical: 10,
						}}>
						<Text style={{ fontSize: 18, fontWeight: "bold" }}>
							Remove Available Stock{" "}
						</Text>
						<Checkbox
							status={removeAvaiableStock ? "checked" : "unchecked"}
							onPress={() => {
								setRemoveAvailableStock(!removeAvaiableStock);
							}}
							center
							checkedIcon='dot-circle-o'
							uncheckedIcon='circle-o'
							containerStyle={{
								backgroundColor: "white",
								borderColor: "white",
							}}
						/>
					</View>

					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							marginVertical: 10,
						}}>
						<Button title='Cancel' onPress={toggleOverlay} />

						<Button
							title='Change'
							onPress={() => updateStockItem(overlayItem.id)}
						/>
					</View>
				</View>
			</Overlay>
			<SafeAreaView style={{ flex: 1 }}>
				<ScrollView>
					<Header
						leftComponent={{
							icon: "menu",
							color: "#fff",
							onPress: () => navigation.openDrawer(),
						}}
						centerComponent={{ text: "Stock Update", style: { color: "#fff" } }}
						rightComponent={{
							icon: "home",
							color: "#fff",
						}}></Header>
					<View style={styles.areaview}>
						<View style={styles.container}>
							{userType === "Admin" && (
								<View style={styles.dropdown}>
									<Text style={styles.text}>Client</Text>
									<View style={styles.menu}>
										<RNPickerSelect
											onValueChange={(value) => {
												setClient(value);
												console.log("changed value1=", value);
												loadRestaurants(value);
											}}
											value={client}
											items={clients}
											placeholder={"Select a client"}
											useNativeAndroidPickerStyle={false}
											fixAndroidTouchableBug={true}
											style={pickerSelectStyles}
										/>
									</View>
								</View>
							)}
							{client ? (
								<View style={styles.dropdown}>
									<Text style={styles.text}>Restaurant / Shop</Text>
									<View style={styles.menu}>
										<RNPickerSelect
											onValueChange={(value) => {
												if (value) {
													setRestaurant(value);
													console.log("changed value2=", value);
													loadlocations(value);
													loadMenuGroups(value);
												}
											}}
											items={restaurants}
											value={restaurant}
											placeholder='Select a restaurant or shop'
											useNativeAndroidPickerStyle={false}
											fixAndroidTouchableBug={true}
											style={pickerSelectStyles}
										/>
									</View>
								</View>
							) : (
								<Text></Text>
							)}
							{client && restaurant ? (
								<View style={styles.dropdown}>
									<Text style={styles.text}>Location</Text>
									<View style={styles.menu}>
										<RNPickerSelect
											onValueChange={(value) => {
												setLocation(value);
												console.log("changed value3=", value);
												setMenuItems([]);
											}}
											items={locations}
											value={location}
											placeholder={"Select a Location"}
											useNativeAndroidPickerStyle={false}
											fixAndroidTouchableBug={true}
											style={pickerSelectStyles}
										/>
									</View>
								</View>
							) : (
								<Text></Text>
							)}

							{client && restaurant && location ? (
								<View style={styles.dropdown}>
									<Text style={styles.text}>Category</Text>
									<View style={styles.menu}>
										<RNPickerSelect
											onValueChange={(value) => {
												setMenuGroup(value);
												console.log("changed value4=", value);
												let groupName = menuGroupList.filter(
													(elem) => elem.value === value
												)[0]?.label;
												let groupId = menuGroupList.filter(
													(elem) => elem.value === value
												)[0]?.value;
												console.log("menu group id", groupId);
												console.log("menu group name", groupName);
												setMenuGroupName(groupName);
												setMenuGroupId(groupId);
												setMenuItems([]);
											}}
											items={menuGroupList}
											value={menuGroup}
											placeholder={"Select a Category"}
											useNativeAndroidPickerStyle={false}
											fixAndroidTouchableBug={true}
											style={pickerSelectStyles}
										/>
									</View>
								</View>
							) : (
								<Text></Text>
							)}

							{client && restaurant && location && menuGroup ? (
								<View style={{ alignItems: "center" }}>
									<TouchableOpacity onPress={showStocks} style={styles.button}>
										<Text style={styles.buttonText}>Show Stocks</Text>
									</TouchableOpacity>
								</View>
							) : (
								<Text></Text>
							)}
						</View>

						{loading == true ? (
							<ActivityIndicator size='large' color='#00ff00' />
						) : (
							<>
								{client && restaurant && location && data ? (
									<ScrollView>
										<View
											style={{
												marginVertical: 10,
												marginHorizontal: 5,
												padding: 10,
												backgroundColor: "white",
												shadowColor: "#000",
												shadowOffset: {
													width: 0,
													height: 5,
												},
												shadowOpacity: 0.36,
												shadowRadius: 6.68,
												elevation: 11,
											}}>
											<View
												style={{
													padding: 10,
													marginVertical: 10,
													borderRadius: 10,
													position: "relative",
												}}>
												<Text
													style={{
														textAlign: "center",
														fontSize: 20,
														fontWeight: "bold",
														textTransform: "uppercase",
													}}>
													{menuGroupName}
												</Text>
												{/* <View
													style={{
														position: "absolute",
														right: 20,
														// bottom: 30,
													}}>
													<Switch
														style={{
															marginBottom: `${
																Platform.OS == "web" ? "0" : "-15"
															}`,
														}}
														value={menuGroupAvailable}
														onValueChange={(value) => {
															setMenuGroupAvailable(value);
															updateMenuGroup(menuGroupId, value);
														}}
													/>
													<Text>
														{menuGroupAvailable ? "In stock" : "Out of stock"}
													</Text>
												</View> */}
											</View>
											{menuItems.map((element, elIndex) => (
												<View key={elIndex}>
													<View style={styles.subitems}>
														<View
															style={{ flexDirection: "row", width: "60%" }}>
															<Text
																style={{
																	backgroundColor: "olive",
																	color: "olive",
																}}>
																" "
															</Text>
															<View
																style={{
																	flexDirection: "column",
																	marginLeft: 10,
																}}>
																<Text style={{ fontSize: 17 }}>
																	{element.name}
																</Text>
																<Text style={styles.itemPrice}>
																	{`₹ ${element.price} `}
																	{element.originalPrice &&
																	<Text style={styles.itemOriginalPrice}>
																		{`₹ ${element.originalPrice} `}
																	</Text>
																	}
																	{element.originalPrice &&
																	<Text style={styles.itemPercentOff}>
																	{`${Math.round( (element.originalPrice - element.price) * 100 / element.originalPrice )}% Off`}
																	</Text>
																	}
																</Text>
															</View>
														</View>
														<View
															style={{
																alignItems: "center",
																marginRight: 6,
																width: "30%",
															}}>
															<Switch
																value={
																	!stocks["items"]
																		?.filter((elem) => elem.id == element.id)
																		.map((elem) => elem.outOfStock)[0]
																}
																onValueChange={(value) => {
																	setLoading(true);
																	updateStock(!value, element.id);
																	let stockList = [];
																	let atleastMatch = false;
																	stocks["items"]
																				.map((elem) => {
																					if(elem.id == element.id)
																					{
																						console.log('setting outOfStock to ', !value, 'for ', elem.id);
																						atleastMatch = true;
																						elem.outOfStock = !value;
																					}
																					stockList.push(elem);
																				});
																	if(!atleastMatch)
																	{
																		stockList.push({id: element.id, outOfStock: !value});
																	}
																	let newStocks = {...stocks};
																	newStocks["items"] = stockList;
																	console.log('stockList', stockList);
																	console.log('newStocks', newStocks);
																	setStocks(newStocks);
																	setLoading(false);
																}}
															/>

															<View style={{ marginTop: 0, paddingTop: 0 }}>
																{
																!stocks["items"]
																	?.filter((elem) => elem.id == element.id)
																	.map((elem) => elem.outOfStock)[0] ? (
																	<Text
																		style={{
																			fontSize: 13,
																			fontWeight: "bold",
																		}}>
																		In Stock
																	</Text>
																) : (
																	<Text
																		style={{
																			fontSize: 13,
																			fontWeight: "bold",
																		}}>
																		Out of Stock
																	</Text>
																)}
															</View>
														</View>
														<TouchableOpacity
															onPress={() => {
																setOverlayItem(element);
																setItemPrice(element.price.toString());
																setOriginalPrice(
																	element?.originalPrice?.toString()
																);
																setAvailableStock(
																	element?.availableStock?.toString()
																);
																toggleOverlay();
															}}>
															<AntDesign name='edit' size={26} color='green' />
														</TouchableOpacity>
													</View>
												</View>
											))}
										</View>
									</ScrollView>
								) : (
									<Text style={{ textAlign: "center", fontSize: 20 }}>
										No items found
									</Text>
								)}
							</>
						)}
					</View>
				</ScrollView>
			</SafeAreaView>
		</SafeAreaProvider>
	);
}
const styles = StyleSheet.create({
	areaview: {
		flex: 1,
		paddingVertical: Platform.OS !== "web" ? "5%" : 20,
		paddingHorizontal: Platform.OS !== "web" ? "5%" : 20,
	},
	container: {
		borderWidth: 1,
		borderColor: "grey",
		borderRadius: 15,
		flexDirection: "column",
		paddingVertical: Platform.OS !== "web" ? "4%" : 20,
		paddingHorizontal: Platform.OS !== "web" ? "4%" : 20,
		marginBottom: 8,
	},
	dropdown: {
		marginBottom: Platform.OS !== "web" ? 25 : 15,
	},
	text: {
		fontSize: 18,
		fontWeight: "bold",
	},
	menu: {},
	button: {
		backgroundColor: "green",
		width: 150,
		borderRadius: 5,
		height: 40,
		justifyContent: "center",
		alignItems: "center",
	},
	buttonText: {
		color: "white",
		textAlign: "center",
		fontSize: 20,
		fontWeight: "bold",
	},
	heading: {
		textAlign: "center",
		fontSize: 20,
		fontWeight: "bold",
		marginBottom: 5,
	},
	subitems: {
		flexDirection: "row",
		borderWidth: 1,
		borderColor: "grey",
		borderRadius: 15,
		padding: 10,
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 10,
	},
	switch: {
		alignItems: "center",
		backgroundColor: "yellow",
	},
	itemPrice: {
		fontWeight: '600',
	},
	itemOriginalPrice: {
		textDecorationLine: 'line-through', 
		textDecorationStyle: 'solid',
		opacity: 0.7,
		fontSize: 12
	},
	itemPercentOff: {
		backgroundColor: 'green',
		color: 'white',
		fontWeight: 'bold',
		fontSize: 14,
		marginHorizontal: 10
	},
});
const pickerSelectStyles = StyleSheet.create({
	inputIOS: {
		borderColor: "grey",
		borderBottomWidth: 1,
		paddingVertical: 1.5,
		paddingHorizontal: 4,
		paddingRight: 30,
		color: "black",
		fontSize: 17,
	},
	inputAndroid: {
		borderColor: "grey",
		color: "black",
		borderBottomWidth: 1,
		paddingVertical: 2,
		paddingHorizontal: 5,
		paddingRight: 30,
		fontSize: 17,
	},
});
