import {
	StyleSheet,
	Text,
	View,
	Image,
	Platform,
	TouchableOpacity,
} from "react-native";
import React, { useContext, useState } from "react";
import BannerDataEditable from "./BannerImageEditable";
import BannerData from "./BannerData";
import { UserContext } from "../src/context/userContext";
import Draggable from "react-draggable";
import { BottomSheet } from "react-native-btr";
import { Entypo } from "@expo/vector-icons";
import { TextInput } from "react-native-paper";
import * as htmlToImage from "html-to-image";

const PreviewBanner = ({ route, navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	const client = state?.user?.attributes["custom:client"];
	const { index, filter } = route.params;
	const [fontStyle, setFontStyle] = useState("normal");
	const [bottomText, setBottomText] = useState("");
	const [fontWeight, setFontWeight] = useState("100");
	const [color, setColor] = useState("");
	const [textList, setTextList] = useState([{}]);
	const [visible, setVisible] = useState(false);
	const [editingVisible, setEditingVisible] = useState(false);
	const [fontSize, setFontSize] = useState(20);
	const [textId, setTextId] = useState("");
	const [isEditing, setIsEditing] = useState(false);
	const toggleBottomNavigationView = () => {
		setVisible(!visible);
	};

	const captureImage = () => {
		htmlToImage
			.toPng(document.getElementById("image-container"))
			.then(function (dataUrl) {
				// console.log(dataUrl);
				var link = document.createElement("a");
				link.download = `${client}_banner.png`;
				link.href = dataUrl;
				link.click();
			});
	};
	return (
		<View>
			<BottomSheet
				visible={visible}
				onBackButtonPress={toggleBottomNavigationView}
				onBackdropPress={toggleBottomNavigationView}>
				<View style={{ backgroundColor: "#fff", width: "100%", height: 400 }}>
					<View>
						<View
							style={{
								paddingVertical: 8,
								margin: 10,
								marginHorizontal: 5,
								borderBottomWidth: 0.2,
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								position: "relative",
								borderColor: "lightgrey",
							}}>
							<Text
								style={{
									fontSize: 20,
									textAlign: "center",
									fontWeight: "bold",
								}}>
								Enter text to display in image{" "}
							</Text>

							<Entypo
								onPress={() => {
									// setCategory(elem.name);
									toggleBottomNavigationView();
								}}
								style={{ position: "absolute", right: 10 }}
								name='cross'
								size={24}
								color='black'
							/>
						</View>
					</View>
					<TextInput
						style={{
							...styles.textInput,
							marginHorizontal: 10,
							borderWidth: 1,
							borderBottomWidth: 0,
							borderColor: "grey",
						}}
						underlineColorAndroid='transparent'
						label='Enter text '
						autoCapitalize='none'
						onChangeText={(value) => {
							if (isEditing) {
								let textListNew = textList.map((elem) => {
									if (elem.index == textId) {
										elem.bottomText = value;
									}
									return elem;
								});
								setTextList(textListNew);
							} else {
								setBottomText(value);
							}
						}}
						value={
							isEditing
								? textList.filter((elem) => elem.index == textId)[0].bottomText
								: bottomText
						}
						theme={{ colors: { primary: "#136eb4" } }}
					/>
					<View style={{ justifyContent: "center", alignItems: "center" }}>
						<TouchableOpacity
							onPress={() => {
								toggleBottomNavigationView();
								if (!isEditing) {
									setTextList((prev) => [
										...prev,
										{
											bottomText: bottomText,
											fontStyle: fontStyle,
											fontWeight: fontWeight,
											color: color,
											index: textList.length,
										},
									]);
								}
							}}
							style={{
								backgroundColor: "#136eb4",
								padding: 10,
								borderRadius: 10,
								marginVertical: 10,
								paddingHorizontal: 20,
								marginHorizontal: 10,
							}}>
							<Text
								style={{
									color: "white",
								}}>
								Done{" "}
							</Text>
						</TouchableOpacity>
					</View>
				</View>
			</BottomSheet>
			<View
				style={{
					justifyContent: "center",
					alignItems: "center",
				}}>
				<div
					id='image-container'
					style={{
						marginVertical: 10,
						position: "relative",
						width: 350,
						height: 320,
					}}>
					<Image
						source={
							filter == "editable"
								? BannerDataEditable[index - 1].url
								: BannerData[index - 1].url
						}
						style={styles.image}
					/>
					{filter == "non-editable" && (
						<Draggable bounds='parent'>
							<Text
								style={{
									fontStyle: fontStyle,
									fontWeight: fontWeight,
									color: color,
									position: "absolute",
									bottom: 20,
								}}>{`https://${client}.delishtic.com`}</Text>
						</Draggable>
					)}
					{textList.map((elem, index) => {
						console.log("elem index", elem.index, textId);
						return (
							<Draggable bounds='parent'>
								<TouchableOpacity
									onPress={() => {
										setTextId(elem.index);
										setIsEditing(true);
										setEditingVisible(true);
									}}
									style={{
										cursor: "pointer",
										position: "absolute",
										bottom: 20,
										borderColor:
											textId == elem.index ? "#136eb4" : "transparent",
									}}>
									<Text
										style={{
											fontStyle: elem?.fontStyle,
											fontWeight: elem?.fontWeight,
											fontSize: `${elem?.fontSize}px`,
											color: elem?.color,
										}}>
										{elem?.bottomText}
									</Text>
								</TouchableOpacity>
							</Draggable>
						);
					})}
				</div>
			</View>
			<div
				style={{
					flexDirection: "row",
					justifyContent: "center",
					display: "flex",
					alignItems: "center",
					marginVertical: 10,
				}}>
				{filter == "editable" && (
					<button
						onClick={() => {
							toggleBottomNavigationView();
							setIsEditing(false);
						}}
						style={{
							backgroundColor: "#136eb4",
							padding: 10,
							borderRadius: 10,
							marginVertical: 10,
							paddingHorizontal: 20,
							marginRight: 10,
							color: "white",
							border: "none",
						}}>
						Add Text
					</button>
				)}

				<button
					onClick={() => {
						captureImage();
					}}
					style={{
						backgroundColor: "#136eb4",
						padding: 10,
						color: "white",
						borderRadius: 10,
						marginVertical: 10,
						paddingHorizontal: 20,
						marginHorizontal: 10,
						border: "none",
					}}>
					Download{" "}
				</button>
			</div>
			{Platform.OS == "web" && editingVisible && (
				<>
					<div
						style={{
							padding: "20px",
						}}>
						<div>
							<h4>Selected text :</h4>
							<p>{textList[textId]?.bottomText}</p>
						</div>
						<button
							onClick={() => {
								let textListNew = textList.filter(
									(elem) => elem.index != textId
								);
								setTextList(textListNew);
								setEditingVisible(false);
							}}
							style={{
								backgroundColor: "#136eb4",
								padding: 10,
								borderRadius: 10,
								marginVertical: 10,
								paddingHorizontal: 20,
								marginHorizontal: 10,
								border: "none",
								color: "white",
								marginRight: 30,
							}}>
							Remove text
						</button>

						<button
							onClick={() => {
								// setBottomText(textList[textId]?.bottomText);
								setVisible(true);
								setIsEditing(true);
							}}
							style={{
								backgroundColor: "#136eb4",
								padding: 10,
								borderRadius: 10,
								marginVertical: 10,
								paddingHorizontal: 20,
								marginHorizontal: 10,
								border: "none",
								color: "white",
							}}>
							Edit text
						</button>
						<h1
							style={{
								fontSize: "20px",
							}}>
							Select text style
						</h1>
						<button
							onClick={() => {
								setFontStyle("normal");
								let textListNew = textList.map((elem) => {
									if (elem.index == textId) {
										elem.fontStyle = "normal";
									}
									return elem;
								});
								setTextList(textListNew);
							}}
							style={{
								backgroundColor:
									fontStyle == "normal" ? "#136eb4" : "lightgrey",
								padding: "10px 20px",
								borderRadius: 10,
								color: "white",
								marginRight: 10,
								border: "none",
								fontSize: 18,
							}}>
							Normal
						</button>
						<button
							onClick={() => {
								setFontStyle("Italic");
								let textListNew = textList.map((elem) => {
									if (elem.index == textId) {
										elem.fontStyle = "italic";
									}
									return elem;
								});
								setTextList(textListNew);
							}}
							style={{
								backgroundColor:
									fontStyle == "Italic" ? "#136eb4" : "lightgrey",
								padding: "10px 20px",
								borderRadius: 10,
								color: "white",
								marginRight: 10,
								border: "none",
								fontSize: 18,
								fontStyle: "italic",
							}}>
							Italic
						</button>
					</div>
					<div
						style={{
							padding: "20px",
						}}>
						<h1
							style={{
								fontSize: "20px",
							}}>
							Adjust font-Weight
						</h1>
						<p
							style={{
								fontWeight: fontWeight,
							}}>
							{fontWeight}
						</p>
						<input
							onChange={(e) => {
								setFontWeight(e.target.value);
								let textListNew = textList.map((elem) => {
									if (elem.index == textId) {
										elem.fontWeight = e.target.value;
									}
									return elem;
								});
								setTextList(textListNew);
							}}
							type='range'
							max={900}
							step={100}
							min={100}
						/>
					</div>
					<div
						style={{
							padding: "20px",
						}}>
						<h1
							style={{
								fontSize: "20px",
							}}>
							Adjust font-size
						</h1>
						<p
							style={{
								fontSize: `${fontSize}px`,
							}}>
							{fontSize}
						</p>
						<input
							onChange={(e) => {
								setFontSize(e.target.value);
								let textListNew = textList.map((elem) => {
									if (elem.index == textId) {
										elem.fontSize = e.target.value;
									}
									return elem;
								});
								setTextList(textListNew);
							}}
							type='range'
							max={100}
							min={20}
						/>
					</div>
					<div
						style={{
							padding: "20px",
						}}>
						<h1
							style={{
								fontSize: "20px",
							}}>
							Adjust Text color
						</h1>

						<input
							type='color'
							onChange={(e) => {
								setColor(e.target.value);
								let textListNew = textList.map((elem) => {
									if (elem.index == textId) {
										elem.color = e.target.value;
									}
									return elem;
								});
								setTextList(textListNew);
							}}
						/>
					</div>
				</>
			)}
		</View>
	);
};

export default PreviewBanner;

const styles = StyleSheet.create({
	image: {
		width: 350,
		height: 320,
		resizeMode: "cover",
		borderRadius: 10,
		marginBottom: 20,
	},
});
