import {
	Platform,
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
} from "react-native";
import React, { useContext, useEffect, useRef, useState } from "react";
import * as DocumentPicker from "expo-document-picker";
import * as XLSX from "xlsx";
import { Dirs, FileSystem } from "react-native-file-access";
import FileViewer from "react-native-file-viewer";
import RNPickerSelect from "react-native-picker-select";
import { MaterialIcons, Feather, AntDesign } from "@expo/vector-icons";

import {
	API_URL_CLIENT,
	API_URL_FULL_MENUS,
	API_URL_LOCATIONS,
	API_URL_MENU_GROUPS,
	API_URL_MENU_ITEMS,
	API_URL_RESTAURANT,
	API_URL_STOCK,
	SERVER_API_URL,
} from "../config/config";
import axios from "axios";
import { UserContext } from "../src/context/userContext";
import FileSaver from "file-saver";

const BulkUpload = () => {
	const { state, dispatch } = useContext(UserContext);
	const userType = state.userType;
	const assignedClient = state.user.attributes["custom:client"];
	const [items, setItems] = useState([]);
	const [menuItems, setMenuItems] = useState([]);
	const [file, setFile] = useState(null);
	const [client, setClient] = useState(assignedClient ? assignedClient : undefined);
	const [clients, setClients] = useState([]);
	const [restaurants, setRestaurants] = useState([]);
	const [restaurant, setRestaurant] = useState();
	const [location, setLocation] = useState();
	const [locations, setLocations] = useState([]);
	const [menuGroup, setMenuGroup] = useState();
	const [menuGroupName, setMenuGroupName] = useState("");
	const [menuGroupList, setMenuGroupList] = useState([]);
	const [data, setData] = useState([]);
	const [menuGroupId, setMenuGroupId] = useState("");
	const [menuItemList, setMenuItemList] = useState([]);
	const [stocks, setStocks] = useState([]);
	const [updateStatus, setUpdateStatus] = useState([false]);

	// const [downloadDone, setDownloadDone] = useState(false);
	const ref = useRef();

	const reset = () => {
		if (ref.current == null) {
			return;
		}
		ref.current.value = null;
		setFile(null);

		// setClient("");
		// setLocation("");
		// setMenuGroup("");
		// setRestaurant("");
	};
	const restructureRestaurant = (item) => {
		let labels = [];
		if (item.length) {
			for (let i = 0; i < item.length; i++) {
				labels[i] = { label: item[i].name, value: item[i].id };
			}
		}
		setRestaurants(labels);
		// console.log("labels=", labels)
		setRestaurant(labels[0].value);
		console.log("labels=", restaurants);
	};
	const restructureLocation = (item) => {
		let labels = [];
		if (item.length) {
			for (let i = 0; i < item.length; i++) {
				labels[i] = { label: item[i].name, value: item[i].id };
			}
		}

		setLocations(labels);
		setLocation(labels[0].value);
		// console.log("labels=", labels)

		//  console.log("labels=", locations)
	};
	const restructureclient = (item) => {
		let labels = [];
		if (item.length) {
			for (let i = 0; i < item.length; i++) {
				labels[i] = { label: item[i].clientName, value: item[i].slug };
			}
		}
		setClients(labels);

		// console.log("labels=", labels)
	};
	const restructureMenuGroups = (items) => {
		let labels = [];
		items.sort((a, b) => a.priority - b.priority);
		if (items.length) {
			for (let i = 0; i < items.length; i++) {
				labels[i] = { label: items[i].name, value: items[i].id };
			}
		}
		setMenuGroupList(labels);
		setMenuGroup(labels[0].value);
		setMenuGroupName(labels[0].label);
		setMenuGroupId(labels[0].value);
	};
	const loadMenuGroups = async (value) => {
		await axios
			.get(`${SERVER_API_URL}${API_URL_MENU_GROUPS}`, {
				headers: {
					restaurantGroup: value,
				},
			})
			.then((res) => {
				console.log("menuGroups=", res.data);
				restructureMenuGroups(res.data);
				setData([]);
			})
			.catch((err) => {
				console.log("err4=", err);
				setMenuGroupList([]);
				setData([]);
			});
	};
	const loadRestaurants = async (value) => {
		await axios
			.get(`${SERVER_API_URL}${API_URL_RESTAURANT}${API_URL_CLIENT}`, {
				headers: {
					client: value,
				},
			})
			.then((res) => {
				// console.log("clients=", res.data)
				restructureRestaurant(res.data);
			})
			.catch((err) => {
				// console.log("err2=", err);
				setRestaurants([]);
			});
	};
	const loadlocations = async (value) => {
		await axios
			.get(`${SERVER_API_URL}${API_URL_LOCATIONS}`, {
				headers: {
					restaurantGroup: value,
				},
			})
			.then((res) => {
				// console.log("clients=", res.data)
				restructureLocation(res.data);
				setData([]);
			})
			.catch((err) => {
				console.log("err3=", err);
				setLocations([]);
			});
	};
	const loadClients = async () => {
		await axios
			.get(SERVER_API_URL + API_URL_CLIENT, {
				headers: {
					Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				},
			})
			.then((res) => {
				// console.log("clients=", res.data);
				restructureclient(res.data);
			})
			.catch((err) => {
				console.log("err1=", err);
			});
	};

	const readExcel = (file) => {
		const promise = new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsArrayBuffer(file);

			fileReader.onload = (e) => {
				const bufferArray = e.target.result;

				const wb = XLSX.read(bufferArray, { type: "buffer" });

				const wsname = wb.SheetNames[0];

				const ws = wb.Sheets[wsname];

				const data = XLSX.utils.sheet_to_json(ws);

				resolve(data);
			};

			fileReader.onerror = (error) => {
				reject(error);
			};
		});

		promise.then((d) => {
			console.log(d);
			setItems(d);
		});
	};

	var itemData = [];
	const getMenuItems = async (searchTerm) => {
		console.log("runs", searchTerm);
		try {
			const { data } = await axios.get(
				`${SERVER_API_URL}${API_URL_MENU_ITEMS}/search`,
				{
					headers: {
						client: assignedClient ? assignedClient : client,
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
						search: searchTerm,
					},
				}
			);

			// console.log(data);
			itemData.push(data);

			setMenuItems(itemData);

			// console.log("menu items", itemData);
		} catch (error) {
			console.log(error);
		}
	};

	// console.log("menu items", menuItems);
	const updateData = async () => {
		let check = false;

		for (let i = 0; i < menuItems.length; i++) {
			let price = "";
			console.log('menuItems', menuItems[i].items[0]);
			let outOfStockStatus = false;
			let currentItem = items.filter(itemVal => itemVal["Item Name"].toUpperCase() === menuItems[i].items[0].name.toUpperCase())[0];
			if (currentItem["Available (Y/N)"] === "N") {
				outOfStockStatus = true;
			}
			price = currentItem["Selling Price"];
			console.log('currentItem', currentItem);

			await axios
				.post(
					`${SERVER_API_URL}${API_URL_STOCK}`,
					{
						items: [
							{ id: menuItems[i].items[0].id, outOfStock: outOfStockStatus },
						],
					},
					{
						headers: {
							client: client,
							restaurantGroup: restaurant,
							locationId: location,
							Authorization:
								state?.user?.signInUserSession?.idToken["jwtToken"],
						},
					}
				)
				.then((res) => {
					console.log("first api updated data are ", res.data);

					if(price)
					{
						axios
							.patch(
								`${SERVER_API_URL}${API_URL_MENU_ITEMS}/${menuItems[i].items[0].id}`,
								{
									price: parseInt(price),
								},
								{
									headers: {
										client: client,
										Authorization:
											state?.user?.signInUserSession?.idToken["jwtToken"],
									},
								}
							)

							.then((res) => {
								console.log("2nd api call data ==", res.data);
								setUpdateStatus([...updateStatus, (updateStatus[i] = true)]);
							})
							.catch((err) => console.log("2nd api error", err));
					}
					check = true;
				})
				.catch((err) => console.log("1st api err==", err));
		}
		if (check) {
			alert("Successfully updated");
		}
	};
	const searchItem = () => {
		console.log("items", items);
		items.map((elem) => getMenuItems(elem["Item Name"]));
	};

	const getMenuItemList = async () => {
		console.log("client ", client);
		console.log("location ", location);
		console.log("restaurant ", restaurant);
		console.log("menuGroup ", menuGroup);
		// setLoading(true);
		console.log("show runs");
		await axios
			.get(`${SERVER_API_URL}${API_URL_MENU_GROUPS}${API_URL_MENU_ITEMS}`, {
				headers: {
					menugroupid: menuGroup,
				},
			})

			.then((res) => {
				// setData(res.data);
				let itemList = res.data.items;
				itemList.sort((a, b) => a.priority - b.priority);
				setMenuItemList(itemList);
				console.log("Menu Items are", res.data);
			})
			.catch((err) => {
				console.log("menu err=", err);
				//setMenuGroups([]);
			});
	};
	const getStockData = async () => {
		await axios
			.get(`${SERVER_API_URL}${API_URL_STOCK}`, {
				headers: {
					client: client,
					restaurantGroup: restaurant,
					locationId: location,
				},
			})
			.then((response) => {
				setStocks(response.data);
				console.log("Stocks are", response.data);
				// setLoading(false);
				jsonToExcel();
			})
			.catch((err) => {
				// setLoading(false);
				console.log("stocks err is ", err);
			});
	};

	const generateData = async () => {
		console.log("generate excel run");
		let data = [];
		let itemList = [];
		let stockList = {};
		await axios
			.get(`${SERVER_API_URL}${API_URL_MENU_GROUPS}${API_URL_MENU_ITEMS}`, {
				headers: {
					menugroupid: menuGroup,
				},
			})

			.then((res) => {
				// setData(res.data);
				itemList = res.data.items;
				itemList.sort((a, b) => a.priority - b.priority);
				setMenuItemList(itemList);
				console.log("Menu Items are", res.data);
			})
			.catch((err) => {
				console.log("menu err=", err);
				//setMenuGroups([]);
			});
		await axios
			.get(`${SERVER_API_URL}${API_URL_STOCK}`, {
				headers: {
					client: client,
					restaurantGroup: restaurant,
					locationId: location,
				},
			})
			.then((response) => {
				stockList = response.data;
				setStocks(stockList);
			})
			.catch((err) => {
				// setLoading(false);
				console.log("stocks err is ", err);
			}).finally(() => {
				itemList.map((elem) => {
					let currentStockItem = stockList["items"]?.filter((element) => element.id === elem.id)[0];
					console.log("second runs", elem, currentStockItem);

					let availableFlag = "Y";
					if (!currentStockItem) {
						availableFlag = "Y";
					}
					else if (currentStockItem && currentStockItem["outOfStock"] == true) {
						availableFlag = "N";
					} else {
						availableFlag = "Y";
					}
					data.push({
						["Item Name"]: elem.name,
						["Selling Price"]: elem.price,
						Category: menuGroupName,
						["Available (Y/N)"]: availableFlag,
					});
				});
				console.log("data is ", data);
				downloadData(data);
			});
	};
	const downloadData = (data) => {
		let worksheet = XLSX.utils.json_to_sheet(data);
		console.log(data.length);
		if (data.length == 0) {
			// generateData();
			alert("Something went wrong try again !!");
			// setDownloadDone(true);
			return;
		} else {
			const fileType =
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
			const fileExtension = ".xlsx";
			const fileName = `${menuGroupName}_Stock_Update`;
			const wb = { Sheets: { data: worksheet }, SheetNames: ["data"] };
			const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
			const fileData = new Blob([excelBuffer], { type: fileType });
			FileSaver.saveAs(fileData, fileName + fileExtension);
		}
	};
	useEffect(() => {
		searchItem();
	}, [items]);

	useEffect(() => {
		loadClients();
		if (assignedClient) {
			loadRestaurants(assignedClient);
		}
	}, []);

	return (
		<View style={styles.mainView}>
			{Platform.OS == "web" ? (
				<>
					<View
						style={{ flexDirection: "row", justifyContent: "space-between" }}>
						<TouchableOpacity onPress={() => reset()}>
							<Feather name='refresh-ccw' size={24} color='green' />
							<Text>Refresh</Text>
						</TouchableOpacity>

						{client && restaurant && location && menuGroup && (
							<TouchableOpacity onPress={() => generateData()}>
								<AntDesign name='download' size={24} color='green' />
								<Text>Download Stock Data</Text>
							</TouchableOpacity>
						)}
					</View>
					{userType === "Admin" && (
						<View style={styles.dropdown}>
							<Text
								style={{
									color: "black",
									marginVertical: 10,
									fontWeight: "bold",
								}}>
								Select Client
							</Text>
							<View style={styles.menu}>
								<RNPickerSelect
									onValueChange={(value) => {
										setClient(value);
										console.log("changed value1=", value);
										// setDownloadDone(false);
										reset();

										loadRestaurants(value);
									}}
									value={client}
									items={clients}
									placeholder={"Select a client"}
									useNativeAndroidPickerStyle={false}
									fixAndroidTouchableBug={true}
									style={pickerSelectStyles}
								/>
							</View>
						</View>
					)}
					{client ? (
						<View style={styles.dropdown}>
							<Text
								style={{
									color: "black",
									marginVertical: 10,
									fontWeight: "bold",
								}}>
								Restaurant / Shop
							</Text>
							<View style={styles.menu}>
								<RNPickerSelect
									onValueChange={(value) => {
										if (value) {
											setRestaurant(value);
											// setDownloadDone(false);
											reset();
											loadlocations(value);
											loadMenuGroups(value);
										}
									}}
									items={restaurants}
									value={restaurant}
									placeholder='Select a restaurant or shop'
									useNativeAndroidPickerStyle={false}
									fixAndroidTouchableBug={true}
									style={pickerSelectStyles}
								/>
							</View>
						</View>
					) : (
						<Text></Text>
					)}
					{client && restaurant ? (
						<View style={styles.dropdown}>
							<Text
								style={{
									color: "black",
									marginVertical: 10,
									fontWeight: "bold",
								}}>
								Location
							</Text>
							<View style={styles.menu}>
								<RNPickerSelect
									onValueChange={(value) => {
										setLocation(value);
										// setDownloadDone(false);
										reset();
										setMenuItems([]);
									}}
									items={locations}
									value={location}
									placeholder={"Select a Location"}
									useNativeAndroidPickerStyle={false}
									fixAndroidTouchableBug={true}
									style={pickerSelectStyles}
								/>
							</View>
						</View>
					) : (
						<Text></Text>
					)}
					{client && restaurant && location ? (
						<View style={styles.dropdown}>
							<Text
								style={{
									color: "black",
									marginVertical: 10,
									fontWeight: "bold",
								}}>
								Category
							</Text>
							<View style={styles.menu}>
								<RNPickerSelect
									onValueChange={(value) => {
										setMenuGroup(value);
										console.log("changed value4=", value);
										let groupName = menuGroupList.filter(
											(elem) => elem.value === value
										)[0]?.label;
										let groupId = menuGroupList.filter(
											(elem) => elem.value === value
										)[0]?.value;
										// console.log("menu group id", groupId);
										// console.log("menu group name", groupName);
										setMenuGroupName(groupName);
										setMenuGroupId(groupId);
										// setDownloadDone(false);
										reset();
										setMenuItems([]);
									}}
									items={menuGroupList}
									value={menuGroup}
									placeholder={"Select a Category"}
									useNativeAndroidPickerStyle={false}
									fixAndroidTouchableBug={true}
									style={pickerSelectStyles}
								/>
							</View>
						</View>
					) : (
						<Text></Text>
					)}
					{client && restaurant && location && (
						<>
							<label
								style={{
									fontWeight: "bold",
									marginTop: 10,
									fontSize: 18,
									fontFamily: "monospace",
									cursor: "pointer",
								}}
								for='stockInput'>
								Upload stock data excel sheet
							</label>
							<input
								id='stockInput'
								ref={ref}
								style={{
									cursor: "pointer",
									backgroundColor: "rgb(56,81,126)",
									padding: 10,
									borderRadius: 10,
									marginTop: 5,
									color: "white",
									textAlign: "center",
								}}
								value={file}
								onChange={(e) => {
									console.log(e.target.value);
									const file = e.target.files[0];
									console.log('file', file);
									if(file)
									{
										setFile(e.target.value);
										readExcel(file);
									}
									else
									{
										reset();
									}
								}}
								type='file'
								accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
							/>
						</>
					)}

					{items.length > 0 && file && (
						<>
							<table
								style={{
									width: "100%",
									backgroundColor: "white",
									borderCollapse: "collapse",
									borderWidth: "2px",
									borderColor: "#7ea8f8",
									borderStyle: "solid",
									marginTop: "20px",
								}}>
								<thead
									style={{
										backgroundColor: " #7ea8f8",
										color: "white",
									}}>
									<tr>
										<th
											style={{
												borderWidth: " 2px",
												borderColor: "#7ea8f8",
												borderStyle: "solid",
												padding: "5px",
											}}>
											Update status
										</th>
										<th
											style={{
												borderWidth: " 2px",
												borderColor: "#7ea8f8",
												borderStyle: "solid",
												padding: "5px",
											}}>
											Item Name
										</th>
										<th
											style={{
												borderWidth: " 2px",
												borderColor: "#7ea8f8",
												borderStyle: "solid",
												padding: "5px",
											}}>
											Selling Price
										</th>
										<th
											style={{
												borderWidth: " 2px",
												borderColor: "#7ea8f8",
												borderStyle: "solid",
												padding: "5px",
											}}>
											Available (Y/N)
										</th>
									</tr>
								</thead>
								<tbody>
									{items.map((elem, idx) => (
										<tr key={elem["Item Name"]}>
											<th
												style={{
													borderWidth: " 2px",
													borderColor: "#7ea8f8",
													borderStyle: "solid",
													padding: "5px",
												}}>
												{updateStatus[idx] == true ? (
													<AntDesign
														name='checkcircle'
														size={24}
														color='green'
													/>
												) : (
													<MaterialIcons name='error' size={24} color='red' />
												)}
											</th>
											<th
												style={{
													borderWidth: " 2px",
													borderColor: "#7ea8f8",
													borderStyle: "solid",
													padding: "5px",
												}}>
												{elem["Item Name"]}
											</th>
											<th
												style={{
													borderWidth: " 2px",
													borderColor: "#7ea8f8",
													borderStyle: "solid",
													padding: "5px",
												}}>
												{elem["Selling Price"]}
											</th>
											<th
												style={{
													borderWidth: " 2px",
													borderColor: "#7ea8f8",
													borderStyle: "solid",
													padding: "5px",
												}}>
												{elem["Available (Y/N)"]}
											</th>
										</tr>
									))}
								</tbody>
							</table>
							<TouchableOpacity
								onPress={() => updateData()}
								style={styles.btnStyles}>
								<Text style={styles.text}>Bulk Update</Text>
							</TouchableOpacity>
						</>
					)}
				</>
			) : (
				<View style={{ justifyContent: "center", alignItems: "center" }}>
					<Text>You need to login in website to view this page</Text>
				</View>
			)}
		</View>
	);
};

export default BulkUpload;

const styles = StyleSheet.create({
	mainView: {
		padding: 20,
	},
	btnStyles: {
		backgroundColor: "rgb(56,81,126)",
		padding: 10,
		borderRadius: 10,
		marginTop: 10,
	},
	text: {
		color: "white",
		textAlign: "center",
	},
});
const pickerSelectStyles = StyleSheet.create({
	inputIOS: {
		borderColor: "grey",
		borderBottomWidth: 1,
		paddingVertical: 1.5,
		paddingHorizontal: 4,
		paddingRight: 30,
		color: "black",
		fontSize: 17,
	},
	inputAndroid: {
		borderColor: "grey",
		color: "black",
		borderBottomWidth: 1,
		paddingVertical: 2,
		paddingHorizontal: 5,
		paddingRight: 30,
		fontSize: 17,
	},
});
