const AMPLIFY_CONFIG = {
  aws_project_region: "ap-south-1",

  aws_cognito_region: "ap-south-1",
  aws_cognito_identity_pool_id:
    "ap-south-1:a0dc2500-37fc-4028-a2e1-39ce6eaf2562",
  aws_user_pools_id: "ap-south-1_Uvwz88vHx",
  aws_user_pools_web_client_id: "2k8mrgr3j5ltfb2mau97m47cjj",

  aws_user_files_s3_bucket: "images.delishtic.com",
  aws_user_files_s3_bucket_region: "ap-south-1",
};

export default AMPLIFY_CONFIG;
