import React, { useContext } from "react";
import { Card, Header, Button, Icon } from "react-native-elements";
import {
	View,
	Text,
	TouchableOpacity,
	StyleSheet,
	Alert,
	ScrollView,
} from "react-native";
import { NavigationContainer, useFocusEffect } from "@react-navigation/native";
import axios from "axios";
import { FlatList } from "react-native-gesture-handler";
import Entypo from "react-native-vector-icons/Entypo";
import Feather from "react-native-vector-icons/Feather";
import {
    SERVER_API_URL,
	API_URL_CLIENT
} from "../../config/config";
import { UserContext } from "../../src/context/userContext";

export const client = ({ navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	const [client, updateClient] = React.useState([]);
	const [refresh, updateRefresh] = React.useState(false);
	console.log('state.user', state.user);
	const getClient = async () => {
		console.log('getClient in client');
		await axios
			.get(SERVER_API_URL + API_URL_CLIENT, {
				headers: {
					Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				},
			})
			.then((res) => updateClient(res.data))
			.catch((err) => console.log(err));
	};

	const deleteClient = async (id) => {
		console.log(id);
		await axios
			.delete(`${SERVER_API_URL}${API_URL_CLIENT}/${id}`, {
				headers: {
					Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				},
			})
			.then((res) => {
				console.log(res), updateRefresh(!refresh);
			})
			.catch((err) => console.log(err));

		alert("Client is removed Sucessfully!");
	};
	useFocusEffect(
		React.useCallback(() => {
			getClient();
			return () => {
				// Do something when the screen is unfocused
				// Useful for cleanup functions
			};
		}, [refresh])
	);
	const renderItem = ({ item }) => {
		return (
			<TouchableOpacity
				style={{
					marginVertical: 10,
					marginHorizontal: 15,
					padding: 10,
					backgroundColor: "white",
					shadowColor: "#000",
					shadowOffset: {
						width: 0,
						height: 5,
					},
					shadowOpacity: 0.36,
					shadowRadius: 6.68,

					elevation: 11,
				}}
				key={item.id}>
				<View containerStyle={{ borderRadius: 20 }}>
					<Card.Title>{item.clientName}</Card.Title>

					<Card.Divider></Card.Divider>
					<View style={styles.buttons}>
						<TouchableOpacity
							onPress={() => {
								Alert.alert(
									"Deleting this Client will also remove all its Restaurants.",
									"Are you sure you want to delete this Client?",
									[
										{
											text: "Cancel",
											onPress: () => console.log("Cancel Pressed"),
											style: "cancel",
										},
										{ text: "OK", onPress: () => deleteClient(item.slug) },
									],
									{ cancelable: false }
								);
							}}>
							<Text>Delete</Text>
							<Icon
								type='material-community'
								name='delete'
								size={24}
								color='red'
							/>
						</TouchableOpacity>
						<TouchableOpacity
							style={{ marginLeft: 30 }}
							onPress={() =>
								navigation.navigate("CreateClient", {
									item: item,
									type: "edit",
								})
							}>
							<Text>Edit</Text>
							<Icon
								type='material-community'
								name='pencil'
								size={24}
								color='green'
							/>
							{/* <Entypo name="edit" size={30} color="green"></Entypo> */}
						</TouchableOpacity>
					</View>
				</View>
			</TouchableOpacity>
		);
	};

	return (
		<ScrollView style={{ paddingBottom: 180 }}>
			{/*    <MyContext.Provider value={{ cars: "try" }}>

            </MyContext.Provider> */}
			{/*  <MyContext.Consumer>
                {
                    response => (updateTest(response.cars))
                }
            </MyContext.Consumer> */}
			<Header
				leftComponent={{
					icon: "menu",
					color: "#fff",
					onPress: () => navigation.openDrawer(),
				}}
				centerComponent={{ text: "CLIENTS", style: { color: "#fff" } }}
				rightComponent={{
					icon: "home",
					color: "#fff",
					onPress: () => navigation.navigate("Dashboard"),
				}}></Header>
			<Card
				containerStyle={{
					backgroundColor: "lightblue",
					borderRadius: 20,
					elevation: 5,
				}}>
				{/* <Card.Title> Create New Client</Card.Title> */}
				<TouchableOpacity>
					<Button
						title='Create New Client'
						onPress={() =>
							navigation.navigate("CreateClient", { type: "create" })
						}
						icon={{ name: "add", size: 20, color: "white" }}
						buttonStyle={{
							width: "100%",
							borderRadius: 35,
							backgroundColor: "olive",
						}}
						containerStyle={{
							width: "75%",
							height: 50,
							marginTop: 20,
							alignSelf: "center",
						}}></Button>
					{/* <Entypo name="plus" size={30} color="green"></Entypo> */}
				</TouchableOpacity>
			</Card>
			<FlatList
				data={client}
				renderItem={renderItem}
				style={{ marginBottom: 20 }}
			/>
		</ScrollView>
	);
};
const styles = StyleSheet.create({
	text: {
		fontSize: 15,
		color: "green",
	},
	addButton: {
		flex: 1,
		paddingLeft: 140,
		paddingTop: 30,
	},
	CardText: {
		fontWeight: "bold",
		color: "red",
		fontSize: 15,
	},
	buttons: {
		flex: 1,
		flexDirection: "row",
		alignSelf: "center",
	},
});
