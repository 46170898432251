import React, { useEffect, useState, useContext } from "react";
import {
	StyleSheet,
	Text,
	View,
	TouchableOpacity,
	FlatList,
} from "react-native";
import { Card, Overlays } from "react-native-elements";
import Entypo from "react-native-vector-icons/Entypo";
import axios from "axios";
import RNPickerSelect from "react-native-picker-select";
import { AntDesign } from "@expo/vector-icons";
import { ScrollView } from "react-native-gesture-handler";
import { useFocusEffect } from "@react-navigation/native";
import { SERVER_API_URL, API_URL_MENUS } from "../../config/config";
import { UserContext } from "../../src/context/userContext";

const Menus = ({ route, navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	const [items, setItems] = useState([]);
	const [focus, updateFocus] = useState(false);
	const [api, setApi] = useState(`${SERVER_API_URL}${API_URL_MENUS}`);
	const id = route.params.id;
	console.log("Route is", route);
	const clientname = route.params.clientname;
	const businessType = route.params.businessType;
	console.log("id is ", id);

	const getMenus = async () => {
		console.log("Api is ", api);
		try {
			const response = await axios.get(api, {
				headers: {
					restaurantgroup: id,
				},
			});
			console.log(response.data);
			setItems(response.data);
		} catch (error) {
			console.log(error);
			setItems([]);
		}
	};
	const deleteItem = async (id) => {
		await axios
			.delete(`${SERVER_API_URL}${API_URL_MENUS}/${id}`, {
				headers: {
					Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				},
			})
			.then(alert("Deleted"))
			.catch((err) => console.log(err));
		return updateFocus(!focus);
	};

	// useEffect(() => {
	// 	getMenus();
	// }, [focus, api]);

	useFocusEffect(
		React.useCallback(() => {
			getMenus();
			return () => getMenus();
		}, [focus, api])
	);
	const renderItem = ({ item }) => {
		console.log("menu is ", item);
		// console.log("item", item.media.restaurantBanner);
		return (
			<>
				<View style={styles.mainView}>
					<View style={styles.itemView}>
						<Text style={styles.label}>Name :</Text>
						<Text style={{ fontSize: 16 }}> {item.name}</Text>
					</View>
					<View style={styles.itemView}>
						<Text style={styles.label}>Priority :</Text>
						<Text style={{ fontSize: 16 }}> {item.priority}</Text>
					</View>
					{item.menuType && (
						<View style={styles.itemView}>
							<Text style={styles.label}>Menu Type :</Text>
							<Text style={{ fontSize: 16 }}> {item.menuType}</Text>
						</View>
					)}

					<View style={styles.buttons}>
						<AntDesign
							onPress={() =>
								navigation.navigate("CreateEditMenu", {
									id: id,
									clientname: clientname,
									item: item,
									businessType: businessType,
									type: "edit",
								})
							}
							name='edit'
							size={24}
							color='green'
						/>
						<AntDesign
							onPress={() => deleteItem(item.id)}
							name='delete'
							size={24}
							color='red'
						/>
					</View>
				</View>
			</>
		);
	};
	return (
		<>
			<View style={{ flex: 1 }}>
				<Card>
					<Text style={styles.text}>Restaurant Menu</Text>
					<Card.Title style={styles.addButton}>
						<TouchableOpacity
							onPress={() =>
								navigation.navigate("CreateEditMenu", {
									id: id,
									clientname: clientname,
									businessType: businessType,

									type: "create",
								})
							}>
							<Entypo name='plus' size={30} color='green' />
							<Text>Add</Text>
						</TouchableOpacity>
					</Card.Title>
				</Card>
				<View style={{ paddingHorizontal: 40, paddingVertical: 10 }}>
					<RNPickerSelect
						fixAndroidTouchableBug={true}
						style={pickerSelectStyles}
						useNativeAndroidPickerStyle={false}
						placeholder={{ label: "Select Menu Type" }}
						items={[
							{ label: "All", value: null },
							{ label: "Delivery", value: "delivery" },
							{ label: "Pickup", value: "pickup" },
						]}
						onValueChange={(value) => {
							// setQuery(value);
							console.log("value is", value);
							if (value == null) {
								setApi(`${SERVER_API_URL}${API_URL_MENUS}`);
							} else
								setApi(`${SERVER_API_URL}${API_URL_MENUS}?menuType=${value}`);
						}}
					/>
				</View>
			</View>
			<FlatList data={items} renderItem={renderItem}></FlatList>
		</>
	);
};

export default Menus;

const styles = StyleSheet.create({
	mainView: {
		margin: 10,
		marginHorizontal: 15,
		padding: 10,
		backgroundColor: "white",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.36,
		shadowRadius: 6.68,

		elevation: 11,
	},
	itemView: {
		flexDirection: "row",
		alignItems: "center",
		marginVertical: 5,
	},
	text: {
		fontSize: 26,
		color: "green",
		textAlign: "center",
	},
	buttons: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-around",
		marginVertical: 20,
	},
	addButton: {
		// paddingLeft: 140,
		marginTop: 20,
	},
	label: {
		fontSize: 16,
		fontWeight: "bold",
	},
});

const pickerSelectStyles = StyleSheet.create({
	inputIOS: {
		fontSize: 12,
		marginHorizontal: 5,
		borderWidth: 1,
		borderColor: "gray",
		borderRadius: 4,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon
		padding: 5,
	},
	inputAndroid: {
		fontSize: 12,
		marginHorizontal: 5,

		borderWidth: 0.5,
		borderColor: "black",
		borderRadius: 8,
		color: "black",
		paddingRight: 30, // to ensure the text is never behind the icon

		padding: 5, // to ensure the text is never behind the icon
	},
});
