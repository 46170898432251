import React, { useContext } from "react";
import { View, Text, Image, StyleSheet, FlatList } from "react-native";
import {
	Card,
	ThemeProvider
} from "react-native-elements";
import { TouchableOpacity } from "react-native";
import axios from "axios";
import { Header } from "react-native-elements";
import { useFocusEffect } from "@react-navigation/native";
import {
    SERVER_API_URL,
	API_URL_CUSTOMER
} from "../../config/config";
import { UserContext } from "../../src/context/userContext";


export const customerView = ({ navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	const [customers, updateCustomers] = React.useState("");
	const [refresh, updateRefresh] = React.useState(false);

	const getCustomers = async () => {
		await axios
			.get(
				`${SERVER_API_URL}${API_URL_CUSTOMER}`, {
					headers: {
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => updateCustomers(res.data))
			.catch((err) => console.log(err));
	};
	const renderItem = ({ item }) => {
		console.log(item);
		return (
			<TouchableOpacity
				style={{ flex: 1 }}
				key={item.id}
				onPress={() =>
					navigation.navigate("CustomerDetails", {item})
				}>
				<Card style={{ flex: 1 }}>
					<Card.Title>{item.name}</Card.Title>
					<Card.Divider></Card.Divider>
					<Card.Title>{item.phoneNumber}</Card.Title>
				</Card>
			</TouchableOpacity>
		);
	};
	useFocusEffect(
		React.useCallback(() => {
			getCustomers();
			return () => {
				// Do something when the screen is unfocused
				// Useful for cleanup functions
			};
		}, [refresh])
	);

	return (
		<ThemeProvider>
			<View style={{ flex: 1 }}>
				<Header
					leftComponent={{
						icon: "menu",
						color: "#fff",
						onPress: () => navigation.openDrawer(),
					}}
					centerComponent={{ text: "CUSTOMERS", style: { color: "#fff" } }}
				>
				</Header>
				<FlatList data={customers} renderItem={renderItem} numColumns={2} />
			</View>
		</ThemeProvider>
	);
};
const styles = StyleSheet.create({
	text: {
		fontSize: 26,
		color: "green",
	},
	addButton: {
		flex: 1,
		paddingLeft: 140,
		paddingTop: 30,
	},
	CardText: {
		fontWeight: "bold",
		color: "red",
		fontSize: 15,
	},
	buttons: {
		flex: 1,
		flexDirection: "row",
		paddingLeft: 10,
	}
});
