import React, { useContext } from "react";
import { StyleSheet, Text, View, ScrollView, Platform } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Auth } from "aws-amplify";
import * as SecureStore from "expo-secure-store";
import { UserContext } from "../src/context/userContext";

const AdminScreen = ({ navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	console.log(state);
	let adminName = state.user.username;

	async function signOut() {
		if (Platform.OS !== "web") {
			SecureStore.deleteItemAsync("refreshTokens");
			SecureStore.deleteItemAsync("accessToken");
			SecureStore.deleteItemAsync("idToken");
			try {
				await Auth.signOut();
				dispatch({ type: "isLoggedIn", payload: false });
				navigation.navigate("Login");
			} catch (error) {
				console.log("error signing out: ", error);
			}
		} else {
			dispatch({ type: "isLoggedIn", payload: false });

			navigation.navigate("Login");
		}
	}

	return (
		<View style={styles.container}>
			<View style={styles.header}>
				<Text style={{ fontSize: 20 }}>
					Welcome
					<Text style={{ textTransform: "uppercase" }}> {adminName}</Text>{" "}
				</Text>
				<View style={{ flexDirection: "column" }}>
					<TouchableOpacity
						style={{
							backgroundColor: "orange",
							padding: 10,
							borderRadius: 50,
							marginTop: 10,
						}}
						onPress={() => signOut()}>
						<MaterialCommunityIcons
							name='logout'
							style={{ textAlign: "center" }}
							size={24}
							color='white'
						/>
					</TouchableOpacity>

					<Text
						style={{
							textAlign: "center",
							textTransform: "capitalize",
							fontSize: 14,
							// margin: 10,
							color: "black",
						}}>
						LogOut
					</Text>
				</View>
			</View>
			<ScrollView>
				<Text>Admin is here </Text>
			</ScrollView>
		</View>
	);
};

export default AdminScreen;

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
		alignItems: "center",
		justifyContent: "center",
	},
	header: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		padding: 10,
		paddingVertical: 5,
		backgroundColor: "#fff",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.36,
		shadowRadius: 6.68,
		elevation: 11,
		width: "100%",
		marginBottom: 20,
	},
});
