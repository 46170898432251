import {
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
	Image,
	Platform,
	Button,
} from "react-native";
import React, { useContext, useEffect, useRef, useState } from "react";
import { TextInput } from "react-native-paper";
import * as ImagePicker from "expo-image-picker";
import * as Notifications from "expo-notifications";
import Constants from "expo-constants";
import { UserContext } from "../src/context/userContext";

const SendNotification = () => {
	const { state, dispatch } = useContext(UserContext);

	const [title, setTitle] = useState("");
	const [image, setImage] = useState(null);
	const [message, setMessage] = useState("");
	const [expoPushToken, setExpoPushToken] = useState("");
	const [notification, setNotification] = useState(false);

	const notificationListener = useRef();
	const responseListener = useRef();
	Notifications.setNotificationHandler({
		handleNotification: async () => ({
			shouldShowAlert: true,
			shouldPlaySound: false,
			shouldSetBadge: false,
		}),
	});

	if (Platform.OS !== "web") {
		useEffect(() => {
			registerForPushNotificationsAsync().then((token) => {
				setExpoPushToken(token);
				dispatch({ type: "setExpoPushToken", payload: token });
			});

			notificationListener.current =
				Notifications.addNotificationReceivedListener((notification) => {
					setNotification(notification);
				});

			responseListener.current =
				Notifications.addNotificationResponseReceivedListener((response) => {
					console.log(response);
				});

			return () => {
				Notifications.removeNotificationSubscription(
					notificationListener.current
				);
				Notifications.removeNotificationSubscription(responseListener.current);
			};
		}, []);
	}
	async function registerForPushNotificationsAsync() {
		let token;
		if (Constants.isDevice) {
			const { status: existingStatus } =
				await Notifications.getPermissionsAsync();
			let finalStatus = existingStatus;
			if (existingStatus !== "granted") {
				const { status } = await Notifications.requestPermissionsAsync();
				finalStatus = status;
			}
			if (finalStatus !== "granted") {
				alert("Failed to get push token for push notification!");
				return;
			}
			token = (await Notifications.getExpoPushTokenAsync()).data;
			console.log(token);
		} else {
			alert("Must use physical device for Push Notifications");
		}

		if (Platform.OS === "android") {
			Notifications.setNotificationChannelAsync("default", {
				name: "default",
				importance: Notifications.AndroidImportance.MAX,
				vibrationPattern: [0, 250, 250, 250],
				lightColor: "#FF231F7C",
			});
		}

		return token;
	}
	async function sendPushNotification(expoPushToken) {
		const message = {
			to: expoPushToken,
			sound: "default",
			title: title,
			body: message,
			data: { image: image },
		};

		await fetch("https://exp.host/--/api/v2/push/send", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Accept-encoding": "gzip, deflate",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(message),
		});
	}

	const pickImage = async () => {
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.All,
			allowsEditing: true,
			aspect: [4, 3],
			quality: 1,
			allowsMultipleSelection: true,
		});
		if (!result.cancelled) {
			console.log("img is ", result.uri);
			setImage(result.uri);
		}
	};
	return (
		<View
			style={{
				padding: 20,
				paddingHorizontal: 10,
			}}>
			<TextInput
				style={styles.textInput}
				underlineColorAndroid='transparent'
				label='Title'
				autoCapitalize='none'
				onChangeText={(value) => {
					setTitle(value);
				}}
				theme={{ colors: { primary: "#136eb4" } }}
				value={title}
			/>
			<TextInput
				style={styles.textInput}
				underlineColorAndroid='transparent'
				label='Message'
				autoCapitalize='none'
				onChangeText={(value) => {
					setMessage(value);
				}}
				theme={{ colors: { primary: "#136eb4" } }}
				value={message}
				multiline
				numberOfLines={6}
			/>
			{image && (
				<Image
					source={{ uri: image }}
					style={{
						width: 150,
						height: 150,
						marginVertical: 20,
						resizeMode: "cover",
					}}
				/>
			)}
			<View style={{ flexDirection: "row", alignItems: "center" }}>
				<TouchableOpacity
					onPress={() => {
						pickImage();
					}}
					style={{
						backgroundColor: "#136eb4",
						padding: 10,
						borderRadius: 10,
						marginVertical: 10,
						marginRight: 10,
						width: 150,
					}}>
					<Text style={{ color: "white", textAlign: "center" }}>
						Upload image
					</Text>
				</TouchableOpacity>
				{image && (
					<TouchableOpacity
						onPress={() => {
							setImage(null);
						}}
						style={{
							backgroundColor: "crimson",
							padding: 10,
							borderRadius: 10,
							marginVertical: 10,
							marginRight: 10,
							width: 150,
						}}>
						<Text style={{ color: "white", textAlign: "center" }}>
							Remove image
						</Text>
					</TouchableOpacity>
				)}
			</View>
			<Button
				title='Press to Send Notification'
				onPress={async () => {
					await sendPushNotification(expoPushToken);
				}}
			/>
		</View>
	);
};

export default SendNotification;

const styles = StyleSheet.create({
	textInput: {
		backgroundColor: "white",

		marginBottom: 10,
		flexGrow: 1,
	},
});
