import React, { useContext } from "react";
import { View, Text, TouchableOpacity, StyleSheet, Alert } from "react-native";
import { Card, Button, Icon, Header } from "react-native-elements";
import axios from "axios";
import { NavigationContainer, useFocusEffect } from "@react-navigation/native";
import { SERVER_API_URL, API_URL_RESTAURANT } from "../../config/config";
import { UserContext } from "../../src/context/userContext";

export const restaurantInfo = ({ route, navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	console.log("route is ", route);

	const [details, updateDetails] = React.useState([]);
	let bannerImage = "";
	let logoImage = "";
	let showCollection;
	const getRestaurantInfo = async () => {
		await axios
			.get(`${SERVER_API_URL}${API_URL_RESTAURANT}/${route.params.id}`)
			.then((res) => {
				// setMedia(res.data.media);
				// console.log("media is ", media);
				// console.log(res.data.media.restaurantBanner);
				// bannerImage = res.data.media.restaurantBanner;
				// logoImage = res.data.media.restaurantLogo;
				console.log(res.data.collections);

				updateDetails(res.data);
				// console.log("bannerImage is", bannerImage);
			})
			.catch((err) => console.log(err));
	};
	const deleteRestaurant = async () => {
		alert(`${route.params.name}  is removed Sucessfully!`);
		await axios
			.delete(`${SERVER_API_URL}${API_URL_RESTAURANT}/${route.params.id}`, {
				headers: {
					Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				},
			})
			.then((res) => {
				console.log(res.data);
				navigation.navigate("Restaurants/Shops");
			})
			.catch((err) => console.log(err));
	};
	useFocusEffect(
		React.useCallback(() => {
			getRestaurantInfo();
			return () => {
				// Do something when the screen is unfocused
				// Useful for cleanup functions
			};
		}, [])
	);
	// console.log(typeof details.media);
	// console.log("details", details.media);

	// Object.keys(details.media).forEach(function (key) {
	// 	console.log("details media is ", details.media[key]);
	// });
	// console.log(Object.values(details.media));

	// console.log("desc is ", route.params.description);
	return (
		<View style={{ flex: 1 }} containerStyle={{ borderRadius: 20 }}>
			<Header
				centerComponent={{
					text: "Restaurant Details",
					style: { color: "#fff", fontSize: 20 },
				}}
				rightComponent={{
					text: "Back",
					style: { color: "#fff", fontSize: 20 },
					onPress: () => navigation.goBack(),
				}}></Header>
			<Card>
				<Card.Title>{details.name}</Card.Title>
				<Card.Divider></Card.Divider>
			</Card>
			<View style={styles.buttonContainer}>
				<TouchableOpacity
					style={{ flex: 1 }}
					onPress={() => {
						Alert.alert(
							"Deleting this Resturant will also remove all of Restaurant Information.",
							"Are you sure you want to delete this Restaurant?",
							[
								{
									text: "Cancel",
									onPress: () => console.log("Cancel Pressed"),
									style: "cancel",
								},
								{
									text: "OK",
									onPress: () => {
										deleteRestaurant();
									},
								},
							],
							{ cancelable: false }
						);
					}}>
					<Icon type='material-community' name='delete' size={24} color='red' />
					<Text style={{ fontWeight: "bold", textAlign: "center" }}>
						Delete
					</Text>
				</TouchableOpacity>
				<TouchableOpacity
					style={{ flex: 1 }}
					onPress={() =>
						navigation.navigate(
							"AddRestaurantGroup",

							// 	{
							// 	params: {
							// 		id: route.params.id,
							// 		name: details.name,
							// 		description: route.params.description,
							// 		collections: route.params.collections,
							// 	},
							// }

							{
								id: route.params.id,
								name: details.name,
								description: route.params.description,
								collections: route.params.collections,
								businessType: details.businessType,
								restaurantBanner: route.params.bannerImage,
								restaurantLogo: route.params.logoImage,
								showCollection: route.params.collections,
								primaryColorCode: route.params.primaryColorCode,
								secondaryColorCode: route.params.secondaryColorCode,
								deliveryRatePerKM: route.params.deliveryRatePerKM,
								masterSwitchOff: route.params.masterSwitchOff,
								minDeliveryCharges: route.params.minDeliveryCharges,
								businessSubType: route.params.businessSubType,
								type: "edit",
							}
						)
					}>
					<Icon
						type='material-community'
						name='pencil'
						size={24}
						color='green'
					/>
					<Text style={{ fontWeight: "bold", textAlign: "center" }}>Edit</Text>
				</TouchableOpacity>
			</View>
		</View>
	);
};
const styles = StyleSheet.create({
	buttonContainer: {
		flex: 1,
		flexDirection: "row",
		paddingBottom: 20,
	},
});
