import React, { useContext, useState } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	FlatList,
	StyleSheet,
	Button,
	ScrollView,
	Dimensions,
	ActivityIndicator,
} from "react-native";
import { Card, Overlays } from "react-native-elements";
import Entypo from "react-native-vector-icons/Entypo";
import Feather from "react-native-vector-icons/Feather";
import { Ionicons, AntDesign } from "@expo/vector-icons";

import axios from "axios";
import { NavigationContainer, useFocusEffect } from "@react-navigation/native";
import Icon from "react-native-vector-icons/MaterialIcons";
import {
	SERVER_API_URL,
	API_URL_MENU_GROUPS,
	API_URL_MENU_ITEMS,
} from "../../config/config";
import { UserContext } from "../../src/context/userContext";

const windowWidth = Dimensions.get("window").width;

export const MenuItem = ({ route, navigation }) => {
	const { state, dispatch } = useContext(UserContext);
	const [items, updateItems] = useState([]);
	const [focus, updateFocus] = useState(false);
	const [loading, setLoading] = useState(false);
	console.log("route is", route.params.params.businessType);
	const businessType = route.params.params.businessType;
	const id = route.params.params.id;
	const menuGroupId = route.params.params.menuGroupId;
	const restaurantId = route.params.params.restaurantId;
	const clientId = route.params.params.client;
	console.log(route);
	console.log("menu grp is", menuGroupId);

	const sortMenuItems = (data) => {
		let sortedGroup = data.sort((a, b) => a.priority - b.priority);

		updateItems(sortedGroup);
		setLoading(false);
	};
	const updatePriorityValue = async (currentItem, swappedItem) => {
		console.log("Current group ==", items[currentItem]);
		console.log("Previous group ==", items[swappedItem]);
		await axios
			.patch(
				`${SERVER_API_URL}${API_URL_MENU_ITEMS}/${items[currentItem].id}`,
				{
					priority: parseInt(items[swappedItem].priority, 10),
				},
				{
					headers: {
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) =>
				console.log("succesfully swapped current group priority", res.data)
			)
			.catch((err) => console.log(err));

		await axios
			.patch(
				`${SERVER_API_URL}${API_URL_MENU_ITEMS}/${items[swappedItem].id}`,
				{
					priority: parseInt(items[currentItem].priority, 10),
				},
				{
					headers: {
						Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
					},
				}
			)
			.then((res) => getItems())
			.catch((err) => console.log(err));

		console.log("called");
	};

	const getItems = async () => {
		setLoading(true);
		return await axios
			.get(`${SERVER_API_URL}${API_URL_MENU_GROUPS}${API_URL_MENU_ITEMS}`, {
				headers: {
					menugroupid: menuGroupId,
				},
			})
			.then((res) => {
				// console.log("data is ", res.data);
				// updateItems(res.data.items);
				sortMenuItems(res.data.items);
			})
			.catch((err) => {
				console.log("err is", err);
				setLoading(false);
			});
	};

	const deleteItem = async (id) => {
		console.log(id);
		await axios
			.delete(`${SERVER_API_URL}${API_URL_MENU_ITEMS}/${id}`, {
				headers: {
					Authorization: state?.user?.signInUserSession?.idToken["jwtToken"],
				},
			})
			.then(alert("Deleted"))
			.catch((err) => console.log(err));

		return updateFocus(!focus);
	};
	useFocusEffect(
		React.useCallback(() => {
			getItems();
			return () => {
				// Do something when the screen is unfocused
				// Useful for cleanup functions
			};
		}, [focus])
	);

	const image = (item) => {
		if (item.image == undefined) {
			return;
		} else {
			return (
				<Card.Image
					source={{ uri: item.images[0] }}
					resizeMode='cover'></Card.Image>
			);
		}
	};
	let menugroupId;
	const renderItem = ({ item, index }) => {
		menugroupId = item.id;
		return (
			<TouchableOpacity key={item.id}>
				<View style={styles.mainView}>
					{image(item)}

					<View style={styles.itemView}>
						<Text style={styles.label}>Name:</Text>
						<Text style={styles.subTitle}>{item.name}</Text>
					</View>

					<View style={styles.itemView}>
						<Text style={styles.label}>Description:</Text>
						<Text style={styles.subTitle}>{item.description}</Text>
					</View>

					<View style={styles.itemView}>
						<Text style={styles.label}>Priority:</Text>
						<Text style={styles.subTitle}>{item.priority}</Text>
					</View>
					<View style={styles.buttons}>
						<View style={{ flexDirection: "row" }}>
							<TouchableOpacity onPress={() => deleteItem(item.id)}>
								<Text>Delete</Text>
								<Ionicons name='trash-outline' size={30} color='red' />
							</TouchableOpacity>
							<TouchableOpacity
								style={{ marginLeft: 20 }}
								onPress={() =>
									navigation.navigate("createMenu", {
										id: route.params.params.id,
										item: item,
										type: "edit",
										businessType: businessType,
									})
								}>
								<Text>Edit</Text>
								<Entypo name='edit' size={30} color='green'></Entypo>
							</TouchableOpacity>
						</View>
						<View style={{ flexDirection: "row" }}>
							{index !== 0 && (
								<TouchableOpacity
									onPress={() => updatePriorityValue(index, index - 1)}
									style={{ marginRight: 5 }}>
									<AntDesign name='arrowup' size={30} color='green' />
								</TouchableOpacity>
							)}
							{index !== items.length - 1 && (
								<TouchableOpacity
									onPress={() => updatePriorityValue(index, index + 1)}>
									<AntDesign name='arrowdown' size={30} color='red' />
								</TouchableOpacity>
							)}
						</View>
					</View>
				</View>
			</TouchableOpacity>
		);
	};
	return (
		<View style={{ flex: 1 }}>
			<Card>
				<Text style={styles.text}>
					Restaurant Menu Item
					<TouchableOpacity
						style={styles.addButton}
						onPress={() =>
							navigation.navigate("createMenu", {
								menugroupId: menuGroupId,
								restaurantId: restaurantId,
								clientId: clientId,
								type: "add",
								businessType: businessType,
							})
						}>
						<Entypo name='plus' size={30} color='green' />
						<Text>Add</Text>
					</TouchableOpacity>
				</Text>
			</Card>
			{loading ? (
				<ActivityIndicator size='large' color='#00ff00' />
			) : (
				<FlatList data={items} renderItem={renderItem}></FlatList>
			)}
		</View>
	);
};
const styles = StyleSheet.create({
	buttonContainer: {
		flexDirection: "row",
	},
	buttonStyles1: {
		padding: 20,
	},
	buttonStyles2: {
		marginTop: 40,
	},
	text: {
		fontSize: 26,
		color: "green",
	},
	addButton: {
		paddingLeft: 140,
		paddingTop: 30,
	},
	textCard: {
		fontSize: 26,
		color: "green",
	},
	CardText: {
		fontWeight: "bold",
		color: "red",
		fontSize: 15,
	},
	buttons: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	mainView: {
		margin: 10,
		marginHorizontal: 15,
		padding: 10,
		backgroundColor: "white",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.36,
		shadowRadius: 6.68,
		elevation: 11,
	},
	label: {
		fontSize: 20,
		fontWeight: "bold",
		marginRight: 4,
	},
	subTitle: {
		fontSize: 16,
		fontWeight: "400",
	},
	itemView: {
		flexDirection: "row",
		marginBottom: 10,
		alignItems: "center",
	},
});
